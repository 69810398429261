import React from "react";
import { Image } from "@chakra-ui/react";
import { env } from "../config/index";

interface LogoComponentProps {}

export const LogoComponent: React.FC<LogoComponentProps> = (
  props: LogoComponentProps
) => {
  return <Image width={32} objectFit="cover" src={env.organizationLogo} />;
};
