import React, { useState } from "react";
import { useEffect } from "react";
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    useToast,
    Heading
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useResetPasswordMutation } from "../../../api";
import { PageCardComponent } from "../../core/components/page-card.component";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { DecorationImageComponent } from './decoration-image.component';

type resetPasswordFormValues = {
    token: string;
    password: string;
    confirmPassword: string;
};

const resetPasswordFormSchema = z.object({
    token: z.string(),
    password: z
        .string()
        .min(8, { message: "Password must be 8 or more characters long" }),

    confirmPassword: z
        .string()
        .min(8, { message: "Password must be 8 or more characters long" })
});
interface ResetPasswordComponetProps {

}

export const ResetPasswordComponet: React.FC<ResetPasswordComponetProps> = (props: ResetPasswordComponetProps) => {
    const navigate = useNavigate();

    const toast = useToast();
    const { userId } = useParams();

    const [resetPassword, resetPasswordResult] = useResetPasswordMutation();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<resetPasswordFormValues>({
        resolver: zodResolver(resetPasswordFormSchema)
    });

    const handleResetPassword = handleSubmit((formData) => {
        if (userId) {
            resetPassword({
                data: {
                    password: formData.password,
                    confirmPassword: formData.confirmPassword,
                    token: formData.token,
                    userId: userId

                }
            });

        }

        if (!resetPasswordResult.isSuccess) {
            toast({
                title: "Error",
                description: "Reset password fail try again",
                status: "error",
                position: "top",
                duration: 2000,
                isClosable: true
            });
            reset();
        }

    });

    useEffect(() => {
        if (resetPasswordResult.isSuccess) {
            toast({
                title: "Success",
                description: "Change password successfully",
                status: "success",
                position: "top",
                duration: 2000,
                isClosable: true
            });
            navigate("/login");
        }
    }, [resetPasswordResult, toast]);

    const [tooglePassword, setTooglePassword] = useState(false);

    const VisiblePassword = (props: {
        tooglePassword: boolean;
        setTooglePassword: typeof setTooglePassword;
    }) => {
        return (
            <InputRightElement
                cursor="pointer"
                onClick={() => setTooglePassword(!tooglePassword)}
                children={
                    tooglePassword ? (
                        <FaRegEye color="gray.300" />
                    ) : (
                        <FaRegEyeSlash color="gray.300" />
                    )
                }
            />
        );
    };
    return (
        <Stack>
            <form onSubmit={handleResetPassword}>
                <Stack>
                    <DecorationImageComponent />
                    <Heading fontSize={"2xl"}>Reset Password</Heading>
                </Stack>
                <Stack
                    direction={{ base: "column", xl: "row" }}
                    justify="space-between"
                    my="2"
                >
                    <PageCardComponent>
                        <FormControl
                            id="token"
                            isInvalid={errors.token ? true : false}
                        >
                            <FormLabel>Token</FormLabel>
                            <InputGroup>
                                <Input
                                    {...register("token")}
                                    placeholder="code"
                                    type="text"
                                />

                            </InputGroup>
                            <FormErrorMessage>{errors.token?.message}</FormErrorMessage>
                        </FormControl>
                    </PageCardComponent>
                </Stack>
                <Stack
                    direction={{ base: "column", xl: "row" }}
                    justify="space-between"
                    mb="2"
                >
                    <PageCardComponent>
                        <FormControl
                            id="oldPassword"
                            isInvalid={errors.password ? true : false}
                        >
                            <FormLabel>New Password</FormLabel>

                            <Input
                                {...register("password")}
                                placeholder="********"
                                type="password"
                            />
                            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                        </FormControl>
                    </PageCardComponent>
                </Stack>

                <Stack
                    direction={{ base: "column", xl: "row" }}
                    justify="space-between"
                    my="2"
                >
                    <PageCardComponent>
                        <FormControl
                            id="newPassword"
                            isInvalid={errors.confirmPassword ? true : false}
                        >
                            <FormLabel>Confirm Password</FormLabel>
                            <InputGroup>
                                <Input
                                    {...register("confirmPassword")}
                                    placeholder="********"
                                    type={tooglePassword ? "text" : "password"}
                                />
                                <VisiblePassword
                                    tooglePassword={tooglePassword}
                                    setTooglePassword={setTooglePassword}
                                />
                            </InputGroup>
                            <FormErrorMessage>{errors.confirmPassword?.message}</FormErrorMessage>
                        </FormControl>
                    </PageCardComponent>
                </Stack>

                <Button
                    width="100%"
                    colorScheme={"blue"}
                    variant={"solid"}
                    type="submit"
                >
                    Submit
                </Button>
            </form>
        </Stack>
    );
}