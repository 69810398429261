import React from "react";
import dayjs from "dayjs";
import {
  Document,
  Font,
  Image,
  Page,
  Text,
  View,
  StyleSheet
} from "@react-pdf/renderer";
import { env } from "../../../../config";
import { DailyProductReportResponse } from "../../../../api/type";
import { userCurrentRole } from "../../../../utils/common-functions";

interface CmchDailyReportPrintComponentProps {
  printData: DailyProductReportResponse;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  page: {
    padding: "0.75in",
    fontFamily: "Noto Serif Bengali"
  },
  organizationLogo: {
    width: "70px",
    objectFit: "contain",
    alignSelf: "center"
  },
  organizationName: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center"
  },
  header: {
    width: "100%",
    marginTop: 3,
    marginBottom: 10
  },
  headerContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    fontSize: 11
  },
  headerContentDetails: {
    width: "50%",
    border: "1px solid #dfdfdf",
    paddingHorizontal: 5,
    paddingVertical: 1
  },
  table: {
    width: "100%",
    borderLeft: "1px solid #dfdfdf",
    borderRight: "1px solid #dfdfdf",
    borderBottom: "1px solid #dfdfdf"
  },
  tableHeader: {
    borderTop: "1px solid #dfdfdf",
    backgroundColor: "#EBECF0",
    fontWeight: "semibold"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    border: "1px solid #dfdfdf",
    fontSize: 11,
    backgroundColor: "#EBECF0"
  },
  serial: {
    width: "8%",
    textAlign: "left",
    paddingLeft: 4,
    borderRight: "1px solid #dfdfdf"
  },
  productName: {
    width: "60%",
    textAlign: "left",
    paddingHorizontal: 5,
    borderRight: "1px solid #dfdfdf"
  },
  totalIn: {
    width: "16%",
    borderRight: "1px solid #dfdfdf"
  },
  totalOut: {
    width: "16%"
  },
  expandRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    fontSize: 8,
    marginBottom: 2
  },
  no: {
    width: "8%",
    paddingLeft: 5
  },
  from: {
    width: "27%",
    paddingHorizontal: 5
  },
  to: {
    width: "27%",
    paddingRight: 5
  },
  quantity: {
    width: "10%"
  },
  operationBy: {
    width: "20%"
  },
  time: {
    width: "8%",
    textAlign: "right",
    paddingRight: 5
  },
  note: {
    width: "100%",
    fontSize: 8,
    fontWeight: "normal"
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    fontSize: 6,
    color: "#666666",
    bottom: "0.50in",
    left: "0.75in",
    right: "0.75in",
    borderTop: "1px solid #000"
  },
  signature: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 11,
    fontWeight: "semibold",
    marginTop: 50
  },
  backgroundImage: {
    position: "absolute",
    top: "45%",
    width: "200px",
    opacity: 0.05,
    objectFit: "contain",
    alignSelf: "center"
  }
});

export const CmchDailyReportPrintComponent: React.FC<
  CmchDailyReportPrintComponentProps
> = (props: CmchDailyReportPrintComponentProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.backgroundImage} src={env.reportLogo} fixed />
        <View>
          <Image style={styles.organizationLogo} src={env.organizationLogo} />
          <Text style={styles.organizationName}>
            {env.organizationFullName}
          </Text>
          <View style={styles.header}>
            <View style={styles.headerContent}>
              <Text style={styles.headerContentDetails}>
                Report Type : Daily Product Report
              </Text>
              <Text style={styles.headerContentDetails}>
                Location Name : {props.printData.data.location.name}
              </Text>
            </View>
            <View style={styles.headerContent}>
              <Text style={styles.headerContentDetails}>
                Report Date :{" "}
                {dayjs(props.printData.data.startDate).format("DD MMMM, YYYY")}
              </Text>
              <Text style={styles.headerContentDetails}>
                Printout Date :{"  "}
                {dayjs().format("DD MMMM YYYY, hh:mm A")}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={[styles.tableHeader, styles.row]}>
            <Text style={styles.serial}>SL</Text>
            <Text style={styles.productName}>Product Name</Text>
            <Text style={styles.totalIn}>Total In</Text>
            <Text style={styles.totalOut}>Total Out</Text>
          </View>
          {props.printData.data.productOperationSummary.map(
            (operationDetails, i) => (
              <View key={i}>
                <View style={styles.row} key={i} wrap={false}>
                  <Text style={styles.serial}>{i + 1} </Text>
                  <Text style={styles.productName}>
                    {operationDetails.name + " "}
                  </Text>
                  <Text style={styles.totalIn}>
                    {operationDetails.inwardCount}
                  </Text>
                  <Text style={styles.totalOut}>
                    {operationDetails.outboundCount}
                  </Text>
                </View>
                <View>
                  <View style={styles.expandRow}>
                    <Text style={styles.no}>No.</Text>
                    <Text style={styles.from}>From</Text>
                    <Text style={styles.to}>To</Text>
                    <Text style={styles.quantity}>Quantity</Text>
                    <Text style={styles.operationBy}>Operation By</Text>
                    <Text style={styles.time}>Time</Text>
                  </View>
                  {operationDetails.operations.map((operation, id) =>
                    operation.notes &&
                    operation.notes !== null &&
                    operation.notes !== "" ? (
                      <View>
                        <View key={id} style={styles.expandRow} wrap={false}>
                          <Text style={styles.no}>{id + 1} </Text>
                          <Text style={styles.from}>
                            {operation.originLocation.name}
                          </Text>
                          <Text style={styles.to}>
                            {operation.destinationLocation.name}
                          </Text>
                          <Text style={styles.quantity}>
                            {operation.quantity}
                          </Text>
                          <Text style={styles.operationBy}>
                            {operation.createdBy.name}
                          </Text>
                          <Text style={styles.time}>
                            {dayjs(operation.createdAt).format("h:mm A")}
                          </Text>
                        </View>
                        <View>
                          <Text
                            style={[
                              {
                                fontSize: 8,
                                fontWeight: "semibold",
                                borderBottom: "1px solid #dfdfdf",
                                paddingHorizontal: 5,
                                paddingBottom: 2
                              }
                            ]}
                          >
                            Note :{" "}
                            <Text style={styles.note}>{operation.notes}</Text>
                          </Text>
                        </View>
                      </View>
                    ) : (
                      <View key={id} style={styles.expandRow} wrap={false}>
                        <Text style={styles.no}>{id + 1} </Text>
                        <Text style={styles.from}>
                          {operation.originLocation.name}
                        </Text>
                        <Text style={styles.to}>
                          {operation.destinationLocation.name}
                        </Text>
                        <Text style={styles.quantity}>
                          {operation.quantity}
                        </Text>
                        <Text style={styles.operationBy}>
                          {operation.createdBy.name}
                        </Text>
                        <Text style={styles.time}>
                          {dayjs(operation.createdAt).format("h:mm A")}
                        </Text>
                      </View>
                    )
                  )}
                </View>
              </View>
            )
          )}
        </View>
        <View style={styles.signature}>
          <View>
            <Text>Verified By,</Text>
          </View>
          <View>
            <Text>Authorized By,</Text>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View>
            <Text>Powered By Bindulogic Limited</Text>
          </View>
          <View>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
          <View>
            <Text>
              Printed By: {userCurrentRole() || "Unknown"},{" "}
              {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
