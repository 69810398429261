import React from "react";
import { ChangePasswordComponent } from '../components/change-password.component';


interface ChangePasswordPageProps { }

export const ChangePasswordPage: React.FC<ChangePasswordPageProps> = (
  props: ChangePasswordPageProps
) => {
  return (
    <>
      <ChangePasswordComponent />
    </>
  );
};
