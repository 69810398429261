import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { DashboardSkeletonComponent } from "../modules/core/components/dashboard-skeleton.component";
import { PageContainerComponent } from "../modules/core/components/page-container.component";
import { MissingPage } from "../modules/core/pages/missing.page";
import { AuthRouteComponent } from "./components/auth-route.component";
import { dashboardList } from "./dashboards/dashboard.route";
import { PrivateRouteComponent } from "./components/private-route.component";
import { publicRoutes } from "./public.route";
import { IPage } from "./route.type";
import SelectLocationPage from "../modules/auth/pages/select-location.page";

export const AppRoute: React.FC = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route element={<AuthRouteComponent />}>
        {publicRoutes.pages.map((page) => {
          return (
            <Route key={page.name} path={page.link} element={page.content} />
          );
        })}
      </Route>
      {/* All Dashboard Routes */}
      {dashboardList.map((dashboard) => {
        return (
          <Route
            key={dashboard.dashboardName}
            element={<PrivateRouteComponent role={dashboard.dashboardName} />}
          >
            <Route
              element={<DashboardSkeletonComponent dashboard={dashboard} />}
            >
              {dashboard.pages.map((page: IPage) => {
                return (
                  <Route
                    path={page.link}
                    key={page.name}
                    element={<PageContainerComponent page={page} />}
                  />
                );
              })}
            </Route>
          </Route>
        );
      })}
      {/* 404 Page */}{" "}
      <Route path="/select-location" element={<SelectLocationPage />} />
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="*" element={<MissingPage />} />
    </Routes>
  );
};
