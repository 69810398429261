//import jsPDF from '../../node_modules/jspdf-yworks/dist/jspdf.debug';
import jsPDF from "jspdf";
import moment from "moment";
import "svg2pdf.js";
import addFontBold from "../fonts/WorkSans-bold";
import addFontNormal from "../fonts/WorkSans-normal";
import address from "./partials/address";
import addressSender from "./partials/addressSender";
import footer from "./partials/footer";
import Heading from "./partials/heading";
import logo from "./partials/logo";
import table3Product from "./partials/table3Product";
import fetchSvg from "./utils/fetchSvg";
import addNotunBangleFontNormal from "../fonts/NotoSansBengali-normal";
import { fetchOrganizationLogo } from "../printInventory/partials/fetchDataFromUrl";

const printPDF = (report, ReportType = "dailyReport") => {
  addFontNormal();
  addFontBold();
  addNotunBangleFontNormal();



  const doc = new jsPDF("p", "pt");
  doc.setLanguage("bn");
  doc.vars = {};
  doc.vars.fontFamily = "WorkSans";
  doc.vars.fontWeightBold = "bold";
  doc.vars.fontWeightNormal = "normal";

  doc.setFont(doc.vars.fontFamily);

  // <><>><><>><>><><><><><>>><><<><><><><>
  // SETTINGS
  // <><>><><>><>><><><><><>>><><<><><><><>

  const fontSizes = {
    TitleFontSize: 12,
    SubTitleFontSize: 10,
    NormalFontSize: 8,
    SmallFontSize: 6
  };
  const lineSpacing = 12;

  let startY = 80; // bit more then 45mm

  const pageWidth = doc.internal.pageSize.width;
  const pageCenterX = pageWidth / 2;

  fetchOrganizationLogo().then((logo) => {
    doc.addImage(logo, "PNG", pageCenterX - 50 / 2, 25, 50, 50);
  });

  // <><>><><>><>><><><><><>>><><<><><><><>
  // COMPONENTS
  // <><>><><>><>><><><><><>>><><<><><><><>

  // <><>><><>><>><><><><><>>><><<><><><><>
  // Background init
  // need to print the background before other elements get printed on
  fetchSvg(`${process.env.REACT_APP_URL}/background.svg`).then(
    async ({ svg, width }) => {
      if (svg) {
        doc.setPage(1);

        doc.vars.bgImageWidth = width;
        doc.vars.bgImage = new XMLSerializer().serializeToString(svg);

        await doc.svg(svg, {
          x: pageCenterX - width / 2,
          y: 250
        });
      }

      // <><>><><>><>><><><><><>>><><<><><><><>
      // Sender's address

      startY = addressSender(
        doc,
        report,
        startY,
        fontSizes,
        lineSpacing,
        ReportType
      );

      const addressSvgLoaded = fetchSvg(
        `${process.env.REACT_APP_URL}/address-bar.svg`
      ).then(({ svg, width, height }) => {
        doc.setPage(1);

        const xOffset = 225;
        const scale = 0.45; // scaling for finer details

        doc.svg(svg, {
          x: xOffset,
          y: 86,
          width: width * scale,
          height: height * scale
        });
      });
      // <><>><><>><>><><><><><>>><><<><><><><>
      // Customer address

      startY += 5;
      startY = address(doc, report, startY, fontSizes, lineSpacing);

      // <><>><><>><>><><><><><>>><><<><><><><>
      // INVOICE DATA
      // <><>><><>><>><><><><><>>><><<><><><><>

      // <><>><><>><>><><><><><>>><><<><><><><>
      // Invoicenumber, -date and subject

      startY = Heading(doc, report, startY, fontSizes, lineSpacing, ReportType);

      startY = await table3Product(
        doc,
        startY,
        fontSizes.NormalFontSize,
        lineSpacing,
        report.data.productOperationSummary
      );

      footer(doc, fontSizes.SmallFontSize, lineSpacing);

      const pageNr = doc.internal.getNumberOfPages();

      // // <><>><><>><>><><><><><>>><><<><><><><>
      // Fold Marks

      const foldX = 12;
      const foldMarksY = [288, 411, 585];
      let n = 0;

      while (n < pageNr) {
        n++;

        doc.setPage(n);

        doc.setDrawColor(157, 183, 128);
        doc.setLineWidth(0.5);

        foldMarksY.map((valueY) => {
          doc.line(foldX, valueY, foldX + 23, valueY);
        });
      }

      // <><>><><>><>><><><><><>>><><<><><><><>
      // Logo

      //    const logoLoaded = logo(doc, pageNr);

      // <><>><><>><>><><><><><>>><><<><><><><>
      // Page Numbers

      if (pageNr > 1) {
        let n = 0;
        doc.setFontSize(fontSizes.SmallFontSize);

        while (n < pageNr) {
          n++;

          doc.setPage(n);

          doc.text(n + " / " + pageNr, pageWidth - 20, 10, "left");
        }
      }

      // <><>><><>><>><><><><><>>><><<><><><><>
      // PRINT
      // <><>><><>><>><><><><><>>><><<><><><><>

      Promise.all([addressSvgLoaded]).then(() => {
        if (ReportType === "dailyReport") {
          doc.save(
            `Daily_Report_${report.data.location.name}_${moment(
              report.data.startDate
            ).format("D-MMMM-YYYY")}.pdf`
          );
        } else {
          doc.save(
            `Monthly_Report_${report.data.location.name}_${moment(
              report.data.startDate
            ).format("D-MMMM-YYYY")}.pdf`
          );
        }
      });
    }
  );
};

export default printPDF;
