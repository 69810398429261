import React from "react";
import { Stack } from "@chakra-ui/react";
import VendorDemandComponent from "../components/vendor-demand.component";

interface VendorDemandPageProps {}

export const VendorDemandPage: React.FC<VendorDemandPageProps> = (
  props: VendorDemandPageProps
) => {
  return (
    <Stack>
      <VendorDemandComponent />
    </Stack>
  );
};
