import React from "react";
import dayjs from "dayjs";
import { InventoryPrintComponent } from "./inventory-print.component";
import { InventoyFilterGroup } from "../../report/types/stock-operation.type";
import { LocationInventoryFilterDataType } from "../../report/types/custom-report.type";
import { Button, useDisclosure } from "@chakra-ui/react";
import { PdfPrinterModal } from "../../core/components/pdf-printer-modal.component";

interface LocationInventoryPrintComponentProps {
  printProductData: LocationInventoryFilterDataType;
  filterBy: InventoyFilterGroup;
  searchKeyword: string;
  shouldHideZeroProducts: boolean;
}

export const LocationInventoryPrintComponent: React.FC<
  LocationInventoryPrintComponentProps
> = (props: LocationInventoryPrintComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = (
    <InventoryPrintComponent
      printData={props.printProductData}
      filterBy={props.filterBy}
      searchKeyword={props.searchKeyword}
      shouldHideZeroProducts={props.shouldHideZeroProducts}
    />
  );

  const inventoryReport = props.printProductData
    ? `Inventory-${props.printProductData.location.name}-${dayjs().format(
        "DD-MMM-YY"
      )}.pdf`
    : "";

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={inventoryReport}
      />
    </>
  );
};
