import React from "react";
import { SingleProductMovementResponse } from "../../../api/type";
import { SingleMovementByLocationPrintComponent } from "./single-movement-by-location-print.component";
import { Button, useDisclosure } from "@chakra-ui/react";
import { PdfPrinterModal } from "../../core/components/pdf-printer-modal.component";

interface SingleProductMovementByLocationPrintComponentProps {
  singleProductMovement: SingleProductMovementResponse;
}

export const SingleProductMovementByLocationPrintComponent: React.FC<
  SingleProductMovementByLocationPrintComponentProps
> = (props: SingleProductMovementByLocationPrintComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = (
    <SingleMovementByLocationPrintComponent
      singleProductMovement={props.singleProductMovement}
    />
  );
  const singleProductMovementReport = `Single Product Movement(By Location)-${props.singleProductMovement.data.product.name}.pdf`;

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={singleProductMovementReport}
      />
    </>
  );
};
