import React from "react";
import { Box, Heading, Stack } from "@chakra-ui/layout";
import { IPage } from "../../../routes/route.type";
import { PageTransitionComponent } from "./page-transition.component";
import { useDocumentTitle } from "../../../hooks/use-document-title.hook";
import { Navigate } from "react-router-dom";
import { FooterComponent } from "./footer.component";

interface Props {
  page: IPage;
}

export const PageContainerComponent: React.FC<Props> = (props: Props) => {
  useDocumentTitle(props.page.name + " | BinduHealth Inventory");

  const doesLocationExist = window.localStorage.getItem("location") !== null;

  if (!doesLocationExist) {
    return <Navigate to="/select-location" />;
  } else {
    return (
      <PageTransitionComponent>
        <Box p={{ base: 2, lg: 3 }}>
          <Stack spacing={2} minHeight="87vh">
            <Heading size="md" mb={2}>
              {props.page.name}
            </Heading>
            {props.page.content}
          </Stack>
          <FooterComponent />
        </Box>
      </PageTransitionComponent>
    );
  }
};
