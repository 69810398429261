import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer
} from "@chakra-ui/react";
interface AlreadyDistributedProductTableProps {
  distributedProducts: {
    productId: number;
    name: string;
    genericName?: string;
    unit: string;
    quantity: number;
  }[];
}

export const AlreadyDistributedProductTable: React.FC<
  AlreadyDistributedProductTableProps
> = (props: AlreadyDistributedProductTableProps) => {
  return (
    <TableContainer>
      <Table variant="striped" colorScheme="gray" fontSize={8} size="sm">
        <TableCaption>Already Distributed Products</TableCaption>
        <Thead>
          <Tr>
            <Th>Product ID</Th>
            <Th>Name</Th>
            <Th>Generic Name</Th>
            <Th isNumeric>Quantity</Th>
            <Th>Unit</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.distributedProducts.map((item) => {
            return (
              <Tr>
                <Td>{item.productId}</Td>
                <Td>{item.name}</Td>
                <Td>{item.genericName}</Td>
                <Td>{item.quantity}</Td>
                <Td>{item.unit}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
