import React, { Fragment, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Spacer,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { PageCardComponent } from "../../core/components/page-card.component";
import { CancelAlertPopupComponent } from "../../core/components/cancel-alert-popup.component";
import { ApproveAlertPopupComponent } from "../../core/components/approve-alert-popup.component";
import { userCurrentRole } from "../../../utils/common-functions";
import {
  useApproveTransferPendingOperationMutation,
  useApproveVendorPendingOperationMutation,
  useCancelTransferPendingOperationMutation,
  useCancelVendorPendingOperationMutation,
  useGetAllOperationDetailsQuery
} from "../../../api";
import { IoMdArrowRoundBack } from "react-icons/io";

interface TransferOperationDetailsProps {
  id: string | null;
  type: string | null;
}

const TransferOperationDetailsComponent: React.FC<
  TransferOperationDetailsProps
> = (props: TransferOperationDetailsProps) => {
  const navigate = useNavigate();
  const toast = useToast();
  const role = userCurrentRole();

  const approveModal = useDisclosure();
  const cancelModal = useDisclosure();
  const cancelRef = React.useRef();

  const { data } = useGetAllOperationDetailsQuery({
    data: { report: { operationId: Number(props.id) } }
  });

  const [approvePendingOperation, approvePendingOperationResult] =
    useApproveTransferPendingOperationMutation();

  const [cancelPendingOperation, cancelPendingOperationResult] =
    useCancelTransferPendingOperationMutation();

  const [approveVendorPendingOperation, approveVendorPendingOperationResult] =
    useApproveVendorPendingOperationMutation();

  const [cancelVendorPendingOperation, cancelVendorPendingOperationResult] =
    useCancelVendorPendingOperationMutation();

  const handleApprove = () => {
    if (data?.data.operationDetails.operationType === "TRANSFER") {
      approvePendingOperation({
        data: {
          stockOperation: {
            id: Number(data?.data.operationDetails.id)
          }
        }
      });
    } else {
      approveVendorPendingOperation({
        data: {
          stockOperation: {
            id: Number(data?.data.operationDetails.id)
          }
        }
      });
    }
  };

  if (approvePendingOperationResult.isSuccess) {
    navigate(`/${role}/report/location-operations`);
  }
  if (approveVendorPendingOperationResult.isSuccess) {
    navigate(`/${role}/report/location-operations`);
  }

  const handleEdit = (operationId: number) => {
    navigate(`/${role}/stock-operation/transfer/edit/${operationId}`);
  };

  const handleCancel = () => {
    if (data?.data.operationDetails.operationType === "TRANSFER") {
      cancelPendingOperation({
        data: {
          stockOperation: {
            id: Number(data?.data.operationDetails.id)
          }
        }
      });
    } else {
      cancelVendorPendingOperation({
        data: {
          stockOperation: {
            id: Number(data?.data.operationDetails.id)
          }
        }
      });
    }
  };

  if (cancelPendingOperationResult.isSuccess) {
    navigate(`/${role}/report/location-operations`);
  }
  if (cancelVendorPendingOperationResult.isSuccess) {
    navigate(`/${role}/report/location-operations`);
  }

  useEffect(() => {
    if (approvePendingOperationResult.isSuccess) {
      toast({
        title: "Success",
        description: "Approved Transfer operation successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
    if (cancelPendingOperationResult.isSuccess) {
      toast({
        title: "Success",
        description: "Cancelled Transfer operation successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
    if (approveVendorPendingOperationResult.isSuccess) {
      toast({
        title: "Success",
        description: "Approved Vendor Supply operation successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
    if (cancelVendorPendingOperationResult.isSuccess) {
      toast({
        title: "Success",
        description: "Cancelled Vendor Supply operation successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
  }, [
    approvePendingOperationResult.isSuccess,
    cancelPendingOperationResult.isSuccess,
    toast,
    approveVendorPendingOperationResult.isSuccess,
    cancelVendorPendingOperationResult.isSuccess
  ]);

  return (
    <Stack>
      <Flex justifyContent="end">
        <Button
          colorScheme="blue"
          leftIcon={<IoMdArrowRoundBack />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Flex>

      <Stack spacing={8} direction={{ base: "column", lg: "row" }}>
        <PageCardComponent>
          <Stack>
            <Table size={"sm"} variant={"unstyled"}>
              <Tr>
                <Th>Origin Location</Th>
                <Td>:</Td>
                <Td>{data?.data.operationDetails.originLocation?.name}</Td>
              </Tr>
              <Tr>
                <Th>Operation Type</Th>
                <Td>:</Td>
                <Td>{data?.data.operationDetails.operationType}</Td>
              </Tr>
              <Tr>
                <Th>Operation Status</Th>
                <Td>:</Td>
                <Td>{data?.data.operationDetails.operationStatus}</Td>
              </Tr>
              <Tr>
                <Th>Operation By</Th>
                <Td>:</Td>
                <Td>{data?.data.operationDetails.createdBy?.name}</Td>
              </Tr>
              <Tr>
                <Th>Phone</Th>
                <Td>:</Td>
                <Td>{data?.data.operationDetails.createdBy?.contact}</Td>
              </Tr>

            </Table>
          </Stack>
        </PageCardComponent>
        <PageCardComponent>
          <Stack>
            <Table size={"sm"} variant={"unstyled"}>
              <Tr>
                <Th>Destination Location</Th>
                <Td>:</Td>
                <Td>
                  {data?.data.operationDetails?.destinationLocation?.name}
                </Td>
              </Tr>
              <Tr>
                <Th>Date</Th>
                <Td>:</Td>
                <Td>
                  {" "}
                  {dayjs(data?.data.operationDetails.createdAt).format(
                    "ddd, MMM D, YYYY h:mm A"
                  )}
                </Td>
              </Tr>
              <Tr>
                <Th>Notes</Th>
                <Td>:</Td>
                <Td>{data?.data.operationDetails.notes}</Td>
              </Tr>
              <Tr>
                <Th>Operation ID</Th>
                <Td>:</Td>
                <Td fontWeight={700}>{data?.data.operationDetails.id}</Td>
              </Tr>
              <Tr>
                <Th> Invoice Number</Th>
                <Td>:</Td>
                <Td fontWeight={700}>{data?.data.operationDetails.invoiceNo}</Td>
              </Tr>

            </Table>
          </Stack>
        </PageCardComponent>
      </Stack>
      <PageCardComponent>
        <Flex alignItems="center">
          <Box>
            <Heading as="h1" size="sm">
              Operation Summary
            </Heading>
          </Box>
          <Spacer />
          <Box>
            {data?.data.operationDetails.operationStatus === "INITIALIZED" &&
              data?.data.operationDetails.operationType === "TRANSFER" && (
                <Fragment>
                  <Button colorScheme="red" mx={3} onClick={cancelModal.onOpen}>
                    Cancel
                  </Button>
                  <Button
                    colorScheme="blue"
                    mx={3}
                    onClick={() => handleEdit(data?.data.operationDetails.id)}
                  >
                    Edit
                  </Button>
                  <Button colorScheme="green" onClick={approveModal.onOpen}>
                    Approve
                  </Button>
                  <CancelAlertPopupComponent
                    cancelRef={cancelRef}
                    onClose={cancelModal.onClose}
                    isOpen={cancelModal.isOpen}
                    onOpen={cancelModal.onOpen}
                    handleCancel={handleCancel}
                  />
                  <ApproveAlertPopupComponent
                    cancelRef={cancelRef}
                    onClose={approveModal.onClose}
                    isOpen={approveModal.isOpen}
                    onOpen={approveModal.onOpen}
                    handleApprove={handleApprove}
                    status={approvePendingOperationResult}
                  />
                </Fragment>
              )}
            {data?.data.operationDetails.operationStatus === "INITIALIZED" &&
              data?.data.operationDetails.operationType === "VENDOR_SUPPLY" && (
                <Fragment>
                  <Button colorScheme="red" mx={3} onClick={cancelModal.onOpen}>
                    Cancel
                  </Button>
                  {/* <Button
                    colorScheme="blue"
                    mx={3}
                    onClick={() => handleEdit(data?.data.operationDetails.id)}
                  >
                    Edit
                  </Button> */}
                  <Button colorScheme="green" onClick={approveModal.onOpen}>
                    Approve
                  </Button>
                  <CancelAlertPopupComponent
                    cancelRef={cancelRef}
                    onClose={cancelModal.onClose}
                    isOpen={cancelModal.isOpen}
                    onOpen={cancelModal.onOpen}
                    handleCancel={handleCancel}
                  />
                  <ApproveAlertPopupComponent
                    cancelRef={cancelRef}
                    onClose={approveModal.onClose}
                    isOpen={approveModal.isOpen}
                    onOpen={approveModal.onOpen}
                    handleApprove={handleApprove}
                    status={approveVendorPendingOperationResult}
                  />
                </Fragment>
              )}
          </Box>
        </Flex>
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr bg="gray.700">
                <Th color="white">ID</Th>
                <Th color="white">Product Name</Th>
                <Th color="white">Tracking Number</Th>
                <Th color="white">Total Amount</Th>
                <Th color="white">Unit</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.data?.operationDetails?.stockOperationItems?.map(
                (data, key) => (
                  <Tr key={key}>
                    <Td>{key + 1}</Td>
                    <Td>{data.product.name}</Td>
                    <Td>{data.trackingNumber}</Td>
                    <Td>{data.quantity}</Td>
                    <Td>{data.product.unit}</Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </PageCardComponent>
    </Stack>
  );
};

export default TransferOperationDetailsComponent;
