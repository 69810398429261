import React from "react";
import { Stack, Flex } from "@chakra-ui/react";
import { ResetPasswordComponet } from '../components/reset-password.componet';
import { PageTransitionComponent } from "../../core/components/page-transition.component";


interface ResetPasswordPageProps {

}

export const ResetPasswordPage: React.FC<ResetPasswordPageProps> = (props: ResetPasswordPageProps) => {
    return (

        <PageTransitionComponent>
            <Stack
                minH={"100vh"}
                bgColor="white"
                borderRadius="md"
                p={4}
                spacing={4}
                direction={{ base: "column", md: "row" }}
            >
                <Flex flex={1} p={8} align="center" justify="center">
                    <ResetPasswordComponet />
                </Flex>
            </Stack>
        </PageTransitionComponent>

    );
}