import { Button, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { GetAnnualProductReportResponse } from "../../../api/type";
import { PdfPrinterModal } from "../../core/components/pdf-printer-modal.component";
import { PredictionReportPrintComponent } from "./prediction-report-print.component";

interface ProductPredictionReportPrintComponentProps {
  data: GetAnnualProductReportResponse;
}

export const ProductPredictionReportPrintComponent: React.FC<
  ProductPredictionReportPrintComponentProps
> = (props: ProductPredictionReportPrintComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = <PredictionReportPrintComponent data={props.data} />;

  const predictionReport = `Product-Prediction-${props.data.location.name}.pdf`;

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={predictionReport}
      />
    </>
  );
};
