import { FaClipboardList, FaServer, FaUserFriends } from "react-icons/fa";
import { IDashboard } from "../route.type";
import DashboardPage from "../../modules/dashboard/pages/dashboard.page";

import { TransferPage } from "../../modules/stock-operation/pages/transfer.page";
import { InitialInventoryPage } from "../../modules/stock-operation/pages/initial-inventory.page";
import { DemandInventoryPage } from "../../modules/stock-operation/pages/demand-inventory.page";

import { PendingOperationPage } from "../../modules/stock-operation/pages/pending-operation.page";

import { LogoComponent } from "../../branding/logo.component";
import { VendorSupplyPage } from "../../modules/stock-operation/pages/vendor-supply.page";
import { DistributionPage } from "../../modules/stock-operation/pages/distribution.page";
import { UserProfilePage } from "../../modules/user/pages/user-profile.page";
import LocationOperationsPage from "../../modules/report/pages/location-operations.page";
import LocationInventoryPage from "../../modules/report/pages/location-inventory.page";
import DailyProductReportPage from "../../modules/report/pages/daily-product-report.page";
import MonthlyProductReportPage from "../../modules/report/pages/monthly-product-report.page";
import OperationDetailsPage from "../../modules/report/pages/all-operation-details.page";
import { SingleProductMovementByDatePage } from "../../modules/report/pages/single-product-movement-by-date.page";
import { SingleProductMovementByLocationPage } from "../../modules/report/pages/single-product-movement-by-location.page";
import ApprovalOperationDetailsPage from "../../modules/stock-operation/pages/approval-operation-details.page";
import { AllNotificationComponent } from "../../modules/notification/component/all-notification.component";
import { LowStockPage } from "../../modules/dashboard/pages/low-stock.page";
import { ToBeExpiredPage } from "../../modules/dashboard/pages/to-be-expired.page";

export const distributorDashboard: IDashboard = {
  dashboardName: "DISTRIBUTOR",
  logo: <LogoComponent />,
  pages: [
    {
      name: "Items To Be expired",
      link: "/distributor/to-be-expired",
      content: <ToBeExpiredPage />
    },
    {
      name: "Low Stock Products",
      link: "/distributor/low-stock",
      content: <LowStockPage />
    },
    {
      name: "All Notification",
      link: "/distributor/notification-all",
      content: <AllNotificationComponent />
    },
    {
      name: "Dashboard",
      link: "/distributor/dashboard",
      content: <DashboardPage />
    },

    {
      name: "Transfer Operation",
      link: "/distributor/stock-operation/transfer/create",
      content: <TransferPage />
    },
    {
      name: "Pending Operation",
      link: "/distributor/stock-operation/pending-operation",
      content: <PendingOperationPage />
    },
    {
      name: "Initial Inventory",
      link: "/distributor/stock-operation/initial-inventory/crete",
      content: <InitialInventoryPage />
    },
    {
      name: "Vendor Supply",
      link: "/distributor/stock-operation/vendor-supply/create",
      content: <VendorSupplyPage />
    },
    {
      name: "Demand Inventory",
      link: "/distributor/stock-operation/demand/create",
      content: <DemandInventoryPage />
    },
    {
      name: "Distribution",
      link: "/distributor/distribution",
      content: <DistributionPage />
    },
    {
      name: "Profile",
      link: "/distributor/profile",
      content: <UserProfilePage />
    },
    {
      name: "Location Operations",
      link: "/distributor/report/location-operations",
      content: <LocationOperationsPage />
    },
    {
      name: "Location Inventory",
      link: "/distributor/report/location-inventory",
      content: <LocationInventoryPage />
    },

    {
      name: "Daily Product Report",
      link: "/distributor/report/daily-product-report",
      content: <DailyProductReportPage />
    },
    {
      name: "Monthly Product Report",
      link: "/distributor/report/monthly-product-report",
      content: <MonthlyProductReportPage />
    },
    {
      name: "Operation Details",
      link: "/distributor/report/operation-details/:id",
      content: <OperationDetailsPage />
    },
    {
      name: "Approval Operation Details",
      link: "/distributor/approval/operation-details",
      content: <ApprovalOperationDetailsPage />
    },
    {
      name: "Single Product Movement (By Date)",
      link: "/distributor/report/date-wise-product-movement",
      content: <SingleProductMovementByDatePage />
    },
    {
      name: "Single Product Movement (By Location)",
      link: "/distributor/report/single-product-movement",
      content: <SingleProductMovementByLocationPage />
    }
  ],

  sidebar: [
    {
      name: "Dashboard",
      link: "/distributor/dashboard",
      content: <DashboardPage />
    },

    {
      header: "Stock Operation",
      icon: FaServer,
      pages: [
        {
          name: "Distribution",
          link: "/distributor/distribution",
          content: <DistributionPage />
        },
        {
          name: "Transfer Operation",
          link: "/distributor/stock-operation/transfer/create",
          content: <TransferPage />
        },
        {
          name: "Initial Inventory",
          link: "/distributor/stock-operation/initial-inventory/crete",
          content: <InitialInventoryPage />
        },

        {
          name: "Demand Inventory",
          link: "/distributor/stock-operation/demand/create",
          content: <DemandInventoryPage />
        },

        {
          name: "Pending Operation",
          link: "/distributor/stock-operation/pending-operation",
          content: <PendingOperationPage />
        }
      ]
    },
    {
      header: "Report",
      icon: FaClipboardList,
      pages: [
        {
          name: "Location Operations",
          link: "/distributor/report/location-operations",
          content: <LocationOperationsPage />
        },
        {
          name: "Location Inventory",
          link: "/distributor/report/location-inventory",
          content: <LocationInventoryPage />
        },

        {
          name: "Daily Product Report",
          link: "/distributor/report/daily-product-report",
          content: <DailyProductReportPage />
        },
        {
          name: "Monthly Product Report",
          link: "/distributor/report/monthly-product-report",
          content: <MonthlyProductReportPage />
        },
        {
          name: "Single Product Movement (By Location)",
          link: "/distributor/report/single-product-movement",
          content: <SingleProductMovementByLocationPage />
        },
        {
          name: " Single Product Movement (By Date)",
          link: "/distributor/report/date-wise-product-movement",
          content: <SingleProductMovementByDatePage />
        }
      ]
    },
    {
      header: "Users",
      icon: FaUserFriends,
      pages: [
        {
          name: "Profile",
          link: "/distributor/profile",
          content: <UserProfilePage />
        }
      ]
    }
  ]
};
