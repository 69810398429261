import React, { useEffect } from "react";
import {
  AvatarBadge,
  Avatar,
  Stack,
  Menu,
  MenuButton,
  Box,
  Text,
  Icon,
  MenuList,
  MenuItem,
  Flex,
  Heading
} from "@chakra-ui/react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { PaginationState } from "@tanstack/react-table";
import { customPagination } from "../../../config";
import {
  useGetNotificationsQuery,
  useReadNotificationMutation
} from "../../../api";
import { useNavigate } from "react-router-dom";
import { userCurrentRole } from "../../../utils/common-functions";
import { notificationPartialLink } from "../../notification/types/notification.type";
interface NotificationComponentProps {}

export const NotificationComponent: React.FC<NotificationComponentProps> = (
  props: NotificationComponentProps
) => {
  const role = userCurrentRole();
  const [readNotification, readNotificationResult] =
    useReadNotificationMutation();
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: customPagination.pageIndex,
      pageSize: customPagination.pageSize
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );

  const { data, status, error, refetch } = useGetNotificationsQuery(
    {
      query: {
        pageIndex: pagination.pageIndex.toString(),
        pageSize: "10",
        isRead: "false"
      }
    },
    {
      pollingInterval: 2000000
    }
  );

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const viewAllClick = () => {
    navigate(`/${role}/notification-all`);
  };
  const notificationClick = (
    notificationId: string,
    entityType: string,
    entityId: string
  ) => {
    readNotification({
      notificationId: notificationId
    });
    navigate(`/${role}/${notificationPartialLink[entityType]}/${entityId}`);
  };
  return (
    <Stack direction="row" spacing={4}>
      <Menu>
        <MenuButton>
          <Box position={"relative"}>
            <Box
              bgColor={"red.500"}
              position={"absolute"}
              py="0.5"
              px="1"
              right="5"
              borderRadius={"50%"}
            >
              <Text fontSize={"xs"} color="white" fontWeight={"700"}>
                {data?.totalCount.unRead}
              </Text>
            </Box>
            <Icon as={IoMdNotificationsOutline} boxSize={8} />
          </Box>
        </MenuButton>
        <MenuList minWidth="340px" px={5}>
          <Flex justifyContent={"space-between"} pb={2}>
            <Text>Notification</Text>
            <Text
              onClick={() => {
                viewAllClick();
              }}
            >
              View All
            </Text>
          </Flex>

          <>
            {data?.listOfNotification?.map((notification) => {
              return (
                <MenuItem
                  onClick={() =>
                    notificationClick(
                      notification.id,
                      notification.entityType.toString(),
                      notification.entityId
                    )
                  }
                >
                  <Stack>
                    <Heading size="sm">{notification.title}</Heading>
                    <Text>{notification.description}</Text>
                  </Stack>
                </MenuItem>
              );
            })}
          </>
          <Flex justifyContent={"center"}>
            <Text
              onClick={() => {
                viewAllClick();
              }}
            >
              See More
            </Text>
          </Flex>
        </MenuList>
      </Menu>
    </Stack>
  );
};
