import { Box, Center, Image } from "@chakra-ui/react";
import { env } from "../../../config";
import { useNavigate } from 'react-router-dom';

export const DecorationImageComponent = () => {
  const navigate = useNavigate();
  return (
    <Center>
      <Box boxSize="100px">
        <Image
          alt="BinduHealth Inventory Cover Image"
          objectFit="cover"
          src={env.organizationLogo}
          onClick={() => {
            navigate("/");
          }}
        />
      </Box>
    </Center>
  );
};
