import React from "react";
import dayjs from "dayjs";
import { Button, useDisclosure } from "@chakra-ui/react";
import { GetLocationInventoryResponse } from "../../../api/type";
import { SubstorePrintComponent } from "./substore-print.component";
import { PdfPrinterModal } from "../../core/components/pdf-printer-modal.component";

interface SubstoreInventoryPrintComponentProps {
  data: GetLocationInventoryResponse;
}
export const SubstoreInventoryPrintComponent: React.FC<
  SubstoreInventoryPrintComponentProps
> = (props: SubstoreInventoryPrintComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = <SubstorePrintComponent data={props.data} />;

  const substoreReport = `Inventory-${
    props.data.data.location.name
  }-${dayjs().format("MMMM-YYYY")}.pdf`;

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={substoreReport}
      />
    </>
  );
};
