import React, { forwardRef, useEffect, useMemo, useState } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  useReactTable,
  FilterFn,
  ExpandedState,
  getExpandedRowModel,
  getPaginationRowModel,
  createColumnHelper
} from "@tanstack/react-table";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select as SelectPageSize,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { FaEye } from "react-icons/fa";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import {
  useGetAssignUserLocationsQuery,
  useGetLocationOperationsQuery
} from "../../../api";
import { Operations, segmentationType } from "../../../api/type";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./chakra-react-datepicker.css";
import { CalendarIcon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { Select } from "chakra-react-select";
import { Controller, useForm } from "react-hook-form";
import { OriginLocationGroup } from "../types/stock-operation.type";
import { customPagination, displayAllTablePage } from "../../../config";
import { PageCardComponent } from "../../core/components/page-card.component";
import {
  canSelectLocation,
  chakraStyles,
  userCurrentRole
} from "../../../utils/common-functions";
import { TableSearchInput } from "../../core/components/table-search-component";
import { LoaderComponent } from "../../core/components/loader.component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { endDate, startDate } from "../state/report.slice";
import { RetrieveQueryParams } from "../../../service/operation/retrieveQueryParams.service";
import { LocationOperationPrintButtonComponent } from "../../print/components/location-operation-print-button.component";

type LocationOperationFormValues = {
  originLocation: OriginLocationGroup;
};

const operationTypeOptions: { [k: string]: string[] } = {
  ALL: [
    "INITIAL_INVENTORY",
    "DISTRIBUTION",
    "TRANSFER",
    "DEMAND",
    "CONSUMPTION",
    "VENDOR_SUPPLY",
    "TRASH",
    "ADJUSTMENT",
    "VENDOR_DEMAND",
    "ALL"
  ],
  INBOUND: [
    "INITIAL_INVENTORY",
    "TRANSFER",
    "VENDOR_SUPPLY",
    "ADJUSTMENT",
    "ALL"
  ],
  OUTBOUND: [
    "DISTRIBUTION",
    "TRANSFER",
    "CONSUMPTION",
    "TRASH",
    "ADJUSTMENT",
    "ALL"
  ]
};

declare module "@tanstack/table-core" {
  interface FilterFns {
    tableFilter: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const tableFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank
  });
  return itemRank.passed;
};

interface LocationOperationsProps {}
const LocationOperationsComponent: React.FC<LocationOperationsProps> = (
  props: LocationOperationsProps
) => {
  let query = RetrieveQueryParams();
  const navigate = useNavigate();
  const role = userCurrentRole();
  let locationId = query.get("locationId")
    ? query.get("locationId")
    : localStorage.getItem("location");
  let selectedFilter =
    query.get("selectedSegmentaion") || segmentationType.OUTBOUND;
  const dispatch = useDispatch();
  const reportDate = useSelector((state: RootState) => state.report);

  let initialState = {
    label: "",
    value: Number(locationId)
  };

  const [originLocation, setOriginLocation] =
    useState<OriginLocationGroup>(initialState);

  const [selectedSegmentaion, setSelectedSegmentaion] =
    useState<string>(selectedFilter);
  const changeSelectFilter = (value: string) => {
    if (value === segmentationType.OUTBOUND) {
      setSelectedSegmentaion(segmentationType.OUTBOUND);
    } else if (value === segmentationType.INBOUND) {
      setSelectedSegmentaion(segmentationType.INBOUND);
    } else if (value === segmentationType.All) {
      setSelectedSegmentaion(segmentationType.All);
    }
    setSelectedOperationType("ALL");
  };

  const [selectedOperationType, setSelectedOperationType] =
    useState<string>("ALL");

  const changeSelectedOperationType = (data: any) => {
    setSelectedOperationType(data);
  };

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);

  const getLocationQuery = useGetAssignUserLocationsQuery({});

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(locationId))!
      );
    }
  }, [locationId, originLocationOptions]);

  const handleGetLocation = (data: any) => {
    setOriginLocation(data);
  };

  const { control } = useForm<LocationOperationFormValues>({});

  // const [dateStart, setDateStart] = useState(new Date());
  // const [dateEnd, setDateEnd] = useState(new Date());

  const { data, isLoading } = useGetLocationOperationsQuery({
    data: {
      report: {
        locationId: Number(originLocation?.value),
        startDate: reportDate.startDate,
        endDate: reportDate.endDate,
        filter: selectedSegmentaion,
        operationType: selectedOperationType
      }
    }
  });

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: customPagination.pageIndex,
    pageSize: customPagination.pageSize
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );

  const handleRowClick = (id: number) => {
    navigate(
      `/${role}/report/operation-details/${id}?locationId=${Number(
        originLocation?.value
      )}&selectedSegmentaion=${selectedSegmentaion}`
    );
  };

  const [globalFilter, setGlobalFilter] = useState("");
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const columnHelper = createColumnHelper<Operations>();
  const columns: ColumnDef<Operations, any>[] = [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      header: () => "ID",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.originLocation?.name, {
      id: "originLocation.name",
      header: () => "Origin Location",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.destinationLocation?.name, {
      id: "destinationLocation.name",
      header: () => "Destination Location",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.invoiceNo, {
      id: "invoiceNo",
      header: () => "Invoice No",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.operationType, {
      id: "operationType",
      header: () => "Operation Type",
      cell: (row) => {
        return (
          <Badge
            p={1}
            textAlign="center"
            width={{
              base: "100%",
              md: "100%",
              lg: "100%",
              xl: "100%",
              "2xl": "55%"
            }}
            borderRadius={5}
            variant="subtle"
            colorScheme="messenger"
          >
            {(row.getValue() === "INITIAL_INVENTORY" && "INITIAL INVENTORY") ||
              (row.getValue() === "END_USER_DISTRIBUTION" && "DISTRIBUTION") ||
              row.getValue()}
          </Badge>
        );
      }
    }),
    columnHelper.accessor((row) => row.operationStatus, {
      id: "operationStatus",
      header: () => "Operation Status",
      cell: (row) => (
        <Badge
          p={1}
          textAlign="center"
          width={{
            base: "100%",
            md: "100%",
            lg: "100%",
            xl: "100%",
            "2xl": "55%"
          }}
          borderRadius={5}
          variant="solid"
          colorScheme={
            (row.getValue() === "INITIALIZED" && "blue") ||
            (row.getValue() === "CANCELLED" && "red") ||
            (row.getValue() === "FINALIZED" && "green") ||
            ""
          }
        >
          {(row.getValue() === "INITIALIZED" && "PENDING APPROVAL") ||
            (row.getValue() === "FINALIZED" && "APPROVED") ||
            (row.getValue() === "CANCELLED" && "REFUSED")}
        </Badge>
      )
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: ({ row, table }) => {
        return (
          <>
            <Button
              mx={3}
              leftIcon={<FaEye />}
              color="messenger.400"
              size={"sm"}
              colorScheme={"messenger"}
              variant={"outline"}
              onClick={() => handleRowClick(row.original.id)}
            >
              View
            </Button>
          </>
        );
      }
    })
  ];
  const table = useReactTable({
    data: data ? data.data.operations : [],
    columns: columns,
    filterFns: {
      tableFilter
    },
    state: {
      pagination,
      globalFilter,
      expanded
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    globalFilterFn: tableFilter,
    debugTable: true
  });

  const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
    <Input
      w={60}
      autoComplete="off"
      background="white"
      value={value}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
    />
  );
  customDateInput.displayName = "DateInput";

  const CustomInput = forwardRef(customDateInput);
  function dateStartHandler(value: any) {
    dispatch(startDate(value.toISOString()));
  }
  function dateEndHandler(value: any) {
    dispatch(endDate(value.toISOString()));
  }
  const icon = <CalendarIcon fontSize="sm" />;
  return (
    <Stack>
      <Stack direction={{ base: "column", xl: "row" }} justify="space-between">
        <PageCardComponent>
          <Controller
            control={control}
            name="originLocation"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { error }
            }) => (
              <FormControl mb="3" isInvalid={!!error} id="originLocation">
                <FormLabel>Select Location</FormLabel>
                <Select
                  chakraStyles={chakraStyles}
                  name={name}
                  ref={ref}
                  onChange={(location) => handleGetLocation(location)}
                  onBlur={onBlur}
                  isDisabled={!canSelectLocation(role)}
                  value={originLocation}
                  options={originLocationOptions}
                  placeholder="Select location"
                  closeMenuOnSelect={true}
                />
                <FormErrorMessage>{error && error.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </PageCardComponent>
      </Stack>
      <Stack direction={{ base: "column", xl: "row" }} width="100%" mb="2">
        <Box width="full">
          <TableSearchInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          />
        </Box>

        <Box width="full">
          <SelectPageSize
            borderColor="#2B6CB0"
            color="#2D3748"
            onChange={(e) => changeSelectFilter(e.target.value)}
            defaultValue={selectedSegmentaion}
          >
            <option value={segmentationType.OUTBOUND}>Outgoing</option>
            <option value={segmentationType.INBOUND}>Incoming</option>
            <option value={segmentationType.All}>All</option>
          </SelectPageSize>
        </Box>

        <Box width="full">
          <SelectPageSize
            borderColor="#2B6CB0"
            color="#2D3748"
            onChange={(e) => changeSelectedOperationType(e.target.value)}
            defaultValue={selectedOperationType}
            value={selectedOperationType}
          >
            {operationTypeOptions[selectedSegmentaion].map((op) => (
              <option value={op}>{op}</option>
            ))}
          </SelectPageSize>
        </Box>

        <Spacer />
        <Box>
          <Flex width="full" align="center" justifyContent="center">
            <InputGroup className="dark-theme">
              <Flex align="center" justifyContent="center">
                <Text mx={3}>From: </Text>
                <DatePicker
                  id="dateStartEnd"
                  selected={new Date(reportDate.startDate)}
                  onChange={dateStartHandler}
                  dateFormat="dd MMM yyyy"
                  className="react-datapicker__input-text"
                  placeholderText="Select Date"
                  customInput={<CustomInput />}
                  showDisabledMonthNavigation
                />
              </Flex>
              <InputRightElement color="gray.500" children={icon} />
            </InputGroup>
            <InputGroup className="dark-theme">
              <Flex align="center" justifyContent="center">
                <Text mx={4}>To: </Text>
                <DatePicker
                  id="dateStartEnd"
                  selected={new Date(reportDate.endDate)}
                  onChange={dateEndHandler}
                  dateFormat="dd MMM yyyy"
                  className="react-datapicker__input-text"
                  placeholderText="Select Date"
                  customInput={<CustomInput />}
                  showDisabledMonthNavigation
                />
              </Flex>
              <InputRightElement color="gray.500" children={icon} />
            </InputGroup>
          </Flex>
        </Box>
        {/* <ButtonGroup gap="2">
          <Button colorScheme="blue">Export CSV</Button>
        </ButtonGroup> */}
        <Box>
          {data && <LocationOperationPrintButtonComponent data={data} />}
        </Box>
      </Stack>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <Table variant="striped">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      bg="gray.700"
                      color="white"
                    >
                      {header.isPlaceholder ? null : (
                        <Box>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Box>
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}

      <Stack
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
        alignItems="center"
      >
        <Box width={{ base: "100%", lg: "60%" }}>
          <Text fontSize="lg" fontWeight="bold">
            Showing (Page {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()})
          </Text>{" "}
        </Box>

        <Stack
          direction="row"
          width={{ base: "100%", lg: "40%" }}
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            mt="2"
            width={{ base: "100%", md: "100%", lg: "100%" }}
          >
            <Button
              colorScheme="blue"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {"<<"}
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {">"}
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {">>"}
            </Button>{" "}
          </Stack>

          <Box width={{ base: "100%", md: "100%", lg: "100%" }}>
            <SelectPageSize
              mt="2"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {displayAllTablePage.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </SelectPageSize>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LocationOperationsComponent;
