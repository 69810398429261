import React from "react";
import { Button, useDisclosure } from "@chakra-ui/react";
import { GetToBeExpiredItemsResponse } from "../../../api/type";
import { PdfPrinterModal } from "../../core/components/pdf-printer-modal.component";
import { ToBeExpiredReportPrintComponent } from "../components/to-be-expired-report-print.component";

interface ToBeExpiredReportPrintPageProps {
  expiredItems: GetToBeExpiredItemsResponse;
}

export const ToBeExpiredReportPrintPage: React.FC<
  ToBeExpiredReportPrintPageProps
> = (props: ToBeExpiredReportPrintPageProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = (
    <ToBeExpiredReportPrintComponent expiredItems={props.expiredItems} />
  );

  const tobeExpiredList = `To Be Expired List.pdf`;

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={tobeExpiredList}
      />
    </>
  );
};
