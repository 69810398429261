import { motion } from "framer-motion";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const animations = {
  initial: {
    opacity: 0,
    y: -50
  },
  animate: {
    opacity: 1,
    y: 0
  },
  exit: {
    opacity: 0,
    y: 50
  }
};

export const PageTransitionComponent = (props: Props) => {
  return (
    <motion.div
      variants={animations}
      // transition={{
      //   duration: 0.2,
      // }}
      // initial="initial"
      // animate="animate"
      // exit="exit"
    >
      {props.children}
    </motion.div>
  );
};
