import React from "react";
import { VendorSupplyComponent } from "../components/vendor-supply.component";
import { CmchVendorSupplyComponent } from '../components/cmch/cmch-vendor-supply';
import { env } from '../../../config/index';
import { allUIType } from "../../../api/type";

interface VendorSupplyPageProps { }

export const VendorSupplyPage: React.FC<VendorSupplyPageProps> = (
  props: VendorSupplyPageProps
) => {
  return (
    <div>
      {
        env.uiType === allUIType.CMCH ? < CmchVendorSupplyComponent /> : <VendorSupplyComponent />
      }

    </div>
  );
};
