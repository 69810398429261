import React from "react";
import { AdjustmentReportComponent } from "../components/adjustment-report.component";

interface AdjustmentReportPageProps {}

export const AdjustmentReportPage: React.FC<AdjustmentReportPageProps> = (
  props: AdjustmentReportPageProps
) => {
  return (
    <div>
      <AdjustmentReportComponent />
    </div>
  );
};
