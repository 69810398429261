import React from "react";
import { LocationOperationsResponse } from "../../../api/type";
import { Button, useDisclosure } from "@chakra-ui/react";
import { GetAnnualProductReportResponse } from "../../../api/type";
import { PdfPrinterModal } from "../../core/components/pdf-printer-modal.component";
import { LocationOperationPrintComponent } from "./location-operation-print.component";
interface LocationOperationPrintButtonComponentProps {
  data: LocationOperationsResponse;
}

export const LocationOperationPrintButtonComponent: React.FC<
  LocationOperationPrintButtonComponentProps
> = (props: LocationOperationPrintButtonComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = <LocationOperationPrintComponent data={props.data} />;

  const predictionReport = `${props.data.data.location.name}-operation-report.pdf`;

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={predictionReport}
      />
    </>
  );
};
