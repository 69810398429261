import React from "react";
import { AnnualProductReportComponent } from "../components/annual-product-report.component";

interface AnnualProductReportPageProps {}

export const AnnualProductReportPage: React.FC<AnnualProductReportPageProps> = (
  props: AnnualProductReportPageProps
) => {
  return (
    <>
      <AnnualProductReportComponent />
    </>
  );
};
