import { Stack, Box} from "@chakra-ui/react";
import DashboardComponent from "../components/dashboard.component";

const DashboardPage = () => {
  return (
    <Box>
      <Stack spacing={4}>
        <DashboardComponent />
      </Stack>
    </Box>
  );
};

export default DashboardPage;
