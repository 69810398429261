import {
  Text,
  StyleSheet,
  Document,
  Font,
  Page,
  View,
  Image
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { GetOperationPrintDataResponse } from "../../../api/type";
import { env } from "../../../config/index";
import { userCurrentRole } from "../../../utils/common-functions";

interface OperationPrintComponentProps {
  printData: GetOperationPrintDataResponse;
}

Font.register({
  family: "Noto Serif Bengali",
  fonts: [
    {
      src: "/fonts/NotoSerifBengali-Regular.ttf"
    },
    {
      src: "/fonts/NotoSerif-SemiBold.ttf",
      fontWeight: "semibold"
    },
    {
      src: "/fonts/NotoSerifBengali-Bold.ttf",
      fontWeight: "bold"
    }
  ]
});

const styles = StyleSheet.create({
  body: {
    padding: "0.75in",
    fontFamily: "Noto Serif Bengali",
    position: "relative"
  },
  backgroundImage: {
    position: "absolute",
    top: "45%",
    width: "200px",
    opacity: 0.05,
    objectFit: "contain",
    alignSelf: "center"
  },
  heading: {
    fontSize: 9,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  organizationLogo: {
    width: "60px",
    objectFit: "contain",
    alignSelf: "center"
  },
  organizationName: {
    fontSize: 12,
    textAlign: "center",
    fontWeight: "semibold"
  },
  reportName: {
    fontSize: 10,
    textAlign: "center",
    fontWeight: "semibold"
  },
  header: {
    width: "100%",
    marginTop: 5,
    marginBottom: 10
  },
  headerTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    fontSize: 9
  },
  headerDetails: {
    width: "50%",
    border: "1px solid #dfdfdf",
    paddingHorizontal: 5,
    paddingVertical: 1
  },
  headerNotes: {
    width: "100%",
    border: "1px solid #dfdfdf",
    paddingHorizontal: 5,
    paddingVertical: 1
  },
  table: {
    width: "100%",
    borderLeft: "1px solid #dfdfdf",
    borderRight: "1px solid #dfdfdf"
  },
  tableHeader: {
    fontSize: 9,
    fontWeight: "semibold",
    borderTop: "1px solid #dfdfdf",
    backgroundColor: "#EBECF0"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 9,
    borderBottom: "1px solid #dfdfdf"
  },
  serial: {
    width: "8%",
    borderRight: "1px solid #dfdfdf"
  },
  productName: {
    width: "42%",
    textAlign: "left",
    paddingHorizontal: 5,
    borderRight: "1px solid #dfdfdf"
  },
  quantity: {
    width: "10%",
    borderRight: "1px solid #dfdfdf"
  },
  unit: {
    width: "10%",
    borderRight: "1px solid #dfdfdf"
  },
  trackingType: {
    width: "15%",
    borderRight: "1px solid #dfdfdf"
  },
  trackingId: {
    width: "15%"
  },
  signature: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 9,
    fontWeight: "semibold",
    marginTop: 50
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    fontSize: 6,
    color: "#666666",
    bottom: "0.50in",
    left: "0.75in",
    right: "0.75in",
    borderTop: "1px solid #000"
  }
});

export const OperationPrintComponent: React.FC<OperationPrintComponentProps> = (
  props: OperationPrintComponentProps
) => {
  const { operationDetails } = props.printData.data;
  const { operationType, prescription, destinationLocation } = operationDetails;
  let destinationName;
  if (operationType === "DISTRIBUTION") {
    destinationName = prescription?.id
      ? prescription.patient?.name || "Unknown"
      : "Unknown";
  } else {
    destinationName = destinationLocation?.name || "";
  }

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <Image style={styles.backgroundImage} src={env.reportLogo} fixed />
        <View style={styles.heading}>
          <Image style={styles.organizationLogo} src={env.organizationLogo} />
          <Text style={styles.organizationName}>
            {env.organizationFullName}
          </Text>
          {operationType === "TRANSFER" && (
            <Text style={styles.reportName}>Transfer Operation Report</Text>
          )}
          {operationType === "INITIAL_INVENTORY" && (
            <Text style={styles.reportName}>
              Initial Inventory Operation Report
            </Text>
          )}
          {operationType === "VENDOR_SUPPLY" && (
            <Text style={styles.reportName}>
              Vendor Supply Operation Report
            </Text>
          )}
          {operationType === "DISTRIBUTION" && (
            <Text style={styles.reportName}>Distribution Operation Report</Text>
          )}
          {operationType === "CONSUMPTION" && (
            <Text style={styles.reportName}>Consumption Operation Report</Text>
          )}
          {operationType === "TRASH" && (
            <Text style={styles.reportName}>Trash Operation Report</Text>
          )}
          {operationType === "ADJUSTMENT" && (
            <Text style={styles.reportName}>Adjustment Operation Report</Text>
          )}
          {operationType === "DEMAND" && (
            <Text style={styles.reportName}>Demand Operation Report</Text>
          )}
          {operationType === "VENDOR_DEMAND" && (
            <Text style={styles.reportName}>
              Vendor Demand Operation Report
            </Text>
          )}
          <View style={styles.header}>
            <View style={styles.headerTable}>
              <Text style={styles.headerDetails}>
                Origin Location :{" "}
                {props.printData.data.operationDetails.originLocation?.name}
              </Text>
              <Text style={styles.headerDetails}>
                Destination Location : {destinationName}
              </Text>
            </View>
            <View style={styles.headerTable}>
              <Text style={styles.headerDetails}>
                Operation Type :{" "}
                {props.printData.data.operationDetails.operationType}
              </Text>
              <Text style={styles.headerDetails}>
                Date :{" "}
                {moment(props.printData.data.operationDetails.createdAt).format(
                  "D MMMM YYYY, LT"
                )}
              </Text>
            </View>

            <View style={styles.headerTable}>
              <Text style={styles.headerDetails}>
                Operation By :{" "}
                {props.printData.data.operationDetails.createdBy.name}
              </Text>
              <Text style={styles.headerDetails}>
                Operation ID : {props.printData.data.operationDetails.id}
              </Text>
            </View>

            <View style={styles.headerTable}>
              <Text style={styles.headerDetails}>
                Mobile No :{" "}
                {props.printData.data.operationDetails.createdBy.contact}
              </Text>
              <Text style={styles.headerDetails}>
                Invoice Number :{" "}
                {props.printData.data.operationDetails.invoiceNo}
              </Text>
            </View>

            <View style={styles.headerTable}>
              <Text style={styles.headerNotes}>
                Notes : {props.printData.data.operationDetails.notes}
              </Text>
            </View>
          </View>
          {props.printData.data.operationDetails.operationType ===
            "DISTRIBUTION" &&
            props.printData.data.operationDetails.prescription && (
              <View style={styles.header}>
                <View style={styles.headerTable}>
                  <Text style={styles.headerDetails}>
                    PRESCRIPTION ID :{" "}
                    {props.printData.data.operationDetails.prescription?.id}
                  </Text>
                  <Text style={styles.headerDetails}>
                    PRESCRIPTION TYPE :{" "}
                    {props.printData.data.operationDetails.prescription?.type}
                  </Text>
                  <Text style={styles.headerDetails}>
                    PATIENT NAME :{" "}
                    {
                      props.printData.data.operationDetails.prescription
                        ?.patient?.name
                    }
                  </Text>
                  <Text style={styles.headerDetails}>
                    CONTACT NUMBER :{" "}
                    {
                      props.printData.data.operationDetails.prescription
                        ?.patient?.contactNumber
                    }
                  </Text>
                </View>
              </View>
            )}
        </View>
        <View style={styles.table}>
          <View style={[styles.tableHeader, styles.row]} fixed>
            <Text style={styles.serial}>Serial</Text>
            <Text style={styles.productName}>Product Name</Text>
            <Text style={styles.quantity}>Quantity</Text>
            <Text style={styles.unit}>Unit</Text>
            <Text style={styles.trackingType}>Tracking Type</Text>
            <Text style={styles.trackingId}>Tracking ID</Text>
          </View>
          {props.printData.data.operationDetails.stockOperationItems.map(
            (row, i) => (
              <View key={i} style={styles.row} wrap={false}>
                <Text style={styles.serial}>{i + 1} </Text>
                <Text style={styles.productName}>{row.product.name + " "}</Text>
                <Text style={styles.quantity}>{row.quantity}</Text>
                <Text style={styles.unit}>{row.product.unit}</Text>
                <Text style={styles.trackingType}>
                  {row.product.trackingType}
                </Text>
                <Text style={styles.trackingId}>
                  {row.trackingNumber || "-"}
                </Text>
              </View>
            )
          )}
        </View>
        <View style={styles.signature}>
          <View>
            <Text>Verified By,</Text>
          </View>
          <View>
            <Text>Authorized By,</Text>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View>
            <Text>Powered By Bindulogic Limited</Text>
          </View>
          <View>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
          <View>
            <Text>
              Printed By: {userCurrentRole() || "Unknown"},{" "}
              {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
