import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button
} from "@chakra-ui/react";
import React from "react";

interface ApproveAlertPopupComponentProps {
  cancelRef: any;
  isOpen: any;
  onOpen: any;
  onClose: any;
  handleApprove: any;
  status: any;
}

export const ApproveAlertPopupComponent: React.FC<
  ApproveAlertPopupComponentProps
> = (props: ApproveAlertPopupComponentProps) => {
  return (
    <div>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={props.cancelRef}
        onClose={props.onClose}
        isOpen={props.isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Confirmation</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to Approve the operation ?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={props.onClose}>No</Button>
            <Button
              colorScheme="green"
              ml={3}
              isLoading={props.status.isLoading ? true : false}
              onClick={props.handleApprove}
            >
              Yes, Approve
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
