import React from "react";
import dayjs from "dayjs";
import { env } from "../../../config/index";
import {
  StyleSheet,
  Document,
  Font,
  Page,
  View,
  Image,
  Text
} from "@react-pdf/renderer";
import { GetLocationInventoryResponse } from "../../../api/type";
import { userCurrentRole } from "../../../utils/common-functions";

interface SubstorePrintComponentProps {
  data: GetLocationInventoryResponse;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  page: {
    padding: "0.75in",
    fontFamily: "Noto Serif Bengali",
    position: "relative"
  },
  logo: {
    width: "70px",
    objectFit: "contain",
    alignSelf: "center"
  },
  organizationName: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center"
  },
  header: {
    width: "100%",
    marginTop: 3,
    marginBottom: 5
  },
  headerContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    fontSize: 11
  },
  headerContentDetails: {
    width: "50%",
    border: "1px solid #dfdfdf",
    paddingHorizontal: 5,
    paddingVertical: 1
  },
  backgroundImage: {
    position: "absolute",
    top: "45%",
    width: "200px",
    opacity: 0.05,
    objectFit: "contain",
    alignSelf: "center"
  },
  table: {
    width: "100%",
    marginTop: 5,
    borderLeft: "1px solid #dfdfdf",
    borderRight: "1px solid #dfdfdf"
  },
  tableHeader: {
    backgroundColor: "#EBECF0",
    fontWeight: "semibold"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 11,
    textAlign: "center",
    borderBottom: "1px solid #dfdfdf"
  },
  serial: {
    width: "10%",
    borderRight: "1px solid #dfdfdf"
  },
  productName: {
    width: "60%",
    borderRight: "1px solid #dfdfdf",
    textAlign: "left",
    paddingHorizontal: 10
  },
  quantity: {
    width: "15%",
    textAlign: "left",
    paddingHorizontal: 10,
    borderRight: "1px solid #dfdfdf"
  },
  unit: {
    width: "15%"
  },
  expandTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    fontSize: 8,
    backgroundColor: "#EBECF0",
    borderBottom: "1px solid #dfdfdf"
  },
  no: {
    width: "10%",
    textAlign: "center",
    borderRight: "1px solid #dfdfdf"
  },
  trackingId: {
    width: "20%",
    textAlign: "left",
    paddingHorizontal: 10,
    borderRight: "1px solid #dfdfdf"
  },
  manufactureDate: {
    width: "20%",
    paddingHorizontal: 5,
    borderRight: "1px solid #dfdfdf"
  },
  expiryDate: {
    width: "20%",
    paddingHorizontal: 5,
    borderRight: "1px solid #dfdfdf"
  },
  singleQuantity: {
    width: "30%",
    paddingHorizontal: 10
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    fontSize: 6,
    color: "#666666",
    bottom: "0.50in",
    left: "0.75in",
    right: "0.75in",
    borderTop: "1px solid #000"
  },
  signature: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 11,
    fontWeight: "semibold",
    marginTop: 50
  }
});

export const SubstorePrintComponent: React.FC<SubstorePrintComponentProps> = (
  props: SubstorePrintComponentProps
) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.backgroundImage} src={env.reportLogo} fixed />
        <View>
          <Image style={styles.logo} src={env.organizationLogo} />
          <Text style={styles.organizationName}>
            {env.organizationFullName}
          </Text>
          <View style={styles.header}>
            <View style={styles.headerContent}>
              <Text style={styles.headerContentDetails}>
                Report Type : Location Inventory Report
              </Text>
              <Text style={styles.headerContentDetails}>
                Location Name : {props.data.data.location.name}
              </Text>
            </View>
            <View style={styles.headerContent}>
              <Text style={styles.headerContentDetails}>
                Report Date :{"  "}
                {dayjs().format("DD MMMM, YYYY")}
              </Text>
              <Text style={styles.headerContentDetails}>
                Printout Date :{"  "}
                {dayjs().format("DD MMMM YYYY, hh:mm A")}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={[styles.tableHeader, styles.row]} fixed>
            <Text style={styles.serial}>Serial</Text>
            <Text style={styles.productName}>Product Name</Text>
            <Text style={styles.quantity}>Quantity</Text>
            <Text style={styles.unit}>Unit</Text>
          </View>
          {props.data.data.inventory.map((row, i) => (
            <View style={styles.row} key={i} wrap={false}>
              <Text style={styles.serial}>{i + 1} </Text>
              <Text style={styles.productName}>{row.productName + " "}</Text>
              <Text style={styles.quantity}>{row.quantity}</Text>
              <Text style={styles.unit}>{row.unit}</Text>
            </View>
          ))}
        </View>
        <View style={styles.signature}>
          <View>
            <Text>Verified By,</Text>
          </View>
          <View>
            <Text>Authorized By,</Text>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View>
            <Text>Powered By Bindulogic Limited</Text>
          </View>
          <View>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
          <View>
            <Text>
              Printed By: {userCurrentRole() || "Unknown"},{" "}
              {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
