import { NotificationEntityType } from "../../../api/type";

export interface Notification {
  id: string;
  title: string;
  description: string;
  entityType: NotificationEntityType;
  entityId: string;
  createdAt: string;
  notificationStatuses: {
    notificationId: string;
    userId: string;
    isRead: boolean;
    readAt: string | null;
  }[];
}

export const notificationPartialLink: {
  [key: string]: string;
} = {
  STOCK_OPERATION: "report/operation-details",
  PENDING_STOCK_OPERATION: "stock-operation/pending-operation",
  PRODUCT: "product-details",
  LOCATION: "location/summary",
  USER: "user-list"
};
