import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Select
} from "@chakra-ui/react";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  useReactTable
} from "@tanstack/react-table";
import { PaginationBottomComponent } from "../../core/components/pagination-bottom.component";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaEye, FaSearch } from "react-icons/fa";
import { CSVLink } from "react-csv";
import { customPagination } from "../../../config";
import { Product } from "../../../api/type";
import { useGetAllProductsQuery } from "../../../api";
import { userCurrentRole } from "../../../utils/common-functions";
import { LoaderComponent } from "../../core/components/loader.component";

const headers = [
  { label: "Product Id", key: "id" },
  { label: "Product Name", key: "name" },
  { label: "Disable", key: "isDisabled" }
];

interface AllProductsTableProps { }

export const AllProductsComponent: React.FC<AllProductsTableProps> = (
  props: AllProductsTableProps
) => {
  const navigate = useNavigate();
  const role = userCurrentRole();
  const addProductPage = () => {
    navigate(`/${role}/product/add`);
  };

  const handleEditButtonClick = (id: number) => {
    navigate(`/${role}/product/edit/${id}`);
  };

  const handleDetailButtonClick = (id: number) => {
    navigate(`/${role}/product-details/${id}`);
  };

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: customPagination.pageIndex,
      pageSize: customPagination.pageSize
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );

  const [searchText, setSearchText] = useState<string>("");
  const [pageData, setPageData] = useState<Product[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [selectedProductFilter, setSelectedProductFilter] =
    useState<boolean>(true);

  const changeSelectFilter = (e: any) => {
    let selectedValue = e.target.selectedOptions[0].value;

    if (selectedValue === "false") {
      setSelectedProductFilter(true);
    } else {
      setSelectedProductFilter(false);
    }
  };
  const getAllProductsResult = useGetAllProductsQuery({
    query: {
      search: searchText.length >= 2 ? searchText : "",
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize
    }
  });

  useEffect(() => {
    if (getAllProductsResult.data) {
      // let finalData: Product[];

      if (selectedProductFilter) {
        let finalData = getAllProductsResult.data.data.products.filter(
          (product) => product.isDisabled !== true
        );
        setPageData(finalData);
        setItemCount(getAllProductsResult.data.data.productCount);
      } else {
        let finalData = getAllProductsResult.data.data.products.filter(
          (product) => product.isDisabled === true
        );
        setPageData(finalData);
        setItemCount(getAllProductsResult.data.data.productCount);
      }
    }
  }, [getAllProductsResult, selectedProductFilter]);

  const columnHelper = createColumnHelper<Product>();

  const columns: ColumnDef<Product, any>[] = [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      header: () => "ID",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => "Name",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.unit, {
      id: "unit",
      header: () => "Unit",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.ownerDepartment, {
      id: "department",
      header: () => "Department",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.medicineGeneric?.genericName, {
      id: "genericName",
      header: () => "genericName",
      cell: (row) => row.getValue()
    }),

    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: ({ row, table }) => {
        return (
          <Stack direction={{ xl: "row" }}>
            <Button
              leftIcon={<FaEdit />}
              size={"sm"}
              color="messenger.400"
              colorScheme={"messenger"}
              variant={"outline"}
              onClick={() => handleEditButtonClick(row.original.id)}
            >
              Edit
            </Button>

            <Button
              mx={3}
              leftIcon={<FaEye />}
              color="messenger.400"
              size={"sm"}
              colorScheme={"messenger"}
              variant={"outline"}
              onClick={() => handleDetailButtonClick(row.original.id)}
            >
              View
            </Button>
          </Stack>
        );
      }
    })
  ];

  const table = useReactTable({
    data: pageData,
    // @ts-ignore
    columns: columns,
    pageCount: Math.ceil(itemCount / pagination.pageSize),
    state: {
      pagination
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    debugTable: true
  });

  return (
    <>
      {getAllProductsResult.isLoading ? (
        <LoaderComponent />
      ) : (
        <Stack>
          <Stack
            direction={{ base: "column", xl: "row" }}
            minWidth="max-content"
            alignItems="center"
            mb="1"
          >
            <Box>
              <InputGroup w="96" display={{ base: "flex", lg: "flex" }}>
                <InputLeftElement color="gray.500" children={<FaSearch />} />
                <Input
                  placeholder="Enter 3 or 4 letters to find..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </InputGroup>
            </Box>
            <Box>
              <Select
                borderColor="#2B6CB0"
                color="#2D3748"
                onChange={(e) => changeSelectFilter(e)}
              >
                <option value="false">Active</option>
                <option value="true">InActive</option>
              </Select>
            </Box>
            <Spacer />
            <ButtonGroup gap="2">
              <Button onClick={() => addProductPage()} colorScheme="blue">
                Add Product
              </Button>
              <CSVLink data={pageData} headers={headers}>
                <Button colorScheme="blue">Export CSV</Button>
              </CSVLink>
            </ButtonGroup>
          </Stack>

          <Table variant="striped">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        bgColor="gray.700"
                        color="white"
                      >
                        {header.isPlaceholder ? null : (
                          <Box>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Box>
                        )}
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <PaginationBottomComponent
            table={table}
            count={itemCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        </Stack>
      )}
    </>
  );
};
