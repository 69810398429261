import React from "react";
import {
  Box,
  Flex,
  Heading,
  Spacer,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { PageCardComponent } from "../../core/components/page-card.component";
import { useGetVendoerDemandDetailsQuery } from "../../../api";
import { IoMdArrowRoundBack } from "react-icons/io";
import { env } from "../../../config/index";
import { VendoerDemandPrintComponet } from "../../print/components/vendoer-demand-print.componet";
import { userCurrentRole } from "../../../utils/common-functions";

interface VendorDemandOperationDetailsComponentProps {
  id: string | null;
  type: string | null;
  locationId?: string | null;
}

export const VendorDemandOperationDetailsComponent: React.FC<
  VendorDemandOperationDetailsComponentProps
> = (props: VendorDemandOperationDetailsComponentProps) => {
  const navigate = useNavigate();
  const { data } = useGetVendoerDemandDetailsQuery({
    data: { report: { demandId: Number(props.id) } }
  });
  const role = userCurrentRole();
  return (
    <Stack>
      <Flex justifyContent="end">
        <Button
          colorScheme="blue"
          leftIcon={<IoMdArrowRoundBack />}
          onClick={() =>
            props.locationId
              ? navigate(
                `/${role}/report/all-vendor-demand?locationId=${props.locationId}`
              )
              : navigate(-1)
          }
        >
          Back
        </Button>
      </Flex>

      <Stack spacing={8} direction={{ base: "column", lg: "row" }}>
        <PageCardComponent>
          <Stack>
            <Table size={"sm"} variant={"unstyled"}>
              <Tr>
                <Th>Origin Location</Th>
                <Td>:</Td>
                <Td>{data?.originLocation?.name}</Td>
              </Tr>
              <Tr>
                <Th>Operation Type</Th>
                <Td>:</Td>
                <Td>{data?.operationType}</Td>
              </Tr>
              <Tr>
                <Th>Operation Status</Th>
                <Td>:</Td>
                <Td>{data?.operationStatus}</Td>
              </Tr>
              <Tr>
                <Th>Operation By</Th>
                <Td>:</Td>
                <Td>{data?.createdBy?.name}</Td>
              </Tr>
              <Tr>
                <Th>Phone</Th>
                <Td>:</Td>
                <Td>{data?.createdBy.contact}</Td>
              </Tr>
            </Table>
          </Stack>
        </PageCardComponent>
        <PageCardComponent>
          <Stack>
            <Table size={"sm"} variant={"unstyled"}>
              <Tr>
                <Th>Destination Location</Th>
                <Td>:</Td>
                <Td>{data?.destinationLocation?.name}</Td>
              </Tr>
              <Tr>
                <Th>Date</Th>
                <Td>:</Td>
                <Td>
                  {" "}
                  {dayjs(data?.createdAt).format("ddd, MMM D, YYYY h:mm A")}
                </Td>
              </Tr>
              <Tr>
                <Th>Notes</Th>
                <Td>:</Td>
                <Td>{data?.notes}</Td>
              </Tr>
              <Tr>
                <Th>Vendor Demand ID</Th>
                <Td>:</Td>
                <Td fontWeight={700}>{data?.id}</Td>
              </Tr>
            </Table>
          </Stack>
        </PageCardComponent>
      </Stack>
      <PageCardComponent>
        <Flex alignItems="center">
          <Box>
            <Heading as="h1" size="sm">
              Operation Summary
            </Heading>
          </Box>
          <Spacer />
          <Box>
            {data?.operationStatus === "FINALIZED" &&
              data?.operationType === "VENDOR_DEMAND" && (
                <VendoerDemandPrintComponet id={data?.id} />
              )}
          </Box>
        </Flex>
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr bg="gray.700">
                <Th color="white">ID</Th>
                <Th color="white">Product Name</Th>
                <Th color="white">Tender S/N</Th>
                <Th color="white">Monthly REQs.</Th>
                <Th color="white">Curr. REQs.</Th>
                <Th color="white">APP. TTL Amount</Th>
                <Th color="white">APP. Pur. Amount</Th>
                <Th color="white">Curr INV.</Th>
                <Th color="white">Last Sup. Amount</Th>
                <Th color="white">Last Sup. Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.stockOperationItems?.map((data, key) => (
                <Tr key={key}>
                  <Td>{key + 1}</Td>
                  <Td>{data.productName}</Td>
                  <Td>{data.tenderSerialNumber}</Td>
                  <Td>{data.monthlyRequirment}</Td>
                  <Td>{data.currentRequirment}</Td>
                  <Td>{data.appTotalAmount}</Td>
                  <Td>{data.appPurchasedAmount}</Td>
                  <Td>{data.currentInventory}</Td>
                  <Td>{data.lastSupplyAmount}</Td>
                  <Td>
                    {data?.lastSupplyDate === null
                      ? ""
                      : dayjs(data?.lastSupplyDate).format(
                        "ddd, MMM D, YYYY h:mm A"
                      )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </PageCardComponent>
    </Stack>
  );
};
