import React, { useEffect, useMemo, useState } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  useReactTable,
  FilterFn,
  getPaginationRowModel,
  createColumnHelper
} from "@tanstack/react-table";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { FaSearch, FaEye } from "react-icons/fa";
import { useGetPendingOperationQuery } from "../../../api";
import { Operations } from "../../../api/type";
import { useNavigate } from "react-router-dom";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import { customPagination, displayAllTablePage } from "../../../config";
import { userCurrentRole } from "../../../utils/common-functions";

declare module "@tanstack/table-core" {
  interface FilterFns {
    tableFilter: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const tableFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank
  });
  return itemRank.passed;
};

interface PendingOperationProps {}
const PendingOperationComponent: React.FC<PendingOperationProps> = (
  props: PendingOperationProps
) => {
  const navigate = useNavigate();
  const role = userCurrentRole();
  const { data } = useGetPendingOperationQuery({});

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: customPagination.pageIndex,
    pageSize: customPagination.pageSize
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const handleRowClick = (row: any) => {
    navigate(
      `/${role}/approval/operation-details?id=${row.id}&type=${row.operationType}`
    );
  };
  const [globalFilter, setGlobalFilter] = useState("");
  const columnHelper = createColumnHelper<Operations>();

  const columns: ColumnDef<Operations, any>[] = [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      header: () => "ID",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.originLocation?.name, {
      id: "originLocation.name",
      header: () => "Origin Location",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.destinationLocation?.name, {
      id: "destinationLocation.name",
      header: () => "Destination Location",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.operationType, {
      id: "operationType",
      header: () => "Operation Type",
      cell: (row) => {
        return (
          <Badge
            p={1}
            textAlign="center"
            width={{
              base: "100%",
              md: "100%",
              lg: "100%",
              xl: "100%",
              "2xl": "55%"
            }}
            borderRadius={5}
            variant="subtle"
            colorScheme="messenger"
          >
            {row.getValue()}
          </Badge>
        );
      }
    }),
    columnHelper.accessor((row) => row.operationStatus, {
      id: "operationStatus",
      header: () => "Operation Status",
      cell: (row) => (
        <Badge
          p={1}
          textAlign="center"
          width={{
            base: "100%",
            md: "100%",
            lg: "100%",
            xl: "100%",
            "2xl": "55%"
          }}
          borderRadius={5}
          variant="solid"
          colorScheme={
            (row.getValue() === "INITIALIZED" && "red") ||
            (row.getValue() === "CANCELLED" && "gray") ||
            (row.getValue() === "FINALIZED" && "green") ||
            ""
          }
        >
          {row.getValue() === "INITIALIZED" && "PENDING APPROVAL"}
        </Badge>
      )
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: ({ row, table }) => {
        return (
          <>
            <Button
              leftIcon={<FaEye />}
              color="messenger.400"
              size={"sm"}
              colorScheme={"messenger"}
              variant={"outline"}
              onClick={() => handleRowClick(row.original)}
            >
              View
            </Button>
          </>
        );
      }
    })
  ];

  const table = useReactTable({
    data: data ? data.data.stockOperations : [],
    // @ts-ignore
    columns: columns,
    filterFns: {
      tableFilter
    },
    state: {
      pagination,
      globalFilter
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    getPaginationRowModel: getPaginationRowModel(),
    globalFilterFn: tableFilter,
    debugTable: true
  });

  const SearchInput = ({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
  }: {
    value: string | number;
    onChange: (value: string | number) => void;
    debounce?: number;
  }) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value);
      }, debounce);

      return () => clearTimeout(timeout);
    }, [value, debounce, onChange]);

    return (
      <InputGroup w="96" display={{ base: "flex", lg: "flex" }}>
        <InputLeftElement color="gray.500" children={<FaSearch />} />
        <Input
          placeholder="Search..."
          value={value}
          autoFocus={value ? true : false}
          onChange={(e) => setValue(e.target.value)}
        />
      </InputGroup>
    );
  };

  return (
    <Stack>
      <Stack direction={{ base: "column", xl: "row" }} minWidth="max-content">
        <Box>
          <SearchInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          />
        </Box>
        <Spacer />
        <ButtonGroup gap="2">
          <Button colorScheme="blue">Export CSV</Button>
        </ButtonGroup>
      </Stack>
      <Table variant="striped">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    bg="gray.700"
                    color="white"
                  >
                    {header.isPlaceholder ? null : (
                      <Box>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Box>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <Stack
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
        alignItems="center"
      >
        <Box width={{ base: "100%", lg: "70%" }}>
          <Text fontSize="lg" fontWeight="bold">
            Showing (Page {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()})
          </Text>{" "}
        </Box>

        <Stack
          direction="row"
          width={{ base: "100%", lg: "30%" }}
          justifyContent="space-between"
        >
          <Stack direction="row" width={{ base: "50%", md: "70%", lg: "80%" }}>
            <Button
              colorScheme="blue"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {"<<"}
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {">"}
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {">>"}
            </Button>{" "}
          </Stack>
          <Box width={{ base: "50%", md: "30%", lg: "20%" }}>
            <Select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {displayAllTablePage.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Select>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default PendingOperationComponent;
