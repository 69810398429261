import React from "react";
import { Flex, Stack } from "@chakra-ui/react";
import { ForgotPasswordComponent } from "../components/forgot-password.component";
interface ForgotPasswordPageProps {}

export const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = (
  props: ForgotPasswordPageProps
) => {
  return (
    <Stack>
      <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
        <Flex flex={1} p={8} align="center" justify="center">
          <ForgotPasswordComponent />
        </Flex>
      </Stack>
    </Stack>
  );
};
