import React from "react";
import { userCurrentRole } from "../../../utils/common-functions";
import { FaBriefcaseMedical, FaMapMarkedAlt, FaMapSigns } from "react-icons/fa";
import {
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Text,
  Heading,
  Spacer,
  Button
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Flex } from "@chakra-ui/layout";
import { IoMdArrowRoundBack } from "react-icons/io";
import dayjs from "dayjs";
import { useGetToBeExpiredItemsQuery } from "../../../api";
import { ToBeExpiredReportPrintPage } from "../../print/pages/to-be-expired-report-print.page";

interface ToBeExpiredComponentProps {}

export const ToBeExpiredComponent: React.FC<ToBeExpiredComponentProps> = (
  props: ToBeExpiredComponentProps
) => {
  let locationId = localStorage.getItem("location");

  const navigate = useNavigate();
  const role = userCurrentRole();
  const goBack = () => {
    navigate(-1);
  };

  const ItemsToBeExpired = useGetToBeExpiredItemsQuery({
    query: {
      locationId: Number(locationId),
      role: role
    }
  });

  const PageCardComponent = (props: {
    locationName: string;
    title: string;
    count: number;
    icon: any;
  }) => {
    return (
      <Stack p="5" bg="white" borderRadius="lg" boxShadow="sm" width="100%">
        <Flex alignItems="center">
          <Box>
            <Text fontSize="md" fontWeight="medium">
              {props.locationName}
            </Text>
            <Text fontSize="sm" fontWeight="medium" color="red">
              {props.title}
            </Text>
            <Heading size="md">{props.count}</Heading>{" "}
          </Box>
          <Spacer />
          <Box
            bgColor="#2B6CB0"
            p={3}
            borderRadius="full"
            color="white"
            fontSize="xl"
          >
            {props.icon}
          </Box>
        </Flex>
      </Stack>
    );
  };
  return (
    <Stack>
      <Flex justifyContent="end">
        <Button
          colorScheme="blue"
          leftIcon={<IoMdArrowRoundBack />}
          onClick={() => goBack()}
        >
          Back
        </Button>
      </Flex>
      {ItemsToBeExpired.isSuccess && (
        <Stack>
          <Stack
            direction={{ base: "column", xl: "row" }}
            onClick={() => goBack()}
          >
            <PageCardComponent
              locationName={
                ItemsToBeExpired.data.type === "ALL"
                  ? "ALL LOCATION"
                  : ItemsToBeExpired.data.location.name
              }
              title="Items To Be expired"
              count={ItemsToBeExpired.data.totalItems}
              icon={<FaBriefcaseMedical />}
            />
          </Stack>
          <Stack>
            <ToBeExpiredReportPrintPage expiredItems={ItemsToBeExpired.data} />
          </Stack>
        </Stack>
      )}
      <Stack width="100%" overflowX="auto">
        <TableContainer width="100%">
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>SL</Th>
                <Th>Product Name</Th>
                <Th>Product Type</Th>
                <Th>Tracking Id</Th>
                <Th>Quantity</Th>
                <Th>Unit</Th>
                <Th>Expire Date</Th>
                {ItemsToBeExpired && ItemsToBeExpired.data?.type === "ALL" && (
                  <Th>Location Name</Th>
                )}
              </Tr>
            </Thead>
            {ItemsToBeExpired.isSuccess && (
              <Tbody>
                {ItemsToBeExpired.data?.allItems.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td
                        style={{
                          whiteSpace: "normal",
                          wordWrap: "break-word"
                        }}
                      >
                        {item.productName}
                      </Td>
                      <Td>{item.type}</Td>
                      <Td>{item.trackingId}</Td>
                      <Td>{item.quantity}</Td>
                      <Td>{item.unit}</Td>
                      <Td>{dayjs(item.expDate).format("DD MMM, YYYY")}</Td>
                      {ItemsToBeExpired.data.type === "ALL" && (
                        <Td>{item.locationName}</Td>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            )}
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
};
