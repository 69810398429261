import React, { forwardRef, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select as SelectPageSize,
  Spacer,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  useToast
} from "@chakra-ui/react";
import {
  useGetAssignUserLocationsQuery,
  useGetProductQuery,
  useGetSingleProductMovementMutation
} from "../../../api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./chakra-react-datepicker.css";
import { CalendarIcon } from "@chakra-ui/icons";
import { Controller, useForm } from "react-hook-form";
import { OriginLocationGroup } from "../types/stock-operation.type";
import { PageCardComponent } from "../../core/components/page-card.component";
import {
  canSelectLocation,
  chakraStyles,
  userCurrentRole
} from "../../../utils/common-functions";
import { LoaderComponent } from "../../core/components/loader.component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { endDate, startDate } from "../state/report.slice";
import { RetrieveQueryParams } from "../../../service/operation/retrieveQueryParams.service";
import { ProductGroup } from "../../stock-operation/types/stock-operation.type";
import { Select, GroupBase } from "chakra-react-select";
import { ProductMovementTableComponent } from "./product-movement-table.component";
import dayjs from "dayjs";
import { SingleProductMovementByLocationPrintComponent } from "../../print/components/single-product-movement-by-location-print.component";
type LocationOperationFormValues = {
  originLocation: OriginLocationGroup;
};
interface SingleProductMovementByLocationComponentProps {}

export const SingleProductMovementByLocationComponent: React.FC<
  SingleProductMovementByLocationComponentProps
> = (props: SingleProductMovementByLocationComponentProps) => {
  let query = RetrieveQueryParams();
  const role = userCurrentRole();
  const dispatch = useDispatch();
  const toast = useToast();
  const reportDate = useSelector((state: RootState) => state.report);
  let locationId = query.get("locationId")
    ? query.get("locationId")
    : localStorage.getItem("location");
  let initialState = {
    label: "",
    value: Number(locationId)
  };
  const [postProductMovementSearch, resultProductMovementSearch] =
    useGetSingleProductMovementMutation();

  const [originLocation, setOriginLocation] =
    useState<OriginLocationGroup>(initialState);

  const { control } = useForm<LocationOperationFormValues>({});

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);

  const getLocationQuery = useGetAssignUserLocationsQuery({});

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(locationId))!
      );
    }
  }, [locationId, originLocationOptions]);
  const handleGetLocation = (data: any) => {
    setOriginLocation(data);
  };
  //date
  const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
    <Input
      w={60}
      autoComplete="off"
      background="white"
      value={value}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
    />
  );
  customDateInput.displayName = "DateInput";

  const CustomInput = forwardRef(customDateInput);
  function dateStartHandler(value: any) {
    dispatch(startDate(value.toISOString()));
  }
  function dateEndHandler(value: any) {
    dispatch(endDate(value.toISOString()));
  }
  const icon = <CalendarIcon fontSize="sm" />;

  //product
  const getProductQuery = useGetProductQuery({ id: Number(locationId) });
  const [productOptions, setProductOptions] = useState<ProductGroup[]>([]);
  const [selectedProduct, setSelectProduct] = useState<number>();
  // TODO: Track this
  useEffect(() => {
    if (getProductQuery.data) {
      const productWithValues = getProductQuery.data.data.products.map(
        (product) => {
          return {
            label: product.name,
            value: product.id,
            type: product.trackingType,
            unit: product.unit
          };
        }
      );
      setProductOptions(productWithValues);
    }
  }, [getProductQuery.data]);

  const ChangeProduct = (e: any) => {
    setSelectProduct(e.value);
  };

  const submitMovement = () => {
    if (selectedProduct) {
      postProductMovementSearch({
        data: {
          locationId: originLocation.value,
          productId: selectedProduct,
          startDate: reportDate.startDate,
          endDate: reportDate.endDate
        }
      });
    } else {
      toast({
        title: "Search fail",
        description: "Please Select product",
        status: "error",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
  };
  return (
    <Stack>
      <Stack direction={{ base: "column", xl: "row" }} justify="space-between">
        <PageCardComponent>
          <Controller
            control={control}
            name="originLocation"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { error }
            }) => (
              <FormControl mb="3" isInvalid={!!error} id="originLocation">
                <FormLabel>Select Location</FormLabel>
                <Select
                  chakraStyles={chakraStyles}
                  name={name}
                  ref={ref}
                  onChange={(location) => handleGetLocation(location)}
                  onBlur={onBlur}
                  isDisabled={!canSelectLocation(role)}
                  value={originLocation}
                  options={originLocationOptions}
                  placeholder="Select location"
                  closeMenuOnSelect={true}
                />
                <FormErrorMessage>{error && error.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </PageCardComponent>
      </Stack>
      {getProductQuery.isLoading ? (
        <LoaderComponent />
      ) : (
        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
          shadow="base"
          bg="white"
          padding={5}
        >
          <Box w={{ base: "100%", md: "100%", xl: "50%" }}>
            <FormControl>
              <FormLabel
                fontSize={{
                  base: "10",
                  md: "10",
                  lg: "10",
                  xl: "10",
                  "2xl": "12"
                }}
              >
                Select Product
              </FormLabel>

              <Select<ProductGroup, true, GroupBase<ProductGroup>>
                options={productOptions}
                placeholder="Select Product"
                closeMenuOnSelect={true}
                size="md"
                onChange={(e) => ChangeProduct(e)}
              />
            </FormControl>
          </Box>

          <Box w={{ base: "100%", md: "100%", xl: "20%" }}>
            <FormControl>
              <FormLabel
                fontSize={{
                  base: "10",
                  md: "10",
                  lg: "10",
                  xl: "10",
                  "2xl": "12"
                }}
              >
                From
              </FormLabel>
              <DatePicker
                id="dateStartEnd"
                selected={new Date(reportDate.startDate)}
                onChange={dateStartHandler}
                dateFormat="dd MMM yyyy"
                className="react-datapicker__input-text"
                placeholderText="Select Date"
                customInput={<CustomInput />}
                showDisabledMonthNavigation
              />
            </FormControl>
          </Box>
          <Spacer />
          <Box w={{ base: "100%", md: "100%", xl: "20%" }}>
            <FormControl>
              <FormLabel
                fontSize={{
                  base: "10",
                  md: "10",
                  lg: "10",
                  xl: "10",
                  "2xl": "12"
                }}
              >
                To
              </FormLabel>
              <DatePicker
                id="dateStartEnd"
                selected={new Date(reportDate.endDate)}
                onChange={dateEndHandler}
                dateFormat="dd MMM yyyy"
                className="react-datapicker__input-text"
                placeholderText="Select Date"
                customInput={<CustomInput />}
                showDisabledMonthNavigation
              />
            </FormControl>
          </Box>
          <Spacer />
          <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
            <FormControl>
              <Button
                colorScheme="blue"
                fontSize={{
                  base: "10",
                  md: "10",
                  lg: "10",
                  xl: "10",
                  "2xl": "12"
                }}
                mt={6}
                onClick={() => submitMovement()}
              >
                Search{" "}
              </Button>
            </FormControl>
          </Box>
        </Stack>
      )}
      {resultProductMovementSearch.isLoading ? (
        <LoaderComponent />
      ) : (
        resultProductMovementSearch.data &&
        resultProductMovementSearch.data.data.locationMovementSummary && (
          <Stack>
            <Stack
              direction={{ base: "column", xl: "row" }}
              justify="space-between"
              my="2"
            >
              <PageCardComponent>
                <Stat>
                  <StatLabel>Report Location</StatLabel>
                  <StatNumber fontSize="lg" fontWeight={600}>
                    {resultProductMovementSearch.data.data.originLocation.name}
                  </StatNumber>
                </Stat>
              </PageCardComponent>
              <PageCardComponent>
                <Stat>
                  <StatLabel>Product Name</StatLabel>
                  <StatNumber fontSize="lg" fontWeight={600}>
                    {resultProductMovementSearch.data.data.product.name}
                  </StatNumber>
                  <StatLabel>Product Unit</StatLabel>
                  <StatNumber fontSize="lg" fontWeight={600}>
                    {resultProductMovementSearch.data.data.product.unit}
                  </StatNumber>
                </Stat>
              </PageCardComponent>
              <PageCardComponent>
                <Stat>
                  <StatLabel>Start Time</StatLabel>
                  <StatNumber fontSize="lg" fontWeight={600}>
                    {" "}
                    {dayjs(
                      resultProductMovementSearch.data.data.startTime
                    ).format("MMM D, YYYY h:mm A")}
                  </StatNumber>
                  <StatLabel>End Time</StatLabel>
                  <StatNumber fontSize="lg" fontWeight={600}>
                    {" "}
                    {dayjs(
                      resultProductMovementSearch.data.data.endTime
                    ).format("MMM D, YYYY h:mm A")}
                  </StatNumber>
                </Stat>
              </PageCardComponent>
              <PageCardComponent>
                <Stat>
                  <StatLabel>Total Number of opearation</StatLabel>
                  <StatNumber fontSize="lg" fontWeight={600}>
                    {resultProductMovementSearch.data.data.totalCount}
                  </StatNumber>
                  <StatLabel>Total Out</StatLabel>
                  <StatNumber fontSize="lg" fontWeight={600}>
                    {resultProductMovementSearch.data.data.totalOut}
                  </StatNumber>
                  <StatLabel>Total In</StatLabel>
                  <StatNumber fontSize="lg" fontWeight={600}>
                    {resultProductMovementSearch.data.data.totalIn}
                  </StatNumber>
                </Stat>
              </PageCardComponent>
            </Stack>
            <Stack
              direction={{ base: "column", xl: "row" }}
              minWidth="max-content"
              alignItems="center"
            >
              <Box></Box>
              <Spacer />
              {resultProductMovementSearch.data.data.locationMovementSummary
                .length > 0 && (
                <SingleProductMovementByLocationPrintComponent
                  singleProductMovement={resultProductMovementSearch.data}
                />
              )}
            </Stack>
            {resultProductMovementSearch.data.data.locationMovementSummary
              .length > 0 ? (
              <ProductMovementTableComponent
                singleProductMovement={
                  resultProductMovementSearch.data.data.locationMovementSummary
                }
                productMovementHistory={resultProductMovementSearch.data}
              />
            ) : (
              <Heading fontSize="lg" fontWeight={600} textAlign="center">
                No movement found
              </Heading>
            )}
          </Stack>
        )
      )}
    </Stack>
  );
};
