import React from "react";
import dayjs from "dayjs";
import {
  Document,
  Image,
  Page,
  Text,
  View,
  Font,
  StyleSheet
} from "@react-pdf/renderer";
import { env } from "../../../config";
import { GetToBeExpiredItemsResponse } from "../../../api/type";
import { userCurrentRole } from "../../../utils/common-functions";

interface ToBeExpiredReportPrintComponentProps {
  expiredItems: GetToBeExpiredItemsResponse;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  page: {
    padding: "0.75in",
    fontFamily: "Noto Serif Bengali"
  },
  header: {
    width: "100%",
    marginTop: 3,
    marginBottom: 10
  },
  headerContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    fontSize: 11
  },
  headerContentDetails: {
    width: "50%",
    border: "1px solid #dfdfdf",
    paddingHorizontal: 5,
    paddingVertical: 1
  },
  organizationLogo: {
    width: "70px",
    objectFit: "contain",
    alignSelf: "center"
  },
  organizationName: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center"
  },
  table: {
    width: "100%",
    borderLeft: "1px solid #dfdfdf",
    borderRight: "1px solid #dfdfdf"
  },
  tableHeader: {
    fontSize: 11,
    fontWeight: "semibold",
    borderTop: "1px solid #dfdfdf",
    backgroundColor: "#EBECF0"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    // paddingHorizontal: 2,
    fontSize: 11,
    borderBottom: "1px solid #dfdfdf"
  },
  serial: {
    width: "5%",
    borderRight: "1px solid #dfdfdf"
  },
  productName: {
    width: "25%",
    textAlign: "left",
    paddingHorizontal: 5,
    borderRight: "1px solid #dfdfdf"
  },
  productType: {
    width: "11%",
    borderRight: "1px solid #dfdfdf"
  },
  trackingId: {
    width: "12%",
    borderRight: "1px solid #dfdfdf"
  },
  quantity: {
    width: "7%",
    borderRight: "1px solid #dfdfdf"
  },
  unit: {
    width: "10%",
    borderRight: "1px solid #dfdfdf"
  },
  expiryDate: {
    width: "10%",
    borderRight: "1px solid #dfdfdf"
  },
  locationName: {
    width: "20%",
    textAlign: "left",
    paddingHorizontal: 5
  },
  signature: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 11,
    fontWeight: "semibold",
    marginTop: 50
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    fontSize: 6,
    color: "#666666",
    bottom: "0.50in",
    left: "0.75in",
    right: "0.75in",
    borderTop: "1px solid #000"
  },
  backgroundImage: {
    position: "absolute",
    top: "45%",
    width: "200px",
    opacity: 0.05,
    objectFit: "contain",
    alignSelf: "center"
  }
});

export const ToBeExpiredReportPrintComponent: React.FC<
  ToBeExpiredReportPrintComponentProps
> = (props: ToBeExpiredReportPrintComponentProps) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <Image style={styles.backgroundImage} src={env.reportLogo} fixed />
        <View>
          <Image style={styles.organizationLogo} src={env.organizationLogo} />
          <Text style={styles.organizationName}>
            {env.organizationFullName}
          </Text>
          <View style={styles.header}>
            <View style={styles.headerContent}>
              <Text style={styles.headerContentDetails}>
                Report Type : Products To Be Expired Report
              </Text>
              <Text style={styles.headerContentDetails}>
                Location Name : {props.expiredItems.location.name}
              </Text>
            </View>
            <View style={styles.headerContent}>
              <Text style={styles.headerContentDetails}>
                Report Date : {dayjs().format("DD MMM YYYY, hh:mm A")}
              </Text>
              <Text style={styles.headerContentDetails}>
                Printout Date : {dayjs().format("DD MMM YYYY, hh:mm A")}
              </Text>
            </View>
            <View style={styles.headerContent}>
              <Text style={styles.headerContentDetails}>
                Location Type : {props.expiredItems.type}
              </Text>
              <Text style={styles.headerContentDetails}>
                Total Products : {props.expiredItems.totalItems}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={[styles.tableHeader, styles.row]} fixed>
            <Text style={styles.serial}>Serial</Text>
            <Text style={styles.productName}>Product Name</Text>
            <Text style={styles.productType}>Product Type</Text>
            <Text style={styles.trackingId}>Tracking ID</Text>
            <Text style={styles.quantity}>Quantity</Text>
            <Text style={styles.unit}>Unit</Text>
            <Text style={styles.expiryDate}>Expire Date</Text>
            <Text style={styles.locationName}>Location Name</Text>
          </View>
          {props.expiredItems.allItems.map((item, index) => (
            <View style={styles.row} key={index} wrap={false}>
              <Text style={styles.serial}>{index + 1} </Text>
              <Text style={styles.productName}>{item.productName + " "}</Text>
              <Text style={styles.productType}>{item.type}</Text>
              <Text style={styles.trackingId}>{item.trackingId}</Text>
              <Text style={styles.quantity}>{item.quantity}</Text>
              <Text style={styles.unit}>{item.unit}</Text>
              <Text style={styles.expiryDate}>
                {" "}
                {dayjs(item.expDate).format("DD MMM YYYY")}
              </Text>
              <Text style={styles.locationName}>{item.locationName}</Text>
            </View>
          ))}
        </View>

        <View style={styles.signature}>
          <View>
            <Text>Verifed By,</Text>
          </View>
          <View>
            <Text>Authorized By,</Text>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View>
            <Text>Powered By Bindulogic Limited</Text>
          </View>
          <View>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
          <View>
            <Text>
              Printed By: {userCurrentRole() || "Unknown"},{" "}
              {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
