import { useEffect, useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDocumentTitle } from "../../../hooks/use-document-title.hook";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLoginMutation } from "../../../api";
import { DecorationImageComponent } from "./decoration-image.component";

type LoginFormValues = {
  email: string;
  password: string;
};

const loginFormSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .min(8, { message: "Password must be 8 or more characters long" })
});

export const LoginFormComponent = () => {
  const navigate = useNavigate();

  const [tooglePassword, setTooglePassword] = useState(false);

  const [login, loginResult] = useLoginMutation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginFormValues>({
    resolver: zodResolver(loginFormSchema)
  });

  useEffect(() => {
    if (loginResult.isSuccess) {
      localStorage.setItem("jwt", loginResult.data.data.accessToken);
      localStorage.setItem("role", loginResult.data.data.role.roleName);
      navigate("/select-location");
    }
  }, [loginResult, navigate]);

  const handleLogin = handleSubmit((data) => {
    login({
      data: {
        user: {
          email: data.email,
          password: data.password
        }
      }
    });
  });

  useDocumentTitle("Login | BinduHealth Inventory");

  const ViewPassword = (props: {
    tooglePassword: boolean;
    setTooglePassword: typeof setTooglePassword;
  }) => {
    return (
      <InputRightElement
        cursor="pointer"
        onClick={() => setTooglePassword(!tooglePassword)}
        children={tooglePassword ? <FaEye /> : <FaEyeSlash />}
      />
    );
  };

  return (
    <Box
      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
      px={{ base: "30px", md: "50px", lg: "60px" }}
      py="12"
      borderRadius="lg"
    >
      <form onSubmit={handleLogin}>
        <Center>
          <Stack spacing="5" w="full" maxW="lg">
            <DecorationImageComponent />
            <Heading fontSize={"2xl"} textAlign="center">
              Login
            </Heading>
            <FormControl id="email" isInvalid={errors.email ? true : false}>
              <FormLabel>Email</FormLabel>
              <Input
                {...register("email")}
                type="email"
                placeholder="johndoe@gmail.com"
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id="password"
              isInvalid={errors.password ? true : false}
            >
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  {...register("password")}
                  pr="9.5rem"
                  placeholder="********"
                  type={tooglePassword ? "text" : "password"}
                />
                <ViewPassword
                  tooglePassword={tooglePassword}
                  setTooglePassword={setTooglePassword}
                />
              </InputGroup>
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>

            <Stack
              direction={{ base: "column", sm: "row" }}
              align={"start"}
              justify={"space-between"}
            >
              <Checkbox>Remember me</Checkbox>
              <Link to="/forgot-password" color={"blue.500"}>
                Forgot password?
              </Link>
            </Stack>
            <Button colorScheme={"blue"} variant={"solid"} type="submit">
              Login
            </Button>
          </Stack>
        </Center>
      </form>
    </Box>
  );
};
