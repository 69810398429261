import { useEffect, useRef, useState } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ButtonGroup,
  useToast,
  useDisclosure,
  Textarea,
  Box,
  Stat,
  StatLabel,
  StatNumber,
  Divider
} from "@chakra-ui/react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Select, GroupBase } from "chakra-react-select";
import { ConfirmAlertComponent } from "./confirm-alert.component";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  DistributionStockOperationItems,
  itemGroup,
  OriginLocationGroup,
  ProductGroup,
  StockOperationItems
} from "../types/stock-operation.type";
import {
  RemoveSelectedProductFromAllProducts,
  userCurrentRole
} from "../../../utils/common-functions";
import {
  useGetAssignUserLocationsQuery,
  useGetFilterProductQuery,
  useDistributionMutation,
  useLazyGetPrescriptionQuery
} from "../../../api";
import { ProductTrackingTypeOptions } from "../../../api/type";
import { OperationService } from "../../../service/operation/operation.service";
import { env } from "../../../config/index";
import { AlreadyDistributedProductTable } from "./already-distributed-product-table";
import { PageCardComponent } from "../../core/components/page-card.component";
import { AddItemComponent } from "./add-item.component";
type DistributionFormValues = {
  originLocation: OriginLocationGroup;
  stockOperationItems: StockOperationItems[];
  notes: string;
};

interface DistributionComponentProps {}

export const DistributionComponent: React.FC<DistributionComponentProps> = (
  props: DistributionComponentProps
) => {
  const toast = useToast();
  const navigate = useNavigate();
  const role = userCurrentRole();
  let id = localStorage.getItem("location");
  const distributionConfirmAlert = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const getLocationQuery = useGetAssignUserLocationsQuery({});
  const getProductQuery = useGetFilterProductQuery({ id: Number(id) });

  const [distributionOperation, distributionOperationResult] =
    useDistributionMutation();

  const [getPrescription, getPrescriptionResult] =
    useLazyGetPrescriptionQuery();

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);
  const [originLocation, setOriginLocation] = useState<OriginLocationGroup>();
  const [productOptions, setProductOptions] = useState<ProductGroup[]>([]);
  const [barcodeInputValue, setBarcodeInputValue] = useState<string>("");

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(id))
      );
    }
  }, [id, originLocationOptions]);

  useEffect(() => {
    if (getProductQuery.data?.data.products) {
      const productWithValues = getProductQuery.data?.data.products.map(
        (product) => {
          return {
            label: product.name,
            value: product.id,
            unit: product.unit,
            type: product.type,
            quantity: product.quantity
          };
        }
      );
      setProductOptions(productWithValues);
    }
  }, [getProductQuery.data]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors }
  } = useForm<DistributionFormValues>({
    defaultValues: {
      stockOperationItems: [{}]
    }
  });

  const { fields, append, update, remove } = useFieldArray<
    DistributionFormValues,
    "stockOperationItems"
  >({
    control,
    name: "stockOperationItems"
  });

  const watch = useWatch({
    control,
    name: "stockOperationItems"
  });

  const distributionHandleSubmit = handleSubmit((data) => {
    if (data.stockOperationItems.length > 0) {
      const allStockOperationItems = data.stockOperationItems.map(
        (singleItem) => {
          return {
            productId: singleItem.product.value,
            quantity: OperationService.getQuantity(
              singleItem.product.type as string,
              singleItem.quantity
            ),
            trackingNumber: singleItem.trackingNumber,
            itemNote: singleItem.itemNote
          };
        }
      );

      if (getPrescriptionResult.isSuccess && getPrescriptionResult.data) {
        const distributedTo = {
          prescription: {
            id: getPrescriptionResult.data.prescription.id,
            type: getPrescriptionResult.data.prescription.type
          },
          patient: {
            id: getPrescriptionResult.data.patient.id,
            name: getPrescriptionResult.data.patient.name,
            contactNumber: getPrescriptionResult.data.patient.contactNumber
          }
        };
        distributionOperation({
          data: {
            stockOperation: {
              locationId: Number(id),
              stockOperationItems: allStockOperationItems,
              notes: data.notes,
              distributedTo: distributedTo
            }
          }
        });
      } else {
        distributionOperation({
          data: {
            stockOperation: {
              locationId: Number(id),
              stockOperationItems: allStockOperationItems,
              notes: data.notes
            }
          }
        });
      }
    }
  });

  useEffect(() => {
    if (distributionOperationResult.isSuccess) {
      toast({
        title: "Success",
        description: "Distribution operation successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      navigate(`/${role}/report/location-operations`);
    }
  }, [distributionOperationResult, toast, navigate, role]);

  const onChangeBarcode = (event: any) => {
    setBarcodeInputValue(event.target.value);
  };
  const onKeyPressBarcode = (event: any) => {
    if (event.keyCode === 13) {
      setBarcodeInputValue(event.target.value);
      getPrescription({ query: { prescriptionId: event.target.value } });
      setBarcodeInputValue("");
    }
  };

  useEffect(() => {
    if (
      getPrescriptionResult.isSuccess &&
      getProductQuery.data?.data.products
    ) {
      let allSelectedItems: DistributionStockOperationItems[] = [];
      getPrescriptionResult.data.medicineGenerics?.map(
        (singleGenericMedicine) => {
          const targetProduct = getProductQuery.data?.data.products.find(
            (pro) => pro.genericName === singleGenericMedicine.name
          );

          if (targetProduct) {
            let newSelectedItem = {
              product: {
                label: targetProduct.name,
                value: targetProduct.id,
                unit: targetProduct.unit,
                type: targetProduct.type,
                quantity: targetProduct.quantity,
                genericName: targetProduct.genericName
              },
              quantity: singleGenericMedicine.quantity
            };
            allSelectedItems.push(newSelectedItem);
          }
        }
      );

      if (allSelectedItems.length > 0) {
        setValue("stockOperationItems", allSelectedItems);
      } else {
        setValue("stockOperationItems", []);
        append({});
      }
    } else if (getPrescriptionResult.isError) {
      toast({
        title: "Failed to find prescription",
        description: "Please provide valid prescription ID",
        status: "error",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
  }, [getPrescriptionResult]);

  const addSingleItems = (selectedItem: itemGroup, quantity: number) => {
    const lastIndex = fields.length - 1;
    const trackingNumber = selectedItem?.trackingData
      ? selectedItem?.trackingData
      : undefined;
    const newProduct = {
      product: {
        label: selectedItem?.label,
        value: selectedItem?.productId,
        quantity: selectedItem.available,
        unit: selectedItem?.unit,
        type: selectedItem?.type
      },
      quantity: quantity,
      trackingNumber: trackingNumber
    };
    if (fields[lastIndex].product) {
      append(newProduct);
    } else {
      update(lastIndex, newProduct);
    }
  };

  return (
    <Stack>
      <form onSubmit={distributionHandleSubmit}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          width="100%"
          bgColor={"white"}
          borderRadius="md"
          p={4}
          mb={4}
        >
          <Box w={{ base: "80%", md: "80%", xl: "80%" }}>
            <FormControl mb="3">
              <FormLabel>Origin Location</FormLabel>
              <Input
                _disabled={{ color: "blackAlpha.700", cursor: "not-allowed" }}
                defaultValue={originLocation ? originLocation.label : ""}
                isDisabled={true}
              />
            </FormControl>
          </Box>
          {env.prescriptionModule && (
            <Box w={{ base: "20%", md: "20%", xl: "20%" }}>
              <FormControl mb="3">
                <FormLabel>Prescription ID</FormLabel>
                <Input
                  autoFocus={true}
                  placeholder="Give Prescription ID"
                  value={barcodeInputValue}
                  onChange={onChangeBarcode}
                  id="prescriptionID"
                  className="searchInput"
                  onKeyDown={onKeyPressBarcode}
                />
              </FormControl>
            </Box>
          )}
        </Stack>

        {getPrescriptionResult.data && env.prescriptionModule && (
          <Stack direction={{ base: "column", xl: "row" }} bgColor={"white"}>
            <Box width="40%" fontSize={8} display="flex">
              <PageCardComponent>
                <Stat>
                  <StatLabel>patient name</StatLabel>
                  <StatNumber fontSize="sm" fontWeight={600}>
                    {getPrescriptionResult.data?.patient.name}
                  </StatNumber>
                  <StatLabel>contactNumber</StatLabel>
                  <StatNumber fontSize="sm" fontWeight={600}>
                    {getPrescriptionResult.data?.patient.contactNumber}
                  </StatNumber>
                </Stat>
              </PageCardComponent>
              <PageCardComponent>
                <Stat>
                  <StatLabel>Prescription ID</StatLabel>
                  <StatNumber fontSize="sm" fontWeight={600}>
                    {getPrescriptionResult.data?.prescription.id}
                  </StatNumber>
                  <StatLabel>Type</StatLabel>
                  <StatNumber fontSize="sm" fontWeight={600}>
                    {getPrescriptionResult.data?.prescription.type}
                  </StatNumber>
                </Stat>
              </PageCardComponent>
            </Box>
            {getPrescriptionResult.data?.distributedProducts && (
              <Box width="60%">
                <AlreadyDistributedProductTable
                  distributedProducts={
                    getPrescriptionResult.data?.distributedProducts
                  }
                />
              </Box>
            )}
          </Stack>
        )}

        <Stack padding="1">
          <Divider />
        </Stack>

        {fields.map((field, index) => {
          return (
            <Stack
              direction={{ base: "column", xl: "row" }}
              width="100%"
              bgColor={"white"}
              borderRadius="md"
              spacing={4}
              px={4}
              py={2}
              key={field.id}
            >
              <Box w={{ base: "100%", md: "100%", xl: "50%" }}>
                <Controller
                  control={control}
                  name={`stockOperationItems.${index}.product`}
                  rules={{ required: "Select product" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error }
                  }) => (
                    <FormControl
                      isInvalid={!!error}
                      id={`stockOperationItems.${index}.product`}
                    >
                      {index === 0 && <FormLabel>Select Product</FormLabel>}
                      <Select<ProductGroup, true, GroupBase<ProductGroup>>
                        name={name}
                        ref={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        options={RemoveSelectedProductFromAllProducts(
                          productOptions,
                          watch
                        )}
                        placeholder="Select product"
                        closeMenuOnSelect={true}
                      />
                      <FormErrorMessage>
                        {error && error.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                <FormControl>
                  {index === 0 && <FormLabel>Unit</FormLabel>}
                  <Input
                    _disabled={{
                      color: "blackAlpha.700",
                      cursor: "not-allowed"
                    }}
                    defaultValue={watch[index]?.product?.unit}
                    disabled={true}
                    placeholder="Product unit"
                  />
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                <FormControl>
                  {index === 0 && <FormLabel>Available</FormLabel>}
                  <Input
                    _disabled={{
                      color: "blackAlpha.700",
                      cursor: "not-allowed"
                    }}
                    defaultValue={watch[index]?.product?.quantity}
                    disabled={true}
                    placeholder="Available"
                  />
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "15%" }}>
                <FormControl
                  isDisabled={
                    watch[index]?.product?.type ===
                    ProductTrackingTypeOptions.NONE
                      ? true
                      : false
                  }
                  isInvalid={
                    errors?.stockOperationItems?.[index]?.trackingNumber
                      ? true
                      : false
                  }
                >
                  {index === 0 && <FormLabel>Tracking Number</FormLabel>}
                  <Input
                    {...register(
                      `stockOperationItems.${index}.trackingNumber` as const,
                      {
                        validate: {
                          required: (value) => {
                            if (
                              watch[index]?.product?.type ===
                                ProductTrackingTypeOptions.NONE ||
                              value !== ""
                            )
                              return true;
                            return "Give Tracking data";
                          }
                        }
                      }
                    )}
                    key={`tracking${index}`}
                    type={"string"}
                    placeholder="Tracking Number"
                  />
                  <FormErrorMessage>
                    {
                      errors?.stockOperationItems?.[index]?.trackingNumber
                        ?.message
                    }
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "20%" }}>
                <FormControl
                  isInvalid={
                    errors?.stockOperationItems?.[index]?.itemNote
                      ? true
                      : false
                  }
                >
                  {index === 0 && <FormLabel>Item Note</FormLabel>}
                  <Textarea
                    {...register(
                      `stockOperationItems.${index}.itemNote` as const
                    )}
                    resize="block"
                    height={"auto"}
                    key={`itemNote${index}`}
                    placeholder="Item Note"
                  />
                  <FormErrorMessage>
                    {errors?.stockOperationItems?.[index]?.itemNote?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "15%" }}>
                <FormControl
                  mb="3"
                  isInvalid={
                    errors?.stockOperationItems?.[index]?.quantity
                      ? true
                      : false
                  }
                >
                  {index === 0 && <FormLabel>Quantity</FormLabel>}
                  <Input
                    {...register(
                      `stockOperationItems.${index}.quantity` as const,
                      {
                        validate: {
                          required: (value) => {
                            if (
                              watch[index]?.product?.type ===
                                ProductTrackingTypeOptions.SERIALIZED ||
                              value > 0
                            )
                              return true;
                            return "Give Amount";
                          }
                        }
                      }
                    )}
                    type={"number"}
                    key={`quantity${index}`}
                    disabled={
                      watch[index]?.product?.type === "SERIALIZED"
                        ? true
                        : false
                    }
                    defaultValue={
                      watch[index]?.product?.type === "SERIALIZED" ? 1 : ""
                    }
                    placeholder="Enter product quantity"
                  />
                  <FormErrorMessage>
                    {errors?.stockOperationItems?.[index]?.quantity?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>

              <ButtonGroup>
                <Button
                  mt={index === 0 ? 8 : 0}
                  colorScheme="red"
                  variant="outline"
                  leftIcon={<FaTimes />}
                  onClick={() => remove(index)}
                  isDisabled={index > 0 ? false : true}
                >
                  Remove
                </Button>
              </ButtonGroup>
            </Stack>
          );
        })}
        <Stack spacing={4}>
          <Button
            my="2"
            width="100%"
            colorScheme="blue"
            variant="outline"
            leftIcon={<FaPlus />}
            onClick={() => append({})}
          >
            Add
          </Button>
          {originLocation && (
            <Stack spacing={4} key="231433124">
              <PageCardComponent>
                <AddItemComponent
                  locationId={Number(originLocation?.value)}
                  addSingleItems={addSingleItems}
                />
              </PageCardComponent>
            </Stack>
          )}

          <Textarea
            placeholder="Enter notes for this Operation"
            {...register("notes")}
          />

          <Button
            width="100%"
            colorScheme="blue"
            onClick={distributionConfirmAlert.onOpen}
          >
            Submit
          </Button>
          <ConfirmAlertComponent
            cancelRef={cancelRef}
            onClose={distributionConfirmAlert.onClose}
            isOpen={distributionConfirmAlert.isOpen}
            onOpen={distributionConfirmAlert.onOpen}
            handleApprove={distributionHandleSubmit}
            status={distributionOperationResult}
          />
        </Stack>
      </form>
    </Stack>
  );
};
