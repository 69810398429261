import React, { useState } from "react";
import { useEffect } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast
} from "@chakra-ui/react";
import { Select, GroupBase } from "chakra-react-select";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PageCardComponent } from "../../core/components/page-card.component";
import { OriginLocationGroup, UserListGroup } from "../types/user.type";
import {
  useGetAssignUserLocationsQuery,
  useGetAssignUserQuery,
  useGetAssignUserLocationPermissionMutation
} from "../../../api";
import { userCurrentRole } from "../../../utils/common-functions";

type AssignUserLocationFormValues = {
  originLocation: OriginLocationGroup;
  userList: UserListGroup;
};

interface AssignUserToLocationComponentProps {}

export const AssignUserToLocationComponent: React.FC<
  AssignUserToLocationComponentProps
> = (props: AssignUserToLocationComponentProps) => {
  const navigate = useNavigate();
  const toast = useToast();
  const role = userCurrentRole();

  const getLocationQuery = useGetAssignUserLocationsQuery({});

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  const getUserQuery = useGetAssignUserQuery({});

  const [userListOptions, setUserListOptions] = useState<UserListGroup[]>([]);

  useEffect(() => {
    if (getUserQuery.data?.data.user) {
      const userWithValues = getUserQuery.data?.data.user.map((user) => {
        return {
          label: user.name,
          value: user.id
        };
      });
      setUserListOptions(userWithValues);
    }
  }, [getUserQuery.data]);

  const { handleSubmit, control } = useForm<AssignUserLocationFormValues>({});

  const [assignedUserPermissionCreate, assignedUserPermissionResult] =
    useGetAssignUserLocationPermissionMutation();

  const handleAssignedUserLocation = handleSubmit((data) => {
    assignedUserPermissionCreate({
      data: {
        locationId: data.originLocation.value,
        userId: data.userList.value
      }
    });
  });

  useEffect(() => {
    if (assignedUserPermissionResult.isSuccess) {
      toast({
        title: "Success",
        description: "Assigned user location successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      navigate(`/${role}/dashboard`);
    }
  }, [assignedUserPermissionResult, toast, navigate, role]);

  return (
    <Stack>
      <form onSubmit={handleAssignedUserLocation}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
        >
          <PageCardComponent>
            <Controller
              control={control}
              name="userList"
              rules={{ required: "Please select at least one user." }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => (
                <FormControl mb="3" isInvalid={!!error} id="userList">
                  <FormLabel>User List</FormLabel>
                  <Select<UserListGroup, true, GroupBase<UserListGroup>>
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    options={userListOptions}
                    placeholder="Select User"
                    closeMenuOnSelect={true}
                  />
                  <FormErrorMessage>{error && error.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </PageCardComponent>

          <PageCardComponent>
            <Controller
              control={control}
              name="originLocation"
              rules={{ required: "Please select at least one location." }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => (
                <FormControl mb="3" isInvalid={!!error} id="originLocation">
                  <FormLabel>Select Location</FormLabel>
                  <Select<
                    OriginLocationGroup,
                    true,
                    GroupBase<OriginLocationGroup>
                  >
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    options={originLocationOptions}
                    placeholder="Select Location"
                    closeMenuOnSelect={true}
                  />
                  <FormErrorMessage>{error && error.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </PageCardComponent>
        </Stack>
        <Button mt="2" width="100%" type="submit" colorScheme="blue">
          Submit
        </Button>
      </form>
    </Stack>
  );
};
