import { useEffect, useRef, useState } from "react";
import {
    Stack,
    Button,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    ButtonGroup,
    useToast,
    Textarea,
    useDisclosure,
    Box
} from "@chakra-ui/react";
import {
    useDemandInventoryMutation,
    useGetAssignUserLocationsQuery,
    useLazyGetFilterProductQuery
} from "../../../../api";
import { Select, GroupBase } from "chakra-react-select";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import {
    DestinationLocationGroup,
    ENTER_KEY,
    fieldNameType,
    OriginLocationGroup,
    ProductGroup,
    StockOperationItems
} from "../../types/stock-operation.type";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { PageCardComponent } from "../../../core/components/page-card.component";
import {
    RemoveSelectedProductFromAllProducts,
    userCurrentRole
} from "../../../../utils/common-functions";
import { ConfirmAlertComponent } from "./../confirm-alert.component";
import { ProductTrackingTypeOptions } from "../../../../api/type";
import { OperationService } from '../../../../service/operation/operation.service';

type DemandInventoryFormValues = {
    originLocation: OriginLocationGroup;
    destinationLocation: DestinationLocationGroup;
    stockOperationItems: StockOperationItems[];
    notes: string;
};
type DestinationLocation = {
    label: string;
    value: number;
};

const CmchDemandInventoryComponent = () => {
    let id = localStorage.getItem("location");
    const toast = useToast();
    const navigate = useNavigate();
    const role = userCurrentRole();
    const demandConfirmAlert = useDisclosure();
    const cancelRef = useRef();



    const [getDemandedLocationProducts, getDemandedLocationProductsResult] =
        useLazyGetFilterProductQuery();


    const getLocationQuery = useGetAssignUserLocationsQuery({});

    const [demandInventoryCreate, demandInventoryResult] =
        useDemandInventoryMutation();
    const [productOptions, setProductOptions] = useState<ProductGroup[]>([]);
    const [originLocationOptions, setOriginLocationOptions] = useState<
        OriginLocationGroup[]
    >([]);

    const [originLocation, setOriginLocation] = useState<OriginLocationGroup>();

    const [destinationLocationOptions, setDestinationLocationOptions] = useState<
        DestinationLocationGroup[]
    >([]);
    const [isAllSelected, setIsAllSelected] = useState(false);

    useEffect(() => {
        if (getLocationQuery.data?.data.locations) {
            const locationsWithValues = getLocationQuery.data?.data.locations.map(
                (location) => {
                    return {
                        label: location.name,
                        value: location.id
                    };
                }
            );
            setOriginLocationOptions(locationsWithValues);
            setDestinationLocationOptions(locationsWithValues);
        }
    }, [getLocationQuery.data]);

    useEffect(() => {
        if (originLocationOptions.length) {
            setOriginLocation(
                originLocationOptions.find((d) => d.value === Number(id))
            );
        }
    }, [id, originLocationOptions]);

    useEffect(() => {
        if (getDemandedLocationProductsResult.data?.data.products) {
            const productWithValues =
                getDemandedLocationProductsResult.data.data.products.map((product) => {
                    return {
                        label: product.name,
                        value: product.id,
                        unit: product.unit,
                        type: product.type,
                        quantity: product.quantity
                    };
                });
            setProductOptions(productWithValues);
        }
    }, [getDemandedLocationProductsResult.data]);

    const {
        register,
        handleSubmit,
        control,
        setFocus,
        setValue,
        formState: { errors }
    } = useForm<DemandInventoryFormValues>({
        defaultValues: {
            stockOperationItems: [{}]
        }
    });

    const { fields, append, remove } = useFieldArray<
        DemandInventoryFormValues,
        "stockOperationItems"
    >({
        control,
        name: "stockOperationItems"
    });

    const watch = useWatch({
        control,
        name: "stockOperationItems"
    });

    const demandHandleSubmit = handleSubmit((data) => {

        const allStockOperationItems = data.stockOperationItems.map((singleItem) => {
            return {
                productId: singleItem.product.value,
                quantity: Number(singleItem.quantity),
            }

        })
        demandInventoryCreate({
            data: {
                stockOperation: {
                    originLocationId: Number(id),
                    destinationLocationId: data.destinationLocation.value,
                    stockOperationItems: allStockOperationItems,
                    notes: data.notes
                }
            }
        });

    });

    useEffect(() => {
        if (demandInventoryResult.isSuccess) {
            toast({
                title: "Success",
                description: "Demand inventory operation successfully",
                status: "success",
                position: "top",
                duration: 2000,
                isClosable: true
            });
            navigate(`/${role}/approval/operation-details?id=${demandInventoryResult.data.data.id}&type=${demandInventoryResult.data.data.operationType}`);
        }
    }, [demandInventoryResult, toast, navigate, role]);

    const handleProductSelect = (OnChange: any, value: any, index: number) => {
        const currentIndex = index;
        OnChange(value);
        setTimeout(() => {
            focusElement(currentIndex, "quantity")
        }, 10)
    }

    const handleKeyDown = (event: any, index: number) => {
        if (event.keyCode !== ENTER_KEY) return;
        const lastIndex = fields.length - 1;
        const currentIndex = index;
        const nextIndex = index + 1;
        let previousIndex = index - 1;
        if (previousIndex < 0) {
            previousIndex = lastIndex
        }

        if (isAllSelected) {
            setTimeout(() => {
                focusElement(previousIndex, "quantity")
            }, 10)
            return;
        }

        let currentSelectedProduct = watch[currentIndex].product;
        if (lastIndex === currentIndex && currentSelectedProduct) {
            append({});
            setTimeout(() => {
                focusElement(nextIndex, "product")
            }, 10)
            return;
        }
        if (currentSelectedProduct) {

            focusElement(nextIndex);
            return;
        }
        focusElement(currentIndex, "product")

    }
    function focusElement(index: number, fieldName?: fieldNameType) {
        if (fieldName) {
            setFocus(`stockOperationItems.${index}.${fieldName}`)
            return;
        }
        let selectedProduct = watch[index].product;
        if (!selectedProduct) {
            setFocus(`stockOperationItems.${index}.product`)
            return;
        }

        setFocus(`stockOperationItems.${index}.quantity`)

    }
    const handleAddItem = () => {
        const nextIndex = fields.length;
        append({});

        setTimeout(() => {
            focusElement(nextIndex, "product")
        }, 10)

    }

    useEffect(() => {
        if (originLocation) {
            getDemandedLocationProducts({
                id: originLocation.value
            });
        }
    }, [originLocation]);



    const addAllProduct = () => {
        let allItems = productOptions.map(
            (item) => {

                return {
                    product: item,
                    quantity: 0,
                };
            }
        );
        setValue("stockOperationItems", allItems);
        setIsAllSelected(true);
        setTimeout(() => {
            const lastIndex = allItems.length - 1;
            focusElement(lastIndex, "quantity")
        }, 100)
    }
    return (
        <Stack>
            <form onSubmit={demandHandleSubmit}>
                <Stack
                    direction={{ base: "column", xl: "row" }}
                    justify="space-between"
                >
                    <PageCardComponent>
                        <FormControl mb="3">
                            <FormLabel>Origin Location</FormLabel>
                            <Input
                                _disabled={{ color: "blackAlpha.900", cursor: "not-allowed" }}
                                defaultValue={originLocation ? originLocation.label : ""}
                                isDisabled={true}
                            />
                        </FormControl>
                    </PageCardComponent>

                    <PageCardComponent>
                        <Controller
                            control={control}
                            name="destinationLocation"
                            rules={{ required: "Select destination location" }}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { error }
                            }) => (
                                <FormControl
                                    mb="3"
                                    isInvalid={!!error}
                                    id="destinationLocation"
                                >
                                    <FormLabel>Destination Location</FormLabel>
                                    <Select<
                                        DestinationLocationGroup,
                                        true,
                                        GroupBase<DestinationLocationGroup>
                                    >
                                        name={name}
                                        ref={ref}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        options={destinationLocationOptions}
                                        placeholder="Select destination"
                                        closeMenuOnSelect={true}
                                    />
                                    <FormErrorMessage>
                                        {errors.destinationLocation?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        />
                    </PageCardComponent>
                </Stack>
                <Stack spacing={4} pb="2">
                    <Button
                        my="2"
                        width="100%"
                        colorScheme="orange"
                        variant="outline"
                        leftIcon={<FaPlus />}
                        onClick={() => addAllProduct()}
                        isDisabled={isAllSelected ? true : false}
                    >
                        Add All Product
                    </Button>
                </Stack>
                <Stack spacing={4}>
                    <Button
                        my="2"
                        width="100%"
                        colorScheme="blue"
                        variant="outline"
                        leftIcon={<FaPlus />}
                        onClick={() => handleAddItem()}
                        isDisabled={isAllSelected ? true : false}
                    >
                        Add
                    </Button>
                </Stack>
                {fields.slice().reverse().map((field, index) => {
                    const currentIndex = fields.length - index - 1;
                    return (
                        <Stack
                            direction={{ base: "column", xl: "row" }}
                            width="100%"
                            bgColor={"white"}
                            borderRadius="md"
                            spacing={4}
                            px="4"
                            py="2"
                            key={field.id}
                            mt="2"
                        >

                            <Box w={{ base: "100%", md: "100%", xl: "2%" }}>
                                <FormControl>
                                    {index === 0 && <FormLabel fontSize={{
                                        base: "10",
                                        md: "10",
                                        lg: "10",
                                        xl: "10",
                                        "2xl": "12"
                                    }}>ID</FormLabel>}

                                    {fields.length - index}
                                </FormControl>
                            </Box>
                            <Box w={{ base: "100%", md: "100%", xl: "60%" }}>
                                <Controller
                                    control={control}
                                    name={`stockOperationItems.${currentIndex}.product`}
                                    rules={{ required: "Select product" }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { error }
                                    }) => (
                                        <FormControl
                                            isInvalid={!!error}
                                            id={`stockOperationItems.${currentIndex}.product`}
                                        >
                                            {index === 0 && <FormLabel fontSize={{
                                                base: "10",
                                                md: "10",
                                                lg: "10",
                                                xl: "10",
                                                "2xl": "12"
                                            }}>Select Product</FormLabel>}
                                            <Select<ProductGroup, true, GroupBase<ProductGroup>>
                                                name={name}
                                                ref={ref}
                                                onChange={(value) => { handleProductSelect(onChange, value, currentIndex) }}
                                                onBlur={onBlur}
                                                value={value}
                                                options={RemoveSelectedProductFromAllProducts(
                                                    productOptions,
                                                    watch
                                                )}
                                                placeholder="Select product"
                                                closeMenuOnSelect={true}
                                                size="md"
                                            />
                                            <FormErrorMessage>
                                                {error && error.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                />
                            </Box>
                            <Box w={{ base: "100%", md: "100%", xl: "15%" }}>
                                <FormControl >
                                    {index === 0 && <FormLabel fontSize={{
                                        base: "10",
                                        md: "10",
                                        lg: "10",
                                        xl: "10",
                                        "2xl": "12"
                                    }}>Unit</FormLabel>}
                                    <Input
                                        _disabled={{ color: "blackAlpha.900", cursor: "not-allowed" }}
                                        defaultValue={watch[currentIndex]?.product?.unit}
                                        disabled={true}
                                        placeholder="Product unit"
                                        fontSize={{
                                            base: "10",
                                            md: "10",
                                            lg: "10",
                                            xl: "10",
                                            "2xl": "12"
                                        }}
                                    />
                                </FormControl>
                            </Box>
                            <Box w={{ base: "100%", md: "100%", xl: "8%" }}>
                                <FormControl>
                                    {index === 0 && <FormLabel fontSize={{
                                        base: "10",
                                        md: "10",
                                        lg: "10",
                                        xl: "10",
                                        "2xl": "12"
                                    }}>Available</FormLabel>}
                                    <Input
                                        _disabled={{
                                            color: "blackAlpha.900",
                                            cursor: "not-allowed"
                                        }}
                                        defaultValue={watch[currentIndex]?.product?.quantity}
                                        disabled={true}
                                        placeholder="Available"
                                        fontSize={{
                                            base: "10",
                                            md: "10",
                                            lg: "10",
                                            xl: "10",
                                            "2xl": "12"
                                        }}
                                    />
                                </FormControl>
                            </Box>
                            <Box w={{ base: "100%", md: "100%", xl: "12%" }}>
                                <FormControl>

                                    {index === 0 && <FormLabel fontSize={{
                                        base: "10",
                                        md: "10",
                                        lg: "10",
                                        xl: "10",
                                        "2xl": "12"
                                    }} >Estimated Balance</FormLabel>}
                                    <Input
                                        _disabled={{
                                            color: "blackAlpha.900",
                                            cursor: "not-allowed"
                                        }}
                                        fontSize={{
                                            base: "10",
                                            md: "10",
                                            lg: "10",
                                            xl: "10",
                                            "2xl": "12"
                                        }}

                                        value={Number(watch[currentIndex]?.product?.quantity) + Number(watch[currentIndex]?.quantity)}
                                        disabled={true}
                                        placeholder="Available"

                                    />
                                </FormControl>
                            </Box>
                            <Box w={{ base: "100%", md: "100%", xl: "15%" }}>

                                <FormControl
                                    mb="3"
                                    isInvalid={
                                        errors?.stockOperationItems?.[currentIndex]?.quantity ? true : false
                                    }
                                >
                                    {index === 0 && <FormLabel fontSize={{
                                        base: "10",
                                        md: "10",
                                        lg: "10",
                                        xl: "10",
                                        "2xl": "12"
                                    }}>Quantity</FormLabel>}
                                    <Input
                                        {...register(
                                            `stockOperationItems.${currentIndex}.quantity` as const,
                                            {
                                                validate: {
                                                    required: value => {
                                                        if (value > 0) return true
                                                        return " Give valid Amount";
                                                    },
                                                }
                                            }
                                        )}
                                        type={"number"}
                                        placeholder="Enter product quantity"
                                        onKeyDown={(e) => handleKeyDown(e, currentIndex)}
                                        fontSize={{
                                            base: "10",
                                            md: "10",
                                            lg: "10",
                                            xl: "10",
                                            "2xl": "12"
                                        }}
                                    />
                                    <FormErrorMessage>
                                        {errors?.stockOperationItems?.[currentIndex]?.quantity?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </Box>
                            <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                                <ButtonGroup>
                                    <Button
                                        mt={index === 0 ? 8 : 0}
                                        colorScheme="red"
                                        variant="outline"
                                        leftIcon={<FaTimes />}
                                        onClick={() => remove(currentIndex)}
                                        size="sm"
                                    >
                                        Remove
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Stack>
                    );
                })}
                <Stack spacing={4}>

                    <Textarea
                        placeholder="Enter notes for this Operation"
                        {...register("notes")}
                    />
                    <Button
                        width="100%"
                        colorScheme="blue"
                        onClick={demandConfirmAlert.onOpen}
                    >
                        Submit
                    </Button>
                    <ConfirmAlertComponent
                        cancelRef={cancelRef}
                        onClose={demandConfirmAlert.onClose}
                        isOpen={demandConfirmAlert.isOpen}
                        onOpen={demandConfirmAlert.onOpen}
                        handleApprove={demandHandleSubmit}
                        status={demandInventoryResult}
                    />
                </Stack>
            </form>
        </Stack>
    );
};
export default CmchDemandInventoryComponent;
