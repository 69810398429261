export const env = {
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT || "192.168.0.175:1337",
  organizationLogo:
    process.env.REACT_APP_ORGANIZATION_LOGO ||
    "https://i.ibb.co/JvStGfv/dghs-Logo-removebg.png",
  reportLogo:
    process.env.REACT_APP_REPORT_BACKGROUND_LOGO ||
    "https://i.ibb.co/5R5MHZF/imageedit-1-6201570837.png",
  uiType: process.env.REACT_APP_UI_TYPE || "CMCH",
  organizationFullName:
    process.env.REACT_APP_ORGANIZATION_FULL_NAME || "BINDULOGIC LTD",
  organizationShortName:
    process.env.REACT_APP_ORGANIZATION_SHORT_NAME || "BINDULOGIC",
  organizationNameBangla:
    process.env.REACT_APP_ORGANIZATION_FULL_NAME_BANGLA ||
    "বিন্দু: লজিক হাসপাতাল",
  organizationAddress:
    process.env.REACT_APP_ORGANIZATION_ADDRESS || "Dhaka, Bangladesh",
  organizationAddressBangla:
    process.env.REACT_APP_ORGANIZATION_ADDRESS_BANGLA || "ঢাকা, বাংলাদেশ",
  prescriptionModule: process.env.REACT_APP_PRESCRIPTION_MODULE === "true",
  appUrl: process.env.REACT_APP_URL || "http://localhost:3000"
};

export const customPagination = {
  pageIndex: 0,
  pageSize: 100
};

export const displayAllTablePage: number[] = [100, 200, 300, 500, 1000];
