import {
  Stack,
  Box,
  Heading,
  Text,
  Flex,
  Spacer,
  Button
} from "@chakra-ui/react";
import { FaBriefcaseMedical, FaMapMarkedAlt, FaMapSigns } from "react-icons/fa";
import {
  useDashboardCountQuery,
  useGetLowStockQuery,
  useGetToBeExpiredItemsQuery
} from "../../../api";
import DashboardCountChartComponent from "./dashboard-count-chart.component";
import DashboardPendingOperationComponent from "./dashboard-pending-operation.component";
import { GetLowStockRequest } from "../../../api/type";
import { useNavigate } from "react-router-dom";
import { userCurrentRole } from "../../../utils/common-functions";
import { FaEye, FaPlus } from "react-icons/fa";
interface IDashboardProps {}

const DashboardComponent = (props: IDashboardProps) => {
  let locationId = localStorage.getItem("location");

  const navigate = useNavigate();
  const role = userCurrentRole();
  const clickLowStockDetails = () => {
    navigate(`/${role}/low-stock`);
  };
  const { data } = useDashboardCountQuery({
    query: {
      locationId: Number(locationId),
      role: role
    }
  });

  const clickExpiredDetails = () => {
    navigate(`/${role}/to-be-expired`);
  };

  const LowStockItems = useGetLowStockQuery({
    query: {
      locationId: Number(locationId),
      role: role
    }
  });

  const ItemsToBeExpired = useGetToBeExpiredItemsQuery({
    query: {
      locationId: Number(locationId),
      role: role
    }
  });

  const PageCardComponent = (props: {
    title: string;
    count: number;
    icon: any;
  }) => {
    return (
      <Stack p="5" bg="white" borderRadius="lg" boxShadow="sm" width="100%">
        <Flex alignItems="center">
          <Box>
            <Text fontSize="sm" fontWeight="medium" color="grey">
              {props.title}
            </Text>
            <Heading size="md">{props.count}</Heading>{" "}
          </Box>
          <Spacer />
          <Box
            bgColor="#2B6CB0"
            p={3}
            borderRadius="full"
            color="white"
            fontSize="xl"
          >
            {props.icon}
          </Box>
        </Flex>
      </Stack>
    );
  };

  return (
    <Box>
      <Stack direction={{ base: "column", xl: "row" }}>
        <PageCardComponent
          title="All Products"
          count={data?.data?.productCount as number}
          icon={<FaBriefcaseMedical />}
        />
        <PageCardComponent
          title="All Operations"
          count={data?.data?.operationCount as number}
          icon={<FaMapSigns />}
        />
        <PageCardComponent
          title="Locations"
          count={data?.data.locationCount as number}
          icon={<FaMapMarkedAlt />}
        />
      </Stack>
      {LowStockItems.isSuccess && (
        <Stack
          direction={{ base: "column", xl: "row" }}
          onClick={() => clickLowStockDetails()}
          my="3"
        >
          <PageCardComponent
            title="Low Stock Product"
            count={LowStockItems.data.lowStockCount}
            icon={<FaEye />}
          />
        </Stack>
      )}
      {ItemsToBeExpired.isSuccess && (
        <Stack
          direction={{ base: "column", xl: "row" }}
          my="3"
          onClick={() => clickExpiredDetails()}
        >
          <PageCardComponent
            title="Items To Be Expired"
            count={ItemsToBeExpired.data.totalItems}
            icon={<FaEye />}
          />
        </Stack>
      )}
      <Stack
        direction={{ base: "column", xl: "row" }}
        justify="space-between"
        my="3"
      >
        <Stack width={"55%"}>
          <DashboardPendingOperationComponent />
        </Stack>
        <Stack width={"45%"}>
          <DashboardCountChartComponent data={data?.data} />
        </Stack>
      </Stack>
    </Box>
  );
};

export default DashboardComponent;
