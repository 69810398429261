import React from "react";
import { Stack } from "@chakra-ui/react";
import { SingleProductMovementByLocationComponent } from "../components/single-product-movement-by-location.component";

interface SingleProductMovementByLocationPageProps {}

export const SingleProductMovementByLocationPage: React.FC<
  SingleProductMovementByLocationPageProps
> = (props: SingleProductMovementByLocationPageProps) => {
  return (
    <Stack>
      <SingleProductMovementByLocationComponent />
    </Stack>
  );
};
