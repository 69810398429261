import React from "react";
import { Stack } from "@chakra-ui/react";
import ConsumptionComponent from "../components/consumption.component";
import { allUIType } from "../../../api/type";
import { env } from '../../../config/index';
import CmchConsumptionComponent from "../components/cmch/cmch-consumption.component";

interface ConsumptionPageProps { }

export const ConsumptionPage: React.FC<ConsumptionPageProps> = (
  props: ConsumptionPageProps
) => {
  return (
    <Stack>
      {
        env.uiType === allUIType.CMCH ? < CmchConsumptionComponent /> : <ConsumptionComponent />
      }

    </Stack>
  );
};
