import React from "react";
import { Stack } from "@chakra-ui/react";
import { ToBeExpiredComponent } from "../components/to-be-expired.component";

interface ToBeExpiredPageProps {}

export const ToBeExpiredPage: React.FC<ToBeExpiredPageProps> = (
  props: ToBeExpiredPageProps
) => {
  return (
    <Stack>
      <ToBeExpiredComponent />
    </Stack>
  );
};
