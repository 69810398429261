import React from "react";
import { DetailProductComponent } from "../components/detail-product.component";

interface DetailProductsPageProps {}

export const DetailProductsPage: React.FC<DetailProductsPageProps> = (
  props: DetailProductsPageProps
) => {
  return (
    <>
      <DetailProductComponent />
    </>
  );
};
