import React from "react";
import { BlobProvider } from "@react-pdf/renderer";
import { useGetOperationPrintDataQuery } from "../../../api";
import { OperationPrintComponent } from "./operation-print.component";

interface OperationDetailsPrintPreviewProps {
  id: number;
}

export const OperationDetailsPrintPreview: React.FC<
  OperationDetailsPrintPreviewProps
> = (props: OperationDetailsPrintPreviewProps) => {
  const opearitonID = props.id;

  const { data } = useGetOperationPrintDataQuery({
    data: { report: { operationId: Number(opearitonID) } }
  });

  return (
    <>
      {data && (
        <BlobProvider document={<OperationPrintComponent printData={data} />}>
          {({ url, ...rest }) => {
            return (
              url && (
                <a
                  href={url as string}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Report Print
                </a>
              )
            );
          }}
        </BlobProvider>
      )}
    </>
  );
};
