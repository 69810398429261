import { ForgotPasswordPage } from "../modules/auth/pages/forgot-password.page";
import { LoginPage } from "../modules/auth/pages/login.page";
import { ResetPasswordPage } from '../modules/auth/pages/reset-password.page';
export const publicRoutes = {
  pages: [
    {
      name: "Login Page",
      link: "/login",
      content: <LoginPage />
    },
    {
      name: "Forgot Password",
      link: "/forgot-password",
      content: <ForgotPasswordPage />
    },
    {
      name: "Forgot Password",
      link: "/reset-password/:userId",
      content: <ResetPasswordPage />
    }
  ]
};
