import footerItem from "./footerItem";

const footer = (doc, fontSize, lineSpacing) => {
  const pageHeight = doc.internal.pageSize.height;
  let startY = pageHeight - 40;

  let startX = 42;
  const pageWidth = doc.internal.pageSize.width;
  const endX = pageWidth - startX;
  const thirdX = (pageWidth - startX * 2) / 2;

  doc.setDrawColor(157, 183, 128);
  doc.setLineWidth(0.5);
  doc.line(startX, startY, endX, startY);
  startY += lineSpacing / 3;

  doc.setFontSize(fontSize);

  //-------Contact Details---------------------
  let footerLeft = {
    title: "Authorized By"
  };
  let footerRight = {
    title: "Verified By"
  };
  footerItem(doc, footerLeft, startX, startY, lineSpacing);
  startX += thirdX;

  // //-------Bank Details---------------------
  // footerItem(doc, data.personalInfo.bank, data.label.bank, startX, startY, lineSpacing);
  // startX += thirdX;

  //-------Tax Details---------------------
  footerItem(doc, footerRight, startX, startY, lineSpacing);
};
export default footer;
