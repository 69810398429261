import React from "react";
import AddProductComponent from "../components/add-product.component";

interface AddProductPageProps {}

export const AddProductPage: React.FC<AddProductPageProps> = (
  props: AddProductPageProps
) => {
  return (
    <>
      <AddProductComponent />
    </>
  );
};
