import {
  Badge,
  Box,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Tr
} from "@chakra-ui/react";
import React from "react";
import { useGetUserProfileQuery } from "../../../api";
import { ChangePasswordComponent } from "../../auth/components/change-password.component";

import { PageCardComponent } from "../../core/components/page-card.component";

interface UserProfileComponentProps { }

export const UserProfileComponent: React.FC<UserProfileComponentProps> = (
  props: UserProfileComponentProps
) => {
  const { data } = useGetUserProfileQuery({});
  return (
    <Stack>
      <PageCardComponent>
        <Box>
          <Stack>
            <Table size={"sm"} variant={"simple"}>
              <Tbody>
                <Tr>
                  <Th>Name</Th>
                  <Td>:</Td>
                  <Td>{data?.data.user.name}</Td>
                </Tr>
                <Tr>
                  <Th>Role</Th>
                  <Td>:</Td>
                  <Td>
                    {data?.data.user.roles.map((d, i) => (
                      <React.Fragment key={i}>{d.roleName}</React.Fragment>
                    ))}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Locations</Th>
                  <Td>:</Td>
                  <Td>
                    {data?.data.user.locations.map((d, i) => (
                      <Badge
                        key={i}
                        mr="3"
                        variant="subtle"
                        colorScheme="green"
                      >
                        {d.name}
                      </Badge>
                    ))}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Stack>
        </Box>
      </PageCardComponent>
      <Box>
        <Heading size={"sm"} mt="3">
          Change Password
        </Heading>
      </Box>
      <ChangePasswordComponent />
    </Stack>
  );
};
