import React from "react";
import { useLocation } from "react-router-dom";
import DemandOperationDetailsComponent from "../components/demand-operation-details.component";
import TransferOperationDetailsComponent from "../components/transfer-operation-details.component";
import { VendorDemandOperationDetailsComponent } from "../components/vendor-demand-operation-details.component";
import { RetrieveQueryParams } from '../../../service/operation/retrieveQueryParams.service';


export default function ApprovalOperationDetailsPage() {

  let query = RetrieveQueryParams();
  return (
    <div>
      {(query.get("type") === "TRANSFER" ||
        query.get("type") === "VENDOR_SUPPLY") && (
          <TransferOperationDetailsComponent
            id={query.get("id")}
            type={query.get("type")}
          />
        )}
      {query.get("type") === "DEMAND" && (
        <DemandOperationDetailsComponent
          id={query.get("id")}
          type={query.get("type")}
        />
      )}
      {query.get("type") === "VENDOR_DEMAND" && (
        <VendorDemandOperationDetailsComponent
          id={query.get("id")}
          type={query.get("type")}
          locationId={query.get("locationId")}
        />
      )}
    </div>
  );
}
