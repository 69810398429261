const footerItem = (doc, data, startX, startY, lineSpacing) => {
  const padding = lineSpacing / 2;
  let tempY = startY;

  //const dataArr = Object.values(data);

  //doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);
  //doc.text(data.title, startX + padding, (tempY += lineSpacing));
  tempY += lineSpacing;

  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);

  //doc.text(data.title, startX + padding, (tempY += lineSpacing));
  startY += lineSpacing * 1.5;
  doc.line(startX, startY, startX + 80, startY);

  doc.setDrawColor(157, 183, 128);
  doc.setLineWidth(1);
  doc.line(startX + 1, startY + padding, startX + 1, tempY + padding / 3);
  startY += lineSpacing;
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);
  doc.text(data.title, startX + padding, startY);
};
export default footerItem;
