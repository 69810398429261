import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button
} from "@chakra-ui/react";
import { DistributedProduct } from "../../../api/type";
interface AllDistributionTableComponentProps {
  overAllDistributedProducts: DistributedProduct[];
}

export const AllDistributionTableComponent: React.FC<
  AllDistributionTableComponentProps
> = (props: AllDistributionTableComponentProps) => {
  return (
    <Stack>
      <Accordion allowMultiple>
        <AccordionItem>
          <Button alignItems={"end"} bg="blue.100">
            <AccordionButton>
              <Box as="span" flex="1" textAlign="center">
                Total Distribution
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Button>
          <AccordionPanel pb={4}>
            <TableContainer>
              <Table variant="striped">
                <TableCaption>Total Distributed Products</TableCaption>
                <Thead>
                  <Tr bg="#2D3748">
                    <Th color="white">NO</Th>
                    <Th color="white">PRODUCT ID</Th>
                    <Th color="white">NAME</Th>
                    <Th color="white">GENERIC NAME</Th>
                    <Th isNumeric color="white">
                      QUANTITY
                    </Th>
                    <Th color="white">UNIT</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {props.overAllDistributedProducts.map((item, index) => (
                    <Tr>
                      <Td>{index + 1}</Td>
                      <Td>{item.productId}</Td>
                      <Td>{item.name}</Td>
                      <Td>{item.genericName}</Td>
                      <Td isNumeric>{item.quantity}</Td>
                      <Td>{item.unit}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};
