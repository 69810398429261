import dayjs from "dayjs";
import moment from "moment";
const addressSender = (doc, report, startY, fontSizes, lineSpacing, Type) => {
  let startX = 42;
  const spaceBetweenWords = 8;
  const pageWidth = doc.internal.pageSize.width;
  const pageCenterX = pageWidth / 2;
  let reportType;
  let date;



  if (Type === "monthlyReport") {
    reportType = "Monthly Report";
    date = dayjs(report.data.startDate).format("MMMM  YYYY");
  } else {
    reportType = "Daily Report";
    date = dayjs(report.data.startDate).format("D  MMMM  YYYY");
  }

  //-------Sender Info Draw Line and Graphic---------------------
  let endX = doc.internal.pageSize.width - startX;
  doc.setLineWidth(0.5);
  doc.line(startX, startY + lineSpacing / 2, endX, startY + lineSpacing / 2);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);

  //-------Sender Info Address---------------------
  doc.setFontSize(fontSizes.SubTitleFontSize, doc.vars.fontWeightBold);

  //dailyReport = Object.values(dailyReport.Location);

  // @todo: more dynamic slice arrays
  //const addressStart = address.slice(0, 3);
  //const addressEnd = address.slice(3);

  //   dailyReport.map((text) => {
  //     if (text) {
  //       doc.text(text, startX, startY);
  //       startX =
  //         startX + doc.getStringUnitWidth(text) * fontSize + spaceBetweenWords;
  //     }
  //   });

  doc.text(reportType, startX, startY);
  startX =
    startX +
    doc.getStringUnitWidth(reportType) * fontSizes.SubTitleFontSize +
    spaceBetweenWords;

  // startX =
  //   startX +
  //   doc.getStringUnitWidth(dailyReport.printOutBy.name) * fontSize +
  //   spaceBetweenWords;

  //end address line
  // doc.text("Daily Report", pageCenterX, startY, "right");

  doc.text(date, endX, startY, "right");
  // endX =
  //   endX -
  //   doc.getStringUnitWidth(`${moment(Date.now()).format("D  MMMM  YYYY")}`) *
  //     fontSize -
  //   spaceBetweenWords;

  // addressEnd.map(text => {
  //     if (text) {
  //         doc.text(text, endX, startY, 'right');
  //         endX = endX - doc.getStringUnitWidth(text) * fontSize - spaceBetweenWords;
  //     }
  // });

  return startY;
};

export default addressSender;
