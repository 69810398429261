import { useEffect, useState } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useToast,
  Flex,
  Box,
  Checkbox
} from "@chakra-ui/react";
import { CreatableSelect, GroupBase, Select } from "chakra-react-select";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { PageCardComponent } from "../../core/components/page-card.component";
import {
  DepartmentGroup,
  ProductStatus,
  TrackingTypeGroup,
  UnitGroup
} from "../type/product.type";
import {
  useEditProductMutation,
  useGetProductDetailsQuery,
  useLazySearchGenericsQuery
} from "../../../api";
import {
  unitOptions,
  departmentOptions,
  trackingTypeOptions,
  productStatusOptions
} from "../data/product.data";
import { userCurrentRole } from "../../../utils/common-functions";
import { IoMdArrowRoundBack } from "react-icons/io";

type EditProductFormValues = {
  name: string;
  isEdcl: boolean;
  ownerDepartment: DepartmentGroup;
  unit: UnitGroup;
  trackingType: TrackingTypeGroup;
  isDisabled: boolean;
  lowStockAmount: number;
};

interface EditProductComponentProps {}

export const EditProductComponent: React.FC<EditProductComponentProps> = (
  props: EditProductComponentProps
) => {
  const navigate = useNavigate();
  const role = userCurrentRole();
  const toast = useToast();
  const { id } = useParams();
  const { data } = useGetProductDetailsQuery({
    data: {
      product: {
        id: Number(id)
      }
    }
  });

  const [name, setName] = useState<string>();
  const [isEdcl, setIsEdcl] = useState<boolean>(false);
  const [lowStockAmount, setLowStockAmount] = useState<number>();
  const [unit, setUnit] = useState<UnitGroup>();
  const [ownerDepartment, setOwnerDepartment] = useState<DepartmentGroup>();
  const [trackingType, setTrackingType] = useState<TrackingTypeGroup>();
  const [productStatus, setProductStatus] = useState<ProductStatus>();
  const [selectValue, setSelectValue] = useState("");
  const [selectedGeneric, setSelectedGeneric] = useState<{
    value: string;
    label: string;
  }>();
  const [searchGenerics, searchGenericsResult] = useLazySearchGenericsQuery();

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<EditProductFormValues>();
  useEffect(() => {
    if (data?.data.product) {
      setName(data.data.product.name);
      setIsEdcl(data.data.product.isEdcl);
      setLowStockAmount(data.data.product.lowStockAmount);
      setUnit({
        label: data.data.product.unit,
        value: data.data.product.unit
      });
      setOwnerDepartment({
        label: data.data.product.ownerDepartment,
        value: data.data.product.ownerDepartment
      });
      setTrackingType({
        label: data.data.product.trackingType,
        value: data.data.product.trackingType
      });
      setProductStatus({
        label: data.data.product.isDisabled === true ? "Inactive" : "Active",
        value: data.data.product.isDisabled
      });
    }
  }, [data]);

  const handleUnitSelect = (unit: any) => {
    setUnit(unit);
  };

  const handleTrackingTypeSelect = (trackingType: any) => {
    setTrackingType(trackingType);
  };

  const handleOwnerDepartmentSelect = (ownerDepartment: any) => {
    setOwnerDepartment(ownerDepartment);
  };

  const handleProductStatus = (status: any) => {
    setProductStatus(status);
  };

  const handleChangeEdcl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEdcl(event.target.checked);
  };

  const [editProductCreate, editProductResult] = useEditProductMutation();

  const handleEditProduct = handleSubmit(() => {
    if (
      unit &&
      name &&
      ownerDepartment &&
      trackingType &&
      productStatus &&
      selectedGeneric &&
      lowStockAmount !== undefined
    ) {
      editProductCreate({
        data: {
          product: {
            id: Number(id),
            name: name,
            unit: unit?.value,
            ownerDepartment: ownerDepartment?.value,
            trackingType: trackingType?.value,
            isDisabled: productStatus?.value,
            medicineGenericId: selectedGeneric.value,
            isEdcl,
            lowStockAmount
          }
        }
      });
    }
  });

  useEffect(() => {
    if (editProductResult.isSuccess) {
      toast({
        title: "Success",
        description: "Update product successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      setTimeout(() => {
        navigate(`/${role}/all-products`);
      }, 2000);
    }
  }, [editProductResult, toast, navigate, role]);

  useEffect(() => {
    if (selectValue.length >= 3) {
      searchGenerics({ query: { keyword: selectValue } });
    }
  }, [selectValue]);

  useEffect(() => {
    if (data) {
      const label = data?.data.product.medicineGeneric?.genericName as string;
      const value = data?.data.product.medicineGeneric?.id as string;

      setSelectedGeneric({ label: label, value: value });
    }
  }, [data]);

  return (
    <Stack>
      <Flex justifyContent="end">
        <Button
          colorScheme="blue"
          leftIcon={<IoMdArrowRoundBack />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Flex>
      <form onSubmit={handleEditProduct}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
        >
          <Box w={{ base: "100%", md: "50%", xl: "50%" }}>
            <PageCardComponent>
              <FormControl isInvalid={errors.name ? true : false}>
                <FormLabel>Product Name</FormLabel>
                <Input
                  defaultValue={data?.data.product.name}
                  onChange={(e) => setName(e.target.value)}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
            </PageCardComponent>
          </Box>
          <Box w={{ base: "100%", md: "25%", xl: "25%" }} alignItems="center">
            <PageCardComponent>
              <FormControl>
                <FormLabel>Product Category</FormLabel>
                <Checkbox isChecked={isEdcl} onChange={handleChangeEdcl}>
                  EDCL
                </Checkbox>
              </FormControl>
            </PageCardComponent>
          </Box>
          <Box w={{ base: "100%", md: "25%", xl: "25%" }}>
            <PageCardComponent>
              <FormControl id="genericName">
                <FormLabel>Generic Name (optional)</FormLabel>
                <CreatableSelect
                  onInputChange={(input) => {
                    setSelectValue(input);
                  }}
                  options={searchGenericsResult.data?.genericNames.map(
                    (item) => ({
                      label: item.genericName,
                      value: item.id
                    })
                  )}
                  value={selectedGeneric}
                  onChange={(event) => {
                    if (event && !event.hasOwnProperty("__isNew__")) {
                      setSelectedGeneric({
                        value: event.value,
                        label: event.label
                      });
                    } else if (event) {
                      setSelectedGeneric({
                        value: "",
                        label: ""
                      });
                    }
                  }}
                  placeholder="Generic Name"
                />
              </FormControl>
            </PageCardComponent>
          </Box>
        </Stack>

        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
          my="2"
        >
          <PageCardComponent>
            <Controller
              control={control}
              name="unit"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormControl id="unit">
                  <FormLabel>Unit</FormLabel>
                  <Select<UnitGroup, true, GroupBase<UnitGroup>>
                    name={name}
                    ref={ref}
                    onBlur={onBlur}
                    value={unit}
                    onChange={(unit) => handleUnitSelect(unit)}
                    options={unitOptions}
                    placeholder="Select unit"
                    closeMenuOnSelect={true}
                  />
                </FormControl>
              )}
            />
          </PageCardComponent>

          <PageCardComponent>
            <Controller
              control={control}
              name="ownerDepartment"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormControl id="ownerDepartment">
                  <FormLabel>Department</FormLabel>
                  <Select
                    name={name}
                    ref={ref}
                    onBlur={onBlur}
                    value={ownerDepartment}
                    onChange={(ownerDepartment) =>
                      handleOwnerDepartmentSelect(ownerDepartment)
                    }
                    options={departmentOptions}
                    closeMenuOnSelect={true}
                  />
                </FormControl>
              )}
            />
          </PageCardComponent>

          <PageCardComponent>
            <Controller
              control={control}
              name="trackingType"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormControl id="trackingType">
                  <FormLabel>Product Type</FormLabel>
                  <Select
                    name={name}
                    ref={ref}
                    onChange={(trackingType) =>
                      handleTrackingTypeSelect(trackingType)
                    }
                    onBlur={onBlur}
                    value={trackingType}
                    options={trackingTypeOptions}
                    isDisabled={true}
                    closeMenuOnSelect={true}
                  />
                </FormControl>
              )}
            />
          </PageCardComponent>

          <PageCardComponent>
            <FormControl>
              <FormLabel>Status</FormLabel>
              <Select
                onChange={(status) => handleProductStatus(status)}
                value={productStatus}
                options={productStatusOptions}
                closeMenuOnSelect={true}
              />
            </FormControl>
          </PageCardComponent>

          <PageCardComponent>
            <FormControl>
              <FormLabel>Low Stock Amount</FormLabel>
              <Input
                type="number"
                value={lowStockAmount}
                onChange={(e) => setLowStockAmount(Number(e.target.value))}
              />
            </FormControl>
          </PageCardComponent>
        </Stack>

        <Stack width="100%">
          <Button type="submit" colorScheme="blue">
            Update Product
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};
