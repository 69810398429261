import React from "react";
import { Stack } from "@chakra-ui/react";
import { AllVendorDemandComponent } from "../components/all-vendor-demand.component";

interface AllVendorDemandPageProps {}

export const AllVendorDemandPage: React.FC<AllVendorDemandPageProps> = (
  props: AllVendorDemandPageProps
) => {
  return (
    <Stack>
      <AllVendorDemandComponent />
    </Stack>
  );
};
