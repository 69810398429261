import { UserRoleGroup } from "../types/user.type";

export const roleOptions: UserRoleGroup[] = [
  {
    label: "SuperAdmin",
    value: "SUPERADMIN"
  },
  {
    label: "Admin",
    value: "ADMIN"
  },
  {
    label: "StoreManager",
    value: "STOREMANAGER"
  },
  {
    label: "SubstoreManager",
    value: "SUBSTOREMANAGER"
  },
  {
    label: "WardManager",
    value: "WARDMANAGER"
  },
  {
    label: "Seller",
    value: "SELLER"
  },
  {
    label: "Distributor",
    value: "DISTRIBUTOR"
  },
  {
    label: "Auditor",
    value: "AUDITOR"
  },
];
