import React from "react";
import { Stack } from "@chakra-ui/react";
import { SingleProductMovementByDateComponent } from "../components/single-product-movement-by-date.component";

interface SingleProductMovementByDatePageProps {}

export const SingleProductMovementByDatePage: React.FC<
  SingleProductMovementByDatePageProps
> = (props: SingleProductMovementByDatePageProps) => {
  return (
    <Stack>
      <SingleProductMovementByDateComponent />
    </Stack>
  );
};
