import { env } from "../../../config";
import MonthlyProductReportComponent from "../components/monthly-product-report.component";
import { CmchMonthlyProductReportComponent } from "../components/cmch/cmch-monthly-product-report.component";

export default function MonthlyProductReportPage() {
  return (
    <div>
      {env.organizationShortName === "CMCH" ? (
        <CmchMonthlyProductReportComponent />
      ) : (
        <MonthlyProductReportComponent />
      )}
    </div>
  );
}
