import React from "react";
import {
  Box,
  Flex,
  Heading,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Spacer
} from "@chakra-ui/react";
import { PageCardComponent } from "../../core/components/page-card.component";
import { useGetAllOperationDetailsQuery } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { IoMdArrowRoundBack } from "react-icons/io";
import { OperationDetailsPrintComponent } from "../../print/components/operation-details-print.component";
import { userCurrentRole } from "../../../utils/common-functions";
import { RetrieveQueryParams } from "../../../service/operation/retrieveQueryParams.service";
import { NotesModalComponent } from "./notes-modal.component";

interface OperationDetailsProps {}

const OperationDetailsComponent: React.FC<OperationDetailsProps> = (
  props: OperationDetailsProps
) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const role = userCurrentRole();
  const { data } = useGetAllOperationDetailsQuery({
    data: { report: { operationId: Number(id) } }
  });

  let query = RetrieveQueryParams();
  const goBack = () => {
    if (query.get("locationId") && query.get("selectedSegmentaion")) {
      navigate(
        `/${role}/report/location-operations?locationId=${query.get(
          "locationId"
        )}&selectedSegmentaion=${query.get("selectedSegmentaion")}`
      );
    } else {
      navigate(-1);
    }
  };
  return (
    <Stack>
      <Flex justifyContent="end">
        <Button
          colorScheme="blue"
          leftIcon={<IoMdArrowRoundBack />}
          onClick={() => goBack()}
        >
          Back
        </Button>
      </Flex>
      <Stack spacing={8} direction={{ base: "column", lg: "row" }}>
        <PageCardComponent>
          <Stack>
            <Table size={"sm"} variant={"unstyled"}>
              <Tr>
                <Th>Origin Location</Th>
                <Td>:</Td>
                <Td>{data?.data.operationDetails.originLocation?.name}</Td>
              </Tr>
              <Tr>
                <Th>Operation Type</Th>
                <Td>:</Td>
                <Td>{data?.data.operationDetails.operationType}</Td>
              </Tr>
              <Tr>
                <Th>Operation Status</Th>
                <Td>:</Td>
                <Td>{data?.data.operationDetails.operationStatus}</Td>
              </Tr>
              <Tr>
                <Th>Operation By</Th>
                <Td>:</Td>
                <Td>{data?.data.operationDetails.createdBy?.name}</Td>
              </Tr>
              <Tr>
                <Th>Phone</Th>
                <Td>:</Td>
                <Td>{data?.data.operationDetails.createdBy?.contact}</Td>
              </Tr>
            </Table>
          </Stack>
        </PageCardComponent>

        <PageCardComponent>
          <Stack>
            <Table size={"sm"} variant={"unstyled"}>
              <Tr>
                <Th>Destination Location</Th>
                <Td>:</Td>
                <Td>
                  {data?.data.operationDetails?.destinationLocation?.name}
                </Td>
              </Tr>

              <Tr>
                <Th>Date</Th>
                <Td>:</Td>
                <Td>
                  {" "}
                  {dayjs(data?.data.operationDetails.createdAt).format(
                    "ddd, MMM D, YYYY h:mm A"
                  )}
                </Td>
              </Tr>
              <Tr>
                <Th>Notes</Th>
                <Td>:</Td>
                <Td>{data?.data.operationDetails.notes}</Td>
                {role === "admin" &&
                  data?.data.operationDetails &&
                  (data?.data.operationDetails.notes ||
                    data?.data.operationDetails.notes === "" ||
                    data.data.operationDetails.notes === null) && (
                    <NotesModalComponent
                      id={data.data.operationDetails.id}
                      note={data?.data.operationDetails.notes}
                    />
                  )}
              </Tr>
              <Tr>
                <Th>Operation ID</Th>
                <Td>:</Td>
                <Td fontWeight={700}>{data?.data.operationDetails.id}</Td>
              </Tr>
              <Tr>
                <Th> Invoice Number</Th>
                <Td>:</Td>
                <Td fontWeight={700}>
                  {data?.data.operationDetails.invoiceNo}
                </Td>
              </Tr>
            </Table>
          </Stack>
        </PageCardComponent>
      </Stack>
      {data?.data.operationDetails.prescription && (
        <Stack>
          <PageCardComponent>
            <Flex alignItems="center">
              <Box>
                <Heading as="h1" size="sm">
                  Distribution To
                </Heading>
              </Box>
              <Spacer />
            </Flex>
            <Stack>
              <Table size={"sm"} variant={"unstyled"}>
                <Tr>
                  <Th>prescription ID</Th>
                  <Td>:</Td>
                  <Td>{data?.data.operationDetails.prescription.id}</Td>
                </Tr>
                <Tr>
                  <Th>Type</Th>
                  <Td>:</Td>
                  <Td>{data?.data.operationDetails.prescription.type}</Td>
                </Tr>
                <Tr>
                  <Th>patient Name</Th>
                  <Td>:</Td>
                  <Td>
                    {data?.data.operationDetails.prescription.patient?.name}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Contact Number</Th>
                  <Td>:</Td>
                  <Td>
                    {
                      data?.data.operationDetails.prescription.patient
                        ?.contactNumber
                    }
                  </Td>
                </Tr>
              </Table>
            </Stack>
          </PageCardComponent>
        </Stack>
      )}
      <PageCardComponent>
        <Flex alignItems="center">
          <Box>
            <Heading as="h1" size="sm">
              Operation Summary
            </Heading>
          </Box>
          <Spacer />
          <Box>
            {data?.data?.operationDetails?.id && (
              <OperationDetailsPrintComponent
                id={data?.data.operationDetails.id}
              />
            )}
          </Box>
        </Flex>
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr bg="gray.700">
                <Th color="white">ID</Th>
                <Th color="white">Product Name</Th>
                <Th color="white">Tracking Number</Th>
                <Th color="white">Item Note</Th>
                <Th color="white">Total Amount</Th>
                <Th color="white">Unit</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.data?.operationDetails?.stockOperationItems?.map(
                (data, key) => (
                  <Tr key={key}>
                    <Td>{key + 1}</Td>
                    <Td>{data.product.name}</Td>
                    <Td>{data?.trackingNumber}</Td>
                    <Td maxW="10%" whiteSpace="pre-line">
                      {data?.itemNote}
                    </Td>
                    <Td>{data.quantity}</Td>
                    <Td>{data.product.unit}</Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </PageCardComponent>
    </Stack>
  );
};

export default OperationDetailsComponent;
