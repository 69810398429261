import { useEffect, useRef, useState } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ButtonGroup,
  useToast,
  Textarea,
  useDisclosure,
  Box
} from "@chakra-ui/react";
import {
  useDemandInventoryMutation,
  useGetAssignUserLocationsQuery,
  useLazyGetFilterProductQuery
} from "../../../api";
import { Select, GroupBase } from "chakra-react-select";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  DestinationLocationGroup,
  OriginLocationGroup,
  ProductGroup,
  StockOperationItems
} from "../types/stock-operation.type";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { PageCardComponent } from "../../core/components/page-card.component";
import {
  RemoveSelectedProductFromAllProducts,
  userCurrentRole
} from "../../../utils/common-functions";
import { ConfirmAlertComponent } from "./confirm-alert.component";

type DemandInventoryFormValues = {
  originLocation: OriginLocationGroup;
  destinationLocation: DestinationLocationGroup;
  stockOperationItems: StockOperationItems[];
  notes: string;
};
type DestinationLocation = {
  label: string;
  value: number;
};

const DemandInventoryComponent = () => {
  let id = localStorage.getItem("location");
  const toast = useToast();
  const navigate = useNavigate();
  const role = userCurrentRole();
  const demandConfirmAlert = useDisclosure();
  const cancelRef = useRef();

  const [destinationLocation, setDestinationLocation] =
    useState<DestinationLocation>();

  const [getDemandedLocationProducts, getDemandedLocationProductsResult] =
    useLazyGetFilterProductQuery();

  const handleDestinationLocation = (location: any) => {
    setDestinationLocation(location);
    getDemandedLocationProducts({
      id: Number(location.value)
    });
  };

  const getLocationQuery = useGetAssignUserLocationsQuery({});

  const [demandInventoryCreate, demandInventoryResult] =
    useDemandInventoryMutation();
  const [productOptions, setProductOptions] = useState<ProductGroup[]>([]);
  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);

  const [originLocation, setOriginLocation] = useState<OriginLocationGroup>();

  const [destinationLocationOptions, setDestinationLocationOptions] = useState<
    DestinationLocationGroup[]
  >([]);

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
      setDestinationLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(id))
      );
    }
  }, [id, originLocationOptions]);

  useEffect(() => {
    if (getDemandedLocationProductsResult.data?.data.products) {
      const productWithValues =
        getDemandedLocationProductsResult.data.data.products.map((product) => {
          return {
            label: product.name,
            value: product.id,
            unit: product.unit,
            type: product.type,
            quantity: product.quantity
          };
        });
      setProductOptions(productWithValues);
    }
  }, [getDemandedLocationProductsResult.data]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<DemandInventoryFormValues>({
    defaultValues: {
      stockOperationItems: [{}]
    }
  });

  const { fields, append, remove } = useFieldArray<
    DemandInventoryFormValues,
    "stockOperationItems"
  >({
    control,
    name: "stockOperationItems"
  });

  const watch = useWatch({
    control,
    name: "stockOperationItems"
  });

  const demandHandleSubmit = handleSubmit((data) => {
    if (destinationLocation) {
      demandInventoryCreate({
        data: {
          stockOperation: {
            originLocationId: Number(id),
            destinationLocationId: destinationLocation.value,
            stockOperationItems: data.stockOperationItems.map((d) => ({
              productId: d.product.value,
              quantity: Number(d.quantity)
            })),
            notes: data.notes
          }
        }
      });
    }
  });

  useEffect(() => {
    if (demandInventoryResult.isSuccess) {
      toast({
        title: "Success",
        description: "Demand inventory operation successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      navigate(`/${role}/stock-operation/pending-operation`);
    }
  }, [demandInventoryResult, toast, navigate, role]);

  return (
    <Stack>
      <form onSubmit={demandHandleSubmit}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
        >
          <PageCardComponent>
            <FormControl mb="3">
              <FormLabel>Origin Location</FormLabel>
              <Input
                _disabled={{ color: "blackAlpha.900", cursor: "not-allowed" }}
                defaultValue={originLocation ? originLocation.label : ""}
                isDisabled={true}
              />
            </FormControl>
          </PageCardComponent>
          <PageCardComponent>
            <Controller
              control={control}
              name="destinationLocation"
              // rules={{ required: "Select destination location" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => (
                <FormControl
                  mb="3"
                  // isInvalid={!!error}
                  id="destinationLocation"
                >
                  <FormLabel>Destination Location</FormLabel>
                  <Select<
                    DestinationLocationGroup,
                    true,
                    GroupBase<DestinationLocationGroup>
                  >
                    name={name}
                    ref={ref}
                    onChange={(e) => handleDestinationLocation(e)}
                    onBlur={onBlur}
                    value={value}
                    options={destinationLocationOptions}
                    placeholder="Select destination"
                    closeMenuOnSelect={true}
                  />
                  <FormErrorMessage>
                    {errors.destinationLocation?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />
          </PageCardComponent>
        </Stack>
        {fields.map((field, index) => {
          return (
            <Stack
              direction={{ base: "column", xl: "row" }}
              width="100%"
              bgColor={"white"}
              borderRadius="md"
              spacing={4}
              px="4"
              py="2"
              key={field.id}
              mt="2"
            >
              <Box w={{ base: "100%", md: "100%", xl: "60%" }}>
                <Controller
                  control={control}
                  name={`stockOperationItems.${index}.product`}
                  rules={{ required: "Select product" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error }
                  }) => (
                    <FormControl
                      isInvalid={!!error}
                      id={`stockOperationItems.${index}.product`}
                    >
                      {index === 0 && <FormLabel>Select Product</FormLabel>}
                      <Select<ProductGroup, true, GroupBase<ProductGroup>>
                        name={name}
                        ref={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        options={RemoveSelectedProductFromAllProducts(
                          productOptions,
                          watch
                        )}
                        placeholder="Select product"
                        closeMenuOnSelect={true}
                      />
                      <FormErrorMessage>
                        {error && error.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "15%" }}>
                <FormControl>
                  {index === 0 && <FormLabel>Unit</FormLabel>}
                  <Input
                    _disabled={{ color: "blackAlpha.900", cursor: "not-allowed" }}
                    defaultValue={watch[index]?.product?.unit}
                    disabled={true}
                    placeholder="Product unit"
                  />
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "15%" }}>

                <FormControl
                  mb="3"
                  isInvalid={
                    errors?.stockOperationItems?.[index]?.quantity ? true : false
                  }
                >
                  {index === 0 && <FormLabel>Quantity</FormLabel>}
                  <Input
                    {...register(
                      `stockOperationItems.${index}.quantity` as const,
                      { required: "Enter product quantity" }
                    )}
                    type={"number"}
                    placeholder="Enter product quantity"
                  />
                  <FormErrorMessage>
                    {errors?.stockOperationItems?.[index]?.quantity?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                <ButtonGroup>
                  <Button
                    mt={index === 0 ? 8 : 0}
                    colorScheme="red"
                    variant="outline"
                    leftIcon={<FaTimes />}
                    onClick={() => remove(index)}
                    isDisabled={index > 0 ? false : true}
                  >
                    Remove
                  </Button>
                </ButtonGroup>
              </Box>
            </Stack>
          );
        })}
        <Stack spacing={4}>
          <Button
            my="2"
            width="100%"
            colorScheme="blue"
            variant="outline"
            leftIcon={<FaPlus />}
            onClick={() => append({})}
          >
            Add
          </Button>
          <Textarea
            placeholder="Enter notes for this Operation"
            {...register("notes")}
          />
          <Button
            width="100%"
            colorScheme="blue"
            onClick={demandConfirmAlert.onOpen}
          >
            Submit
          </Button>
          <ConfirmAlertComponent
            cancelRef={cancelRef}
            onClose={demandConfirmAlert.onClose}
            isOpen={demandConfirmAlert.isOpen}
            onOpen={demandConfirmAlert.onOpen}
            handleApprove={demandHandleSubmit}
            status={demandInventoryResult}
          />
        </Stack>
      </form>
    </Stack>
  );
};
export default DemandInventoryComponent;
