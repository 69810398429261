
import dayjs from 'dayjs';
export const productExpColorLevel = (time: string) => {

    const currentDate = dayjs();
    let dayDifference = dayjs(time).diff(currentDate, 'day', true);
    if (dayDifference > 50) {
        return "black"
    } else if (dayDifference > 20) {
        return "#ff7b00"
    } else {
        return "#c10000"
    }

}