import React from "react";
import { EditTransferComponent } from "../components/edit-transfer.component";
import { env } from '../../../config/index';
import { allUIType } from "../../../api/type";
import { CmchEditTransferComponent } from "../components/cmch/cmch-edit-transfer.component";


interface EditTransferPageProps { }

export const EditTransferPage: React.FC<EditTransferPageProps> = (
  props: EditTransferPageProps
) => {
  return (
    <>
      {
        env.uiType === allUIType.CMCH ? <CmchEditTransferComponent /> : <EditTransferComponent />
      }

    </>
  );
};