import { OptionBase } from "chakra-react-select";

export interface OriginLocationGroup extends OptionBase {
  label: string;
  value: number;
}
export interface UserListGroup extends OptionBase {
  label: string;
  value: number;
}

export interface UserRoleGroup extends OptionBase {
  label: string;
  value: string;
}



export enum UserRole {
  admin = "admin",
  distributor = "distributor",
  seller = "seller",
  storeManager = "store-manager",
  substoreManager = "substore-manager",
  superAdmin = "super-admin",
  wordManager = "ward-manager",
  auditor = "auditor"

}