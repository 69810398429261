import React, { useEffect, useState } from "react";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  useReactTable
} from "@tanstack/react-table";
import {
  Notification,
  notificationPartialLink
} from "../types/notification.type";
import {
  Box,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { customPagination } from "../../../config";
import { PaginationBottomComponent } from "../../core/components/pagination-bottom.component";

import {
  useLazyGetNotificationsQuery,
  useReadNotificationMutation
} from "../../../api";
import { userCurrentRole } from "../../../utils/common-functions";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
interface AllNotificationTableComponentProps {
  isRead?: boolean;
  tabIndex: number;
}

export const AllNotificationTableComponent: React.FC<
  AllNotificationTableComponentProps
> = (props: AllNotificationTableComponentProps) => {
  const [count, setCount] = useState(0);
  const [getNotifications, getNotificationsResult] =
    useLazyGetNotificationsQuery();
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: customPagination.pageIndex,
      pageSize: customPagination.pageSize
    });

  const role = userCurrentRole();
  const navigate = useNavigate();
  const [readNotification, readNotificationResult] =
    useReadNotificationMutation();

  const notificationClick = (
    notificationId: string,
    entityType: string,
    entityId: string
  ) => {
    readNotification({
      notificationId: notificationId
    });
    navigate(`/${role}/${notificationPartialLink[entityType]}/${entityId}`);
  };

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    if (props.isRead === undefined) {
      getNotifications({
        query: {
          pageIndex: pagination.pageIndex.toString(),
          pageSize: pagination.pageSize.toString()
        }
      });
    } else {
      getNotifications({
        query: {
          pageIndex: pagination.pageIndex.toString(),
          pageSize: pagination.pageSize.toString(),
          isRead: props.isRead.toString()
        }
      });
    }
  }, [props.isRead, props.tabIndex, pagination]);

  useEffect(() => {
    if (getNotificationsResult.data?.totalCount) {
      if (!props.isRead) {
        setCount(getNotificationsResult.data?.totalCount.total);
      } else if (props.isRead === true) {
        setCount(getNotificationsResult.data?.totalCount.read);
      } else {
        setCount(getNotificationsResult.data?.totalCount.unRead);
      }
    }
  }, [getNotificationsResult]);

  const columnHelper = createColumnHelper<Notification>();
  const columns: ColumnDef<Notification, any>[] = [
    columnHelper.accessor((row) => row.title, {
      id: "title",
      header: () => "Title",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.description, {
      id: "description",
      header: () => "description",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.entityType, {
      id: "entityType",
      header: () => "Type",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.createdAt, {
      id: "createdAt",
      header: () => "time",
      cell: (row) => {
        return dayjs(row.getValue()).format("MMM D, YYYY h:mm A");
      }
    })
  ];

  const table = useReactTable({
    data: getNotificationsResult.data?.listOfNotification
      ? getNotificationsResult.data.listOfNotification
      : [],
    // @ts-ignore
    columns: columns,
    pageCount: count ? Math.ceil(count / pagination.pageSize) : 0,
    state: {
      pagination
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    debugTable: true
  });
  return (
    <Stack>
      <TableContainer>
        <Table>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      bg="gray.700"
                      color="white"
                    >
                      {header.isPlaceholder ? null : (
                        <Box>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Box>
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              const notification = row.original as Notification;

              return (
                <Tr
                  key={row.id}
                  onClick={() =>
                    notificationClick(
                      notification.id,
                      notification.entityType.toString(),
                      notification.entityId
                    )
                  }
                  bg={
                    !notification.notificationStatuses[0].isRead
                      ? "#2B6CB0"
                      : "#F7FAFC"
                  }
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationBottomComponent
        table={table}
        count={count}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
    </Stack>
  );
};
