import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Stack,
  useToast
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRegisterMutation } from "../../../api";
import { PageCardComponent } from "../../core/components/page-card.component";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

type RegisterFormValues = {
  name: string;
  email: string;
  contact: string;
  password: string;
  confirmPassword: string;
};

const registerFormSchema = z
  .object({
    name: z
      .string()
      .min(3, { message: "Name must be at least 3 characters long" }),
    email: z.string().email(),
    contact: z.string().min(11, { message: "Enter valid phone number" }),

    password: z
      .string()
      .min(8, { message: "Password must be 8 or more characters long" }),
    confirmPassword: z.string()
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match.",
    path: ["confirmPassword"]
  });

export const RegisterFormComponent = () => {
  const toast = useToast();

  const [tooglePassword, setTooglePassword] = useState(false);

  const [registerUser, registerResult] = useRegisterMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<RegisterFormValues>({
    resolver: zodResolver(registerFormSchema)
  });

  const handleRegister = handleSubmit((formData) => {
    registerUser({
      data: {
        user: {
          name: formData.name,
          email: formData.email,
          contact: formData.contact,
          password: formData.password
        }
      }
    });
    reset();
  });

  useEffect(() => {
    if (registerResult.isSuccess) {
      toast({
        title: "Success",
        description: "User registered successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
  }, [registerResult, toast]);

  const VisiblePassword = (props: {
    tooglePassword: boolean;
    setTooglePassword: typeof setTooglePassword;
  }) => {
    return (
      <InputRightElement
        cursor="pointer"
        onClick={() => setTooglePassword(!tooglePassword)}
        children={
          tooglePassword ? (
            <FaRegEye color="gray.300" />
          ) : (
            <FaRegEyeSlash color="gray.300" />
          )
        }
      />
    );
  };

  return (
    <Stack>
      <form onSubmit={handleRegister}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
          mb="2"
        >
          <PageCardComponent>
            <FormControl id="name" isInvalid={errors.name ? true : false}>
              <FormLabel>Name</FormLabel>
              <Input
                {...register("name")}
                type="string"
                placeholder="John Doe"
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
          </PageCardComponent>
        </Stack>

        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
        >
          <PageCardComponent>
            <FormControl id="email" isInvalid={errors.email ? true : false}>
              <FormLabel>Email</FormLabel>
              <Input
                {...register("email")}
                type="email"
                placeholder="someone@gmail.com"
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
          </PageCardComponent>
          <PageCardComponent>
            <FormControl id="contact" isInvalid={errors.contact ? true : false}>
              <FormLabel>Phone Number</FormLabel>
              <InputGroup>
                <InputLeftAddon children="+88" />
                <Input
                  {...register("contact")}
                  type="tel"
                  placeholder="01XXXXXXXXX"
                />
              </InputGroup>
              <FormErrorMessage>{errors.contact?.message}</FormErrorMessage>
            </FormControl>
          </PageCardComponent>
        </Stack>

        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
          my="2"
        >
          <PageCardComponent>
            <FormControl
              id="password"
              isInvalid={errors.password ? true : false}
            >
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  {...register("password")}
                  placeholder="********"
                  type={tooglePassword ? "text" : "password"}
                />
                <VisiblePassword
                  tooglePassword={tooglePassword}
                  setTooglePassword={setTooglePassword}
                />
              </InputGroup>
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
          </PageCardComponent>
          <PageCardComponent>
            <FormControl
              id="password-confirmation"
              isInvalid={errors.confirmPassword ? true : false}
            >
              <FormLabel>Confirm Password</FormLabel>
              <InputGroup>
                <Input
                  {...register("confirmPassword")}
                  placeholder="********"
                  type={tooglePassword ? "text" : "password"}
                />
                <VisiblePassword
                  tooglePassword={tooglePassword}
                  setTooglePassword={setTooglePassword}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.confirmPassword?.message}
              </FormErrorMessage>
            </FormControl>
          </PageCardComponent>
        </Stack>

        <Button
          width="100%"
          colorScheme={"blue"}
          variant={"solid"}
          type="submit"
        >
          Register
        </Button>
      </form>
    </Stack>
  );
};
