import React from "react";
import { EditProductComponent } from "../components/edit-product.component";

interface EditProductPageProps {}

export const EditProductPage: React.FC<EditProductPageProps> = (
  props: EditProductPageProps
) => {
  return (
    <>
      <EditProductComponent />
    </>
  );
};
