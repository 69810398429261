import React, { forwardRef } from "react";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  useReactTable
} from "@tanstack/react-table";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { FaSearch, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { customPagination } from "../../../config";
import { Operations } from "../../../api/type";
import { useGetAllOperationQuery } from "../../../api";
import { PaginationBottomComponent } from "../../core/components/pagination-bottom.component";
import { LoaderComponent } from "../../core/components/loader.component";
import { userCurrentRole } from "../../../utils/common-functions";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../../store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./chakra-react-datepicker.css";
import { endDate, startDate } from "../state/report.slice";
import { CalendarIcon } from "@chakra-ui/icons";
interface AllOperationProps { }
const AllOperationComponent: React.FC<AllOperationProps> = (
  props: AllOperationProps
) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reportDate = useSelector((state: RootState) => state.report);
  const role = userCurrentRole();
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: customPagination.pageIndex,
      pageSize: customPagination.pageSize
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );

  const { data, isLoading } = useGetAllOperationQuery({
    query: { pageIndex: pagination.pageIndex, pageSize: pagination.pageSize, startTime: reportDate.startDate, endTime: reportDate.endDate }
  });
  const handleRowClick = (id: number) => {
    navigate(`/${role}/report/operation-details/${id}`);
  };

  const columnHelper = createColumnHelper<Operations>();

  const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
    <Input
      w={60}
      autoComplete="off"
      background="white"
      value={value}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
    />
  );
  customDateInput.displayName = "DateInput";

  const CustomInput = forwardRef(customDateInput);
  function dateStartHandler(value: any) {
    dispatch(startDate(value.toISOString()));
  }
  function dateEndHandler(value: any) {
    dispatch(endDate(value.toISOString()));
  }
  const icon = <CalendarIcon fontSize="sm" />;

  const columns: ColumnDef<Operations, any>[] = [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      header: () => "ID",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.originLocation?.name, {
      id: "originLocation.name",
      header: () => "Origin Location",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.destinationLocation?.name, {
      id: "destinationLocation.name",
      header: () => "Destination Location",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.operationType, {
      id: "operationType",
      header: () => "Operation Type",
      cell: (row) => {
        return (
          <Badge
            p={1}
            textAlign="center"
            width={{
              base: "100%",
              md: "100%",
              lg: "100%",
              xl: "100%",
              "2xl": "55%"
            }}
            borderRadius={5}
            variant="subtle"
            colorScheme="messenger"
          >
            {(row.getValue() === "INITIAL_INVENTORY" && "INITIAL INVENTORY") ||
              (row.getValue() === "END_USER_DISTRIBUTION" && "DISTRIBUTION") ||
              row.getValue()}
          </Badge>
        );
      }
    }),
    columnHelper.accessor((row) => row.operationStatus, {
      id: "operationStatus",
      header: () => "Operation Status",
      cell: (row) => (
        <Badge
          p={1}
          textAlign="center"
          width={{
            base: "100%",
            md: "100%",
            lg: "100%",
            xl: "100%",
            "2xl": "55%"
          }}
          borderRadius={5}
          variant="solid"
          colorScheme={
            (row.getValue() === "INITIALIZED" && "red") ||
            (row.getValue() === "CANCELLED" && "gray") ||
            (row.getValue() === "FINALIZED" && "green") ||
            ""
          }
        >
          {(row.getValue() === "INITIALIZED" && "PENDING APPROVAL") ||
            (row.getValue() === "FINALIZED" && "APPROVED") ||
            (row.getValue() === "CANCELLED" && "REFUSED")}
        </Badge>
      )
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: ({ row, table }) => {
        return (
          <>
            <Button
              leftIcon={<FaEye />}
              color="messenger.400"
              size={"sm"}
              colorScheme={"messenger"}
              variant={"outline"}
              onClick={() => handleRowClick(row.original.id)}
            >
              View
            </Button>
          </>
        );
      }
    })
  ];

  const table = useReactTable({
    data: data ? data.data.operations : [],
    // @ts-ignore
    columns: columns,
    pageCount: data
      ? Math.ceil(data.data.operationsCount / pagination.pageSize)
      : 0,
    state: {
      pagination
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    debugTable: true
  });
  return (
    <>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <Stack>
          <Stack
            direction={{ base: "column", xl: "row" }}
            minWidth="max-content"
            mb="1"
          >
            <Box>
              <Flex width="full" align="center" justifyContent="center">
                <InputGroup className="dark-theme">
                  <Flex align="center" justifyContent="center">
                    <Text mx={3}>From: </Text>
                    <DatePicker
                      id="dateStartEnd"
                      selected={new Date(reportDate.startDate)}
                      onChange={dateStartHandler}
                      dateFormat="dd MMM yyyy"
                      className="react-datapicker__input-text"
                      placeholderText="Select Date"
                      customInput={<CustomInput />}
                      showDisabledMonthNavigation
                    />
                  </Flex>
                  <InputRightElement color="gray.500" children={icon} />
                </InputGroup>
                <InputGroup className="dark-theme">
                  <Flex align="center" justifyContent="center">
                    <Text mx={4}>To: </Text>
                    <DatePicker
                      id="dateStartEnd"
                      selected={new Date(reportDate.endDate)}
                      onChange={dateEndHandler}
                      dateFormat="dd MMM yyyy"
                      className="react-datapicker__input-text"
                      placeholderText="Select Date"
                      customInput={<CustomInput />}
                      showDisabledMonthNavigation
                    />
                  </Flex>
                  <InputRightElement color="gray.500" children={icon} />
                </InputGroup>
              </Flex>
            </Box>
            <Spacer />
            <ButtonGroup gap="2">
              <Button colorScheme="blue">Export CSV</Button>
            </ButtonGroup>
          </Stack>
          <Table variant="striped">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        bg="gray.700"
                        color="white"
                      >
                        {header.isPlaceholder ? null : (
                          <Box>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Box>
                        )}
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <PaginationBottomComponent
            table={table}
            count={data?.data.operationsCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        </Stack>
      )}
    </>
  );
};
export default AllOperationComponent;
