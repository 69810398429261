import React from "react";
import { AdjustmentComponent } from "../components/adjustment.component";

interface AdjustmentPageProps {}

export const AdjustmentPage: React.FC<AdjustmentPageProps> = (
  props: AdjustmentPageProps
) => {
  return (
    <>
      <AdjustmentComponent />
    </>
  );
};
