import React from "react";
import CmchTransferComponent from "../components/cmch/cmch-transfer.component";
import TransferComponent from "../components/transfer.component";
import { env } from "../../../config/index";
import { allUIType } from "../../../api/type";
interface TransferPageProps { }

export const TransferPage: React.FC<TransferPageProps> = (
  props: TransferPageProps
) => {
  return (
    <>
      {
        env.uiType === allUIType.CMCH ? <CmchTransferComponent /> : <TransferComponent />
      }
    </>
  );
};
