import { Center } from "@chakra-ui/react";
import React from "react";
import { InfinitySpin } from "react-loader-spinner";

interface LoaderComponentProps {}

export const LoaderComponent: React.FC<LoaderComponentProps> = (
  props: LoaderComponentProps
) => {
  return (
    <Center height="75vh">
      <InfinitySpin width="200" color="#2B6CB0" />
    </Center>
  );
};
