import React from "react";
import dayjs from "dayjs";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  Font,
  View,
  Image
} from "@react-pdf/renderer";
import { GetDemandPrintDataResponse } from "../../../api/type";
import { userCurrentRole } from "../../../utils/common-functions";
import { env } from "../../../config";

interface InventoryDemandPrintPageProps {
  printData: GetDemandPrintDataResponse;
  originLocationName: string;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  body: {
    padding: "0.75in",
    position: "relative",
    fontFamily: "Noto Serif Bengali"
  },
  title: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center"
  },
  subtitle: {
    fontSize: 12,
    textAlign: "center"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    fontSize: 11
  },
  subHeader: {
    fontSize: 12,
    marginVertical: 10
  },
  table: {
    fontSize: 11,
    width: "100%",
    borderLeft: "1px solid #dfdfdf",
    borderRight: "1px solid #dfdfdf",
    borderTop: "1px solid #dfdfdf"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 11,
    borderBottom: "1px solid #dfdfdf"
  },
  serial: {
    width: "8%",
    borderRight: "1px solid #dfdfdf"
  },
  productName: {
    width: "25%",
    borderRight: "1px solid #dfdfdf"
  },
  demandAmount: {
    width: "10%",
    borderRight: "1px solid #dfdfdf"
  },
  lastSupplyDate: {
    width: "17%",
    borderRight: "1px solid #dfdfdf"
  },
  lastSupplyQuantity: {
    width: "10%",
    borderRight: "1px solid #dfdfdf"
  },
  currentInventory: {
    width: "10%",
    borderRight: "1px solid #dfdfdf"
  },
  productQuantity: {
    width: "10%",
    borderRight: "1px solid #dfdfdf"
  },
  comment: {
    width: "10%"
  },
  footerContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    fontSize: 11,
    marginTop: 40
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    fontSize: 6,
    color: "#666666",
    bottom: "0.50in",
    left: "0.75in",
    right: "0.75in",
    borderTop: "1px solid #000"
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 5,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey"
  },
  backgroundImage: {
    position: "absolute",
    top: "45%",
    width: "200px",
    opacity: 0.05,
    objectFit: "contain",
    alignSelf: "center"
  },
  logo: {
    width: "50px",
    objectFit: "contain",
    alignSelf: "center"
  },
  valueWithLine: {
    paddingBottom: "3px",
    borderTop: "1px dotted #000",
    backgroundColor: "#FFFFFF"
  }
});

export const InventoryDemandPrintPage: React.FC<
  InventoryDemandPrintPageProps
> = (props: InventoryDemandPrintPageProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <Image style={styles.backgroundImage} src={env.reportLogo} fixed />
        <View>
          <View>
            <Image style={styles.logo} src={env.organizationLogo} />
            <Text style={styles.subtitle}>গণপ্রজাতন্ত্রী বাংলাদেশ সরকার</Text>
            <Text style={styles.title}>{env.organizationNameBangla} </Text>
            <Text style={styles.subtitle}>
              {env.organizationAddressBangla}{" "}
            </Text>
            <Text style={[styles.subtitle]}>চাহিদা</Text>
          </View>
          <View>
            <View style={styles.header}>
              <Text>Origin Location : {props.originLocationName}</Text>
              <Text>
                Destination Location :{" "}
                {props.printData.destinationLocation.name}
              </Text>
            </View>
            <View style={styles.header}>
              <Text>Operation ID: {props.printData.id}</Text>
              <Text>
                Date :{" "}
                {dayjs(props.printData.createdAt).format("DD MMMM, YYYY")}
              </Text>
            </View>
          </View>
          <Text style={styles.subHeader}>
            এই মর্মে আমি জনাব/জনাবা {props.printData.originLocation.name}{" "}
            শাখা/ওয়ার্ড/বিভাগের জন্য নিম্নলিখিত মালামালের চাহিদা প্রদান করছি ।
          </Text>
        </View>

        <View style={styles.table}>
          <View style={[styles.row]} fixed>
            <Text style={styles.serial}>ক্রমিক নং </Text>
            <Text style={styles.productName}>মালামালের বিবরণ </Text>
            <Text style={styles.currentInventory}>স্টকের স্থিতি</Text>
            <Text style={styles.lastSupplyDate}>পূর্ব প্রাপ্তির তারিখ</Text>
            <Text style={styles.lastSupplyQuantity}>
              পূর্ব প্রাপ্তির সংখ্যা
            </Text>
            <Text style={styles.demandAmount}>চাহিদার পরিমাণ</Text>
            <Text style={styles.productQuantity}>প্রদান সংখ্যা</Text>
            <Text style={styles.comment}>মন্তব্য</Text>
          </View>
          {props.printData.stockOperationItems.map((row, index) => (
            <View key={index} style={styles.row} wrap={false}>
              <Text style={styles.serial}>{index + 1}</Text>
              <Text style={styles.productName}>{row.productName + " "}</Text>
              <Text style={styles.currentInventory}>{row.available}</Text>
              <Text style={styles.lastSupplyDate}>
                {row.lastTransferDate === null
                  ? ""
                  : dayjs(row.lastTransferDate).format("DD MMM, YYYY")}
              </Text>
              <Text style={styles.lastSupplyQuantity}>{row.lastTransfer}</Text>
              <Text style={styles.demandAmount}>{row.demandAmount}</Text>
              <Text style={styles.productQuantity}></Text>
              <Text style={styles.comment}></Text>
            </View>
          ))}
        </View>

        <View style={[styles.footerContent]}>
          <View>
            <Text style={{ marginBottom: 0 }}>
              {props.printData.createdBy.name}
            </Text>
            <Text style={styles.valueWithLine}>চাহিদাকারীর নাম</Text>
          </View>
          <Text style={styles.valueWithLine}>বিভাগীয় প্রধান</Text>
          <Text style={styles.valueWithLine}>সহকারী পরিচালক</Text>
          <Text style={styles.valueWithLine}>উপ পরিচালক</Text>
          <Text style={styles.valueWithLine}>পরিচালক</Text>
        </View>
        <View style={styles.footerContent}>
          <Text style={styles.valueWithLine}>গ্রহণকারীর নাম</Text>
          <Text style={styles.valueWithLine}>স্টোর ইনচার্জ </Text>
        </View>
        <View style={styles.footer} fixed>
          <View>
            <Text>Powered By Bindulogic Limited</Text>
          </View>
          <View>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
          <View>
            <Text>
              Printed By: {userCurrentRole() || "Unknown"},{" "}
              {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
