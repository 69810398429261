import React from "react";
import { UserListComponent } from "../components/user-list.component";

interface UserListPageProps {}

export const UserListPage: React.FC<UserListPageProps> = (
  props: UserListPageProps
) => {
  return (
    <>
      <UserListComponent />
    </>
  );
};
