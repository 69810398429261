import React, { useEffect, useState } from "react";
import { Stack, Box, FormControl, Flex, Button, FormLabel } from "@chakra-ui/react";
import { SingleProductInventoryReportComponent } from "../components/single-product-inventory-report.component";
import { useGetProductQuery } from "../../../api";
import { ProductGroup } from "../types/stock-operation.type";
import { Select, GroupBase } from 'chakra-react-select';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { env } from '../../../config/index';
import { userCurrentRole } from "../../../utils/common-functions";
import { UserRole } from "../../user/types/user.type";


interface SingleProductInventoryPageProps {

}

export const SingleProductInventoryPage: React.FC<SingleProductInventoryPageProps> = (props: SingleProductInventoryPageProps) => {

    const navigate = useNavigate();
    const role = userCurrentRole();


    let locationId = localStorage.getItem("location");




    const getProductQuery = useGetProductQuery({ id: Number(locationId) });
    const [productOptions, setProductOptions] = useState<ProductGroup[]>([]);
    const [selectedProduct, setSelectProduct] = useState<number>();
    // TODO: Track this
    useEffect(() => {
        if (getProductQuery.data) {
            const productWithValues = getProductQuery.data.data.products.map(
                (product) => {
                    return {
                        label: product.name,
                        value: product.id,
                        type: product.trackingType,
                        unit: product.unit
                    };
                }
            );
            setProductOptions(productWithValues);
        }
    }, [getProductQuery.data]);

    const ChangeProduct = (e: any) => {
        setSelectProduct(e.value)
    }

    return (<div>
        <Stack>
            <Flex justifyContent="end">
                <Button
                    colorScheme="blue"
                    leftIcon={<IoMdArrowRoundBack />}
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
            </Flex>

            {(role === UserRole.admin || role === UserRole.auditor || env.organizationShortName === "CMCH") ? <Stack>
                <Box w={{ base: "100%", md: "100%", xl: "50%" }}>

                    <FormControl


                    >
                        <FormLabel fontSize={{
                            base: "10",
                            md: "10",
                            lg: "10",
                            xl: "10",
                            "2xl": "12"
                        }} >Select Product</FormLabel>

                        <Select<ProductGroup, true, GroupBase<ProductGroup>>

                            options={
                                productOptions}

                            placeholder="Select Product"
                            closeMenuOnSelect={true}
                            size="md"
                            onChange={(e) => ChangeProduct(e)}
                        />
                    </FormControl>


                </Box>
                {selectedProduct && <SingleProductInventoryReportComponent productId={selectedProduct} />}
            </Stack> : <Stack>
                <FormControl


                >
                    <FormLabel fontSize={{
                        base: "20",
                        md: "20",
                        lg: "20",
                        xl: "20",
                        "2xl": "24"
                    }}>don't have permission for this page</FormLabel>


                </FormControl></Stack>}

        </Stack>


    </div>);
}