import React from "react";
import { CmchDemandFulfilComponent } from "../components/cmch/cmch-demand-fulfill.component";

interface DemanFulfillPageProps {}

export const DemanFulfillPage: React.FC<DemanFulfillPageProps> = (
  props: DemanFulfillPageProps
) => {
  return (
    <>
      <CmchDemandFulfilComponent />
    </>
  );
};
