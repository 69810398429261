import React from "react";
import { Stack } from "@chakra-ui/react";
import { DistributionReportForPrescriptionComponent } from "../components/distribution-report-for-prescription.component";

interface DistributionReportForPrescriptionPageProps {}

export const DistributionReportForPrescriptionPage: React.FC<
  DistributionReportForPrescriptionPageProps
> = (props: DistributionReportForPrescriptionPageProps) => {
  return (
    <Stack>
      <DistributionReportForPrescriptionComponent />
    </Stack>
  );
};
