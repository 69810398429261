import React from "react";
import { UpdatePasswordComponent } from "../components/update-password.component";

interface UpdatePasswordPageProps {}

export const UpdatePasswordPage: React.FC<UpdatePasswordPageProps> = (
  props: UpdatePasswordPageProps
) => {
  return (
    <>
      <UpdatePasswordComponent />
    </>
  );
};
