import { useEffect } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useToast
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { Select, GroupBase } from "chakra-react-select";
import { PageCardComponent } from "../../core/components/page-card.component";
import { useAddLocationMutation } from "../../../api";

import {
  DepartmentGroup,
  LocationTypeGroup,
  ParentLocationGroup
} from "../types/location.type";
import {
  departmentOptions,
  locationTypeOptions,
  parentLocationOptions
} from "../data/location.data";

type AddLocationFormValues = {
  name: string;
  type: LocationTypeGroup;
  department: DepartmentGroup;
  parentID: ParentLocationGroup;
};

const AddLocationComponent = () => {
  const toast = useToast();

  const [addLocation, resultLocation] = useAddLocationMutation();

  const {
    register,
    handleSubmit,
    resetField,
    control,
    formState: { errors }
  } = useForm<AddLocationFormValues>({});

  const handleAddLocation = handleSubmit((data) => {
    addLocation({
      data: {
        location: {
          name: data.name,
          type: data.type.value,
          department: data.department.value
        }
      }
    });
    resetField("name");
  });

  useEffect(() => {
    if (resultLocation.isSuccess) {
      toast({
        title: "Success",
        description: "Location added successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
  }, [resultLocation, toast]);

  return (
    <>
      <form onSubmit={handleAddLocation}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
        >
          <PageCardComponent>
            <FormControl mb="3" isInvalid={errors.name ? true : false}>
              <FormLabel>Location Name</FormLabel>
              <Input
                {...register("name", { required: "Enter location name" })}
                type="string"
                placeholder="Enter location name"
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
          </PageCardComponent>

          <PageCardComponent>
            <Controller
              control={control}
              name="type"
              rules={{ required: "Select location type" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => (
                <FormControl isInvalid={!!error} id="type">
                  <FormLabel>Location Type</FormLabel>
                  <Select<LocationTypeGroup, true, GroupBase<LocationTypeGroup>>
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    options={locationTypeOptions}
                    placeholder="Select location type"
                    closeMenuOnSelect={true}
                  />
                  <FormErrorMessage>{error && error.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </PageCardComponent>
        </Stack>

        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
          my="2"
        >
          <PageCardComponent>
            <Controller
              control={control}
              name="department"
              rules={{ required: "Select department" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => (
                <FormControl isInvalid={!!error} id="department">
                  <FormLabel>Department</FormLabel>
                  <Select<DepartmentGroup, true, GroupBase<DepartmentGroup>>
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    options={departmentOptions}
                    placeholder="Select department"
                    closeMenuOnSelect={true}
                  />
                  <FormErrorMessage>{error && error.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </PageCardComponent>

          <PageCardComponent>
            <Controller
              control={control}
              name="parentID"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => (
                <FormControl isInvalid={!!error} id="parentID">
                  <FormLabel>Parent Location</FormLabel>
                  <Select<
                    ParentLocationGroup,
                    true,
                    GroupBase<ParentLocationGroup>
                  >
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    options={parentLocationOptions}
                    placeholder="Select parent location"
                    closeMenuOnSelect={true}
                  />
                  <FormErrorMessage>{error && error.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </PageCardComponent>
        </Stack>

        <Stack width="100%">
          <Button type="submit" colorScheme="blue">
            Add Location
          </Button>
        </Stack>
      </form>
    </>
  );
};
export default AddLocationComponent;
