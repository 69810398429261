import { ChakraStylesConfig } from "chakra-react-select";
import { UserRole } from "../modules/user/types/user.type";
export const RemoveSelectedProductFromAllProducts = (
  allProductArray: any,
  allSelectedProductArray: any
) => {
  let RemainingProducts: any = [];
  RemainingProducts = allProductArray.filter((el: any) => {
    return !allSelectedProductArray.find((element: any) => {
      return element?.product?.value === el?.value && el.type === "NONE";
    });
  });
  return RemainingProducts;
};

export function userCurrentRole() {
  let role = window.localStorage.getItem("role") as string;
  if (role === "ADMIN") {
    role = "admin";
  } else if (role === "DISTRIBUTOR") {
    role = "distributor";
  } else if (role === "SELLER") {
    role = "seller";
  } else if (role === "STOREMANAGER") {
    role = "store-manager";
  } else if (role === "SUBSTOREMANAGER") {
    role = "substore-manager";
  } else if (role === "SUPERADMIN") {
    role = "super-admin";
  } else if (role === "WARDMANAGER") {
    role = "ward-manager";
  } else if (role === "AUDITOR") {
    role = "auditor";
  }
  return role;
}

export const chakraStyles: ChakraStylesConfig = {
  container: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? "black" : provided.color,
    fontWeight: state.isDisabled ? "800" : provided.fontWeight
  })
};

export function canSelectLocation(role: string) {
  return role === UserRole.admin || role === UserRole.auditor;
}
