import {
  FaServer,
  FaUserFriends,
  FaMapMarkerAlt,
  FaClipboardList,
  FaBriefcaseMedical
} from "react-icons/fa";
import { IDashboard } from "../route.type";
import DashboardPage from "../../modules/dashboard/pages/dashboard.page";
import { AddProductPage } from "../../modules/product/pages/add-product.page";
import { AllProductsPage } from "../../modules/product/pages/all-products.page";
import AddLocationPage from "../../modules/location/pages/add-location.page";
import { AllLocationsPage } from "../../modules/location/pages/all-locations.page";
import { TransferPage } from "../../modules/stock-operation/pages/transfer.page";
import { InitialInventoryPage } from "../../modules/stock-operation/pages/initial-inventory.page";
import { DemandInventoryPage } from "../../modules/stock-operation/pages/demand-inventory.page";
import LocationInventoryPage from "../../modules/report/pages/location-inventory.page";
import AllOperationPage from "../../modules/report/pages/all-operation.page";
import { RegisterPage } from "../../modules/auth/pages/register.page";
import { UserListPage } from "../../modules/user/pages/user-list.page";
import DailyProductReportPage from "../../modules/report/pages/daily-product-report.page";
import MonthlyProductReportPage from "../../modules/report/pages/monthly-product-report.page";
import LocationOperationsPage from "../../modules/report/pages/location-operations.page";
import OperationDetailsPage from "../../modules/report/pages/all-operation-details.page";
import { PendingOperationPage } from "../../modules/stock-operation/pages/pending-operation.page";
import { AssignUserToLocationPage } from "../../modules/user/pages/assign-user-to-location.page";
import { EditProductPage } from "../../modules/product/pages/edit-product.page";
import ApprovalOperationDetailsPage from "../../modules/stock-operation/pages/approval-operation-details.page";
import { EditLocationPage } from "../../modules/location/pages/edit.location.page";
import { LogoComponent } from "../../branding/logo.component";
import { AssignUserRolePage } from "../../modules/user/pages/assign-user-role.page";
import { DetailProductsPage } from "../../modules/product/pages/detail-product.page";
import { EditTransferPage } from "../../modules/stock-operation/pages/edit-transfer.page";
import InventoryByDatePage from "../../modules/report/pages/inventory-by-date.page";
// import { CustomReportPage } from "../../modules/report/pages/custom-report.page";
// import { CustomReportDetailsComponent } from "../../modules/report/components/custom-report-details.component";
import { VendorSupplyPage } from "../../modules/stock-operation/pages/vendor-supply.page";
import { AdjustmentPage } from "../../modules/stock-operation/pages/adjustment.page";
import { RemoveUserLocationPage } from "../../modules/user/pages/remove-user-location.page";
import { TrashPage } from "../../modules/stock-operation/pages/trash.page";
import { SubstoreInventoryPage } from "../../modules/report/pages/substore.inventory.page";
import { UserProfilePage } from "../../modules/user/pages/user-profile.page";
import { ConsumptionPage } from "../../modules/stock-operation/pages/consumption.page";
import { DistributionPage } from "../../modules/stock-operation/pages/distribution.page";
import { VendorDemandPage } from "../../modules/stock-operation/pages/vendor-demand.page";
import { AllVendorDemandPage } from "../../modules/report/pages/all-vendor-demand.page";
import { AdjustmentReportPage } from "../../modules/report/pages/adjustment-report.page";
import { DemanFulfillPage } from "../../modules/stock-operation/pages/deman-fulfill.page";
import { SingleProductInventoryPage } from "../../modules/report/pages/single-product-inventory.page";
import { SingleProductMovementByLocationPage } from "../../modules/report/pages/single-product-movement-by-location.page";
import { SingleProductMovementByDatePage } from "../../modules/report/pages/single-product-movement-by-date.page";
import { AllNotificationComponent } from "../../modules/notification/component/all-notification.component";
import { LowStockPage } from "../../modules/dashboard/pages/low-stock.page";
import { ToBeExpiredPage } from "../../modules/dashboard/pages/to-be-expired.page";

export const auditorDashboard: IDashboard = {
  dashboardName: "AUDITOR",
  logo: <LogoComponent />,
  pages: [
    {
      name: "Items To Be expired",
      link: "/auditor/to-be-expired",
      content: <ToBeExpiredPage />
    },
    {
      name: "Low Stock Products",
      link: "/auditor/low-stock",
      content: <LowStockPage />
    },
    {
      name: "All Notification",
      link: "/auditor/notification-all",
      content: <AllNotificationComponent />
    },
    {
      name: "Dashboard",
      link: "/auditor/dashboard",
      content: <DashboardPage />
    },
    {
      name: "Add Product",
      link: "/auditor/product/add",
      content: <AddProductPage />
    },
    {
      name: "All Products",
      link: "/auditor/all-products",
      content: <AllProductsPage />
    },
    {
      name: "Product Details",
      link: "/auditor/product-details/:id",
      content: <DetailProductsPage />
    },
    {
      name: "Add Location",
      link: "/auditor/location/create",
      content: <AddLocationPage />
    },
    {
      name: "All Locations",
      link: "/auditor/location/summary",
      content: <AllLocationsPage />
    },
    {
      name: "Transfer Operation",
      link: "/auditor/stock-operation/transfer/create",
      content: <TransferPage />
    },
    {
      name: "Edit Transfer Operation",
      link: "/auditor/stock-operation/transfer/edit/:operationId",
      content: <EditTransferPage />
    },
    {
      name: "Pending Operation",
      link: "/auditor/stock-operation/pending-operation",
      content: <PendingOperationPage />
    },
    {
      name: "Initial Inventory",
      link: "/auditor/stock-operation/initial-inventory/crete",
      content: <InitialInventoryPage />
    },
    {
      name: "Vendor Supply",
      link: "/auditor/stock-operation/vendor-supply/create",
      content: <VendorSupplyPage />
    },
    {
      name: "Vendor Demand",
      link: "/auditor/stock-operation/vendor-demand/create",
      content: <VendorDemandPage />
    },
    {
      name: "Trash",
      link: "/auditor/stock-operation/trash/create",
      content: <TrashPage />
    },
    {
      name: "Consumption",
      link: "/auditor/stock-operation/consumption/create",
      content: <ConsumptionPage />
    },
    {
      name: "Adjustment",
      link: "/auditor/stock-operation/adjustment",
      content: <AdjustmentPage />
    },
    {
      name: "Demand Inventory",
      link: "/auditor/stock-operation/demand/create",
      content: <DemandInventoryPage />
    },
    {
      name: "Demand Fulfill",
      link: "/auditor/demand-fulfil/:id",
      content: <DemanFulfillPage />
    },
    {
      name: "Location Inventory",
      link: "/auditor/report/location-inventory",
      content: <LocationInventoryPage />
    },
    {
      name: "Sub Location Inventory",
      link: "/auditor/report/sub-location-inventory",
      content: <SubstoreInventoryPage />
    },
    {
      name: "All Operation",
      link: "/auditor/report/all-operation",
      content: <AllOperationPage />
    },
    {
      name: "Location Operations",
      link: "/auditor/report/location-operations",
      content: <LocationOperationsPage />
    },
    {
      name: "Operation Details",
      link: "/auditor/report/operation-details/:id",
      content: <OperationDetailsPage />
    },
    {
      name: "Approval Operation Details",
      link: "/auditor/approval/operation-details",
      content: <ApprovalOperationDetailsPage />
    },
    {
      name: "Daily Product Report",
      link: "/auditor/report/daily-product-report",
      content: <DailyProductReportPage />
    },
    {
      name: "Monthly Product Report",
      link: "/auditor/report/monthly-product-report",
      content: <MonthlyProductReportPage />
    },
    {
      name: "Register User",
      link: "/auditor/register",
      content: <RegisterPage />
    },
    {
      name: "User List",
      link: "/auditor/user-list",
      content: <UserListPage />
    },
    {
      name: "Assign User Location",
      link: "/auditor/assign-user-to-location",
      content: <AssignUserToLocationPage />
    },
    {
      name: "Remove User Location",
      link: "/auditor/remove-user-location",
      content: <RemoveUserLocationPage />
    },
    {
      name: "Edit Product",
      link: "/auditor/product/edit/:id",
      content: <EditProductPage />
    },
    {
      name: "Edit Location",
      link: "/auditor/location/edit/:id",
      content: <EditLocationPage />
    },
    {
      name: "Assign User Role",
      link: "/auditor/assign-user-role",
      content: <AssignUserRolePage />
    },
    {
      name: "Inventory By Date",
      link: "/auditor/report/previous-inventory",
      content: <InventoryByDatePage />
    },
    // {
    //   name: "Custom Report",
    //   link: "/auditor/report/custom-report",
    //   content: <CustomReportPage />
    // },
    // {
    //   name: "Custom Report",
    //   link: "/auditor/custom-reports",
    //   content: <CustomReportDetailsComponent />
    // },
    {
      name: "Profile",
      link: "/auditor/profile",
      content: <UserProfilePage />
    },
    {
      name: "Distribution",
      link: "/auditor/distribution",
      content: <DistributionPage />
    },
    {
      name: "All Vendor Demand",
      link: "/auditor/report/all-vendor-demand",
      content: <AllVendorDemandPage />
    },
    {
      name: "Adjustment Report",
      link: "/auditor/report/adjustment-report",
      content: <AdjustmentReportPage />
    },
    {
      name: "Single Product Inventory",
      link: "/auditor/report/single-product-inventory",
      content: <SingleProductInventoryPage />
    },
    {
      name: "Single Product Movement (By Location)",
      link: "/auditor/report/single-product-movement",
      content: <SingleProductMovementByLocationPage />
    },
    {
      name: "Single Product Movement (By Date)",
      link: "/auditor/report/date-wise-product-movement",
      content: <SingleProductMovementByDatePage />
    }
  ],

  sidebar: [
    {
      name: "Dashboard",
      link: "/auditor/dashboard",
      content: <DashboardPage />
    },

    {
      header: "Report",
      icon: FaClipboardList,
      pages: [
        {
          name: "All Operation",
          link: "/auditor/report/all-operation",
          content: <AllOperationPage />
        },
        {
          name: "Location Operations",
          link: "/auditor/report/location-operations",
          content: <LocationOperationsPage />
        },
        {
          name: "Location Inventory",
          link: "/auditor/report/location-inventory",
          content: <LocationInventoryPage />
        },
        {
          name: "Inventory By Date",
          link: "/auditor/report/previous-inventory",
          content: <InventoryByDatePage />
        },
        {
          name: "Daily Product Report",
          link: "/auditor/report/daily-product-report",
          content: <DailyProductReportPage />
        },
        {
          name: "Monthly Product Report",
          link: "/auditor/report/monthly-product-report",
          content: <MonthlyProductReportPage />
        },
        {
          name: "All Vendor Demand",
          link: "/auditor/report/all-vendor-demand",
          content: <AllVendorDemandPage />
        },
        {
          name: "Adjustment Report",
          link: "/auditor/report/adjustment-report",
          content: <AdjustmentReportPage />
        },
        {
          name: "Single Product Inventory",
          link: "/auditor/report/single-product-inventory",
          content: <SingleProductInventoryPage />
        },
        {
          name: "Single Product Movement (By Location)",
          link: "/auditor/report/single-product-movement",
          content: <SingleProductMovementByLocationPage />
        },
        {
          name: "Single Product Movement (By Date)",
          link: "/auditor/report/date-wise-product-movement",
          content: <SingleProductMovementByDatePage />
        }
      ]
    },
    {
      header: "Users",
      icon: FaUserFriends,
      pages: [
        {
          name: "Profile",
          link: "/auditor/profile",
          content: <UserProfilePage />
        }
      ]
    }
  ]
};
