import React from "react";
import { RegisterFormComponent } from "../components/register-form.component";

interface RegisterPageProps {}

export const RegisterPage: React.FC<RegisterPageProps> = (
  props: RegisterPageProps
) => {
  return (
    <>
      <RegisterFormComponent />
    </>
  );
};
