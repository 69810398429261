import React from "react";
import { DistributionComponent } from "../components/distribution.component";
import { env } from '../../../config/index';
import { allUIType } from "../../../api/type";
import { CmchDistributionComponent } from '../components/cmch/cmch-distribution.component';

interface DistributionPageProps { }

export const DistributionPage: React.FC<DistributionPageProps> = (
  props: DistributionPageProps
) => {
  return (
    <>
      {
        env.uiType === allUIType.CMCH ? <CmchDistributionComponent /> : <DistributionComponent />
      }
    </>
  );
};
