import React, { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useToast
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useChangePasswordMutation } from "../../../api";
import { PageCardComponent } from "../../core/components/page-card.component";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

type changePasswordFormValues = {
  oldPassword: string;
  newPassword: string;
};

const changePasswordFormSchema = z.object({
  oldPassword: z
    .string()
    .min(8, { message: "Password must be 8 or more characters long" }),

  newPassword: z
    .string()
    .min(8, { message: "Password must be 8 or more characters long" })
});

interface ChangePasswordComponentProps { }

export const ChangePasswordComponent: React.FC<ChangePasswordComponentProps> = (
  props: ChangePasswordComponentProps
) => {
  const toast = useToast();

  const [changePassword, changePasswordResult] = useChangePasswordMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<changePasswordFormValues>({
    resolver: zodResolver(changePasswordFormSchema)
  });

  const handleChangePassword = handleSubmit((formData) => {
    changePassword({
      data: {
        user: {
          oldPassword: formData.oldPassword,
          newPassword: formData.newPassword
        }
      }
    });
    reset();
  });

  useEffect(() => {
    if (changePasswordResult.isSuccess) {
      toast({
        title: "Success",
        description: "Change password successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
  }, [changePasswordResult, toast]);

  const [tooglePassword, setTooglePassword] = useState(false);

  const VisiblePassword = (props: {
    tooglePassword: boolean;
    setTooglePassword: typeof setTooglePassword;
  }) => {
    return (
      <InputRightElement
        cursor="pointer"
        onClick={() => setTooglePassword(!tooglePassword)}
        children={
          tooglePassword ? (
            <FaRegEye color="gray.300" />
          ) : (
            <FaRegEyeSlash color="gray.300" />
          )
        }
      />
    );
  };

  return (
    <Stack>
      <form onSubmit={handleChangePassword}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
          mb="2"
        >
          <PageCardComponent>
            <FormControl
              id="oldPassword"
              isInvalid={errors.oldPassword ? true : false}
            >
              <FormLabel>Old Password</FormLabel>

              <Input
                {...register("oldPassword")}
                placeholder="********"
                type="password"
              />
              <FormErrorMessage>{errors.oldPassword?.message}</FormErrorMessage>
            </FormControl>
          </PageCardComponent>
        </Stack>

        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
          my="2"
        >
          <PageCardComponent>
            <FormControl
              id="newPassword"
              isInvalid={errors.newPassword ? true : false}
            >
              <FormLabel>New Password</FormLabel>
              <InputGroup>
                <Input
                  {...register("newPassword")}
                  placeholder="********"
                  type={tooglePassword ? "text" : "password"}
                />
                <VisiblePassword
                  tooglePassword={tooglePassword}
                  setTooglePassword={setTooglePassword}
                />
              </InputGroup>
              <FormErrorMessage>{errors.newPassword?.message}</FormErrorMessage>
            </FormControl>
          </PageCardComponent>
        </Stack>

        <Button
          width="100%"
          colorScheme={"blue"}
          variant={"solid"}
          type="submit"
        >
          Submit
        </Button>
      </form>
    </Stack>
  );
};
