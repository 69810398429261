import React from "react";
import { Stack } from "@chakra-ui/react";
import SubStoreInventoryComponent from "../components/substore-inventory.component";

interface SubstoreInventoryPageProps {}

export const SubstoreInventoryPage: React.FC<SubstoreInventoryPageProps> = (
  props: SubstoreInventoryPageProps
) => {
  return (
    <Stack>
      <SubStoreInventoryComponent />
    </Stack>
  );
};
