import React from "react";
import dayjs from "dayjs";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  Font,
  View
} from "@react-pdf/renderer";
import { GetDemandPrintDataResponse } from "../../../api/type";
interface PrintComponentProps {
  printData: GetDemandPrintDataResponse;
  originLocationName: string;
}
Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 5,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Noto Serif Bengali"
  },
  date: {
    fontSize: 12,
    textAlign: "right",
    fontFamily: "Noto Serif Bengali",
    position: "absolute",
    top: 26,
    left: 0,
    right: 0
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Noto Serif Bengali"
  },
  text: {
    margin: 12,
    fontSize: 14,
    fontFamily: "Noto Serif Bengali"
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100
  },
  header: {
    fontSize: 14,
    marginBottom: 10,
    textAlign: "center",
    fontFamily: "Noto Serif Bengali"
  },
  subHeader: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    fontFamily: "Noto Serif Bengali"
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 5,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey"
  },
  table: {
    width: "100%",
    borderTop: "1px solid #000",
    borderBottom: "1px solid #000"
  },
  tableHeader: {
    borderTop: "none"
  },
  bold: {
    fontWeight: "bold"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 8
  },
  numberRow: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
    borderBottom: "1px solid #000",
    justifyContent: "center",
    textAlign: "center"
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
    justifyContent: "center",
    textAlign: "center"
  },
  row1: {
    width: "5%",
    fontFamily: "Noto Serif Bengali",
    borderTop: "1px solid #000",
    borderRight: "1px solid #000"
  },
  row2: {
    width: "20%",
    fontFamily: "Noto Serif Bengali",
    borderTop: "1px solid #000",
    borderRight: "1px solid #000"
  },
  row3: {
    width: "10%",
    fontFamily: "Noto Serif Bengali",
    borderTop: "1px solid #000",
    borderRight: "1px solid #000"
  },
  row4: {
    width: "10%",
    fontFamily: "Noto Serif Bengali",
    borderTop: "1px solid #000",
    borderRight: "1px solid #000"
  },
  row5: {
    width: "10%",
    fontFamily: "Noto Serif Bengali",
    borderTop: "1px solid #000",
    borderRight: "1px solid #000"
  },
  row6: {
    width: "10%",
    fontFamily: "Noto Serif Bengali",
    borderTop: "1px solid #000",
    borderRight: "1px solid #000"
  },
  row7: {
    width: "15%",
    fontFamily: "Noto Serif Bengali",
    borderTop: "1px solid #000",
    borderRight: "1px solid #000"
  },
  row8: {
    width: "10%",
    fontFamily: "Noto Serif Bengali",
    borderTop: "1px solid #000",
    borderRight: "1px solid #000"
  },
  row9: {
    width: "10%",
    fontFamily: "Noto Serif Bengali",
    borderTop: "1px solid #000"
  },
  irow1: {
    width: "5%",
    fontFamily: "Noto Serif Bengali",

    borderRight: "1px solid #000",
    borderBottom: "1px solid #000"
  },
  irow2: {
    width: "20%",
    fontFamily: "Noto Serif Bengali",

    borderRight: "1px solid #000",
    borderBottom: "1px solid #000"
  },
  irow3: {
    width: "10%",
    fontFamily: "Noto Serif Bengali",

    borderRight: "1px solid #000",
    borderBottom: "1px solid #000"
  },
  irow4: {
    width: "10%",
    fontFamily: "Noto Serif Bengali",

    borderRight: "1px solid #000",
    borderBottom: "1px solid #000"
  },
  irow5: {
    width: "10%",
    fontFamily: "Noto Serif Bengali",

    borderRight: "1px solid #000",
    borderBottom: "1px solid #000"
  },
  irow6: {
    width: "10%",
    fontFamily: "Noto Serif Bengali",

    borderRight: "1px solid #000",
    borderBottom: "1px solid #000"
  },
  irow7: {
    width: "15%",
    fontFamily: "Noto Serif Bengali",

    borderRight: "1px solid #000",
    borderBottom: "1px solid #000"
  },
  irow8: {
    width: "10%",
    fontFamily: "Noto Serif Bengali",

    borderRight: "1px solid #000",
    borderBottom: "1px solid #000"
  },
  irow9: {
    width: "10%",
    fontFamily: "Noto Serif Bengali"
  },
  footer: {
    position: "absolute",
    paddingTop: 30,
    marginTop: 30,
    fontSize: 12,
    bottom: 0,
    left: 0,
    right: 0
  },
  signatureRow: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    paddingTop: 30,
    marginTop: 10,
    paddingBottom: 0,
    fontSize: 10,
    fontFamily: "Noto Serif Bengali"
  },
  signatureItem1: {
    width: "24%"
  },
  signatureItem2: {
    width: "24%"
  },
  signatureItem3: {
    width: "24%"
  },
  signatureItem4: {
    width: "24%"
  },
  instraction: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 50,
    textAlign: "left",
    paddingBottom: 0,
    fontSize: 9,
    fontFamily: "Noto Serif Bengali"
  },
  instractionItem: {
    width: "50%"
  }
});

export const PrintComponent: React.FC<PrintComponentProps> = (
  props: PrintComponentProps
) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.body}>
        <View fixed>
          <Text style={styles.header}>ঢাকা মেডিকেল কলেজ হাসপাতাল</Text>
          <Text style={styles.subHeader}>
            ..{props.printData.originLocation.name}.. ওয়ার্ড/বিভাগের জন্য
            নিম্নলিখিত দ্রব্যসমূহ সরবারহ করুন।
          </Text>
          <Text style={styles.date}>
            তারিখ :{dayjs(props.printData.createdAt).format(" D MMMM, YYYY")}
          </Text>
        </View>

        <View style={styles.table}>
          <View style={[styles.row, styles.tableHeader]} fixed>
            <Text style={styles.row1}>ক্রমিক নং </Text>
            <Text style={styles.row2}>দ্রব্যাদির নাম </Text>
            <Text style={styles.row3}>প্রয়োজনের পরিমাণ</Text>
            <Text style={styles.row4}>অতিরিক্ত (ক) * পালটান (খ) *</Text>
            <Text style={styles.row5}>স্টকের স্থিতি</Text>
            <Text style={styles.row6}>শেষ সরবরাহের পরিমাণ</Text>
            <Text style={styles.row7}>শেষ ফরমায়েশের নং ও তারিখ</Text>
            <Text style={styles.row8}>সরবরাহের পরিমাণ **</Text>
            <Text style={styles.row9}>মন্তব্য</Text>
          </View>
          <View style={[styles.numberRow]} fixed>
            <Text style={styles.row1}>১</Text>
            <Text style={styles.row2}>২ </Text>
            <Text style={styles.row3}>৩</Text>
            <Text style={styles.row4}>৪ </Text>
            <Text style={styles.row5}>৫</Text>
            <Text style={styles.row6}> ৬ </Text>
            <Text style={styles.row7}> ৭ </Text>
            <Text style={styles.row8}>৮</Text>
            <Text style={styles.row9}> ৯ </Text>
          </View>
          {props.printData.stockOperationItems.map((row, i) => (
            <View key={i} style={styles.itemRow} wrap={false}>
              <Text style={styles.irow1}>
                <Text style={styles.bold}>{i + 1}</Text>
              </Text>
              <Text style={styles.irow2}>{row.productName + " "}</Text>
              <Text style={styles.irow3}>
                <Text style={styles.bold}>{row.demandAmount}</Text>
              </Text>
              <Text style={styles.irow4}></Text>
              <Text style={styles.irow5}>{row.available}</Text>
              <Text style={styles.irow6}>{row.lastTransfer}</Text>
              <Text style={styles.irow7}>
                {row.lastTransferDate === null
                  ? ""
                  : dayjs(row.lastTransferDate).format(" DD MMMM, YYYY")}
              </Text>
              <Text style={styles.irow8}></Text>
              <Text style={styles.irow9}></Text>
            </View>
          ))}
        </View>
        <View style={styles.footer} fixed>
          <View style={[styles.signatureRow]} fixed>
            <Text style={styles.signatureItem1}>মেডিকেল সুপারিন্টেন্ডেন্ট</Text>
            <Text style={styles.signatureItem2}>সিনিয়র হাউজ অফিসার/নার্স </Text>
            <Text style={styles.signatureItem3}>ম্যান্টুন/পরিদর্শনকারী </Text>
            <Text style={styles.signatureItem4}>প্রেরনকারী স্বাক্ষর</Text>
          </View>
          <View style={[styles.instraction]} fixed>
            <Text style={styles.instractionItem}>
              *পাল্টানোর ক্ষেত্রে নষ্ট দ্রব্যাদির রিপোর্ট নাম্বার ও তারিখ উল্লেখ
              করুন
            </Text>
            <Text style={styles.instractionItem}>
              **সরবারহকারী কর্তৃক পুরন করিতে হইবে ।
            </Text>
            <Text style={styles.instractionItem}>
              নং সওম(বি)ফ-২৯৫/৭৫-১০২২ তাং ৮-১-৭৬
            </Text>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </Page>
    </Document>
  );
};
