import React from "react";
import dayjs from "dayjs";
import {
  Table,
  TableContainer,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton
} from "@chakra-ui/react";
import { ProductOperation } from "../../../../api/type";

interface CmchReportHistoryModalComponentProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  operationSummary: ProductOperation | undefined;
}

export const CmchReportHistoryModalComponent: React.FC<
  CmchReportHistoryModalComponentProps
> = (props: CmchReportHistoryModalComponentProps) => {
  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Operation History</ModalHeader>
          <ModalCloseButton />
          {props.operationSummary && (
            <ModalBody>
              <TableContainer>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>Origin Location</Th>
                      <Th>Destination Location</Th>
                      <Th>Operation Type</Th>
                      <Th>Quantity</Th>
                      <Th>User</Th>
                      <Th>Operation Time</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {props.operationSummary.operations.map((row, key) => (
                      <Tr key={key}>
                        <Td fontSize="xs">{row.originLocation.name}</Td>
                        <Td fontSize="xs">{row.destinationLocation.name}</Td>
                        <Td fontSize="xs">{row.operationType}</Td>
                        <Td fontSize="xs">{row.quantity}</Td>
                        <Td fontSize="xs">{row.createdBy.name}</Td>
                        <Td fontSize="xs">
                          {dayjs(row.createdAt).format("DD MMM YYYY, hh:mm A")}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
