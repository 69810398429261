import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  useReactTable,
  FilterFn,
  ExpandedState,
  getExpandedRowModel,
  getPaginationRowModel
} from "@tanstack/react-table";
import {
  Box,
  Button,
  ButtonGroup,
  Select as SelectPageSize,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Input,
  Switch,
  Checkbox,
  Center
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { FaMinusCircle, FaPlusCircle, FaPeriscope } from "react-icons/fa";

import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import { CSVLink } from "react-csv";
import { PageCardComponent } from "../../core/components/page-card.component";
import { Controller, useForm } from "react-hook-form";
import {
  currentSelectedExpairyProduct,
  InventoyFilterGroup,
  OriginLocationGroup
} from "../types/stock-operation.type";
import { customPagination, displayAllTablePage } from "../../../config";
import {
  Location,
  LocationInventory,
  ProductTrackingTypeOptions,
  TrackingData
} from "../../../api/type";
import {
  useGetAssignUserLocationsQuery,
  useGetLocationInventoryQuery
} from "../../../api";
import { LoaderComponent } from "../../core/components/loader.component";
import {
  canSelectLocation,
  chakraStyles,
  userCurrentRole
} from "../../../utils/common-functions";
import { TableSearchInput } from "../../core/components/table-search-component";
import { LocationInventoryPrintComponent } from "../../print/components/location-inventory-print.component";
import { ExpiryDateModalComponent } from "./expiry-date-modal.component";
import dayjs from "dayjs";
import { LocationInventoryFilterDataType } from "../types/custom-report.type";
import { inventoryFilterOptions } from "../data/custom-report.data";
import { productExpColorLevel } from "../../../utils/exp-color";

type LocationInventoryFormValues = {
  originLocation: OriginLocationGroup;
};

declare module "@tanstack/table-core" {
  interface FilterFns {
    tableFilter: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const tableFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank
  });
  return itemRank.passed;
};

const headers = [
  { label: "Product ID", key: "productId" },
  { label: "Product Name", key: "productName" },
  { label: "Unit", key: "unit" },
  { label: "Tracking Type", key: "trackingType" },
  { label: "Quantity", key: "quantity" }
];

interface LocationInventoryComponentProps {}

const LocationInventoryComponent: React.FC<LocationInventoryComponentProps> = (
  props: LocationInventoryComponentProps
) => {
  const expiryDateModal = useDisclosure();

  const [globalFilter, setGlobalFilter] = useState("");
  const [currentSelectedProduct, setCurrentSelectedProduct] =
    useState<currentSelectedExpairyProduct>();
  let locationId = localStorage.getItem("location");
  const role = userCurrentRole();

  let initialState = {
    label: "",
    value: Number(locationId)
  };

  const [selectedFilter, setSelectedFilter] = useState<InventoyFilterGroup>({
    label: "All",
    value: "ALL"
  });
  const [isAdvanceSearch, setIsAdvanceSearch] = useState<boolean>(false);
  const [shouldHideZeroProducts, setShouldHideZeroProducts] = useState(false);

  const changeSelectFilter = (data: any) => {
    setSelectedFilter(data);
  };

  const [originLocation, setOriginLocation] =
    useState<OriginLocationGroup>(initialState);

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);

  const getLocationInventoryResult = useGetLocationInventoryQuery({
    data: {
      report: {
        locationId: Number(originLocation?.value),
        filterBy: selectedFilter.value
      }
    }
  });

  const getLocationQuery = useGetAssignUserLocationsQuery({});

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(locationId))!
      );
    }
  }, [locationId, originLocationOptions]);

  const handleGetLocation = (data: any) => {
    setOriginLocation(data);
  };

  const { control } = useForm<LocationInventoryFormValues>({});

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: customPagination.pageIndex,
    pageSize: customPagination.pageSize
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const [filterData, setFilterData] =
    useState<LocationInventoryFilterDataType>();

  useEffect(() => {
    if (getLocationInventoryResult.data) {
      if (globalFilter.length > 0) {
        searchFn(globalFilter);
      } else {
        let newData: LocationInventoryFilterDataType = {
          inventory: shouldHideZeroProducts
            ? getLocationInventoryResult.data.data.inventory.filter(
                (locationInventory) => locationInventory.quantity > 0
              )
            : getLocationInventoryResult.data.data.inventory,
          location: getLocationInventoryResult.data?.data.location
        };
        setFilterData(newData);
      }
    }
  }, [
    getLocationInventoryResult.data,
    isAdvanceSearch,
    shouldHideZeroProducts
  ]);

  const columns = useMemo<ColumnDef<LocationInventory, any>[]>(
    () => [
      {
        header: "SL",
        cell: (row) => {
          return Number(row.row.id) + 1;
        }
      },
      {
        header: "Product Name",
        accessorFn: (row) => row.productName,
        id: "productName",
        cell: (row) => row.getValue()
      },
      {
        header: "Quantity",
        accessorFn: (row) => row.quantity,
        id: "quantity",
        cell: (row) => row.getValue()
      },
      {
        header: "Unit",
        accessorFn: (row) => row.unit,
        id: "unit",
        cell: (row) => row.getValue()
      },
      {
        id: "expander",
        header: "Expand",
        cell: ({ row }) => {
          return (
            row &&
            row.getCanExpand() &&
            row.original.trackingType !== ProductTrackingTypeOptions.NONE && (
              <button
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: "pointer" }
                }}
              >
                {row.getIsExpanded() ? <FaMinusCircle /> : <FaPlusCircle />}
              </button>
            )
          );
        }
      }
    ],
    []
  );

  const [expanded, setExpanded] = useState<ExpandedState>({});

  useEffect(() => {
    const initialExpanded = table
      .getRowModel()
      .rows.reduce<{ [key: string]: boolean }>((acc, row) => {
        acc[row.id] = true;
        return acc;
      }, {});
    setExpanded(initialExpanded);
  }, [filterData]);

  const table = useReactTable({
    data: filterData?.inventory ? filterData.inventory : [],
    columns: columns,
    filterFns: {
      tableFilter
    },
    state: {
      pagination,
      globalFilter,
      expanded
    },

    getRowCanExpand(row) {
      return true;
    },
    getCoreRowModel: getCoreRowModel(),
    // getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    // onGlobalFilterChange: setGlobalFilter,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onExpandedChange: setExpanded,
    // globalFilterFn: tableFilter,
    debugTable: true
  });

  const searchFn = (searchString: string) => {
    if (getLocationInventoryResult.data) {
      let finalItems = (
        shouldHideZeroProducts
          ? getLocationInventoryResult.data.data.inventory.filter(
              (locationInventory) => locationInventory.quantity > 0
            )
          : getLocationInventoryResult.data.data.inventory
      ).filter((item) => {
        let isInSearch = item.productName
          .toLowerCase()
          .includes(searchString.toLowerCase());
        if (
          isAdvanceSearch &&
          item.trackingType !== ProductTrackingTypeOptions.NONE &&
          !isInSearch &&
          item.trackingData.length > 0
        ) {
          let filterTrIds = item.trackingData.filter((trData) =>
            trData.trackingId.includes(searchString)
          );
          if (filterTrIds.length > 0) {
            isInSearch = true;
          }
        }
        return isInSearch;
      });

      let newData: LocationInventoryFilterDataType = {
        inventory: finalItems,
        location: getLocationInventoryResult.data?.data.location
      };
      setFilterData(newData);
    }
  };

  const searchFieldOnChange = (e: any) => {
    let searchString = e.target.value;
    setGlobalFilter(searchString);
    if (getLocationInventoryResult.data) {
      if (searchString.length > 0) {
        searchFn(searchString);
      } else {
        let newData: LocationInventoryFilterDataType = {
          inventory: getLocationInventoryResult.data.data.inventory,
          location: getLocationInventoryResult.data?.data.location
        };
        setFilterData(newData);
      }
    }
  };
  const renderSubComponent = ({ row }: any) => {
    const hasExpandableData =
      row.original.trackingData && row.original.trackingData.length > 0;
    if (!hasExpandableData) return null;
    return (
      <Table variant="striped" size="sm">
        <Thead>
          <Tr bg="gray.500">
            <Th color="white">Tracking ID</Th>
            <Th color="white">Quantity</Th>
            <Th color="white">Manufacturing Date</Th>
            <Th color="white">Expiry Date</Th>
            <Th color="white">Fix Expiry Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {(shouldHideZeroProducts
            ? row.original.trackingData.filter(
                (singleTrackingData: unknown & { amount: number }) =>
                  singleTrackingData.amount > 0
              )
            : row.original.trackingData
          ).map((d: any, index: any) => (
            <Tr
              key={index}
              color={
                d.expiryDate ? productExpColorLevel(d.expiryDate) : "black"
              }
            >
              <Td>{d.trackingId}</Td>
              <Td>{d.amount}</Td>
              <Td>
                {d.manufactureDate
                  ? dayjs(d.manufactureDate).format("MMM-YY")
                  : null}
              </Td>
              <Td>
                {d.expiryDate ? dayjs(d.expiryDate).format("MMM-YY") : null}
              </Td>
              <Td
                onClick={() =>
                  expiryAddModalOpen(
                    row.original.productId,
                    row.original.productName,
                    d.trackingId,
                    d.expiryDate,
                    d.manufactureDate
                  )
                }
              >
                <FaPeriscope />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  };
  const expiryAddModalOpen = (
    productId: number,
    productName: string,
    tranckingId: string,
    expiryDate: string | null,
    manufactureDate: string | null
  ) => {
    expiryDateModal.onOpen();
    //expiryAddModalOpen(row.orginal.productId, row.productName, d.trackingId, d.expiryDate, d.manufactureDate)
    setCurrentSelectedProduct({
      productId: productId,
      productName: productName,
      tranckingId: tranckingId,
      expiryDate: expiryDate,
      manufactureDate: manufactureDate
    });
  };

  const cancelRef = React.useRef();

  const rowColorDef = (tracKingData: TrackingData[]) => {
    let color = "black";
    let filterTrackingData = tracKingData.filter(
      (tr) => tr.expiryDate !== null
    );
    let oldestExpItem = filterTrackingData.sort((objA, objB) => {
      let dateOne = new Date(objA.expiryDate).getTime();
      let dateTwo = new Date(objB.expiryDate).getTime();
      return dateOne - dateTwo;
    })[0];

    if (oldestExpItem) {
      color = productExpColorLevel(oldestExpItem.expiryDate);
    }

    // .sort((objA, objB) => objA.date.getTime() - objB.date.getTime())[0];
    return color;
  };

  return (
    <Stack>
      {currentSelectedProduct && (
        <ExpiryDateModalComponent
          cancelRef={cancelRef}
          onClose={expiryDateModal.onClose}
          isOpen={expiryDateModal.isOpen}
          onOpen={expiryDateModal.onOpen}
          product={currentSelectedProduct}
          key={currentSelectedProduct.tranckingId}
        />
      )}
      <Stack direction={{ base: "column", xl: "row" }} justify="space-between">
        <PageCardComponent>
          <Controller
            control={control}
            name="originLocation"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { error }
            }) => (
              <FormControl mb="3" isInvalid={!!error} id="originLocation">
                <FormLabel>Select Location</FormLabel>
                <Select
                  chakraStyles={chakraStyles}
                  name={name}
                  ref={ref}
                  onBlur={onBlur}
                  value={originLocation}
                  isDisabled={!canSelectLocation(role)}
                  onChange={(location) => handleGetLocation(location)}
                  options={originLocationOptions}
                  placeholder="Select location"
                  closeMenuOnSelect={true}
                />
                <FormErrorMessage>{error && error.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </PageCardComponent>
      </Stack>

      <Stack
        direction={{
          base: "column",
          md: "row",
          lg: "row",
          xl: "row",
          "2xl": "row"
        }}
        minWidth="max-content"
        alignItems="center"
      >
        {getLocationInventoryResult.data && (
          <Box>
            {/* <TableSearchInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          /> */}
            <Input
              placeholder="Enter 3 or 4 letters to find "
              onChange={(e) => searchFieldOnChange(e)}
            />
          </Box>
        )}
        <Box width="200px">
          <Select
            chakraStyles={chakraStyles}
            name="selected filter"
            value={selectedFilter}
            onChange={(filter) => changeSelectFilter(filter)}
            options={inventoryFilterOptions}
            placeholder="select filter"
            closeMenuOnSelect={true}
          />
        </Box>
        <Stack direction="row" gap="2">
          <Box>
            <FormControl display="flex" alignItems="end">
              <FormLabel htmlFor="advanced-search" mb="0">
                Advance Search
              </FormLabel>
              <Switch
                id="advanced-search"
                isChecked={isAdvanceSearch}
                onChange={(e) => setIsAdvanceSearch((pre) => !pre)}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl display="flex" alignItems="end">
              <FormLabel htmlFor="advanced-search" mb="0">
                Hide Unavailable Products
              </FormLabel>
              <Switch
                onChange={(e) => setShouldHideZeroProducts((value) => !value)}
              />
            </FormControl>
          </Box>
        </Stack>

        <Spacer />
        <ButtonGroup gap="2">
          {filterData ? (
            <CSVLink data={filterData.inventory} headers={headers}>
              <Button colorScheme="blue">Export CSV</Button>
            </CSVLink>
          ) : undefined}
          {filterData?.location && (
            <LocationInventoryPrintComponent
              printProductData={filterData}
              filterBy={selectedFilter}
              searchKeyword={globalFilter}
              shouldHideZeroProducts={shouldHideZeroProducts}
            />
          )}
        </ButtonGroup>
      </Stack>

      {getLocationInventoryResult.isLoading ? (
        <LoaderComponent />
      ) : getLocationInventoryResult.isSuccess &&
        getLocationInventoryResult.data.data.inventory.length <= 0 ? (
        <Center height="300px">
          <Text as="h5" size="sm">
            There is no product in{" "}
            {getLocationInventoryResult.data.data.location.name}
          </Text>
        </Center>
      ) : (
        <Table variant="striped">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      bg="gray.700"
                      color="white"
                    >
                      {header.isPlaceholder ? null : (
                        <Box>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Box>
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Fragment key={row.id}>
                  <Tr color={rowColorDef(row.original.trackingData)}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                  {row.getIsExpanded() && (
                    <Tr>
                      <Td colSpan={row.getVisibleCells().length}>
                        {renderSubComponent({
                          row
                        })}
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              );
            })}
          </Tbody>
        </Table>
      )}
      {getLocationInventoryResult.data?.data &&
        getLocationInventoryResult.data?.data.inventory.length > 0 && (
          <Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              justify="space-between"
              alignItems="center"
              mt="2"
            >
              <Box width={{ base: "100%", lg: "60%" }}>
                <Text fontSize="lg" fontWeight="bold">
                  Showing (Page {table.getState().pagination.pageIndex + 1} of{" "}
                  {table.getPageCount()})
                </Text>{" "}
              </Box>
              <Stack
                direction="row"
                width={{ base: "100%", lg: "40%" }}
                justifyContent="space-between"
              >
                <Stack direction="row" width={{ lg: "80%" }}>
                  <Button
                    colorScheme="blue"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {"<<"}
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {"<"}
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    {">"}
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                  >
                    {">>"}
                  </Button>{" "}
                </Stack>

                <Box width={{ base: "100%", md: "50%", lg: "100%" }}>
                  <SelectPageSize
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      table.setPageSize(Number(e.target.value));
                    }}
                  >
                    {displayAllTablePage.map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </SelectPageSize>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        )}
    </Stack>
  );
};

export default LocationInventoryComponent;
