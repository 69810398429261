import React, {
  forwardRef,
  Fragment,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stat,
  StatLabel,
  StatNumber,
  Divider,
  AbsoluteCenter,
  TableContainer,
  Center,
  Text
} from "@chakra-ui/react";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { Select } from "chakra-react-select";
import "./chakra-react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { PageCardComponent } from "../../core/components/page-card.component";
import { LoaderComponent } from "../../core/components/loader.component";
import { OriginLocationGroup } from "../types/stock-operation.type";
import { RetrieveQueryParams } from "../../../service/operation/retrieveQueryParams.service";
import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  PaginationState,
  useReactTable
} from "@tanstack/react-table";
import {
  canSelectLocation,
  chakraStyles,
  userCurrentRole
} from "../../../utils/common-functions";
import {
  useGetAnnualProductReportQuery,
  useGetAssignUserLocationsQuery
} from "../../../api";
import { customPagination } from "../../../config";
import { ProductPrediction } from "../../../api/type";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import { ProductPredictionReportPrintComponent } from "../../print/components/product-prediction-report-print.component";
import { TableSearchInput } from "../../core/components/table-search-component";

type AnnualReportFormValues = {
  originLocation: OriginLocationGroup;
};

declare module "@tanstack/table-core" {
  interface FilterFns {
    tableFilter: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}
const tableFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank
  });
  return itemRank.passed;
};

interface AnnualProductReportComponentProps {}

export const AnnualProductReportComponent: React.FC<
  AnnualProductReportComponentProps
> = (props: AnnualProductReportComponentProps) => {
  const role = userCurrentRole();
  let query = RetrieveQueryParams();

  const { control } = useForm<AnnualReportFormValues>({});

  let locationId = query.get("locationId")
    ? query.get("locationId")
    : localStorage.getItem("location");

  let initialState = {
    label: "",
    value: Number(locationId)
  };

  const [originLocation, setOriginLocation] =
    useState<OriginLocationGroup>(initialState);

  const handleGetLocation = (data: any) => {
    setOriginLocation(data);
  };

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);

  const getLocationQuery = useGetAssignUserLocationsQuery({});

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(locationId))!
      );
    }
  }, [locationId, originLocationOptions]);

  const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
    <Input
      w={60}
      autoComplete="off"
      background="white"
      value={value}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
    />
  );
  customDateInput.displayName = "DateInput";

  const CustomInput = forwardRef(customDateInput);

  const previousYear = dayjs().subtract(1, "year").toISOString();

  const [startDate, setStartDate] = useState(new Date(previousYear));

  function onChangeStartDateHandler(value: any) {
    setStartDate(value);
  }

  const [endDate, setEndDate] = useState(new Date());

  function onChangeEndDateHandler(value: any) {
    setEndDate(value);
  }

  const { data, isLoading } = useGetAnnualProductReportQuery({
    data: {
      locationId: Number(originLocation?.value),
      startDate: dayjs(startDate).toISOString(),
      endDate: dayjs(endDate).toISOString()
    }
  });

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: customPagination.pageIndex,
    pageSize: customPagination.pageSize
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const [globalFilter, setGlobalFilter] = useState("");

  const columns = useMemo<ColumnDef<ProductPrediction, any>[]>(
    () => [
      {
        header: "SL",
        cell: (row) => {
          return Number(row.row.id) + 1;
        }
      },
      {
        header: "Product Name",
        accessorFn: (row) => row.name,
        id: "name",
        cell: (row) => row.getValue()
      },
      {
        header: "Unit",
        accessorFn: (row) => row.unit,
        id: "unit",
        cell: (row) => row.getValue()
      },
      {
        header: "Total In",
        accessorFn: (row) => row.totalIn,
        id: "totalIn",
        cell: (row) => row.getValue()
      },
      {
        header: "Total Out",
        accessorFn: (row) => row.totalOut,
        id: "totalOut",
        cell: (row) => row.getValue()
      }
    ],
    []
  );

  const table = useReactTable({
    data: data ? data.productPrediction : [],
    columns: columns,
    filterFns: {
      tableFilter
    },
    state: {
      pagination,
      globalFilter
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    getPaginationRowModel: getPaginationRowModel(),
    globalFilterFn: tableFilter,
    debugTable: true
  });

  return (
    <Stack>
      <Stack
        direction={{ base: "column", xl: "row" }}
        justify="space-between"
        width="100%"
        bgColor="white"
        borderRadius="md"
        p={4}
        spacing={4}
      >
        <Box w={{ base: "100%", md: "100%", xl: "50%" }}>
          <Controller
            control={control}
            name="originLocation"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { error }
            }) => (
              <FormControl mb="3" isInvalid={!!error} id="originLocation">
                <FormLabel>Select Location</FormLabel>
                <Select
                  chakraStyles={chakraStyles}
                  name={name}
                  ref={ref}
                  onChange={(location) => handleGetLocation(location)}
                  onBlur={onBlur}
                  isDisabled={!canSelectLocation(role)}
                  value={originLocation}
                  options={originLocationOptions}
                  placeholder="Select location"
                  closeMenuOnSelect={true}
                />
                <FormErrorMessage></FormErrorMessage>
              </FormControl>
            )}
          />
        </Box>
        <Spacer />
        <Box w={{ base: "100%", md: "100%", xl: "20%" }}>
          <FormControl>
            <FormLabel>From</FormLabel>
            <InputGroup className="dark-theme">
              <DatePicker
                id="dateStartEnd"
                selected={startDate}
                onChange={onChangeStartDateHandler}
                dateFormat="dd MMM yyyy"
                className="react-datapicker__input-text"
                placeholderText="Select Month"
                customInput={<CustomInput />}
                showDisabledMonthNavigation
              />
            </InputGroup>
          </FormControl>
        </Box>
        <Spacer />
        <Box w={{ base: "100%", md: "100%", xl: "20%" }}>
          <FormControl>
            <FormLabel>To</FormLabel>
            <InputGroup className="dark-theme">
              <DatePicker
                id="dateStartEnd"
                selected={endDate}
                onChange={onChangeEndDateHandler}
                dateFormat="dd MMM yyyy"
                className="react-datapicker__input-text"
                placeholderText="Select Month"
                customInput={<CustomInput />}
                showDisabledMonthNavigation
              />
            </InputGroup>
          </FormControl>
        </Box>
      </Stack>

      <Box position="relative" py="5">
        <Divider />
        <AbsoluteCenter bg="white" px="4">
          Product Prediction
        </AbsoluteCenter>
      </Box>

      <Stack direction={{ base: "column", xl: "row" }}>
        <PageCardComponent>
          <Stat>
            <StatLabel>Report Location</StatLabel>
            <StatNumber fontSize="lg" fontWeight={600}>
              {data?.location.name}
            </StatNumber>
          </Stat>
        </PageCardComponent>
        <PageCardComponent>
          <Stat>
            <StatLabel>Start Date</StatLabel>
            <StatNumber fontSize="lg" fontWeight={600}>
              {dayjs(data?.startDate).format("DD MMM YYYY, hh:mm A")}
            </StatNumber>
          </Stat>
        </PageCardComponent>
        <PageCardComponent>
          <Stat>
            <StatLabel>End Date</StatLabel>
            <StatNumber fontSize="lg" fontWeight={600}>
              {dayjs(data?.endDate).format("DD MMM YYYY, hh:mm A")}
            </StatNumber>
          </Stat>
        </PageCardComponent>
      </Stack>

      <Stack direction={{ base: "column", xl: "row" }} width="100%" my="2">
        <Box>
          <TableSearchInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          />
        </Box>
        <Spacer />
        <Box>
          {data && <ProductPredictionReportPrintComponent data={data} />}
        </Box>
      </Stack>

      {isLoading ? (
        <LoaderComponent />
      ) : (
        <TableContainer>
          {data?.location.name && data.productPrediction.length > 0 ? (
            <Table variant="striped">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <Th
                          key={header.id}
                          colSpan={header.colSpan}
                          bg="gray.700"
                          color="white"
                        >
                          {header.isPlaceholder ? null : (
                            <Box>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </Box>
                          )}
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <Fragment key={row.id}>
                      <Tr>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <Td key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Td>
                          );
                        })}
                      </Tr>
                    </Fragment>
                  );
                })}
              </Tbody>
            </Table>
          ) : (
            <Center height={"30vh"}>
              <Text>There is no product in this location !!</Text>
            </Center>
          )}
        </TableContainer>
      )}
    </Stack>
  );
};
