import React, { useState } from "react";
import {
  Stack,
  Flex,
  Button,
  useColorModeValue,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from "@chakra-ui/react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { AllNotificationTableComponent } from "./all-notification-table.component";
import { customPagination } from "../../../config";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  useReactTable
} from "@tanstack/react-table";
import { useGetNotificationsQuery } from "../../../api";
interface AllNotificationComponentProps {}

export const AllNotificationComponent: React.FC<
  AllNotificationComponentProps
> = (props: AllNotificationComponentProps) => {
  const [tabIndex, setTabIndex] = useState(0);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Stack>
      {" "}
      <Flex justifyContent="end">
        <Button
          colorScheme="blue"
          leftIcon={<IoMdArrowRoundBack />}
          onClick={() => goBack()}
        >
          Back
        </Button>
      </Flex>
      <Stack>
        <Tabs onChange={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>New</Tab>
            <Tab>Read</Tab>
            <Tab>All</Tab>
          </TabList>

          <TabPanels p="2rem">
            <TabPanel>
              {" "}
              <AllNotificationTableComponent
                isRead={false}
                tabIndex={tabIndex}
              />
            </TabPanel>
            <TabPanel>
              <AllNotificationTableComponent
                isRead={true}
                tabIndex={tabIndex}
              />
            </TabPanel>
            <TabPanel>
              {" "}
              <AllNotificationTableComponent tabIndex={tabIndex} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Stack>
  );
};
