import React from "react";
import { RemoveUserLocationComponent } from "../components/remove-user-location.component";

interface RemoveUserLocationPageProps {}

export const RemoveUserLocationPage: React.FC<RemoveUserLocationPageProps> = (
  props: RemoveUserLocationPageProps
) => {
  return (
    <>
      <RemoveUserLocationComponent />
    </>
  );
};
