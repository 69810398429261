import React, { useEffect, useState } from "react";
import {
  Button,
  Heading,
  Stack,
  Center,
  Box,
  useToast
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { api, useGetAuthorizedLocationQuery } from "../../../api";
import { useNavigate } from "react-router-dom";
import { userCurrentRole } from "../../../utils/common-functions";
import { DecorationImageComponent } from "./decoration-image.component";
import { useTypedDispatch } from "../../../hooks/use-typed-dispatch.hook";

interface SelectLocationComponentProps {}

export const SelectLocationComponent: React.FC<SelectLocationComponentProps> = (
  props: SelectLocationComponentProps
) => {
  const getAuthorizedLocationsResult = useGetAuthorizedLocationQuery({});
  const navigate = useNavigate();
  const toast = useToast();
  const role = userCurrentRole();
  const dispatch = useTypedDispatch();

  const [selectedLocation, setSelectedLocation] = useState<{
    label: string;
    value: number;
  }>();

  const [locationOptions, setLocationOptions] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);

  useEffect(() => {
    if (getAuthorizedLocationsResult.data) {
      const locationValues =
        getAuthorizedLocationsResult.data.data.locations.map((location) => {
          return {
            label: location.name,
            value: location.id
          };
        });

      setLocationOptions([...locationValues]);
    }
  }, [getAuthorizedLocationsResult]);

  useEffect(() => {
    if (locationOptions.length === 1) {
      window.localStorage.setItem(
        "location",
        locationOptions[0].value.toString()
      );

      navigate(`/${role}/dashboard`);
    }
  }, [locationOptions, navigate, role]);

  const handleSelectLocation = () => {
    if (selectedLocation) {
      window.localStorage.setItem(
        "location",
        selectedLocation.value.toString()
      );

      navigate(`/${role}/dashboard`);
    }
  };

  const logOut = () => {
    window.localStorage.clear();
    toast({
      title: "Success",
      description: "Logged out successfully",
      status: "info",
      position: "top",
      duration: 2000,
      isClosable: true
    });
    dispatch(api.util.resetApiState());
    navigate("/login");
  };

  return (
    <Box
      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
      px={{ base: "30px", md: "50px", lg: "60px" }}
      py="12"
      borderRadius="lg"
    >
      <Center>
        <Stack spacing="3" width={["300px", "500px"]}>
          <DecorationImageComponent />
          <Heading fontSize="lg">Select Location</Heading>
          <Select
            value={selectedLocation}
            onChange={(event) => {
              if (event) {
                setSelectedLocation({
                  label: event.label,
                  value: event.value
                });
              }
            }}
            selectedOptionStyle="check"
            options={locationOptions}
          />
          <Button colorScheme="blue" onClick={handleSelectLocation}>
            Set Current Location
          </Button>
          <Button colorScheme="blue" onClick={logOut}>
            Log Out
          </Button>
        </Stack>
      </Center>
    </Box>
  );
};
