import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialStateType {
  startDate: string;
  endDate: string;
}

const initialState: InitialStateType = {
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString()
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    startDate: (state, action: PayloadAction<any>) => {
      state.startDate = action.payload;
    },
    endDate: (state, action: PayloadAction<any>) => {
      state.endDate = action.payload;
    }
  }
});
// Export actions
export const { startDate, endDate } = reportSlice.actions;
// Export reducer
export default reportSlice.reducer;
