import moment from "moment";
import dayjs from "dayjs";
const Heading = (doc, report, startY, fontSizes, lineSpacing, Type) => {
  const invoiceNrTxt = report.data.location.name;
  let startX = 42;
  const pageWidth = doc.internal.pageSize.width;
  const endX = pageWidth - startX;

  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);
  doc.setFontSize(fontSizes.SubTitleFontSize);

  startY = 150;

  startY += lineSpacing;

  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);

  const printtime = `Printout Date: ${moment().format("D  MMMM  YYYY LT")} `;
  doc.text(printtime, startX, startY);

  startX += doc.getStringUnitWidth(invoiceNrTxt) * fontSizes.SubTitleFontSize;

  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);

  let location;
  if (Type === "monthlyReport") {
    location = `Report Month: ${dayjs(report.data.startDate).format(
      " MMMM  YYYY "
    )} `;
  } else {
    location = `Report Date: ${dayjs(report.data.startDate).format(
      "DD  MMMM  YYYY "
    )} `;
  }

  doc.text(location, endX, startY, "right");
  startX = 42;

  doc.setDrawColor(157, 183, 128);
  doc.setLineWidth(0.5);
  startY += lineSpacing;
  doc.line(startX, startY, endX, startY);

  return startY;
};

export default Heading;
