import React from "react";
import { useEffect, useState } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  ButtonGroup,
  useToast,
  Box,
  useDisclosure,
  Textarea,
  Input
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import {
  DestinationLocationGroup,
  ProductGroup
} from "../types/stock-operation.type";
import { PageCardComponent } from "../../core/components/page-card.component";

import {
  useAdjustmentMutation,
  useGetLocationQuery,
  useLazyGetFilterProductQuery
} from "../../../api";
import { ConfirmAlertComponent } from "./confirm-alert.component";
import { ProductTrackingTypeOptions } from "../../../api/type";

interface AdjustmentComponentProps { }

export const AdjustmentComponent: React.FC<AdjustmentComponentProps> = (
  props: AdjustmentComponentProps
) => {
  const toast = useToast();
  const adjustmentConfirmAlert = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const getLocationQuery = useGetLocationQuery({});

  const [destinationLocationOptions, setDestinationLocationOptions] = useState<
    DestinationLocationGroup[]
  >([]);
  const [adjustmentType, setAdjustmentType] = useState("increment");

  const [selectedLocation, setSelectedLocation] =
    useState<DestinationLocationGroup>();
  const [quantity, setQuantity] = useState<number>();
  const [notes, setNotes] = useState<string>("");
  const [trackingNumber, settrackingNumber] = useState<string>("");

  const [createAdjustment, createAdjustmentResult] = useAdjustmentMutation();

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setDestinationLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  const [productOptions, setProductOptions] = useState<ProductGroup[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<ProductGroup>();
  const [getProductQuery, getProductQueryResult] =
    useLazyGetFilterProductQuery();

  useEffect(() => {
    if (getProductQueryResult.data?.data.products) {
      const productWithValues = getProductQueryResult.data?.data.products.map(
        (product) => {
          return {
            label: product.name,
            value: product.id,
            unit: product.unit,
            quantity: product.quantity,
            type: product.type
          };
        }
      );
      setProductOptions(productWithValues);
    }
  }, [getProductQueryResult.data]);

  const handleSelectLocation = (data: any) => {
    setSelectedLocation(data);
    getProductQuery({ id: Number(data.value) });
  };
  const handleSelectProduct = (data: any) => {
    setSelectedProduct(data);

    if (data.type === ProductTrackingTypeOptions.SERIALIZED) {
      setQuantity(1);
    }
  };

  const supplyHandleSubmit = (e: any) => {
    e.preventDefault();

    if (selectedLocation && selectedProduct && quantity) {
      if (selectedProduct.type === ProductTrackingTypeOptions.NONE) {
        createAdjustment({
          data: {
            stockOperation: {
              locationId: selectedLocation.value,
              productId: selectedProduct.value,
              quantity:
                adjustmentType === "increment" ? quantity : -Math.abs(quantity),
              notes: notes,
              trackingNumber: ""
            }
          }
        });
      }
      else if (trackingNumber && selectedProduct.type !== ProductTrackingTypeOptions.NONE) {
        createAdjustment({
          data: {
            stockOperation: {
              locationId: selectedLocation.value,
              productId: selectedProduct.value,
              trackingNumber: trackingNumber,
              quantity:
                adjustmentType === "increment" ? quantity : -Math.abs(quantity),
              notes: notes
            }
          }
        });

      } else {
        toast({
          title: "Fail",
          description: "Give tracking number",
          status: "error",
          position: "top",
          duration: 2000,
          isClosable: true
        });
      }

    }
  };

  useEffect(() => {
    if (createAdjustmentResult.isSuccess) {
      toast({
        title: "Success",
        description: "Adjustment successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      adjustmentConfirmAlert.onClose();
    }
  }, [createAdjustmentResult, toast]);

  return (
    <div>
      <Stack>
        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
        >
          <PageCardComponent>
            <FormControl mb="3">
              <FormLabel>Location</FormLabel>
              <Select
                onChange={(location) => handleSelectLocation(location)}
                value={selectedLocation}
                options={destinationLocationOptions}
                placeholder="Select Location"
                closeMenuOnSelect={true}
              />
            </FormControl>
          </PageCardComponent>
        </Stack>
        <Stack spacing={2}>
          <Stack
            direction={{ base: "column", xl: "row" }}
            width="100%"
            bgColor={"white"}
            borderRadius="md"
            spacing={4}
            px="4"
            py="2"
            mt="2"
          >
            <Box w={{ base: "100%", md: "100%", xl: "45%" }}>
              <FormControl>
                <FormLabel>Select Product</FormLabel>
                <Select
                  onChange={(product) => handleSelectProduct(product)}
                  value={selectedProduct}
                  options={productOptions}
                  placeholder="Select product"
                  closeMenuOnSelect={true}
                />
              </FormControl>
            </Box>
            <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
              <FormControl>
                <FormLabel>Unit</FormLabel>
                <Input

                  isDisabled={true}
                  placeholder="Unit"
                  value={selectedProduct?.unit}
                />
              </FormControl>
            </Box>
            <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
              <FormControl>
                <FormLabel>Available</FormLabel>
                <Input

                  isDisabled={true}
                  placeholder="Available"
                  value={selectedProduct?.quantity}
                />
              </FormControl>
            </Box>
            <Box w={{ base: "100%", md: "100%", xl: "15%" }}>
              <FormControl

              >
                <FormLabel >Tracking Number</FormLabel>
                <Input

                  isDisabled={selectedProduct?.type === ProductTrackingTypeOptions.NONE || selectedProduct === undefined ? true : false}
                  key={`trackingNumber`}
                  type={"string"}
                  placeholder="Tracking Number"
                  onChange={(e) => settrackingNumber(e.target.value)}
                  value={trackingNumber}
                />

              </FormControl>
            </Box>
            <Box w={{ base: "100%", md: "100%", xl: "20%" }}>
              <FormControl mb="3">
                <FormLabel>Quantity</FormLabel>
                <Input
                  placeholder="Enter product quantity"
                  onChange={(e) => setQuantity(Number(e.target.value))}
                  isDisabled={selectedProduct?.type === ProductTrackingTypeOptions.SERIALIZED ? true : false}
                  value={quantity}
                />


              </FormControl>
            </Box>
          </Stack>

          <Stack spacing={4} width="100%">
            <ButtonGroup isAttached w={"100%"}>
              <Button
                w={"50%"}
                colorScheme={"blue"}
                variant={adjustmentType === "increment" ? "solid" : "outline"}
                fontSize="sm"
                onClick={() => setAdjustmentType("increment")}
                isActive={adjustmentType === "increment"}
              >
                Increase
              </Button>
              <Button
                w={"50%"}
                colorScheme={"blue"}
                variant={adjustmentType === "decrement" ? "solid" : "outline"}
                fontSize="sm"
                onClick={() => setAdjustmentType("decrement")}
                isActive={adjustmentType === "decrement"}
              >
                Decrease
              </Button>
            </ButtonGroup>
          </Stack>
          <Textarea
            placeholder="Enter notes for this Operation"
            onChange={(e) => setNotes(e.target.value)}
          />
          <Button
            width="100%"
            onClick={adjustmentConfirmAlert.onOpen}
            colorScheme="blue"
            isDisabled={
              selectedLocation && selectedProduct && quantity ? false : true
            }
          >
            Adjust
          </Button>
          <ConfirmAlertComponent
            cancelRef={cancelRef}
            onClose={adjustmentConfirmAlert.onClose}
            isOpen={adjustmentConfirmAlert.isOpen}
            onOpen={adjustmentConfirmAlert.onOpen}
            handleApprove={supplyHandleSubmit}
            status={createAdjustmentResult}
          />
        </Stack>
      </Stack>
    </div>
  );
};
