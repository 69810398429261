import React, { useEffect, useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PrintComponent } from "./print.component";
import { useGetDemandPrintDataQuery } from "../../../api";
import { GetDemandPrintDataResponse } from "../../../api/type";
import dayjs from "dayjs";

interface LocationDemandPrintComponentProps {
  id: number;
}

export const LocationDemandPrintComponent: React.FC<
  LocationDemandPrintComponentProps
> = (props: LocationDemandPrintComponentProps) => {
  const { data } = useGetDemandPrintDataQuery({
    data: { report: { demandStockOperationId: Number(props.id) } }
  });

  const [printData, setPrintData] = useState<GetDemandPrintDataResponse>();
  useEffect(() => {
    setPrintData(data);
  }, [data]);

  const demandHistory = `Demand-History-${
    printData?.originLocation.name
  }-${dayjs().format("DD-MM-YYYY")}.pdf`;

  return (
    <>
      {printData && (
        <PDFDownloadLink
          document={
            <PrintComponent
              printData={printData}
              originLocationName={printData.originLocation.name}
            />
          }
          fileName={demandHistory}
        >
          {({ blob, url, loading, error }) =>
            loading && data ? "Loading document..." : "Print Report"
          }
        </PDFDownloadLink>
      )}
    </>
  );
};
