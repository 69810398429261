import { extendTheme } from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";

// Global style overrides
import styles from "./styles";
import config from "./config";

// Foundational style overrides
import fonts from "./foundations/fonts";
import colors from "./foundations/colors";

// Component style overrides
// import Button from "./components/button";

const overrides = {
  config,
  styles,
  fonts,
  colors,

  // Other foundational style overrides go here

  components: {
    // Button,
  }
};

const theme = extendTheme({
  components: {
    Steps
  }
});

export default extendTheme(overrides, theme);
