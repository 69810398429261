import React from "react";
import { Stack } from "@chakra-ui/react";
import { LowStockComponent } from "../components/low-stock.component";

interface LowStockPageProps {}

export const LowStockPage: React.FC<LowStockPageProps> = (
  props: LowStockPageProps
) => {
  return (
    <Stack>
      <LowStockComponent />
    </Stack>
  );
};
