import { useEffect, useState } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useToast,
  Textarea,
  Box,
  Checkbox
} from "@chakra-ui/react";
import { Select, GroupBase, CreatableSelect } from "chakra-react-select";
import { useForm, Controller } from "react-hook-form";
import { PageCardComponent } from "../../core/components/page-card.component";
import {
  useAddProductMutation,
  useLazySearchGenericsQuery
} from "../../../api";

import {
  DepartmentGroup,
  TrackingTypeGroup,
  UnitGroup
} from "../type/product.type";
import {
  departmentOptions,
  unitOptions,
  trackingTypeOptions
} from "../data/product.data";

type AddProductFormValues = {
  name: string;
  ownerDepartment: DepartmentGroup;
  unit: UnitGroup;
  trackingType: TrackingTypeGroup;
  notes: string;
  medicineGenericId?: string;
  lowStockAmount: number;
};

const AddProductComponent = () => {
  const toast = useToast();
  const [isEdcl, setIsEdcl] = useState<boolean>(false);
  const [addProduct, resultProduct] = useAddProductMutation();
  const [searchGenerics, searchGenericsResult] = useLazySearchGenericsQuery();
  const [selectValue, setSelectValue] = useState("");
  const [selectedGeneric, setSelectedGeneric] = useState<{
    value: string;
    label: string;
  }>();

  const [lowStockAmount, setLowStockAmount] = useState<number>(50);

  const {
    register,
    handleSubmit,
    resetField,
    control,
    formState: { errors }
  } = useForm<AddProductFormValues>({});

  const handleAddProduct = handleSubmit((data) => {
    if (selectedGeneric && selectedGeneric.value !== "") {
      addProduct({
        data: {
          product: {
            name: data.name,
            isEdcl: isEdcl,
            unit: data.unit.value,
            trackingType: data.trackingType.value,
            ownerDepartment: data.ownerDepartment.value,
            notes: data.notes,
            medicineGenericId: selectedGeneric?.value,
            lowStockAmount
          }
        }
      });
    } else {
      addProduct({
        data: {
          product: {
            name: data.name,
            isEdcl: isEdcl,
            unit: data.unit.value,
            trackingType: data.trackingType.value,
            ownerDepartment: data.ownerDepartment.value,
            notes: data.notes,
            lowStockAmount
          }
        }
      });
    }
    resetField("name");
  });

  useEffect(() => {
    if (resultProduct.isSuccess) {
      toast({
        title: "Success",
        description: "Product added successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
  }, [resultProduct, toast]);

  useEffect(() => {
    if (selectValue.length >= 3) {
      searchGenerics({ query: { keyword: selectValue } });
    }
  }, [selectValue]);

  return (
    <Stack spacing={"2"}>
      <form onSubmit={handleAddProduct}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
        >
          <Box w={{ base: "100%", md: "50%", xl: "50%" }}>
            <PageCardComponent>
              <FormControl isInvalid={errors.name ? true : false}>
                <FormLabel>Product Name</FormLabel>
                <Input
                  {...register("name", { required: "Enter product name" })}
                  type="string"
                  placeholder="Enter product name"
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
            </PageCardComponent>
          </Box>

          <Box w={{ base: "100%", md: "25%", xl: "25%" }} alignItems="center">
            <PageCardComponent>
              <FormControl>
                <FormLabel>Product Category</FormLabel>
                <Checkbox onChange={(e) => setIsEdcl((value) => !value)}>
                  EDCL
                </Checkbox>
              </FormControl>
            </PageCardComponent>
          </Box>

          <Box w={{ base: "100%", md: "25%", xl: "25%" }}>
            <PageCardComponent>
              <Controller
                control={control}
                name="unit"
                rules={{ required: "Select unit" }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error }
                }) => (
                  <FormControl isInvalid={!!error} id="unit">
                    <FormLabel>Unit</FormLabel>
                    <Select<UnitGroup, true, GroupBase<UnitGroup>>
                      name={name}
                      ref={ref}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      options={unitOptions}
                      placeholder="Select unit"
                      closeMenuOnSelect={true}
                    />
                    <FormErrorMessage>
                      {error && error.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </PageCardComponent>
          </Box>
        </Stack>

        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
          my="2"
        >
          <PageCardComponent>
            <Controller
              control={control}
              name="trackingType"
              rules={{ required: "Select Product Tracking Type" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => (
                <FormControl isInvalid={!!error} id="trackingType">
                  <FormLabel>Product Type</FormLabel>
                  <Select<TrackingTypeGroup, true, GroupBase<TrackingTypeGroup>>
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    options={trackingTypeOptions}
                    placeholder="Select tracking type"
                    closeMenuOnSelect={true}
                  />
                  <FormErrorMessage>{error && error.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </PageCardComponent>
          <PageCardComponent>
            <Controller
              control={control}
              name="ownerDepartment"
              rules={{ required: "Select department" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => (
                <FormControl isInvalid={!!error} id="ownerDepartment">
                  <FormLabel>Department</FormLabel>
                  <Select<DepartmentGroup, true, GroupBase<DepartmentGroup>>
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    options={departmentOptions}
                    placeholder="Select department"
                    closeMenuOnSelect={true}
                  />
                  <FormErrorMessage>{error && error.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </PageCardComponent>

          <PageCardComponent>
            <FormControl id="genericName">
              <FormLabel>Generic Name (optional)</FormLabel>
              <CreatableSelect
                onInputChange={(input) => {
                  setSelectValue(input);
                }}
                options={searchGenericsResult.data?.genericNames.map(
                  (item) => ({
                    label: item.genericName,
                    value: item.id
                  })
                )}
                value={selectedGeneric}
                onChange={(event) => {
                  if (event && !event.hasOwnProperty("__isNew__")) {
                    setSelectedGeneric({
                      value: event.value,
                      label: event.label
                    });
                  } else if (event) {
                    setSelectedGeneric({
                      value: "",
                      label: ""
                    });
                  }
                }}
                placeholder="Generic Name"
              />
            </FormControl>
          </PageCardComponent>

          <PageCardComponent>
            <FormControl isInvalid={errors.lowStockAmount ? true : false}>
              <FormLabel>Low Stock Amount</FormLabel>
              <Input
                type="number"
                value={lowStockAmount}
                onChange={(e) => setLowStockAmount(Number(e.target.value))}
                placeholder="Enter low stock amount"
              />
              <FormErrorMessage>
                {errors.lowStockAmount?.message}
              </FormErrorMessage>
            </FormControl>
          </PageCardComponent>
        </Stack>
        <Stack width="100%">
          <PageCardComponent>
            <FormControl mb="3" isInvalid={errors.notes ? true : false}>
              <FormLabel>Add Notes</FormLabel>
              <Textarea
                placeholder="Notes for this Product"
                {...register("notes")}
              />
              <FormErrorMessage>{errors.notes?.message}</FormErrorMessage>
            </FormControl>
          </PageCardComponent>

          <Button type="submit" colorScheme="blue">
            Add Product
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};
export default AddProductComponent;
