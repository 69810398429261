import React from "react";
import InitialInventoryComponent from "../components/initial-inventory.component";
import CmchInitialInventoryComponent from '../components/cmch/cmch-inital-inventory.component';
import { env } from '../../../config/index';
import { allUIType } from "../../../api/type";

interface InitialInventoryPageProps { }

export const InitialInventoryPage: React.FC<InitialInventoryPageProps> = (
  props: InitialInventoryPageProps
) => {
  return (
    <>
      {
        env.uiType === allUIType.CMCH ? <CmchInitialInventoryComponent /> : <InitialInventoryComponent />
      }


    </>
  );
};
