import {
  LocationTypeGroup,
  DepartmentGroup,
  ParentLocationGroup
} from "./../types/location.type";

export const locationTypeOptions: LocationTypeGroup[] = [
  {
    label: "Store",
    value: "STORE"
  },
  {
    label: "SubStore",
    value: "SUBSTORE"
  },
  {
    label: "Ward",
    value: "WARD"
  },
  {
    label: "Counter",
    value: "COUNTER"
  }
];

export const departmentOptions: DepartmentGroup[] = [
  {
    label: "Medicine",
    value: "MEDICINE"
  },
  {
    label: "Chemical",
    value: "CHEMICAL"
  },
  {
    label: "General",
    value: "GENERAL"
  },
  {
    label: "ICT",
    value: "ICT"
  }
];

export const parentLocationOptions: ParentLocationGroup[] = [
  {
    label: "Medicine",
    value: "MEDICINE"
  },
  {
    label: "Chemical",
    value: "CHEMICAL"
  },
  {
    label: "General",
    value: "GENERAL"
  },
  {
    label: "ICT",
    value: "ICT"
  }
];
