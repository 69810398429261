import React, { forwardRef, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Spacer,
  Stack,
  Stat,
  StatLabel,
  StatNumber
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { Select } from "chakra-react-select";
import { OriginLocationGroup } from "../types/stock-operation.type";
import {
  canSelectLocation,
  chakraStyles,
  userCurrentRole
} from "../../../utils/common-functions";
import {
  useGetAssignUserLocationsQuery,
  useGetDistributedForPrescriptionMutation
} from "../../../api";
import { RetrieveQueryParams } from "../../../service/operation/retrieveQueryParams.service";
import { DistributionReportForPrescriptionTableComponent } from "./distribution-report-for-prescription-table.component";
import { AllDistributionTableComponent } from "./all-distribution-table.component";
import { LoaderComponent } from "../../core/components/loader.component";
import { PageCardComponent } from "../../core/components/page-card.component";

interface DistributionReportForPrescriptionComponentProps {}
type AnnualReportFormValues = {
  originLocation: OriginLocationGroup;
};

export const DistributionReportForPrescriptionComponent: React.FC<
  DistributionReportForPrescriptionComponentProps
> = (props: DistributionReportForPrescriptionComponentProps) => {
  const { control } = useForm<AnnualReportFormValues>({});
  const role = userCurrentRole();
  let query = RetrieveQueryParams();
  let locationId = query.get("locationId")
    ? query.get("locationId")
    : localStorage.getItem("location");

  let initialState = {
    label: "",
    value: Number(locationId)
  };

  const [originLocation, setOriginLocation] =
    useState<OriginLocationGroup>(initialState);

  const handleGetLocation = (data: any) => {
    setOriginLocation(data);
  };

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);

  const getLocationQuery = useGetAssignUserLocationsQuery({});
  const [
    getDistributionForPrescriptionCall,
    getDistributionForPrescriptionResult
  ] = useGetDistributedForPrescriptionMutation();

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(locationId))!
      );
    }
  }, [locationId, originLocationOptions]);

  const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
    <Input
      w={60}
      autoComplete="off"
      background="white"
      value={value}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
    />
  );
  customDateInput.displayName = "DateInput";

  const CustomInput = forwardRef(customDateInput);

  const [startDate, setStartDate] = useState(new Date());

  function onChangeStartDateHandler(value: any) {
    setStartDate(value);
  }

  const [endDate, setEndDate] = useState(new Date());

  function onChangeEndDateHandler(value: any) {
    setEndDate(value);
  }

  useEffect(() => {
    getDistributionForPrescriptionCall({
      locationId: originLocation.value,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    });
  }, [originLocation, startDate, endDate]);

  return (
    <Stack>
      {" "}
      <Stack
        direction={{ base: "column", xl: "row" }}
        justify="space-between"
        width="100%"
        bgColor="white"
        borderRadius="md"
        p={4}
        spacing={4}
      >
        <Box w={{ base: "100%", md: "100%", xl: "50%" }}>
          <Controller
            control={control}
            name="originLocation"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { error }
            }) => (
              <FormControl mb="3" isInvalid={!!error} id="originLocation">
                <FormLabel>Select Location</FormLabel>
                <Select
                  chakraStyles={chakraStyles}
                  name={name}
                  ref={ref}
                  onChange={(location) => handleGetLocation(location)}
                  onBlur={onBlur}
                  isDisabled={!canSelectLocation(role)}
                  value={originLocation}
                  options={originLocationOptions}
                  placeholder="Select location"
                  closeMenuOnSelect={true}
                />
                <FormErrorMessage></FormErrorMessage>
              </FormControl>
            )}
          />
        </Box>
        <Spacer />
        <Box w={{ base: "100%", md: "100%", xl: "20%" }}>
          <FormControl>
            <FormLabel>From</FormLabel>
            <InputGroup className="dark-theme">
              <DatePicker
                id="dateStartEnd"
                selected={startDate}
                onChange={onChangeStartDateHandler}
                dateFormat="dd MMM yyyy"
                className="react-datapicker__input-text"
                placeholderText="Select Month"
                customInput={<CustomInput />}
                showDisabledMonthNavigation
              />
            </InputGroup>
          </FormControl>
        </Box>
        <Spacer />
        <Box w={{ base: "100%", md: "100%", xl: "20%" }}>
          <FormControl>
            <FormLabel>To</FormLabel>
            <InputGroup className="dark-theme">
              <DatePicker
                id="dateStartEnd"
                selected={endDate}
                onChange={onChangeEndDateHandler}
                dateFormat="dd MMM yyyy"
                className="react-datapicker__input-text"
                placeholderText="Select Month"
                customInput={<CustomInput />}
                showDisabledMonthNavigation
              />
            </InputGroup>
          </FormControl>
        </Box>
      </Stack>
      {getDistributionForPrescriptionResult.isLoading ? (
        <LoaderComponent />
      ) : (
        getDistributionForPrescriptionResult.data &&
        getDistributionForPrescriptionResult.data && (
          <>
            <Stack>
              <Stack
                direction={{ base: "column", xl: "row" }}
                justify="space-between"
                my="2"
              >
                <PageCardComponent>
                  <Stat>
                    <StatLabel>Report Location</StatLabel>
                    <StatNumber fontSize="lg" fontWeight={600}>
                      {
                        getDistributionForPrescriptionResult.data.originLocation
                          .name
                      }
                    </StatNumber>
                    <StatLabel>Total Operation</StatLabel>
                    <StatNumber fontSize="lg" fontWeight={600}>
                      {" "}
                      {
                        getDistributionForPrescriptionResult.data
                          .totalOperationCount
                      }
                    </StatNumber>
                  </Stat>
                </PageCardComponent>

                <PageCardComponent>
                  <Stat>
                    <StatLabel>Start Time</StatLabel>
                    <StatNumber fontSize="lg" fontWeight={600}>
                      {dayjs(startDate).format("D MMM, YYYY h:mm A")}
                    </StatNumber>
                    <StatLabel>End Time</StatLabel>
                    <StatNumber fontSize="lg" fontWeight={600}>
                      {dayjs(
                        getDistributionForPrescriptionResult.data.endDate
                      ).format("D MMM, YYYY h:mm A")}
                    </StatNumber>
                  </Stat>
                </PageCardComponent>
              </Stack>
            </Stack>
            <Stack>
              <AllDistributionTableComponent
                overAllDistributedProducts={
                  getDistributionForPrescriptionResult.data
                    .overAllDistributedProducts
                }
              />
              <DistributionReportForPrescriptionTableComponent
                distributedPrescriptions={
                  getDistributionForPrescriptionResult.data
                    .distributedPrescriptions
                }
              />
            </Stack>
          </>
        )
      )}
    </Stack>
  );
};
