import { Flex, Image, Stack, Link, VStack, Text } from "@chakra-ui/react";
import { LoginFormComponent } from "../components/login-form.component";

export const LoginPage = () => {
  return (
    <Stack>
      <Stack minH="75vh" direction={{ base: "column", md: "row" }}>
        <Flex flex={1} p={8} align="center" justify="center">
          <LoginFormComponent />
        </Flex>
      </Stack>
      <Stack>
        <VStack textAlign="center" px="5">
          <Image
            src="/images/mis-logo.png"
            alt="mis logo"
            width={{ base: "60px", md: "80px" }}
            objectFit="cover"
          />
          <Text fontSize={{ base: "xs", md: "md", lg: "md" }}>
            কারিগরি সহায়তায় ও সর্বস্বত্ব সংরক্ষিত ম্যানেজমেন্ট ইনফরমেশন সিস্টেম,
            স্বাস্থ্য অধিদপ্তর
          </Text>
          <Stack direction="row" alignItems="center">
            <Text fontSize={{ base: "xs", md: "md", lg: "md" }}>
              প্রযুক্তি সহযোগী{" "}
            </Text>
            <Link color="blue.400" href="https://bindulogic.com/" isExternal>
              <Image
                src="/images/bindulogic-flat-logo.png"
                alt="bindulogic logo"
                width={{ base: "100px", md: "150px" }}
                objectFit="cover"
              />
            </Link>
          </Stack>
        </VStack>
      </Stack>
    </Stack>
  );
};
