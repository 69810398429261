import autoTable from "jspdf-autotable";
import moment from "moment";
import newPage from "../utils/newPage";
const table3Product = async (
  doc,
  startY,
  fontSize,
  lineSpacing,
  dailyReportProductSummary
) => {
  let startX = 42;
  const pageWidth = doc.internal.pageSize.width;
  const endX = pageWidth - startX;

  doc.setFontSize(fontSize);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);

  //-------Table Header---------------------
  startY += lineSpacing * 1.5;

  const tableColumn = ["serial", "ProductName", "Total In", "Total Out"];

  for (let [index, item] of dailyReportProductSummary.entries()) {
    const ticketData = [
      index + 1,
      item?.name,
      item?.inwardCount,
      item?.outboundCount
    ];
    let theight = 0;

    autoTable(doc, {
      head: [tableColumn],
      body: [ticketData],
      startY: startY,
      theme: "grid",
      tableWidth: "fixed",
      cellWidth: "fixed",
      showHead: "everyPage",
      tableLineColor: 200,
      Padding: 0,
      tableLineWidth: 0,

      margin: { horizontal: startX, vertical: 0 },
      headStyles: {
        fillColor: [223, 227, 230],
        textColor: [87, 91, 94],
        fontSize: 8,
        padding: 0
      },

      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: "fixed" },
        2: { cellWidth: 80 },
        3: { cellWidth: 80 }
      },
      styles: {
        overflow: "linebreak",
        cellWidth: "fixed",
        fontSize: 9,
        // font: "arial",
        // fontSize: 10,
        font: "NotoSansBengali",
        cellPadding: 2,
        overflowColumns: "linebreak"
      },
      didDrawPage: (d) => (theight = d.cursor.y)
    });
    startY = theight + lineSpacing;
    //startY = await newPage(doc, startY, lineSpacing);

    //nseted table*******************************
    const tablecol2X = 75;
    const tablecol3X = 260;
    const tablecol4X = 420;
    const tablecol5X = 460;

    //startY += lineSpacing;

    doc.text("No.", startX, startY);
    doc.text("From", tablecol2X, startY, { align: "left" });
    doc.text("To", tablecol3X, startY);
    doc.text("Quantity", tablecol4X, startY);
    doc.text("by", tablecol5X, startY);
    doc.text("time", endX, startY, { align: "right" });

    startY += lineSpacing;
    for (let [index, it] of item?.operations.entries()) {
      doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
      const splitFrom = doc.splitTextToSize(
        it?.originLocation.name,
        tablecol3X - startX
      );

      if (it.destinationLocation?.name) {
        const splitTO = doc.splitTextToSize(
          it?.destinationLocation?.name,
          tablecol4X - tablecol3X
        );
        const heightDescription = splitTO.length * doc.internal.getLineHeight();
        startY = await newPage(doc, startY, heightDescription);
        doc.text(`${splitTO}`, tablecol3X, startY);
      } else {
        doc.text("N/A", tablecol3X, startY);
      }
      doc.text(`${index + 1}`, startX, startY);
      doc.text(`${splitFrom}`, tablecol2X, startY);

      doc.text(`${it?.quantity}`, tablecol4X, startY);
      doc.text(`${it?.createdBy.name.substring(0, 10)}`, tablecol5X, startY);

      doc.text(`${moment(it?.createdAt).format("DD-LT")}`, endX, startY, {
        align: "right"
      });

      startY += lineSpacing;
    }

    startY += lineSpacing;
  }

  return startY;
};

export default table3Product;
