import React from "react";
import { useEffect } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  useToast
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForgetPasswordMutation } from "../../../api";
import { DecorationImageComponent } from "./decoration-image.component";

interface ForgotPasswordComponentProps {}
type ForgetPasswordFormValues = {
  email: string;
};

const ForgetPasswordFormSchema = z.object({
  email: z.string().email()
});

export const ForgotPasswordComponent: React.FC<ForgotPasswordComponentProps> = (
  props: ForgotPasswordComponentProps
) => {
  const toast = useToast();
  const navigate = useNavigate();

  const [forgetPasswordRequest, forgetPasswordResult] =
    useForgetPasswordMutation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ForgetPasswordFormValues>({
    resolver: zodResolver(ForgetPasswordFormSchema)
  });

  const handleForgotPassword = handleSubmit((data) => {
    forgetPasswordRequest({
      data: {
        email: data.email
      }
    });
  });

  useEffect(() => {
    if (forgetPasswordResult.isSuccess) {
      toast({
        title: "Success",
        description:
          "Reset password email was sent. Check your email inbox to reset your password",
        status: "success",
        position: "top",
        duration: 6000,
        isClosable: true
      });
      navigate("/login");
    }
  }, [forgetPasswordResult, toast]);

  return (
    <form onSubmit={handleForgotPassword}>
      <Box boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px" borderRadius="lg" p="12">
        <Center>
          <Stack spacing={4} w={"full"} maxW={"lg"}>
            <DecorationImageComponent />
            <Heading fontSize={"2xl"} textAlign="center">
              Forgot Password
            </Heading>
            <FormControl id="email" isInvalid={errors.email ? true : false}>
              <FormLabel>Email</FormLabel>
              <Input
                {...register("email")}
                type="email"
                placeholder="johndoe@gmail.com"
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
            <Button colorScheme={"blue"} variant={"solid"} type="submit">
              Send Recover Email
            </Button>
          </Stack>
        </Center>
      </Box>
    </form>
  );
};
