import React, { useEffect, useState, useRef } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ButtonGroup,
  useToast,
  Heading,
  useDisclosure,
  Flex,
  Box,
  IconButton
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { PageCardComponent } from "../../../core/components/page-card.component";
import { userCurrentRole } from "../../../../utils/common-functions";
import {
  fieldNameType,
  ENTER_KEY,
  DemandFulFillStockOperationItems
} from "../../types/stock-operation.type";
import {
  useGetDemandFulfillOperationDetailsQuery,
  useTransferOperationMutation
} from "../../../../api";
import { ConfirmAlertComponent } from "./../confirm-alert.component";
import { IoMdArrowRoundBack } from "react-icons/io";
import { ProductTrackingTypeOptions } from "../../../../api/type";
import { FaPlus } from "react-icons/fa";
import { OperationService } from "../../../../service/operation/operation.service";
import { FaTimes } from "react-icons/fa";

type DemandFulfilFormValues = {
  stockOperationItems: DemandFulFillStockOperationItems[];
};

interface DemandFulfilComponentProps {}

export const CmchDemandFulfilComponent: React.FC<DemandFulfilComponentProps> = (
  props: DemandFulfilComponentProps
) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const role = userCurrentRole();
  const demandFulfillConfirmAlert = useDisclosure();
  const cancelRef = useRef();
  const toast = useToast();

  const { data } = useGetDemandFulfillOperationDetailsQuery({
    data: { report: { operationId: Number(id) } }
  });

  const [updateStockOperationItem, setUpdateStockOperationItem] = useState<
    DemandFulFillStockOperationItems[]
  >([]);

  const [fulfilDemandCreate, fulfilDemandResult] =
    useTransferOperationMutation();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setFocus,
    getValues,
    formState: { errors }
  } = useForm<DemandFulfilFormValues>({
    defaultValues: {
      stockOperationItems: updateStockOperationItem
    }
  });

  const { fields, append, remove, insert } = useFieldArray<
    DemandFulfilFormValues,
    "stockOperationItems"
  >({
    control,
    name: "stockOperationItems"
  });

  useEffect(() => {
    if (data?.data.operationDetails.stockOperationItems.length) {
      let allItems = data?.data.operationDetails.stockOperationItems.map(
        (item) => {
          return {
            product: {
              label: item.product.name,
              value: item.product.id,
              unit: item.product.unit,
              type: item.product.trackingType,
              available: item.availableQuantity
            },
            demandAmount: item.quantity
          };
        }
      );
      setUpdateStockOperationItem(allItems);
    }
  }, [data]);

  useEffect(() => {
    setValue("stockOperationItems", updateStockOperationItem);
  }, [updateStockOperationItem, setValue]);

  const watch = useWatch({
    control,
    name: "stockOperationItems"
  });

  const handleFulfilDemandSubmit = handleSubmit((formData) => {
    const allStockOperationItems = formData.stockOperationItems.map(
      (singleItem) => {
        return {
          productId: singleItem.product.value,
          quantity: OperationService.getQuantity(
            singleItem.product.type as string,
            Number(singleItem.quantity)
          ),
          trackingNumber: singleItem.trackingNumber
        };
      }
    );
    fulfilDemandCreate({
      data: {
        stockOperation: {
          originLocationId: Number(
            data?.data.operationDetails.destinationLocation.id
          ),
          destinationLocationId: Number(
            data?.data.operationDetails.originLocation.id
          ),
          demandStockOperationId: data?.data.operationDetails.id,
          stockOperationItems: allStockOperationItems
        }
      }
    });
  });

  useEffect(() => {
    if (fulfilDemandResult.isSuccess) {
      toast({
        title: "Success",
        description: "Fulfil demand operation successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      navigate(`/${role}/stock-operation/pending-operation`);
    }
  }, [fulfilDemandResult, toast, navigate, role]);

  const addItem = (index: number) => {
    let newProduct = {
      product: watch[index].product,
      quantity: watch[index].quantity,
      demandAmount: watch[index].demandAmount,
      trackingNumber: ""
    };
    insert(index + 1, newProduct);
  };
  const [componetMount, setComponetMount] = useState(false);
  useEffect(() => {
    setComponetMount(true);
  }, []);
  useEffect(() => {
    if (componetMount) {
      setTimeout(() => {
        if (
          data?.data.operationDetails.stockOperationItems[0].product
            .trackingType !== ProductTrackingTypeOptions.NONE
        ) {
          setFocus(`stockOperationItems.0.trackingNumber`);
        } else {
          setFocus(`stockOperationItems.0.quantity`);
        }
      }, 1000);
    }
  }, [componetMount, data]);
  const handleKeyDown = (event: any, index: number, fildName: string) => {
    if (event.keyCode !== ENTER_KEY) return;
    const lastIndex = fields.length - 1;
    let nextIndex = index + 1;
    if (nextIndex > lastIndex) {
      nextIndex = 0;
    }

    let currentProductType = watch[index].product.type;
    if (
      currentProductType === ProductTrackingTypeOptions.NONE &&
      fildName === "quantity"
    ) {
      focusElement(nextIndex);
    } else if (
      currentProductType === ProductTrackingTypeOptions.SERIALIZED &&
      fildName === "trackingNumber"
    ) {
      focusElement(nextIndex);
    } else if (
      currentProductType === ProductTrackingTypeOptions.BATCH &&
      fildName === "trackingNumber"
    ) {
      focusElement(index, "quantity");
    } else if (
      currentProductType === ProductTrackingTypeOptions.BATCH &&
      fildName === "quantity"
    ) {
      focusElement(nextIndex);
    }
  };
  function focusElement(index: number, fieldName?: fieldNameType) {
    if (fieldName) {
      return setFocus(`stockOperationItems.${index}.${fieldName}`);
    }
    let productType = watch[index].product.type;
    if (productType !== ProductTrackingTypeOptions.NONE) {
      return setFocus(`stockOperationItems.${index}.trackingNumber`);
    }

    setFocus(`stockOperationItems.${index}.quantity`);
  }
  const getQuantity = (productType: any) => {
    if (productType === ProductTrackingTypeOptions.SERIALIZED) {
      return 1;
    }
    return "";
  };

  return (
    <>
      <Stack>
        <Flex justifyContent="end">
          <Button
            colorScheme="blue"
            leftIcon={<IoMdArrowRoundBack />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Flex>
        <form onSubmit={handleFulfilDemandSubmit}>
          <Stack
            direction={{ base: "column", xl: "row" }}
            justify="space-between"
          >
            <PageCardComponent>
              <Heading as="h5" size="sm">
                Demand By Location
              </Heading>
              <Input
                _disabled={{ color: "blackAlpha.900", cursor: "not-allowed" }}
                defaultValue={data?.data.operationDetails.originLocation.name}
                isDisabled={true}
              />
            </PageCardComponent>
          </Stack>

          {fields.map((field, index) => {
            return (
              <Stack
                direction={{ base: "column", xl: "row" }}
                width="100%"
                bgColor={"white"}
                borderRadius="md"
                spacing={4}
                px="4"
                py="2"
                key={field.id}
                mt="2"
              >
                <Box w={{ base: "100%", md: "100%", xl: "2%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        ID
                      </FormLabel>
                    )}

                    {index + 1}
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "40%" }}>
                  <FormControl mb="3">
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Product Name
                      </FormLabel>
                    )}
                    <Input
                      _disabled={{
                        color: "blackAlpha.900",
                        cursor: "not-allowed"
                      }}
                      type={"text"}
                      value={watch[index]?.product?.label}
                      disabled={true}
                      key={index}
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                    />
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "8%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Unit
                      </FormLabel>
                    )}
                    <Input
                      _disabled={{
                        color: "blackAlpha.900",
                        cursor: "not-allowed"
                      }}
                      defaultValue={watch[index]?.product?.unit}
                      disabled={true}
                      placeholder="Product unit"
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                    />
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "8%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Available
                      </FormLabel>
                    )}
                    <Input
                      _disabled={{
                        color: "blackAlpha.900",
                        cursor: "not-allowed"
                      }}
                      value={watch[index]?.product.available}
                      disabled={true}
                      placeholder="Available"
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                    />
                  </FormControl>
                </Box>

                <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                  <FormControl mb="3">
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Demand Quantity
                      </FormLabel>
                    )}
                    <Input
                      _disabled={{
                        color: "blackAlpha.900",
                        cursor: "not-allowed"
                      }}
                      type={"number"}
                      defaultValue={watch[index]?.demandAmount}
                      disabled={true}
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                    />
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                  <FormControl
                    isDisabled={
                      watch[index]?.product?.type !==
                        ProductTrackingTypeOptions.SERIALIZED &&
                      watch[index]?.product?.type !==
                        ProductTrackingTypeOptions.BATCH
                    }
                    isInvalid={
                      errors?.stockOperationItems?.[index]?.trackingNumber
                        ? true
                        : false
                    }
                  >
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Tracking Number
                      </FormLabel>
                    )}
                    <Input
                      {...register(
                        `stockOperationItems.${index}.trackingNumber` as const,
                        {
                          validate: {
                            required: (value) => {
                              if (
                                watch[index]?.product?.type ===
                                  ProductTrackingTypeOptions.NONE ||
                                value !== ""
                              )
                                return true;
                              return "Give Tracking data";
                            }
                          }
                        }
                      )}
                      key={`tracking${index}`}
                      type={"string"}
                      placeholder="Tracking Number"
                      onKeyDown={(e) =>
                        handleKeyDown(e, index, "trackingNumber")
                      }
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                    />
                    <FormErrorMessage>
                      {
                        errors?.stockOperationItems?.[index]?.trackingNumber
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Estimated Balance
                      </FormLabel>
                    )}
                    <Input
                      _disabled={{
                        color: "blackAlpha.900",
                        cursor: "not-allowed"
                      }}
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                      value={
                        watch[index]?.product &&
                        watch[index]?.product.type &&
                        watch[index]?.product.type ===
                          ProductTrackingTypeOptions.SERIALIZED
                          ? Number(watch[index]?.product?.available) - 1
                          : Number(watch[index]?.product?.available) -
                            Number(watch[index]?.quantity)
                      }
                      disabled={true}
                      placeholder="Available"
                    />
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                  <FormControl
                    mb="3"
                    isInvalid={
                      errors?.stockOperationItems?.[index]?.quantity
                        ? true
                        : false
                    }
                  >
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Supply Quantity
                      </FormLabel>
                    )}
                    <Input
                      {...register(
                        `stockOperationItems.${index}.quantity` as const,
                        {
                          validate: {
                            required: (value) => {
                              if (
                                watch[index]?.product?.type ===
                                ProductTrackingTypeOptions.SERIALIZED
                              )
                                return true;
                              if (
                                value &&
                                (value > watch[index].demandAmount ||
                                  value >
                                    Number(watch[index].product.available))
                              )
                                return "Give valid amount";
                              if (value && Number(value) >= 0) return true;
                              return "Give Amount";
                            }
                          }
                        }
                      )}
                      key={`quantity${index}`}
                      disabled={
                        watch[index]?.product?.type !==
                          ProductTrackingTypeOptions.NONE &&
                        watch[index]?.product?.type !==
                          ProductTrackingTypeOptions.BATCH
                      }
                      defaultValue={getQuantity(watch[index]?.product?.type)}
                      type="number"
                      placeholder="Enter product quantity"
                      onKeyDown={(e) => handleKeyDown(e, index, "quantity")}
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                    />
                    <FormErrorMessage>
                      {errors?.stockOperationItems?.[index]?.quantity?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <ButtonGroup>
                  <IconButton
                    mt={index === 0 ? 7 : 0}
                    colorScheme="red"
                    variant="outline"
                    icon={<FaTimes />}
                    aria-label="Add to friends"
                    onClick={() => remove(index)}
                  ></IconButton>
                  <IconButton
                    mt={index === 0 ? 7 : 0}
                    colorScheme="blue"
                    variant="outline"
                    icon={<FaPlus />}
                    aria-label="Add to friends"
                    onClick={() => addItem(index)}
                    isDisabled={
                      watch[index]?.product?.type ===
                      ProductTrackingTypeOptions.NONE
                        ? true
                        : false
                    }
                  ></IconButton>
                </ButtonGroup>
              </Stack>
            );
          })}
          <Button
            width="100%"
            colorScheme="blue"
            onClick={demandFulfillConfirmAlert.onOpen}
          >
            Submit
          </Button>
          <ConfirmAlertComponent
            cancelRef={cancelRef}
            onClose={demandFulfillConfirmAlert.onClose}
            isOpen={demandFulfillConfirmAlert.isOpen}
            onOpen={demandFulfillConfirmAlert.onOpen}
            handleApprove={handleFulfilDemandSubmit}
            status={fulfilDemandResult}
          />
        </form>
      </Stack>
    </>
  );
};
