import React, { useEffect, useState } from "react";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  useReactTable
} from "@tanstack/react-table";
import {
  Box,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { FaEdit, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { customPagination } from "../../../config";
import { Location } from "../../../api/type";
import { useGetAllLocationsQuery } from "../../../api";
import { PaginationBottomComponent } from "../../core/components/pagination-bottom.component";
import { userCurrentRole } from "../../../utils/common-functions";
import { LoaderComponent } from "../../core/components/loader.component";

const headers = [
  { label: "Location Id", key: "id" },
  { label: "Location Name", key: "name" },
  { label: "Disable", key: "isDisabled" }
];

interface AllLocationsTableProps { }

export const AllLocationsComponent: React.FC<AllLocationsTableProps> = (
  props: AllLocationsTableProps
) => {
  const navigate = useNavigate();
  const role = userCurrentRole();
  const addLocationPage = () => {
    navigate(`/${role}/location/create`);
  };

  const handleRowClick = (id: number) => {
    navigate(`/${role}/location/edit/${id}`);
  };

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: customPagination.pageIndex,
      pageSize: customPagination.pageSize
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );

  const [searchText, setSearchText] = useState<string>("");
  const [pageData, setPageData] = useState<Location[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);

  const getAllLocationsResult = useGetAllLocationsQuery({
    query: {
      search: searchText.length >= 2 ? searchText : "",
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize
    }
  });

  useEffect(() => {
    if (getAllLocationsResult.data) {
      setPageData(getAllLocationsResult.data.data.locations);
      setItemCount(getAllLocationsResult.data.data.locationCount);
    }
  }, [getAllLocationsResult]);

  const columnHelper = createColumnHelper<Location>();

  const columns: ColumnDef<Location, any>[] = [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      header: () => "ID",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => "Name",
      cell: (row) => row.getValue()
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: ({ row, table }) => {
        return (
          <>
            <Button
              leftIcon={<FaEdit />}
              size={"sm"}
              color="messenger.400"
              colorScheme={"messenger"}
              variant={"outline"}
              onClick={() => handleRowClick(row.original.id)}
            >
              Edit
            </Button>
          </>
        );
      }
    })
  ];

  const table = useReactTable({
    data: pageData,
    // @ts-ignore
    columns: columns,
    pageCount: Math.ceil(itemCount / pagination.pageSize),
    state: {
      pagination
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    debugTable: true
  });

  return (
    <>
      {getAllLocationsResult.isLoading ? (
        <LoaderComponent />
      ) : (
        <Stack>
          <Stack
            direction={{ base: "column", xl: "row" }}
            minWidth="max-content"
            alignItems="center"
            mb="1"
          >
            <Box>
              <InputGroup w="96" display={{ base: "flex", lg: "flex" }}>
                <InputLeftElement color="gray.500" children={<FaSearch />} />
                <Input
                  placeholder="Enter 3 or 4 letters to find"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </InputGroup>
            </Box>
            <Spacer />
            <ButtonGroup gap="2">
              <Button onClick={() => addLocationPage()} colorScheme="blue">
                Add Location
              </Button>
              <CSVLink data={pageData} headers={headers}>
                <Button colorScheme="blue">Export CSV</Button>
              </CSVLink>
            </ButtonGroup>
          </Stack>

          <Table variant="striped">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        bgColor="gray.700"
                        color="white"
                      >
                        {header.isPlaceholder ? null : (
                          <Box>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Box>
                        )}
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          <PaginationBottomComponent
            table={table}
            count={itemCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        </Stack>
      )}
    </>
  );
};
