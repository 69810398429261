import React from "react";
import { LocationInventory } from "../../../api/type";
import {
  StyleSheet,
  Document,
  Font,
  Page,
  View,
  Image,
  Text
} from "@react-pdf/renderer";
import { env } from "../../../config/index";
import { OriginLocationGroup } from "../../stock-operation/types/stock-operation.type";
import dayjs from "dayjs";
import { userCurrentRole } from "../../../utils/common-functions";

interface InventoryByDatePrintComponentProps {
  data: LocationInventory[];
  location: OriginLocationGroup;
  reportDate: string | undefined;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  page: {
    padding: "0.75in",
    position: "relative",
    fontFamily: "Noto Serif Bengali"
  },
  header: {
    width: "100%",
    marginTop: 3,
    marginBottom: 10
  },
  headerContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    fontSize: 11
  },
  headerContentDetails: {
    width: "50%",
    border: "1px solid #dfdfdf",
    paddingHorizontal: 5,
    paddingVertical: 1
  },
  organizationLogo: {
    height: "auto",
    width: "50px",
    marginHorizontal: 240
  },
  organizationName: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center"
  },
  table: {
    width: "100%",
    borderLeft: "1px solid #dfdfdf",
    borderRight: "1px solid #dfdfdf"
  },
  tableHeader: {
    fontSize: 11,
    fontWeight: "semibold",
    borderTop: "1px solid #dfdfdf",
    backgroundColor: "#EBECF0"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 11,
    borderBottom: "1px solid #dfdfdf"
  },
  row1: {
    width: "10%",
    borderRight: "1px solid #dfdfdf"
  },
  row2: {
    width: "60%",
    textAlign: "left",
    paddingHorizontal: 5,
    borderRight: "1px solid #dfdfdf"
  },
  row3: {
    width: "15%",
    borderRight: "1px solid #dfdfdf"
  },
  row4: {
    width: "15%"
  },
  signature: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 11,
    fontWeight: "semibold",
    marginTop: 50
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    fontSize: 6,
    color: "#666666",
    bottom: "0.50in",
    left: "0.75in",
    right: "0.75in",
    borderTop: "1px solid #000"
  },
  backgroundImage: {
    position: "absolute",
    top: "45%",
    width: "200px",
    opacity: 0.05,
    objectFit: "contain",
    alignSelf: "center"
  }
});

export const InventoryByDatePrintComponent: React.FC<
  InventoryByDatePrintComponentProps
> = (props: InventoryByDatePrintComponentProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.backgroundImage} src={env.reportLogo} fixed />
        <View>
          <Image style={styles.organizationLogo} src={env.organizationLogo} />
          <Text style={styles.organizationName}>
            {env.organizationFullName}
          </Text>
          <View style={styles.header}>
            <View style={styles.headerContent}>
              <Text style={styles.headerContentDetails}>
                Report Type : Location Inventory By Date
              </Text>
              <Text style={styles.headerContentDetails}>
                Location Name : {props.location.label}
              </Text>
            </View>
            <View style={styles.headerContent}>
              <Text style={styles.headerContentDetails}>
                Report Date :{"  "}
                {dayjs(props.reportDate).format("DD MMM, YYYY")}
              </Text>
              <Text style={styles.headerContentDetails}>
                Printout Date :{"  "}
                {dayjs().format("DD MMMM YYYY, hh:mm A")}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={[styles.tableHeader, styles.row]} fixed>
            <Text style={styles.row1}>Serial</Text>
            <Text style={styles.row2}>Product Name</Text>
            <Text style={styles.row3}>Quantity</Text>
            <Text style={styles.row4}>Unit</Text>
          </View>
          {props.data.map((row, i) => (
            <View style={styles.row} key={i} wrap={false}>
              <Text style={styles.row1}>{i + 1} </Text>
              <Text style={styles.row2}>{row.productName + " "}</Text>
              <Text style={styles.row3}>{row.quantity}</Text>
              <Text style={styles.row4}>{row.unit}</Text>
            </View>
          ))}
        </View>
        <View style={styles.signature}>
          <View>
            <Text>Verify By,</Text>
          </View>
          <View>
            <Text>Authorized By,</Text>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View>
            <Text>Powered By Bindulogic Limited</Text>
          </View>
          <View>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
          <View>
            <Text>
              Printed By: {userCurrentRole() || "Unknown"},{" "}
              {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
