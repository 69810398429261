import React from "react";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  Font,
  View,
  Image
} from "@react-pdf/renderer";
import { GetVendorDemandDataResponse } from "../../../api/type";
import dayjs from "dayjs";
import { env } from "../../../config";
import { userCurrentRole } from "../../../utils/common-functions";

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  body: {
    padding: "0.50in",
    fontFamily: "Noto Serif Bengali"
  },
  application: {
    fontSize: 10,
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  applicationFormat: {
    width: "100%"
  },
  applicationDetails: {
    marginTop: 10,
    width: "100%"
  },
  table: {
    width: "100%",
    borderLeft: "1px solid #dfdfdf",
    borderRight: "1px solid #dfdfdf"
  },
  tableHeader: {
    borderTop: "1px solid #dfdfdf"
  },
  bold: {
    fontWeight: "bold"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 10,
    borderBottom: "1px solid #dfdfdf"
  },
  serial: {
    width: "5%",
    borderRight: "1px solid #dfdfdf"
  },
  tenderSerialNo: {
    width: "8%",
    borderRight: "1px solid #dfdfdf"
  },
  productName: {
    width: "32%",
    textAlign: "left",
    paddingHorizontal: 5,
    borderRight: "1px solid #dfdfdf"
  },
  fiscalYear: {
    width: "16%",
    borderRight: "1px solid #dfdfdf"
  },
  appFiscalYear: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #dfdfdf"
  },
  appTotal: {
    width: "50%",
    borderRight: "1px solid #dfdfdf"
  },
  appPurchased: {
    width: "50%"
  },
  lastSupply: {
    width: "20%",
    alignSelf: "flex-end"
  },
  lastSupplyDeatils: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #dfdfdf"
  },
  supplyDate: {
    width: "60%",
    borderRight: "1px solid #dfdfdf"
  },
  supplyAmount: {
    width: "40%"
  },
  currentStock: {
    width: "8%",
    borderRight: "1px solid #dfdfdf",
    borderLeft: "1px solid #dfdfdf"
  },
  monthlyDemand: {
    width: "8%",
    borderRight: "1px solid #dfdfdf"
  },
  currentDemand: {
    width: "8%"
  },
  appTotalAmount: {
    width: "8%",
    borderRight: "1px solid #dfdfdf"
  },
  appPurchasedAmount: {
    width: "8%",
    borderRight: "1px solid #dfdfdf"
  },
  lastSupplyDate: {
    width: "12%",
    borderRight: "1px solid #dfdfdf"
  },
  lastSupplyAmount: {
    width: "8%",
    borderRight: "1px solid #dfdfdf"
  },
  currentInventory: {
    width: "8%",
    borderRight: "1px solid #dfdfdf"
  },
  signature: {
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    marginTop: 80
  },
  footerLeft: {
    width: "50%",
    position: "absolute",
    left: 0
  },
  footerRight: {
    position: "absolute",
    textAlign: "right",
    right: 0,
    width: "50%"
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    fontSize: 6,
    color: "#666666",
    bottom: "0.50in",
    left: "0.50in",
    right: "0.50in",
    borderTop: "1px solid #000"
  },
  backgroundImage: {
    position: "absolute",
    top: "45%",
    width: "200px",
    opacity: 0.05,
    objectFit: "contain",
    alignSelf: "center"
  }
});

interface VendorPrintComponentProps {
  printData: GetVendorDemandDataResponse;
  organizationNameBangla: string;
}

export const VendorPrintComponent: React.FC<VendorPrintComponentProps> = (
  props: VendorPrintComponentProps
) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <Image style={styles.backgroundImage} src={env.reportLogo} fixed />
        <View style={styles.application}>
          <View style={styles.applicationFormat}>
            <Text>বরাবর </Text>
            <Text>পরিচালক, </Text>
            <Text>{props.organizationNameBangla}</Text>
            <Text>মাধ্যমঃ যথাযথ কর্তৃপক্ষ</Text>
            <Text>
              বিষয়ঃ এম.এস.আর / ও.সি.সি / ক্লিনিং /.......... দ্রব্যাদি চাহিদা
              প্রসঙ্গে ।
            </Text>
          </View>
          <View style={styles.applicationDetails}>
            <Text>জনাব, </Text>
            <Text>
              আপনার সদয় অবগতি ও প্রয়োজনীয় ব্যবস্থা গ্রহণের জন্য জানাইতেছি যে,
              নিম্নলিখিত এম.এস.আর/ও.সি.সি/ক্লিনিং/.......... দ্রব্যাদি .......
              মাসের জন্য হাসপাতালের রোগীদের চিকিৎসার স্বার্থে জরুরী ভিত্তিতে
              সংগ্রহ করা প্রয়োজন । {"।"}
            </Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={[styles.row, styles.tableHeader]} fixed>
            <Text style={styles.serial}>নং</Text>
            <Text style={styles.tenderSerialNo}>দরপত্রের ক্রমিক নং</Text>
            <Text style={styles.productName}> দ্রব্যাদির নাম</Text>
            <View style={styles.fiscalYear}>
              <Text>এ পি পি</Text>
              <Text>অর্থবছর ২০ ... - ২০ ... </Text>
              <View style={styles.appFiscalYear}>
                <Text style={styles.appTotal}>মোট </Text>
                <Text style={styles.appPurchased}>ক্রয়</Text>
              </View>
            </View>
            <View style={styles.lastSupply}>
              <Text>সর্বশেষ সরবারহ</Text>
              <View style={styles.lastSupplyDeatils}>
                <Text style={styles.supplyDate}>তারিখ </Text>
                <Text style={styles.supplyAmount}>পরিমান</Text>
              </View>
            </View>
            <Text style={styles.currentStock}>বর্তমান মজুদ</Text>
            <Text style={styles.monthlyDemand}>মাসিক চাহিদা</Text>
            <Text style={styles.currentDemand}>বর্তমান চাহিদা</Text>
          </View>

          {props.printData.stockOperationItems.map((row, i) => (
            <View key={i} style={styles.row} wrap={false}>
              <Text style={styles.serial}>{i + 1}</Text>
              <Text style={styles.tenderSerialNo}>
                {row.tenderSerialNumber}
              </Text>
              <Text style={styles.productName}>{row.productName + " "}</Text>
              <Text style={styles.appTotalAmount}>{row.appTotalAmount}</Text>
              <Text style={styles.appPurchasedAmount}>
                {row.appPurchasedAmount}
              </Text>
              <Text style={styles.lastSupplyDate}>
                {row.lastSupplyDate === null
                  ? ""
                  : dayjs(row.lastSupplyDate).format("DD MMM, YY")}
              </Text>
              <Text style={styles.lastSupplyAmount}>
                {row.lastSupplyAmount}
              </Text>
              <Text style={styles.currentInventory}>
                {row.currentInventory}
              </Text>
              <Text style={styles.monthlyDemand}>{row.monthlyRequirment}</Text>
              <Text style={styles.currentDemand}>{row.currentRequirment}</Text>
            </View>
          ))}
        </View>

        <View style={styles.signature}>
          <View style={styles.footerLeft}>
            <Text>
              অতএব, যথাযথ ব্যাবস্থা গ্রহণের জন্য পেশ করা হলো ।{"\n"}
              অগ্রবর্তী করা হলো{" "}
            </Text>
          </View>
          <View style={styles.footerRight}>
            <Text>বিনীত নিবেদক,</Text>
          </View>
        </View>

        <View style={styles.footer} fixed>
          <View>
            <Text>Powered By Bindulogic Limited</Text>
          </View>
          <View>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
          <View>
            <Text>
              Printed By: {userCurrentRole() || "Unknown"},{" "}
              {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
