import React from "react";
import { LocationOperationsResponse } from "../../../api/type";
import dayjs from "dayjs";
import {
  StyleSheet,
  Document,
  Font,
  Page,
  View,
  Image,
  Text
} from "@react-pdf/renderer";
import { env } from "../../../config";
import { GetAnnualProductReportResponse } from "../../../api/type";
import { userCurrentRole } from "../../../utils/common-functions";
interface LocationOperationPrintComponentProps {
  data: LocationOperationsResponse;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  page: {
    padding: "0.75in",
    position: "relative",
    fontFamily: "Noto Serif Bengali"
  },
  header: {
    width: "100%",
    marginTop: 3,
    marginBottom: 10
  },
  headerContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    fontSize: 11
  },
  headerContentDetails: {
    width: "50%",
    border: "1px solid #dfdfdf",
    paddingHorizontal: 5,
    paddingVertical: 1
  },
  organizationLogo: {
    height: "auto",
    width: "50px",
    marginHorizontal: 240
  },
  organizationName: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center"
  },
  table: {
    width: "100%",
    borderLeft: "1px solid #dfdfdf",
    borderRight: "1px solid #dfdfdf"
  },
  tableHeader: {
    fontSize: 11,
    fontWeight: "semibold",
    borderTop: "1px solid #dfdfdf",
    backgroundColor: "#EBECF0"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 11,
    borderBottom: "1px solid #dfdfdf"
  },
  operationId: {
    width: "10%",
    textAlign: "left",
    borderRight: "1px solid #dfdfdf"
  },
  from: {
    width: "25%",
    textAlign: "left",
    paddingHorizontal: 5,
    borderRight: "1px solid #dfdfdf"
  },
  to: {
    width: "20%%",
    textAlign: "left",
    paddingHorizontal: 5,
    borderRight: "1px solid #dfdfdf"
  },
  type: {
    width: "15%",
    borderRight: "1px solid #dfdfdf"
  },
  by: {
    width: "15%"
  },
  signature: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 11,
    fontWeight: "semibold",
    marginTop: 50
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    fontSize: 6,
    color: "#666666",
    bottom: "0.50in",
    left: "0.75in",
    right: "0.75in",
    borderTop: "1px solid #000"
  },
  backgroundImage: {
    position: "absolute",
    top: "45%",
    width: "200px",
    opacity: 0.05,
    objectFit: "contain",
    alignSelf: "center"
  }
});

export const LocationOperationPrintComponent: React.FC<
  LocationOperationPrintComponentProps
> = (props: LocationOperationPrintComponentProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.backgroundImage} src={env.reportLogo} fixed />
        <View>
          <Image style={styles.organizationLogo} src={env.organizationLogo} />
          <Text style={styles.organizationName}>
            {env.organizationFullName}
          </Text>
          <View style={styles.header}>
            <View style={styles.headerContent}>
              <Text style={styles.headerContentDetails}>
                Report Type : Product Prediction Report
              </Text>
              <Text style={styles.headerContentDetails}>
                Start Date :{"  "}
                {dayjs(props.data.data.startDate).format(
                  "DD MMM YYYY, hh:mm A"
                )}
              </Text>
            </View>
            <View style={styles.headerContent}>
              <Text style={styles.headerContentDetails}>
                Location : {props.data.data.location.name}
              </Text>
              <Text style={styles.headerContentDetails}>
                End Date :{"  "}
                {dayjs(props.data.data.endDate).format("DD MMM YYYY, hh:mm A")}
              </Text>
            </View>
            <View style={styles.headerContent}>
              <Text style={styles.headerContentDetails}>
                SegmentType : {props.data.data.segmentationType}
              </Text>
              <Text style={styles.headerContentDetails}>
                Operation Type :{"  "}
                {props.data.data.operationType}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={[styles.tableHeader, styles.row]} fixed>
            <Text style={styles.operationId}>OP Id</Text>
            <Text style={styles.from}>From</Text>
            <Text style={styles.to}>To</Text>
            <Text style={styles.type}>Type</Text>
            <Text style={styles.by}>Time</Text>
          </View>
          {props.data.data.operations.map((row, i) => (
            <View style={styles.row} key={i} wrap={false}>
              <Text style={styles.operationId}>{row.id} </Text>
              <Text style={styles.from}>{row.originLocation.name + " "}</Text>
              <Text style={styles.to}>
                {row.destinationLocation?.name || " "}
              </Text>
              <Text style={styles.type}>{row.operationType}</Text>
              <Text style={styles.by}>
                {dayjs(row.createdAt).format("DD-MM-YY, hh:mm A")}
              </Text>
            </View>
          ))}
        </View>
        <View style={styles.signature}>
          <View>
            <Text>Verify By,</Text>
          </View>
          <View>
            <Text>Authorized By,</Text>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View>
            <Text>Powered By Bindulogic Limited</Text>
          </View>
          <View>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
          <View>
            {/* <Text>
              Printed By: {userCurrentRole() || "Unknown"},{" "}
              {dayjs(props.data.reportViewTime).format("DD MMM YYYY, hh:mm A")}
            </Text> */}
          </View>
        </View>
      </Page>
    </Document>
  );
};
