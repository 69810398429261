import React, { useEffect, useState } from "react";
import {
  Button,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  FormControl,
  FormLabel,
  useToast,
  IconButton
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import { useUpdateNoteMutation } from "../../../api";

interface NotesModalComponentProps {
  id: number;
  note: string;
}

export const NotesModalComponent: React.FC<NotesModalComponentProps> = (
  props: NotesModalComponentProps
) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updateNote, resultUpdateNote] = useUpdateNoteMutation();

  const [notesValue, setNotesValue] = useState(props.note);

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNotesValue(event.target.value);
  };

  const handleSubmit = () => {
    onClose();
    updateNote({
      data: {
        notes: {
          id: props.id,
          notes: notesValue
        }
      }
    });
  };

  useEffect(() => {
    if (resultUpdateNote.isSuccess) {
      toast({
        title: "Success",
        description: "Operation notes updated successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
  }, [resultUpdateNote, toast]);

  return (
    <>
      <IconButton
        icon={<FaEdit />}
        variant="outline"
        colorScheme="blue"
        aria-label="Update note"
        onClick={onOpen}
      />
      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Operation Notes</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Operation ID</FormLabel>
              <Input type="text" defaultValue={props.id} />
            </FormControl>
            <Textarea
              mt="2"
              height="200px"
              value={notesValue}
              onChange={handleTextareaChange}
              placeholder="Enter your note here"
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="blue" onClick={handleSubmit}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
