import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Tr
} from "@chakra-ui/react";
import { FaEye, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useGetPendingOperationQuery } from "../../../api";
import { userCurrentRole } from "../../../utils/common-functions";
import { PageCardComponent } from "../../core/components/page-card.component";

interface DashboardPendingOperationComponentProps {}

const DashboardPendingOperationComponent = (
  props: DashboardPendingOperationComponentProps
) => {
  const { data } = useGetPendingOperationQuery({});
  const navigate = useNavigate();
  const role = userCurrentRole();
  const handleRowClick = (row: any) => {
    navigate(
      `/${role}/approval/operation-details?id=${row.id}&type=${row.operationType}`
    );
  };
  const handleViewMore = () => {
    navigate(`/${role}/stock-operation/pending-operation`);
  };
  return (
    <Stack boxShadow={"sm"}>
      <PageCardComponent>
        <Box borderWidth="1px" borderRadius="lg" p={4}>
          <Flex mb={3}>
            <Box>
              <Heading size={"sm"}>Pending Operations </Heading>
            </Box>
            <Spacer />
            <Box>
              <Tag colorScheme={"red"}>
                <Text fontWeight={"bold"}>
                  {data?.data.stockOperations.length}
                </Text>
              </Tag>
            </Box>
          </Flex>
          {data?.data.stockOperations.slice(-1).map((d, i) => {
            return (
              <Stack key={i}>
                <Table size={"md"} variant={"unstyled"}>
                  <Tbody>
                    <Tr borderWidth="1px" borderRadius="lg">
                      <Th>Origin Location</Th>
                      <Td>:</Td>
                      <Td>
                        <Badge mr="3" variant="subtle" colorScheme="green">
                          {d.originLocation.name}
                        </Badge>
                      </Td>
                    </Tr>
                    <Tr borderWidth="1px" borderRadius="lg">
                      <Th>Destination Location</Th>
                      <Td>:</Td>
                      <Td>
                        <Badge mr="3" variant="subtle" colorScheme="green">
                          {d?.destinationLocation?.name}
                        </Badge>
                      </Td>
                    </Tr>
                    <Tr borderWidth="1px" borderRadius="lg">
                      <Th>Operation Type</Th>
                      <Td>:</Td>
                      <Td>
                        <Badge mr="3" variant="subtle" colorScheme="blue">
                          {d.operationType}
                        </Badge>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
                <Button
                  leftIcon={<FaEye />}
                  color="messenger.400"
                  colorScheme={"messenger"}
                  variant={"outline"}
                  onClick={() => handleRowClick(d)}
                >
                  View
                </Button>
                <Button
                  leftIcon={<FaPlus />}
                  colorScheme={"blue"}
                  onClick={() => handleViewMore()}
                >
                  View More...
                </Button>
              </Stack>
            );
          })}
        </Box>
      </PageCardComponent>
    </Stack>
  );
};

export default DashboardPendingOperationComponent;
