import React, { useEffect, useState } from "react";
import { useGetVendoerDemandDetailsQuery } from "../../../api";
import { GetVendorDemandDataResponse } from "../../../api/type";
import { VendorPrintComponent } from "./vendor-print.component";
import { Button, Spinner, useDisclosure } from "@chakra-ui/react";
import { PdfPrinterModal } from "../../core/components/pdf-printer-modal.component";
import { env } from "../../../config/index";
interface VendoerDemandPrintComponetProps {
  id: number;
}

export const VendoerDemandPrintComponet: React.FC<
  VendoerDemandPrintComponetProps
> = (props: VendoerDemandPrintComponetProps) => {
  const { data } = useGetVendoerDemandDetailsQuery({
    data: { report: { demandId: Number(props.id) } }
  });

  const [printData, setPrintData] = useState<GetVendorDemandDataResponse>();
  useEffect(() => {
    setPrintData(data);
  }, [data]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = printData ? (
    <VendorPrintComponent printData={printData} organizationNameBangla={env.organizationNameBangla} />
  ) : (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
    />
  );

  const vendorDemand = `Vendor-Demand-${printData?.id}.pdf`;

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={vendorDemand}
      />
    </>
  );
};
