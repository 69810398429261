import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useToast,
  Flex
} from "@chakra-ui/react";

import { Select, GroupBase } from "chakra-react-select";
import { useForm, Controller } from "react-hook-form";
import { PageCardComponent } from "../../core/components/page-card.component";
import {
  useGetLocationDetailsQuery,
  useEditLocationMutation,
  useGetAssignUserLocationsQuery
} from "../../../api";

import {
  DepartmentGroup,
  LocationTypeGroup,
  ParentLocationGroup
} from "../types/location.type";
import { departmentOptions, locationTypeOptions } from "../data/location.data";
import { userCurrentRole } from "../../../utils/common-functions";
import { IoMdArrowRoundBack } from "react-icons/io";

type EditLocationFormValues = {
  name: string;
  type: LocationTypeGroup;
  department: DepartmentGroup;
  parentID: ParentLocationGroup;
};

type EditLocationUpdatedValues = {
  name: string;
  locationType: string;
  department: string;
  parentLocation?: string | number;
};

interface EditLocationComponentProps { }

export const EditLocationComponent: React.FC<EditLocationComponentProps> = (
  props: EditLocationComponentProps
) => {
  const navigate = useNavigate();
  const role = userCurrentRole();
  const toast = useToast();
  const { id } = useParams();
  const { data } = useGetLocationDetailsQuery({
    data: {
      location: {
        locationId: Number(id)
      }
    }
  });
  const getLocationQuery = useGetAssignUserLocationsQuery({});
  const [name, setName] = useState<string>();
  const [type, setType] = useState<LocationTypeGroup>();
  const [department, setDepartment] = useState<DepartmentGroup>();
  const [parentLocationOptions, setParentLocationOptions] = useState<
    ParentLocationGroup[]
  >([]);
  const [parentLocation, setParentLocation] = useState<ParentLocationGroup>();
  useEffect(() => {
    if (data) {
      setParentLocation(
        parentLocationOptions.find(
          (d) => d.value === data.location.parentLocationId
        )
      );
    }
  }, [data, parentLocationOptions]);
  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setParentLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<EditLocationFormValues>();

  useEffect(() => {
    if (data?.location) {
      setName(data.location.name);
      setType({
        label: data.location.locationType,
        value: data.location.locationType
      });
      setDepartment({
        label: data.location.department,
        value: data.location.department
      });
    }
  }, [data]);

  const handleLocationTypeSelect = (locationType: any) => {
    setType(locationType);
  };
  const handleDepartmentSelect = (department: any) => {
    setDepartment(department);
  };
  const handleParentLocationSelect = (parentLocation: any) => {
    setParentLocation(parentLocation);
  };

  const [editLocationCreate, editLocationResult] = useEditLocationMutation();

  const handleEditLocation = handleSubmit(() => {
    if (name && type && department) {
      if (parentLocation) {
        let newData: EditLocationUpdatedValues = {
          name: name,
          locationType: type.value,
          department: department.value,
          parentLocation: parentLocation.value
        };
        editLocationCreate({
          data: {
            location: {
              locationId: Number(id),
              ...newData
            }
          }
        });
      } else {
        let newData: EditLocationUpdatedValues = {
          name: name,
          locationType: type.value,
          department: department.value,
        };
        editLocationCreate({
          data: {
            location: {
              locationId: Number(id),
              ...newData
            }
          }
        });

      }

    }
  });

  useEffect(() => {
    if (editLocationResult.isSuccess) {
      toast({
        title: "Success",
        description: "Location updated successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      setTimeout(() => {
        navigate(`/${role}/location/summary`);
      }, 2000);
    }
  }, [editLocationResult, toast, navigate, role]);

  return (
    <Stack>
      <Flex justifyContent="end">
        <Button
          colorScheme="blue"
          leftIcon={<IoMdArrowRoundBack />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Flex>
      <form onSubmit={handleEditLocation}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
        >
          <PageCardComponent>
            <FormControl mb="3" isInvalid={errors.name ? true : false}>
              <FormLabel>Location Name</FormLabel>
              <Input
                type="text"
                defaultValue={data?.location.name}
                onChange={(e) => setName(e.target.value)}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
          </PageCardComponent>

          <PageCardComponent>
            <Controller
              control={control}
              name="type"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => (
                <FormControl isInvalid={!!error} id="type">
                  <FormLabel>Location Type</FormLabel>
                  <Select<LocationTypeGroup, true, GroupBase<LocationTypeGroup>>
                    name={name}
                    ref={ref}
                    onChange={(locationType) =>
                      handleLocationTypeSelect(locationType)
                    }
                    onBlur={onBlur}
                    value={type}
                    options={locationTypeOptions}
                    closeMenuOnSelect={true}
                  />
                  <FormErrorMessage>{error && error.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </PageCardComponent>
        </Stack>

        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
          my="2"
        >
          <PageCardComponent>
            <Controller
              control={control}
              name="department"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => (
                <FormControl isInvalid={!!error} id="department">
                  <FormLabel>Department</FormLabel>
                  <Select<DepartmentGroup, true, GroupBase<DepartmentGroup>>
                    name={name}
                    ref={ref}
                    onChange={(department) =>
                      handleDepartmentSelect(department)
                    }
                    onBlur={onBlur}
                    value={department}
                    options={departmentOptions}
                    closeMenuOnSelect={true}
                  />
                  <FormErrorMessage>{error && error.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </PageCardComponent>
          <PageCardComponent>
            <Controller
              control={control}
              name="parentID"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => (
                <FormControl isInvalid={!!error} id="parentID">
                  <FormLabel>Parent Location</FormLabel>
                  <Select<
                    ParentLocationGroup,
                    true,
                    GroupBase<ParentLocationGroup>
                  >
                    name={name}
                    ref={ref}
                    onChange={(parentLocation) =>
                      handleParentLocationSelect(parentLocation)
                    }
                    onBlur={onBlur}
                    value={parentLocation}
                    options={parentLocationOptions}
                    closeMenuOnSelect={true}
                  // isDisabled={true}
                  />
                  <FormErrorMessage>{error && error.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </PageCardComponent>
        </Stack>

        <Stack width="100%">
          <Button type="submit" colorScheme="blue">
            Update Location
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};
