import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  useReactTable,
  FilterFn,
  ExpandedState,
  getExpandedRowModel,
  getPaginationRowModel,
  createColumnHelper
} from "@tanstack/react-table";
import {
  Box,
  Button,
  Select as SelectPageSize,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Badge
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { FaEye } from "react-icons/fa";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import { PageCardComponent } from "../../core/components/page-card.component";
import { Controller, useForm } from "react-hook-form";
import { OriginLocationGroup } from "../types/stock-operation.type";
import { customPagination, displayAllTablePage } from "../../../config";
import { VendorOperation } from "../../../api/type";
import {
  useGetAllVendorDemandQuery,
  useGetAssignUserLocationsQuery
} from "../../../api";
import { LoaderComponent } from "../../core/components/loader.component";
import { canSelectLocation, chakraStyles, userCurrentRole } from "../../../utils/common-functions";
import { TableSearchInput } from "../../core/components/table-search-component";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { RetrieveQueryParams } from '../../../service/operation/retrieveQueryParams.service';


type LocationInventoryFormValues = {
  originLocation: OriginLocationGroup;
};

declare module "@tanstack/table-core" {
  interface FilterFns {
    tableFilter: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const tableFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank
  });
  return itemRank.passed;
};

interface AllVendorDemandComponentProps { }

export const AllVendorDemandComponent: React.FC<
  AllVendorDemandComponentProps
> = (props: AllVendorDemandComponentProps) => {

  let query = RetrieveQueryParams();
  let locationId = query.get("locationId") ? query.get("locationId") : localStorage.getItem("location");
  const navigate = useNavigate();
  const role = userCurrentRole();
  let initialState = {
    label: "",
    value: Number(locationId)
  };

  const [originLocation, setOriginLocation] =
    useState<OriginLocationGroup>(initialState);

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);

  const { data, isLoading } = useGetAllVendorDemandQuery({
    data: { report: { locationId: Number(originLocation?.value) } }
  });

  const getLocationQuery = useGetAssignUserLocationsQuery({});

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(locationId))!
      );
    }
  }, [locationId, originLocationOptions]);

  const handleGetLocation = (data: any) => {
    setOriginLocation(data);
  };

  const { control } = useForm<LocationInventoryFormValues>({});

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: customPagination.pageIndex,
    pageSize: customPagination.pageSize
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const handleRowClick = (row: any) => {
    navigate(
      `/${role}/approval/operation-details?id=${row.id}&type=${row.operationType}&locationId=${Number(originLocation?.value)}`
    );
  };
  const [globalFilter, setGlobalFilter] = useState("");
  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const columnHelper = createColumnHelper<VendorOperation>();
  const columns: ColumnDef<VendorOperation, any>[] = [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      header: () => "ID",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.originLocation?.name, {
      id: "originLocation.name",
      header: () => "Origin Location",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.operationType, {
      id: "operationType",
      header: () => "Operation Type",
      cell: (row) => {
        return (
          <Badge
            p={1}
            textAlign="center"
            width={{
              base: "100%",
              md: "100%",
              lg: "100%",
              xl: "100%",
              "2xl": "55%"
            }}
            borderRadius={5}
            variant="subtle"
            colorScheme="messenger"
          >
            {row.getValue() === "VENDOR_DEMAND" && "VENDOR DEMAND"}
          </Badge>
        );
      }
    }),
    columnHelper.accessor((row) => row.operationStatus, {
      id: "operationStatus",
      header: () => "Operation Status",
      cell: (row) => (
        <Badge
          p={1}
          textAlign="center"
          width={{
            base: "100%",
            md: "100%",
            lg: "100%",
            xl: "100%",
            "2xl": "55%"
          }}
          borderRadius={5}
          variant="solid"
          colorScheme={"green"}
        >
          {row.getValue() === "FINALIZED" && "APPROVED"}
        </Badge>
      )
    }),
    columnHelper.accessor((row) => row.createdAt, {
      id: "date",
      header: () => "Date",
      cell: (row) => dayjs(row.getValue()).format("MMM D, YYYY")
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: ({ row, table }) => {
        return (
          <>
            <Button
              leftIcon={<FaEye />}
              color="messenger.400"
              size={"sm"}
              colorScheme={"messenger"}
              variant={"outline"}
              onClick={() => handleRowClick(row.original)}
            >
              View
            </Button>
          </>
        );
      }
    })
  ];
  const table = useReactTable({
    data: data ? data.data.operations : [],
    columns: columns,
    filterFns: {
      tableFilter
    },
    state: {
      pagination,
      globalFilter,
      expanded
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.trackingData,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    globalFilterFn: tableFilter,
    debugTable: true
  });
  return (
    <Stack>
      <Stack direction={{ base: "column", xl: "row" }} justify="space-between">
        <PageCardComponent>
          <Controller
            control={control}
            name="originLocation"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { error }
            }) => (
              <FormControl mb="3" isInvalid={!!error} id="originLocation">
                <FormLabel>Select Location</FormLabel>
                <Select
                  chakraStyles={chakraStyles}
                  selectedOptionColor="purple"
                  name={name}
                  ref={ref}
                  onBlur={onBlur}
                  value={originLocation}
                  isDisabled={!canSelectLocation(role)}
                  onChange={(location) => handleGetLocation(location)}
                  options={originLocationOptions}
                  placeholder="Select location"
                  closeMenuOnSelect={true}
                />
                <FormErrorMessage>{error && error.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </PageCardComponent>
      </Stack>

      <Stack>
        <Stack
          my="2"
          direction={{ base: "column", xl: "row" }}
          minWidth="max-content"
        >
          <Box>
            <TableSearchInput
              value={globalFilter ?? ""}
              onChange={(value) => setGlobalFilter(String(value))}
            />
          </Box>
        </Stack>
      </Stack>

      {isLoading ? (
        <LoaderComponent />
      ) : (
        <Table variant="striped">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      bg="gray.700"
                      color="white"
                    >
                      {header.isPlaceholder ? null : (
                        <Box>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Box>
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Fragment key={row.id}>
                  <Tr>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                  {/* {row.getIsExpanded() && (
                <Tr>
                  <Td colSpan={row.getVisibleCells().length}>
                    {renderSubComponent(row.subRows)}
                  </Td>
                </Tr>
              )} */}
                </Fragment>
              );
            })}
          </Tbody>
        </Table>
      )}

      <Stack>
        <Stack
          direction={{ base: "column", lg: "row" }}
          justify="space-between"
          alignItems="center"
          mt="2"
        >
          <Box width={{ base: "100%", lg: "60%" }}>
            <Text fontSize="lg" fontWeight="bold">
              Showing (Page {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()})
            </Text>{" "}
          </Box>
          <Stack
            direction="row"
            width={{ base: "100%", lg: "40%" }}
            justifyContent="space-between"
          >
            <Stack direction="row" width={{ lg: "80%" }}>
              <Button
                colorScheme="blue"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {"<<"}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {"<"}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {">"}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {">>"}
              </Button>{" "}
            </Stack>

            <Box width={{ base: "100%", md: "50%", lg: "100%" }}>
              <SelectPageSize
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {displayAllTablePage.map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </SelectPageSize>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
