import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button
} from "@chakra-ui/react";
import React from "react";

interface CancelAlertPopupComponentProps {
  cancelRef: any;
  isOpen: any;
  onOpen: any;
  onClose: any;
  handleCancel: any;
}

export const CancelAlertPopupComponent: React.FC<
  CancelAlertPopupComponentProps
> = (props: CancelAlertPopupComponentProps) => {
  return (
    <div>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={props.cancelRef}
        onClose={props.onClose}
        isOpen={props.isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Confirmation</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to cancel the operation ?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={props.onClose}>No</Button>
            <Button colorScheme="red" ml={3} onClick={props.handleCancel}>
              Yes, Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
