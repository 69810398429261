import React, { useState, useEffect } from "react";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  useReactTable
} from "@tanstack/react-table";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { useGetAllUsersQuery } from "../../../api";
import { User } from "../../../api/type";
import { customPagination, displayAllTablePage } from "../../../config";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { userCurrentRole } from "../../../utils/common-functions";
import { LoaderComponent } from "../../core/components/loader.component";

const headers = [
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Contact", key: "contact" }
];

interface UserListTableProps {}

export const UserListComponent: React.FC<UserListTableProps> = (
  props: UserListTableProps
) => {
  const navigate = useNavigate();
  const role = userCurrentRole();

  const registerUserPage = () => {
    navigate(`/${role}/register`);
  };

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: customPagination.pageIndex,
      pageSize: customPagination.pageSize
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );

  const [searchText, setSearchText] = useState<string>("");
  const [pageData, setPageData] = useState<User[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);

  const getAllUsersResult = useGetAllUsersQuery({
    query: {
      search: searchText.length >= 2 ? searchText : "",
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize
    }
  });

  useEffect(() => {
    if (getAllUsersResult.data) {
      setPageData(getAllUsersResult.data.data.user);
      setItemCount(getAllUsersResult.data.data.userCount);
    }
  }, [getAllUsersResult]);

  const columnHelper = createColumnHelper<User>();

  const columns: ColumnDef<User, any>[] = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => "Name",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.email, {
      id: "email",
      header: () => "Email",
      cell: (row) => row.getValue()
    }),
    columnHelper.accessor((row) => row.contact, {
      id: "contact",
      header: () => "Contact",
      cell: (row) => row.getValue()
    }),
    columnHelper.display({
      id: "location",
      header: "Locations",
      cell: ({ row, table }) => {
        return (
          <Stack direction="row">
            {row.original.locations.map((d) => {
              return (
                <Badge
                  p={1}
                  textAlign="center"
                  width={{
                    base: "100%"
                  }}
                  borderRadius={5}
                  variant="subtle"
                  colorScheme="green"
                >
                  {d.name}
                </Badge>
              );
            })}
          </Stack>
        );
      }
    }),
    columnHelper.accessor((row) => row.roles.map((d) => d.roleName), {
      id: "role",
      header: () => "Role",
      cell: (row) => {
        return (
          <Badge
            p={1}
            textAlign="center"
            width={{
              base: "100%"
            }}
            borderRadius={5}
            variant="subtle"
            colorScheme="messenger"
          >
            {row.getValue()}
          </Badge>
        );
      }
    })
  ];

  const table = useReactTable({
    data: pageData,
    // @ts-ignore
    columns: columns,
    pageCount: Math.ceil(itemCount / pagination.pageSize),
    state: {
      pagination
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    debugTable: true
  });

  return (
    <>
      {getAllUsersResult.isLoading ? (
        <LoaderComponent />
      ) : (
        <Stack>
          <Stack
            direction={{ base: "column", xl: "row" }}
            minWidth="max-content"
            alignItems="center"
            mb="3"
          >
            <Box>
              <InputGroup w="96" display={{ base: "flex", lg: "flex" }}>
                <InputLeftElement color="gray.500" children={<FaSearch />} />
                <Input
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </InputGroup>
            </Box>
            <Spacer />
            <ButtonGroup gap="2">
              <Button onClick={() => registerUserPage()} colorScheme="blue">
                Register User
              </Button>
              <CSVLink data={pageData} headers={headers}>
                <Button colorScheme="blue">Export CSV</Button>
              </CSVLink>
            </ButtonGroup>
          </Stack>
          <Table variant="striped">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        bgColor="gray.700"
                        color="white"
                      >
                        {header.isPlaceholder ? null : (
                          <Box>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Box>
                        )}
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          <Stack
            direction={{ base: "column", lg: "row", md: "row" }}
            justify="space-between"
            alignItems="center"
          >
            <Box width={{ base: "100%", lg: "60%" }}>
              <Text fontSize="lg" fontWeight="bold">
                Showing {pageIndex * pageSize + 1} -{" "}
                {Math.min((pageIndex + 1) * pageSize + 1, itemCount)} of{" "}
                {itemCount} (Page {table.getState().pagination.pageIndex + 1} of{" "}
                {table.getPageCount()})
              </Text>{" "}
            </Box>

            <Stack
              direction="row"
              width={{ base: "100%", lg: "40%" }}
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                mt="3"
                width={{ base: "100%", md: "100%", lg: "100%" }}
              >
                <Button
                  colorScheme="blue"
                  onClick={() => table.setPageIndex(0)}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<<"}
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<"}
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {">"}
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                  disabled={!table.getCanNextPage()}
                >
                  {">>"}
                </Button>{" "}
              </Stack>
              <Box width={{ base: "100%", md: "100%", lg: "100%" }}>
                <Select
                  mt="3"
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                  {displayAllTablePage.map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </Select>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};
