import { adminDashboard } from "./admin-dashboard.route";
import { auditorDashboard } from "./auditor-dashboard.route";
import { distributorDashboard } from "./distributor-dashboard.route";
import { sellerDashboard } from "./seller-dashboard.route";
import { storeManagerDashboard } from "./store-manager-dashboard.route";
import { substoreManagerDashboard } from "./substore-manager-dashboard.route";
import { superAdminDashboard } from "./super-admin-dashboard.route";
import { wardManagerDashboard } from "./ward-manager-dashboard.route";

export const dashboardList = [
  adminDashboard,
  superAdminDashboard,
  storeManagerDashboard,
  substoreManagerDashboard,
  wardManagerDashboard,
  sellerDashboard,
  distributorDashboard,
  auditorDashboard
];
