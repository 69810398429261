import React from "react";
import dayjs from "dayjs";
import { LocationInventory } from "../../../api/type";
import { OriginLocationGroup } from "../../stock-operation/types/stock-operation.type";
import { InventoryByDatePrintComponent } from "./inventory-by-date-print.component";
import { Button, useDisclosure } from "@chakra-ui/react";
import { PdfPrinterModal } from "../../core/components/pdf-printer-modal.component";

interface LocationInventoryByDatePrintComponentProps {
  printData: LocationInventory[];
  location: OriginLocationGroup;
  reportDate: string | undefined;
}

export const LocationInventoryByDatePrintComponent: React.FC<
  LocationInventoryByDatePrintComponentProps
> = (props: LocationInventoryByDatePrintComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = (
    <InventoryByDatePrintComponent
      data={props.printData}
      location={props.location}
      reportDate={props.reportDate}
    />
  );

  const inventoryByDateReport = `Inventory-${props.location.label}-${dayjs(
    props.reportDate
  ).format("DD-MMM-YY")}.pdf`;

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={inventoryByDateReport}
      />
    </>
  );
};
