import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";

export const TableSearchInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
}) => {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return (
    <InputGroup
      w={{ lg: "30", xl: "30", "2xl": "96" }}
      display={{ base: "flex", lg: "flex" }}
    >
      <InputLeftElement color="gray.300" children={<FaSearch />} />
      <Input
        placeholder="Enter 3 or 4 letters to find"
        value={value}
        autoFocus={value ? true : false}
        onChange={(e) => setValue(e.target.value)}
      />
    </InputGroup>
  );
};
