import { env } from "../../../config";
import { CmchDailyProductReportComponent } from "../components/cmch/cmch-daily-product-report.component";
import DailyProductReportComponent from "../components/daily-product-report.component";

export default function DailyProductReportPage() {
  return (
    <div>
      {env.organizationShortName === "CMCH" ? (
        <CmchDailyProductReportComponent />
      ) : (
        <DailyProductReportComponent />
      )}
    </div>
  );
}
