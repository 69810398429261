const address = (doc, dailyReport, startY, fontSizes, lineSpacing) => {
  const startX = 42;

  // TitleFontSize: 12,
  // SubTitleFontSize: 10,
  // NormalFontSize: 8,
  // SmallFontSize: 6,

  //-------Customer Info Address---------------------
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);
  doc.setFontSize(fontSizes.SubTitleFontSize);

  //  address = Object.values(address);
  // doc.setFontSize(fontSize.SubTitleFontSize);
  
  const invoiceNrTxt = dailyReport.data.location.name;
  doc.text(invoiceNrTxt, startX, (startY += lineSpacing));

  // doc.setFontSize(fontSizes.NormalFontSize);

  // doc.text(`${dailyReport.printOutBy.name}`, startX, (startY += lineSpacing));
  // doc.text(`${dailyReport.printOutBy.phone}`, startX, (startY += lineSpacing));
  // doc.text(`${dailyReport.printOutBy.email}`, startX, (startY += lineSpacing));

  return startY;
};
export default address;
