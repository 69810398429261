import React, { forwardRef, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./../components/chakra-react-datepicker.css";
import { CalendarIcon } from "@chakra-ui/icons";

import { Controller, useForm } from "react-hook-form";
import { PageCardComponent } from "../../core/components/page-card.component";
import { Select, GroupBase } from "chakra-react-select";
import { OriginLocationGroup } from "../types/stock-operation.type";

import {
  useGetAssignUserLocationsQuery,
  useLazyGetPreviousInventoryQuery
} from "../../../api";
import { canSelectLocation, chakraStyles, userCurrentRole } from "../../../utils/common-functions";

import PreviousInventoryTableComponent from "../components/inventory-table.component";
import dayjs from "dayjs";
import { LoaderComponent } from "../../core/components/loader.component";

type DailyReportFormValues = {
  originLocation: OriginLocationGroup;
};
export default function InventoryByDateComponent() {
  const icon = <CalendarIcon fontSize="sm" />;
  const { control } = useForm<DailyReportFormValues>({});
  let locationId = localStorage.getItem("location");
  const role = userCurrentRole();

  const [getPreviousInventory, getPreviousInventoryResult] =
    useLazyGetPreviousInventoryQuery();

  let initialState = {
    label: "",
    value: Number(locationId)
  };
  const [originLocation, setOriginLocation] =
    useState<OriginLocationGroup>(initialState);
  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);

  const getLocationQuery = useGetAssignUserLocationsQuery({});
  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);
  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === initialState.value)!
      );
    }
  }, [initialState.value, originLocationOptions]);
  const handleGetLocation = (data: any) => {
    setOriginLocation(data);
  };
  function onChangeHandler(value: any) {
    setDateStart(value);
  }
  const [dateStart, setDateStart] = useState(new Date());
  const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
    <Input
      autoComplete="off"
      background="white"
      value={value}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
    />
  );
  customDateInput.displayName = "DateInput";

  const CustomInput = forwardRef(customDateInput);
  const findPreviousInventory = () => {
    getPreviousInventory({
      data: {
        report: {
          locationId: originLocation.value,
          date: dateStart.toISOString()
        }
      }
    });
  };
  return (
    <>
      {getPreviousInventoryResult.isLoading ? (
        <LoaderComponent />
      ) : (
        <Stack>
          <Stack
            direction={{ base: "column", xl: "row" }}
            justify="space-between"
          >
            <PageCardComponent>
              <Stack direction="row" spacing="12">
                <Box width="40%">
                  <Controller
                    control={control}
                    name="originLocation"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { error }
                    }) => (
                      <FormControl
                        mb="3"
                        isInvalid={!!error}
                        id="originLocation"
                      >
                        <FormLabel>Select Location</FormLabel>
                        <Select
                          chakraStyles={chakraStyles}
                          name={name}
                          ref={ref}
                          onChange={(location) => handleGetLocation(location)}
                          onBlur={onBlur}
                          isDisabled={!canSelectLocation(role)}
                          value={originLocation}
                          options={originLocationOptions}
                          placeholder="Select location"
                          closeMenuOnSelect={true}
                        />
                        <FormErrorMessage>
                          {error && error.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </Box>
                <Box width="40%">
                  <FormControl>
                    <FormLabel>Select Date</FormLabel>
                    <InputGroup className="dark-theme">
                      <DatePicker
                        id="dateStartEnd"
                        selected={dateStart}
                        onChange={onChangeHandler}
                        minDate={new Date("12-20-2022")}
                        maxDate={new Date("12-12-2099")}
                        dateFormat="dd MMM yyyy"
                        className="react-datapicker__input-text"
                        placeholderText="Select Date"
                        customInput={<CustomInput />}
                        showDisabledMonthNavigation
                      />
                      <InputRightElement color="gray.500" children={icon} />
                    </InputGroup>
                  </FormControl>
                </Box>
                <Box width="20%" alignSelf="center">
                  <Button
                    width="100%"
                    colorScheme={"blue"}
                    onClick={() => findPreviousInventory()}
                    mt="5"
                  >
                    Find
                  </Button>
                </Box>
              </Stack>
            </PageCardComponent>
          </Stack>

          <Stack>
            {getPreviousInventoryResult.data?.data && (
              <PageCardComponent>
                <Heading fontSize={14}>
                  {dayjs(
                    getPreviousInventoryResult.data?.data.reportDate
                  ).format("dddd, MMMM D, YYYY ")}
                </Heading>
                <PreviousInventoryTableComponent
                  previousInventory={
                    getPreviousInventoryResult.data.data.previousInventory
                  }
                  location={originLocation}
                  reportDate={
                    getPreviousInventoryResult.currentData?.data.reportDate
                  }
                />
              </PageCardComponent>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
}
