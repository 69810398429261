import { useEffect, useRef, useState } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ButtonGroup,
  useToast,
  useDisclosure,
  Textarea,
  Text,
  Box
} from "@chakra-ui/react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { Select, GroupBase } from "chakra-react-select";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  ENTER_KEY,
  fieldNameType,
  OriginLocationGroup,
  ProductGroup
} from "../../types/stock-operation.type";
import {
  useGetAssignUserLocationsQuery,
  useGetProductQuery,
  useInitialInventoryMutation
} from "../../../../api";
import { useNavigate } from "react-router-dom";
import {
  RemoveSelectedProductFromAllProducts,
  userCurrentRole
} from "../../../../utils/common-functions";
import { ConfirmAlertComponent } from "../confirm-alert.component";
import { StockOperationItems } from "../../types/stock-operation.type";
import { ProductTrackingTypeOptions } from "../../../../api/type";
import { OperationService } from "../../../../service/operation/operation.service";
import dayjs from "dayjs";

type InitialInventoryFormValues = {
  originLocation: OriginLocationGroup;
  stockOperationItems: StockOperationItems[];
  notes: string;
};

const CmchInitialInventoryComponent = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const role = userCurrentRole();
  let id = localStorage.getItem("location");
  const initialInventoryConfirmAlert = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const getLocationQuery = useGetAssignUserLocationsQuery({});
  const getProductQuery = useGetProductQuery({ id: Number(id) });

  const [initialOperationCreate, initialOperationResult] =
    useInitialInventoryMutation();

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);
  const [originLocation, setOriginLocation] = useState<OriginLocationGroup>();
  const [productOptions, setProductOptions] = useState<ProductGroup[]>([]);

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(id))
      );
    }
  }, [id, originLocationOptions]);

  // TODO: Track this
  useEffect(() => {
    if (getProductQuery.data) {
      const productWithValues = getProductQuery.data.data.products.map(
        (product) => {
          return {
            label: product.name,
            value: product.id,
            type: product.trackingType,
            unit: product.unit
          };
        }
      );
      setProductOptions(productWithValues);
    }
  }, [getProductQuery.data]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setFocus,
    getValues,
    formState: { errors }
  } = useForm<InitialInventoryFormValues>({
    defaultValues: {
      stockOperationItems: [{}]
    },
    shouldUnregister: false
  });

  const { fields, append, remove } = useFieldArray<
    InitialInventoryFormValues,
    "stockOperationItems"
  >({
    control,
    name: "stockOperationItems"
  });

  const watch = useWatch({
    control,
    name: "stockOperationItems"
  });

  const initialInventoryHandleSubmit = handleSubmit((data) => {
    const allStockOperationItems = data.stockOperationItems.map(
      (singleItem) => {
        let tempObj: any = {};
        if (singleItem.expiryDate) {
          tempObj["expiryDate"] = dayjs(singleItem.expiryDate);
        }
        if (singleItem.manufactureDate) {
          tempObj["manufactureDate"] = dayjs(singleItem.manufactureDate);
        }

        return {
          productId: singleItem.product.value,
          quantity: OperationService.getQuantity(
            singleItem.product.type as string,
            singleItem.quantity
          ),
          trackingNumber: singleItem.trackingNumber,
          ...tempObj
        };
      }
    );
    initialOperationCreate({
      data: {
        stockOperation: {
          locationId: Number(id),
          stockOperationItems: allStockOperationItems,
          notes: data.notes
        }
      }
    });
  });

  useEffect(() => {
    if (initialOperationResult.isSuccess) {
      toast({
        title: "Success",
        description: "Initial inventory operation successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      navigate(`/${role}/report/location-operations`);
    }
  }, [initialOperationResult, toast, navigate, role]);
  const handleAddItem = () => {
    const currentindex = fields.length - 1;
    const nextIndex = currentindex + 1;
    append({});

    setTimeout(() => {
      focusElement(nextIndex, "product");
    }, 10);
  };

  const handleKeyDown = (event: any, index: number, fildName: string) => {
    if (event.keyCode !== ENTER_KEY) return;
    const lastIndex = fields.length - 1;
    const currentIndex = index;
    const nextIndex = index + 1;

    let currentProductType = watch[index].product.type;

    if (
      currentIndex === lastIndex &&
      currentProductType === ProductTrackingTypeOptions.SERIALIZED &&
      fildName === "trackingNumber"
    ) {
      append({});
      setTimeout(() => {
        focusElement(nextIndex, "product");
      }, 100);
    } else if (currentIndex === lastIndex && fildName === "quantity") {
      append({});
      setTimeout(() => {
        focusElement(nextIndex, "product");
      }, 100);
    } else if (
      currentProductType === ProductTrackingTypeOptions.NONE &&
      fildName === "quantity"
    ) {
      focusElement(nextIndex);
    } else if (
      currentProductType === ProductTrackingTypeOptions.SERIALIZED &&
      fildName === "trackingNumber"
    ) {
      focusElement(nextIndex);
    } else if (
      currentProductType === ProductTrackingTypeOptions.BATCH &&
      fildName === "trackingNumber"
    ) {
      focusElement(currentIndex, "quantity");
    } else if (
      currentProductType === ProductTrackingTypeOptions.BATCH &&
      fildName === "quantity"
    ) {
      focusElement(nextIndex);
    }
  };

  function focusElement(index: number, fieldName?: fieldNameType) {
    if (fieldName) {
      setFocus(`stockOperationItems.${index}.${fieldName}`);
      return;
    }

    let selectedProduct = watch[index].product;
    if (!selectedProduct) {
      setFocus(`stockOperationItems.${index}.product`);
      return;
    }
    let productType = selectedProduct.type;
    if (productType !== ProductTrackingTypeOptions.NONE) {
      setFocus(`stockOperationItems.${index}.trackingNumber`);
      return;
    }
    setFocus(`stockOperationItems.${index}.quantity`);
  }

  const handleProductSelect = (OnChange: any, value: any, index: number) => {
    const currentIndexId = index;
    OnChange(value);
    setTimeout(() => {
      if (value.type) {
        value.type === ProductTrackingTypeOptions.NONE
          ? focusElement(currentIndexId, "quantity")
          : focusElement(currentIndexId, "trackingNumber");
      }
    }, 10);
  };
  const getQuantity = (productType: any) => {
    if (productType === ProductTrackingTypeOptions.SERIALIZED) {
      return 1;
    }
    return "";
  };

  return (
    <Stack>
      <form onSubmit={initialInventoryHandleSubmit}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          width="100%"
          bgColor={"white"}
          borderRadius="md"
          p={4}
          mb={4}
        >
          <FormControl mb="3">
            <FormLabel>Origin Location</FormLabel>
            <Input
              _disabled={{ color: "blackAlpha.900", cursor: "not-allowed" }}
              defaultValue={originLocation ? originLocation.label : ""}
              isDisabled={true}
            />
          </FormControl>
        </Stack>
        <Stack spacing={4}>
          <Button
            my="2"
            width="100%"
            colorScheme="blue"
            variant="outline"
            leftIcon={<FaPlus />}
            onClick={() => handleAddItem()}
          >
            Add
          </Button>
        </Stack>

        {fields
          .slice()
          .reverse()
          .map((field, index) => {
            const currentIndex = fields.length - index - 1;
            return (
              <Stack
                direction={{ base: "column", xl: "row" }}
                width="100%"
                bgColor={"white"}
                borderRadius="md"
                spacing={4}
                px={4}
                py={2}
                key={field.id}
              >
                <Box w={{ base: "100%", md: "100%", xl: "2%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        ID
                      </FormLabel>
                    )}

                    {fields.length - index}
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "50%" }}>
                  <Controller
                    control={control}
                    name={`stockOperationItems.${currentIndex}.product`}
                    rules={{ required: "Select product" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { error }
                    }) => (
                      <FormControl
                        isInvalid={!!error}
                        id={`stockOperationItems.${currentIndex}.product`}
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        {index === 0 && (
                          <FormLabel
                            fontSize={{
                              base: "10",
                              md: "10",
                              lg: "10",
                              xl: "10",
                              "2xl": "12"
                            }}
                          >
                            Select Product
                          </FormLabel>
                        )}
                        <Select<ProductGroup, true, GroupBase<ProductGroup>>
                          key={`product${currentIndex}`}
                          name={name}
                          ref={ref}
                          onChange={(value) => {
                            handleProductSelect(onChange, value, currentIndex);
                          }}
                          onBlur={onBlur}
                          value={value}
                          options={RemoveSelectedProductFromAllProducts(
                            productOptions,
                            watch
                          )}
                          placeholder="Select Product"
                          closeMenuOnSelect={true}
                          size="md"
                        />
                        <FormErrorMessage>
                          {error && error.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Unit
                      </FormLabel>
                    )}
                    <Input
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                      _disabled={{
                        color: "blackAlpha.700",
                        cursor: "not-allowed"
                      }}
                      defaultValue={watch[currentIndex]?.product?.unit}
                      disabled={true}
                      placeholder="Product unit"
                    />
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "15%" }}>
                  <FormControl
                    isDisabled={
                      watch[currentIndex]?.product?.type !==
                        ProductTrackingTypeOptions.SERIALIZED &&
                      watch[currentIndex]?.product?.type !==
                        ProductTrackingTypeOptions.BATCH
                    }
                    isInvalid={
                      errors?.stockOperationItems?.[currentIndex]
                        ?.trackingNumber
                        ? true
                        : false
                    }
                  >
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Tracking Number
                      </FormLabel>
                    )}
                    <Input
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                      {...register(
                        `stockOperationItems.${currentIndex}.trackingNumber` as const,
                        {
                          validate: {
                            required: (value) => {
                              if (
                                watch[currentIndex]?.product?.type ===
                                  ProductTrackingTypeOptions.NONE ||
                                value !== ""
                              )
                                return true;
                              return "Give Tracking data";
                            }
                          }
                        }
                      )}
                      onKeyDown={(e) =>
                        handleKeyDown(e, currentIndex, "trackingNumber")
                      }
                      key={`tracking${currentIndex}`}
                      type={"string"}
                      placeholder="Tracking Number"
                    />
                    <FormErrorMessage>
                      {
                        errors?.stockOperationItems?.[currentIndex]
                          ?.trackingNumber?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "15%" }}>
                  <FormControl
                    isDisabled={
                      watch[currentIndex]?.product?.type !==
                        ProductTrackingTypeOptions.SERIALIZED &&
                      watch[currentIndex]?.product?.type !==
                        ProductTrackingTypeOptions.BATCH
                    }
                    isInvalid={
                      errors?.stockOperationItems?.[currentIndex]
                        ?.manufactureDate
                        ? true
                        : false
                    }
                  >
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Manufacturing Date
                      </FormLabel>
                    )}
                    <Input
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                      type="date"
                      {...register(
                        `stockOperationItems.${currentIndex}.manufactureDate` as const,
                        { valueAsDate: true }
                      )}
                      defaultValue={undefined}
                      // onKeyDown={(e) => handleKeyDown(e, currentIndex, "trackingNumber")}

                      key={`tracking${currentIndex}`}
                      placeholder="Tracking Number"
                    />
                    <FormErrorMessage>
                      {
                        errors?.stockOperationItems?.[currentIndex]
                          ?.manufactureDate?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "15%" }}>
                  <FormControl
                    isDisabled={
                      watch[currentIndex]?.product?.type !==
                        ProductTrackingTypeOptions.SERIALIZED &&
                      watch[currentIndex]?.product?.type !==
                        ProductTrackingTypeOptions.BATCH
                    }
                    isInvalid={
                      errors?.stockOperationItems?.[currentIndex]?.expiryDate
                        ? true
                        : false
                    }
                  >
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Expiry Date
                      </FormLabel>
                    )}
                    <Input
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                      type="date"
                      {...register(
                        `stockOperationItems.${currentIndex}.expiryDate` as const,
                        { valueAsDate: true }
                      )}
                      defaultValue={undefined}
                      // onKeyDown={(e) => handleKeyDown(e, currentIndex, "trackingNumber")}

                      key={`tracking${currentIndex}`}
                      placeholder="Expiry Data"
                    />
                    <FormErrorMessage>
                      {
                        errors?.stockOperationItems?.[currentIndex]?.expiryDate
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "15%" }}>
                  <FormControl
                    isInvalid={
                      errors?.stockOperationItems?.[currentIndex]?.quantity
                        ? true
                        : false
                    }
                  >
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Quantity
                      </FormLabel>
                    )}
                    <Input
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                      {...register(
                        `stockOperationItems.${currentIndex}.quantity` as const,
                        {
                          validate: {
                            required: (value) => {
                              if (
                                watch[currentIndex]?.product?.type ===
                                  ProductTrackingTypeOptions.SERIALIZED ||
                                value > 0
                              )
                                return true;
                              return "Give Amount";
                            }
                          }
                        }
                      )}
                      onKeyDown={(e) =>
                        handleKeyDown(e, currentIndex, "quantity")
                      }
                      key={`quantity${currentIndex}`}
                      type={"number"}
                      placeholder="Enter product quantity"
                      disabled={
                        watch[currentIndex]?.product?.type !==
                          ProductTrackingTypeOptions.NONE &&
                        watch[currentIndex]?.product?.type !==
                          ProductTrackingTypeOptions.BATCH
                      }
                      defaultValue={getQuantity(
                        watch[currentIndex]?.product?.type
                      )}
                    />
                    <FormErrorMessage>
                      {
                        errors?.stockOperationItems?.[currentIndex]?.quantity
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box w={{ base: "100%", md: "100%", xl: "8%" }}>
                  <FormControl mb="3">
                    <ButtonGroup>
                      <Button
                        mt={index === 0 ? 8 : 1}
                        colorScheme="red"
                        variant="outline"
                        leftIcon={<FaTimes />}
                        onClick={() => remove(currentIndex)}
                        //  isDisabled={index > 0 ? false : true}
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                        size="sm"
                      >
                        Remove
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </Box>
              </Stack>
            );
          })}
        <Stack spacing={4}>
          <Textarea
            placeholder="Enter notes for this Operation"
            {...register("notes")}
          />

          <Button
            width="100%"
            colorScheme="blue"
            onClick={initialInventoryConfirmAlert.onOpen}
          >
            Submit
          </Button>
          <ConfirmAlertComponent
            cancelRef={cancelRef}
            onClose={initialInventoryConfirmAlert.onClose}
            isOpen={initialInventoryConfirmAlert.isOpen}
            onOpen={initialInventoryConfirmAlert.onOpen}
            handleApprove={initialInventoryHandleSubmit}
            status={initialOperationResult}
          />
        </Stack>
      </form>
    </Stack>
  );
};
export default CmchInitialInventoryComponent;
