import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  useReactTable,
  FilterFn,
  ExpandedState,
  getExpandedRowModel,
  getPaginationRowModel
} from "@tanstack/react-table";
import {
  Box,
  Button,
  ButtonGroup,
  Select as SelectPageSize,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import { CSVLink } from "react-csv";

import { customPagination, displayAllTablePage } from "../../../config";
import { LocationInventory } from "../../../api/type";
import { TableSearchInput } from "../../core/components/table-search-component";
import { LocationInventoryByDatePrintComponent } from "../../print/components/location-inventory-by-date-print.component";
import { OriginLocationGroup } from "../types/stock-operation.type";

declare module "@tanstack/table-core" {
  interface FilterFns {
    tableFilter: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const tableFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank
  });
  return itemRank.passed;
};

const headers = [
  { label: "Product Id", key: "productId" },
  { label: "Product Name", key: "productName" },
  { label: "Unit", key: "unit" },
  { label: "Quantity", key: "quantity" }
];

interface PreviousInventoryTableComponentProps {
  previousInventory: LocationInventory[];
  location: OriginLocationGroup;
  reportDate: string | undefined;
}
const PreviousInventoryTableComponent: React.FC<
  PreviousInventoryTableComponentProps
> = (props: PreviousInventoryTableComponentProps) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: customPagination.pageIndex,
    pageSize: customPagination.pageSize
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const columns = useMemo<ColumnDef<LocationInventory, any>[]>(
    () => [
      {
        header: "ID",
        accessorFn: (row) => row.productId,
        id: "productId",
        cell: (row) => row.getValue()
      },
      {
        header: "Product Name",
        accessorFn: (row) => row.productName,
        id: "productName",
        cell: (row) => row.getValue()
      },
      {
        header: "Quantity",
        accessorFn: (row) => row.quantity,
        id: "quantity",
        cell: (row) => row.getValue()
      },
      {
        header: "Unit",
        accessorFn: (row) => row.unit,
        id: "unit",
        cell: (row) => row.getValue()
      }
    ],
    []
  );
  const table = useReactTable({
    data: props.previousInventory ? props.previousInventory : [],
    columns: columns,
    filterFns: {
      tableFilter
    },
    state: {
      pagination,
      globalFilter,
      expanded
    },
    onExpandedChange: setExpanded,
    //getSubRows: (row) => row.trackingData,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    globalFilterFn: tableFilter,
    debugTable: true
  });
  const renderSubComponent = (row: any) => {
    return (
      <Table variant="striped" size="sm">
        <Thead>
          <Tr bg="gray.500">
            <Th color="white">Tracking Id</Th>
            <Th color="white">Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {row.map((d: any, index: any) => (
            <Tr key={index}>
              <Td>{d.original.trackingId}</Td>
              <Td>{d.original.amount}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  };

  return (
    <Stack>
      <Stack>
        <Stack
          my="2"
          direction={{ base: "column", xl: "row" }}
          minWidth="max-content"
        >
          <Box>
            <TableSearchInput
              value={globalFilter ?? ""}
              onChange={(value) => setGlobalFilter(String(value))}
            />
          </Box>
          <Spacer />
          <ButtonGroup gap="2">
            {props.previousInventory ? (
              <CSVLink data={props.previousInventory} headers={headers}>
                <Button colorScheme="blue">Export CSV</Button>
              </CSVLink>
            ) : undefined}
          </ButtonGroup>
          <ButtonGroup gap="2">
            {props.previousInventory && (
              <LocationInventoryByDatePrintComponent
                printData={props.previousInventory}
                location={props.location}
                reportDate={props.reportDate}
              />
            )}
          </ButtonGroup>
        </Stack>
      </Stack>
      <Table variant="striped">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    bg="gray.700"
                    color="white"
                  >
                    {header.isPlaceholder ? null : (
                      <Box>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Box>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Fragment key={row.id}>
                <Tr>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
                {row.getIsExpanded() && (
                  <Tr>
                    <Td colSpan={row.getVisibleCells().length}>
                      {renderSubComponent(row.subRows)}
                    </Td>
                  </Tr>
                )}
              </Fragment>
            );
          })}
        </Tbody>
      </Table>

      <Stack>
        <Stack
          direction={{ base: "column", lg: "row" }}
          justify="space-between"
          alignItems="center"
          mt="2"
        >
          <Box width={{ base: "100%", lg: "70%" }}>
            <Text fontSize="lg" fontWeight="bold">
              Showing (Page {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()})
            </Text>{" "}
          </Box>
          <Stack
            direction="row"
            width={{ base: "100%", lg: "30%" }}
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              width={{ base: "50%", md: "70%", lg: "80%" }}
            >
              <Button
                colorScheme="blue"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {"<<"}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {"<"}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {">"}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {">>"}
              </Button>{" "}
            </Stack>

            <Box width={{ base: "50%", md: "30%", lg: "20%" }}>
              <SelectPageSize
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {displayAllTablePage.map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </SelectPageSize>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PreviousInventoryTableComponent;
