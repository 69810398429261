import React from "react";
import { AllLocationsComponent } from "../components/all-locations.component";

interface AllLocationsPageProps {}

export const AllLocationsPage: React.FC<AllLocationsPageProps> = (
  props: AllLocationsPageProps
) => {
  return (
    <>
      <AllLocationsComponent />
    </>
  );
};
