import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface StockOperationItems {
  quantity: number;
  product: {
    name: string;
    ownerDepartment: string;
    unit: string;
  };
}

interface DataType {
  createdAt?: string;
  createdBy?: {
    name: string;
  };
  originLocation?: {
    name: string;
  };
  destinationLocation?: {
    name: string;
  };
  productName?: string;
  quantity?: number;
  unit?: string;
  operationStatus?: string;
  operationType?: string;
  stockOperationItems?: StockOperationItems[];
}
interface GroupType {
  title: string;
}

export interface CustomReportState {
  customReport: {
    data: {
      query: {
        report: {
          endDate: string;
          groupBy: string;
          location: number[];
          product: number[];
          reportType: string;
          startDate: string;
        };
      };
      report: {
        data: [DataType[]];
        groups: GroupType[];
        metaData: {
          groupType: string;
        };
      };
    };
  };
}

const initialState: CustomReportState = {
  customReport: {
    data: {
      query: {
        report: {
          endDate: "",
          groupBy: "",
          location: [],
          product: [],
          reportType: "",
          startDate: ""
        }
      },
      report: {
        data: [[]],
        groups: [],
        metaData: {
          groupType: ""
        }
      }
    }
  }
};

export const customReportSlice = createSlice({
  name: "customReport",
  initialState,
  reducers: {
    customReport: (state, action: PayloadAction<any>) => {
      state.customReport = action.payload;
    }
  }
});

export const { customReport } = customReportSlice.actions;

export default customReportSlice.reducer;
