import React from "react";
import { AssignUserRoleComponent } from "../components/assign-user-role.component";

interface AssignUserRolePageProps {}

export const AssignUserRolePage: React.FC<AssignUserRolePageProps> = (
  props: AssignUserRolePageProps
) => {
  return (
    <div>
      <AssignUserRoleComponent />
    </div>
  );
};
