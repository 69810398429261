import React from "react";
import CmchDemandInventoryComponent from "../components/cmch/cmch-demand-inventory.component";
import DemandInventoryComponent from "../components/demand-inventory.component";
import { env } from '../../../config/index';
import { allUIType } from "../../../api/type";

interface DemandInventoryPageProps { }

export const DemandInventoryPage: React.FC<DemandInventoryPageProps> = (
  props: DemandInventoryPageProps
) => {
  return (
    <>
      {
        env.uiType === allUIType.CMCH ? <CmchDemandInventoryComponent /> : <DemandInventoryComponent />
      }

    </>
  );
};
