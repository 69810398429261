import React, { useRef } from "react";
import { useEffect, useState } from "react";
import {
    Stack,
    Button,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    ButtonGroup,
    useToast,
    useDisclosure,
    Flex,
    Box
} from "@chakra-ui/react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Select, GroupBase } from "chakra-react-select";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { PageCardComponent } from "../../../core/components/page-card.component";
import {
    ProductGroup,
    StockOperationItems,
    fieldNameType,
    ENTER_KEY
} from "../../types/stock-operation.type";
import {
    useGetAllOperationDetailsQuery,
    useGetFilterProductQuery,
    useTransferEditMutation
} from "../../../../api";
import {
    chakraStyles,
    RemoveSelectedProductFromAllProducts,
    userCurrentRole
} from "../../../../utils/common-functions";
import { ConfirmAlertComponent } from "./../confirm-alert.component";
import { IoMdArrowRoundBack } from "react-icons/io";
import { ProductTrackingTypeOptions } from "../../../../api/type";

type DemandFulfilFormValues = {
    stockOperationItems: StockOperationItems[];
};

interface CmchEditTransferComponentProps { }

interface deletedStockOperationItemType {
    id: number;
}

export const CmchEditTransferComponent: React.FC<CmchEditTransferComponentProps> = (
    props: CmchEditTransferComponentProps
) => {
    let locationId = localStorage.getItem("location");
    const { operationId } = useParams();
    const navigate = useNavigate();
    const role = userCurrentRole();
    const editTransferConfirmAlert = useDisclosure();
    const cancelRef = useRef();
    const toast = useToast();

    const { data } = useGetAllOperationDetailsQuery({
        data: { report: { operationId: Number(operationId) } }
    });

    const [updateStockOperationItem, setUpdateStockOperationItem] = useState<
        StockOperationItems[]
    >([]);

    const [deletedStockItems, setDeletedStockItems] = useState<
        deletedStockOperationItemType[]
    >([]);

    const getProductQuery = useGetFilterProductQuery({ id: Number(locationId) });

    const [productOptions, setProductOptions] = useState<ProductGroup[]>([]);

    useEffect(() => {
        if (getProductQuery.data?.data.products) {
            const productWithValues = getProductQuery.data?.data.products.map(
                (product) => {
                    return {
                        label: product.name,
                        value: product.id,
                        unit: product.unit,
                        type: product.type
                    };
                }
            );
            setProductOptions(productWithValues);
        }
    }, [getProductQuery.data]);

    const [transferEdit, transferEditResponse] = useTransferEditMutation();

    const {
        register,
        handleSubmit,
        control,
        setValue,
        setFocus,
        getValues,
        formState: { errors }
    } = useForm<DemandFulfilFormValues>({
        defaultValues: {
            stockOperationItems: updateStockOperationItem
        }
    });

    const { fields, append, remove } = useFieldArray<
        DemandFulfilFormValues,
        "stockOperationItems"
    >({
        control,
        name: "stockOperationItems"
    });

    useEffect(() => {
        if (data?.data.operationDetails.stockOperationItems.length) {
            let allItems = data?.data.operationDetails.stockOperationItems.map(
                (item) => {
                    return {
                        product: {
                            label: item.product.name,
                            value: item.product.id,
                            unit: item.product.unit,
                            type: item.product.trackingType,
                            itemId: item.id
                        },
                        trackingNumber: item.trackingNumber,
                        quantity: item.quantity
                    };
                }
            );
            setUpdateStockOperationItem(allItems);
        }
    }, [data]);

    useEffect(() => {
        setValue("stockOperationItems", updateStockOperationItem);
    }, [updateStockOperationItem, setValue]);

    const watch = useWatch({
        control,
        name: "stockOperationItems"
    });

    const handleEditTransferSubmit = handleSubmit((formData) => {
        const operationDetails = data?.data.operationDetails;
        const allStockOperationItems = formData.stockOperationItems.map((singleItem) => {
            if (singleItem.product.type === ProductTrackingTypeOptions.SERIALIZED) {
                return {
                    id: singleItem.product.itemId,
                    productId: singleItem.product.value,
                    quantity: 1,
                    trackingNumber: singleItem.trackingNumber
                };

            } else if (singleItem.product.type === ProductTrackingTypeOptions.BATCH) {
                return {
                    id: singleItem.product.itemId,
                    productId: singleItem.product.value,
                    quantity: Number(singleItem.quantity),
                    trackingNumber: singleItem.trackingNumber
                }
            }

            return {
                id: singleItem.product.itemId,
                productId: singleItem.product.value,
                quantity: Number(singleItem.quantity),
                trackingNumber: ""
            }

        })

        if (operationDetails) {
            transferEdit({
                data: {
                    stockOperation: {
                        id: operationDetails.id,
                        operationStatus: operationDetails.operationStatus,
                        deletedStockOperationItems: deletedStockItems,
                        stockOperationItems: allStockOperationItems
                    }
                }
            });
        }
    });

    const removeItem = (index: number, field: any) => {
        if (field?.product?.itemId) {
            setDeletedStockItems((pre) => [...pre, { id: field.product.itemId }]);
        } else if (field.id) {
            remove(index);
        }
        remove(index);
    };

    useEffect(() => {
        if (transferEditResponse.isSuccess) {
            toast({
                title: "Success",
                description: "Update Transfer successfully",
                status: "success",
                position: "top",
                duration: 2000,
                isClosable: true
            });
            navigate(`/${role}/stock-operation/pending-operation`);
        }

    }, [transferEditResponse, toast, navigate, role]);

    const handleKeyDown = (event: any, index: number, fildName: string) => {
        if (event.keyCode !== ENTER_KEY) return;
        const lastIndex = fields.length - 1;
        const nextIndex = index + 1;

        let currentProductType = watch[index].product.type;

        if (index === lastIndex && currentProductType === ProductTrackingTypeOptions.SERIALIZED && fildName === 'trackingNumber') {

            append({});
            setTimeout(() => {
                focusElement(nextIndex, "product");
            }, 100)
        } else if (index === lastIndex && fildName === 'quantity') {

            append({});
            setTimeout(() => {
                focusElement(nextIndex, "product");
            }, 100)
        } else if (currentProductType === ProductTrackingTypeOptions.NONE && fildName === 'quantity') {
            focusElement(nextIndex);
        } else if (currentProductType === ProductTrackingTypeOptions.SERIALIZED && fildName === 'trackingNumber') {
            focusElement(nextIndex);
        } else if (currentProductType === ProductTrackingTypeOptions.BATCH && fildName === 'trackingNumber') {
            focusElement(index, "quantity");
        } else if (currentProductType === ProductTrackingTypeOptions.BATCH && fildName === 'quantity') {
            focusElement(nextIndex);
        }



    }
    function focusElement(index: number, fieldName?: fieldNameType) {

        if (fieldName) {
            setFocus(`stockOperationItems.${index}.${fieldName}`)
            return;
        }

        let selectedProduct = watch[index].product;
        if (!selectedProduct) {
            setFocus(`stockOperationItems.${index}.product`)
            return;
        }
        let productType = selectedProduct.type;
        if (productType !== ProductTrackingTypeOptions.NONE) {
            setFocus(`stockOperationItems.${index}.trackingNumber`);
            return;
        }
        setFocus(`stockOperationItems.${index}.quantity`)



    }

    const handleProductSelect = (OnChange: any, value: any, index: number) => {
        OnChange(value);
        setTimeout(() => {
            if (value.type) {
                value.type === ProductTrackingTypeOptions.NONE ? setFocus(`stockOperationItems.${index}.quantity`) : setFocus(`stockOperationItems.${index}.trackingNumber`)
            }
        }, 10)


    }
    const [componetMount, setComponetMount] = useState(false);
    useEffect(() => {
        setComponetMount(true);

    }, []);
    useEffect(() => {
        if (componetMount) {
            setTimeout(() => {
                if (getValues("stockOperationItems.0.product").type === ProductTrackingTypeOptions.SERIALIZED || getValues("stockOperationItems.0.product").type === ProductTrackingTypeOptions.BATCH) {
                    focusElement(0, "trackingNumber");

                } else {
                    focusElement(0, "quantity");
                }
            }, 2000)



        }

    }, [componetMount]);
    const handleAddItem = () => {
        const currentindex = fields.length - 1;
        const nextIndex = currentindex + 1;
        append({});

        setTimeout(() => {
            setFocus(`stockOperationItems.${nextIndex}.product`)
        }, 10)

    }
    const getQuantity = (productType: any) => {
        if (productType === ProductTrackingTypeOptions.SERIALIZED) {
            return 1;
        }
        return ""

    }

    return (
        <Stack>
            <Flex justifyContent="end">
                <Button
                    colorScheme="blue"
                    leftIcon={<IoMdArrowRoundBack />}
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
            </Flex>
            <form onSubmit={handleEditTransferSubmit}>
                <Stack
                    direction={{ base: "column", xl: "row" }}
                    justify="space-between"
                >
                    <PageCardComponent>
                        <Input
                            _disabled={{ color: "blackAlpha.900", cursor: "not-allowed" }}
                            defaultValue={data?.data.operationDetails.originLocation.name}
                            isDisabled={true}
                        />
                    </PageCardComponent>

                    <PageCardComponent>
                        <Input
                            _disabled={{ color: "blackAlpha.900", cursor: "not-allowed" }}
                            defaultValue={
                                data?.data.operationDetails.destinationLocation.name
                            }
                            isDisabled={true}
                        />
                    </PageCardComponent>
                </Stack>

                {fields.map((field, index) => {
                    return (
                        <Stack
                            direction={{ base: "column", xl: "row" }}
                            width="100%"
                            bgColor={"white"}
                            borderRadius="md"
                            spacing={4}
                            px="4"
                            py="2"
                            key={field.id}
                            mt="2"
                        >
                            <Box w={{ base: "100%", md: "100%", xl: "5%" }} key={index}>
                                <FormControl>
                                    {index === 0 && <FormLabel fontSize={{
                                        base: "10",
                                        md: "10",
                                        lg: "10",
                                        xl: "10",
                                        "2xl": "12"
                                    }}>ID</FormLabel>}

                                    {index + 1}
                                </FormControl>
                            </Box>
                            <Box w={{ base: "100%", md: "100%", xl: "40%" }} >
                                <Controller
                                    control={control}
                                    name={`stockOperationItems.${index}.product`}
                                    rules={{ required: "Select product" }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { error }
                                    }) => (
                                        <FormControl
                                            isInvalid={!!error}
                                            id={`stockOperationItems.${index}.product`}
                                        >
                                            {index === 0 && <FormLabel>Select Product</FormLabel>}
                                            <Select
                                                chakraStyles={chakraStyles}
                                                name={name}
                                                ref={ref}
                                                onChange={(value) => { handleProductSelect(onChange, value, index) }}

                                                onBlur={onBlur}
                                                isDisabled={watch[index]?.product?.itemId ? true : false}
                                                value={value}
                                                options={RemoveSelectedProductFromAllProducts(
                                                    productOptions,
                                                    watch
                                                )}
                                                placeholder="Select product"
                                                closeMenuOnSelect={true}

                                            />
                                            <FormErrorMessage>
                                                {error && error.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                />
                            </Box>
                            <Box w={{ base: "100%", md: "100%", xl: "10%" }} >
                                <FormControl>
                                    {index === 0 && <FormLabel>Unit</FormLabel>}
                                    <Input
                                        _disabled={{ color: "blackAlpha.900", cursor: "not-allowed" }}
                                        defaultValue={watch[index]?.product?.unit}
                                        disabled={true}
                                        placeholder="Product unit"
                                    />
                                </FormControl>
                            </Box>
                            <Box w={{ base: "100%", md: "100%", xl: "15%" }} >
                                <FormControl
                                    isDisabled={watch[index]?.product?.type !== ProductTrackingTypeOptions.SERIALIZED && watch[index]?.product?.type !== ProductTrackingTypeOptions.BATCH}
                                    isInvalid={
                                        errors?.stockOperationItems?.[index]?.trackingNumber ? true : false
                                    }
                                >
                                    {index === 0 && <FormLabel>Tracking Number</FormLabel>}
                                    <Input
                                        {...register(
                                            `stockOperationItems.${index}.trackingNumber` as const,
                                            {
                                                validate: {
                                                    required: value => {
                                                        if (watch[index]?.product?.type === ProductTrackingTypeOptions.NONE || value !== "") return true
                                                        return "Give Tracking data";
                                                    },
                                                }
                                            }

                                        )}

                                        key={`tracking${index}`}
                                        type={"string"}
                                        placeholder="Tracking Number"
                                        onKeyDown={(e) => handleKeyDown(e, index, "trackingNumber")}
                                    />
                                    <FormErrorMessage>
                                        {
                                            errors?.stockOperationItems?.[index]?.trackingNumber?.message
                                        }
                                    </FormErrorMessage>
                                </FormControl>
                            </Box>
                            <Box w={{ base: "100%", md: "100%", xl: "15%" }} >

                                <FormControl
                                    mb="3"
                                    isInvalid={
                                        errors?.stockOperationItems?.[index]?.quantity ? true : false
                                    }

                                >
                                    {index === 0 && <FormLabel>Quantity</FormLabel>}
                                    <Input
                                        {...register(
                                            `stockOperationItems.${index}.quantity` as const,
                                            {
                                                validate: {
                                                    required: value => {

                                                        if (watch[index]?.product?.type === ProductTrackingTypeOptions.SERIALIZED || value > 0) return true
                                                        return "Give Tracking data";
                                                    },
                                                }
                                            }
                                        )}
                                        type={"number"}
                                        placeholder="Enter product quantity"
                                        disabled={watch[index]?.product?.type !== ProductTrackingTypeOptions.NONE && watch[index]?.product?.type !== ProductTrackingTypeOptions.BATCH}
                                        onKeyDown={(e) => handleKeyDown(e, index, "quantity")}
                                        key={`quantity${index}`}
                                        defaultValue={getQuantity(watch[index]?.product?.type)}

                                    />
                                    <FormErrorMessage>
                                        {errors?.stockOperationItems?.[index]?.quantity?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </Box>
                            <Box w={{ base: "100%", md: "100%", xl: "10%" }} >

                                <ButtonGroup>
                                    <Button
                                        mt={index === 0 ? 8 : 0}
                                        colorScheme="red"
                                        variant="outline"
                                        leftIcon={<FaTimes />}
                                        onClick={() => removeItem(index, field)}

                                    >
                                        Remove
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Stack>
                    );
                })}

                <Button
                    my="2"
                    width="100%"
                    colorScheme="blue"
                    variant="outline"
                    leftIcon={<FaPlus />}
                    onClick={() => handleAddItem()}
                >
                    Add
                </Button>

                <Button
                    width="100%"
                    colorScheme="blue"
                    onClick={editTransferConfirmAlert.onOpen}
                >
                    Submit
                </Button>
                <ConfirmAlertComponent
                    cancelRef={cancelRef}
                    onClose={editTransferConfirmAlert.onClose}
                    isOpen={editTransferConfirmAlert.isOpen}
                    onOpen={editTransferConfirmAlert.onOpen}
                    handleApprove={handleEditTransferSubmit}
                    status={transferEditResponse}
                />
            </form>
        </Stack>
    );
};
