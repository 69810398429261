import React, { useEffect, useState } from "react";
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import { currentSelectedExpairyProduct } from "../types/stock-operation.type";
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useUpdateExpiryDateAndManufactureDateMutation } from "../../../api";

interface ExpiryDateModalComponentProps {
    cancelRef: any;
    isOpen: any;
    onOpen: any;
    onClose: any;
    product: currentSelectedExpairyProduct
}

export const ExpiryDateModalComponent: React.FC<ExpiryDateModalComponentProps> = (props: ExpiryDateModalComponentProps) => {
    const toast = useToast();
    const [expiryDate, setExpiryDate] = useState<Date | null>();
    const [manufactorDate, setManufactorDate] = useState<Date | null>();
    const [updateExpAndManDate, updateExpAndManDateResult] = useUpdateExpiryDateAndManufactureDateMutation()

    useEffect(() => {
        if (props.product.expiryDate) {

            setExpiryDate(new Date(props.product.expiryDate))
        } else {
            setExpiryDate(null)
        }

    }, [props.product.expiryDate])

    useEffect(() => {
        if (props.product.manufactureDate) {

            setManufactorDate(new Date(props.product.manufactureDate))
        } else {
            setManufactorDate(null)
        }

    }, [props.product.manufactureDate])


    const changeExpiryDate = (e: Date) => {
        setExpiryDate(e);
    }
    const changeManufactorDate = (e: Date) => {
        setManufactorDate(e);
    }

    const formSubmit = () => {
        let data: any = {
            productId: props.product.productId,
            trackingNumber: props.product.tranckingId,
        }
        if (expiryDate) {
            data["expiryDate"] = expiryDate.toISOString()
        }
        if (manufactorDate) {
            data["manufactureDate"] = manufactorDate.toISOString()
        }
        updateExpAndManDate({
            data: data

        })
        props.onClose();
    }

    useEffect(() => {
        if (updateExpAndManDateResult.isSuccess) {
            toast({
                title: "Success",
                description: "date Update was successfull.",
                status: "success",
                position: "top",
                duration: 2000,
                isClosable: true
            });


        }
    }, [updateExpAndManDateResult])

    return (<Stack>

        <Modal
            motionPreset="slideInBottom"
            onClose={props.onClose}
            isOpen={props.isOpen}
            size="6xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Update Product ExpiryDate and Manufacture Date</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>Product name</FormLabel>
                        <Input value={props.product.productName} isDisabled />
                    </FormControl>
                    <FormControl>
                        <FormLabel>TrackingData</FormLabel>
                        <Input placeholder='First name' value={props.product.tranckingId} isDisabled />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Manufacture Date</FormLabel>
                        <DatePicker selected={manufactorDate ? new Date(manufactorDate) : undefined} onChange={(date: Date) => changeManufactorDate(date)} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Expiry Date</FormLabel>
                        <DatePicker selected={expiryDate ? new Date(expiryDate) : undefined} onChange={(date: Date) => changeExpiryDate(date)} />
                    </FormControl>


                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={() => formSubmit()}>
                        Save
                    </Button>
                    <Button onClick={props.onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
        {/* <Button variant="outline" onClick={() => props.onOpen()}>
            Details
        </Button> */}
    </Stack>);
}