import React from "react";
import dayjs from "dayjs";
import { useGetOperationPrintDataQuery } from "../../../api";
import { OperationPrintComponent } from "./operation-print.component";
import { Button, Spinner, useDisclosure } from "@chakra-ui/react";
import { PdfPrinterModal } from "../../core/components/pdf-printer-modal.component";

interface OperationDetailsPrintDownloadProps {
  id: number;
}

export const OperationDetailsPrintDownload: React.FC<
  OperationDetailsPrintDownloadProps
> = (props: OperationDetailsPrintDownloadProps) => {
  const opearitonID = props.id;

  const { data } = useGetOperationPrintDataQuery({
    data: { report: { operationId: Number(opearitonID) } }
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = data?.data ? (
    <OperationPrintComponent printData={data} />
  ) : (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
    />
  );

  const operationDetails = `Operation-Details-${data?.data.operationDetails.id}.pdf`;

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={operationDetails}
      />
    </>
  );
};
