import React from "react";
import { UserProfileComponent } from "../components/user-profile.component";

interface UserProfilePageProps {}

export const UserProfilePage: React.FC<UserProfilePageProps> = (
  props: UserProfilePageProps
) => {
  return (
    <div>
      <UserProfileComponent />
    </div>
  );
};
