import {
  Stack,
  Button,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableContainer
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { PageCardComponent } from "../../core/components/page-card.component";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Pie } from "react-chartjs-2";
import {
  useGetProductDetailsQuery,
  useGetProductLocationInventoryQuery
} from "../../../api";

import { FaArrowLeft } from "react-icons/fa";
import { useEffect, useState } from "react";

interface DetailProductComponentProps { }

export const DetailProductComponent: React.FC<DetailProductComponentProps> = (
  props: DetailProductComponentProps
) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useGetProductDetailsQuery({
    data: {
      product: {
        id: Number(id)
      }
    }
  });

  const tableValues = useGetProductLocationInventoryQuery({
    data: {
      product: {
        id: Number(id)
      }
    }
  });

  const backButtonHandler = () => {
    navigate(-1);
  };

  ChartJS.register(ArcElement, Tooltip, Legend);

  const [pieData, setPieData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: []
      }
    ]
  });

  useEffect(() => {
    const rgb1 = "rgba(255, 99, 132)";
    const rgb2 = "rgba(255, 206, 86)";
    const rgb3 = "rgba(153, 102, 255)";
    const rgb4 = "rgba(54, 162, 235)";

    let tempPieData = {
      labels: [],
      datasets: [
        {
          label: data?.data.product.name,
          data: [],
          backgroundColor: [rgb1, rgb2, rgb3, rgb4]
        }
      ]
    };

    let count = 3;
    let otherLocationQuantity = 0;

    tableValues.data?.data.map((value) => {
      //@ts-ignore
      const locationName = value.location.name;
      //@ts-ignore
      const quantity = parseInt(value.quantity);
      if (count > 0) {
        //@ts-ignore
        tempPieData.labels.push(locationName);
        //@ts-ignore
        tempPieData.datasets[0].data.push(quantity);
      } else otherLocationQuantity += quantity;

      count--;
    });

    //@ts-ignore
    tempPieData.labels.push("Other Locations");
    //@ts-ignore
    tempPieData.datasets[0].data.push(otherLocationQuantity);
    //@ts-ignore
    setPieData(tempPieData);
  }, [tableValues]);

  return (
    <Stack spacing={"2"}>
      <Stack
        direction={{ base: "column", xl: "row" }}
        justify="space-between"
        my="2"
      >
        <PageCardComponent>
          <Stat>
            <StatLabel>Product Name</StatLabel>
            <StatNumber>{data?.data.product.name}</StatNumber>
            {data?.data.product.medicineGenericId && <StatHelpText>{data?.data.product.medicineGeneric?.genericName}</StatHelpText>}
          </Stat>
        </PageCardComponent>

        <PageCardComponent>
          <Stat>
            <StatLabel>Product Unit</StatLabel>
            <StatNumber> {data?.data.product.unit}</StatNumber>
            <StatHelpText>The unit this product is recoreded in</StatHelpText>
          </Stat>
        </PageCardComponent>

        <PageCardComponent>
          <Stat>
            <StatLabel>Product Tracking Type</StatLabel>
            <StatNumber>{data?.data.product.trackingType}</StatNumber>
            <StatHelpText>
              How the product is tracked in the software
            </StatHelpText>
          </Stat>
        </PageCardComponent>
      </Stack>
      <Stack
        direction={{ base: "column", xl: "row" }}
        justify="space-between"
        my="2"
      >
        <PageCardComponent>
          <Stat>
            <StatLabel>Notes</StatLabel>
            <StatHelpText>{data?.data.product.notes}</StatHelpText>
          </Stat>
        </PageCardComponent>


      </Stack>

      <Stack
        direction={{ base: "column", xl: "row" }}
        justify="space-between"
        my="2"
      >
        <Stack width={"100%"}>
          <TableContainer>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Location Name</Th>
                  <Th isNumeric>Quantity Available</Th>
                </Tr>
              </Thead>
              <Tbody>
                {tableValues.data?.data.map((value) => {
                  return (
                    <Tr>
                      <Td>{value.location.name}</Td>
                      <Td isNumeric>{parseInt(value.quantity)}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        {/* This is where the chart should go */}
        <Stack width={"70%"} px={20} py={5}>
          <Pie
            data={pieData}
            width={"5em"}
            options={{ maintainAspectRatio: true }}
          />
        </Stack>
      </Stack>

      <Stack width="100%">
        <Button
          type="submit"
          leftIcon={<FaArrowLeft />}
          colorScheme="blue"
          onClick={backButtonHandler}
        >
          Go Back
        </Button>
      </Stack>
    </Stack>
  );
};
