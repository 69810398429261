// Styling and theming
import { ChakraProvider } from "@chakra-ui/react";
import theme from "../theme/chakra";

// Routes
import { BrowserRouter } from "react-router-dom";
import { AppRoute } from "../routes/app.route";

// State
import { Provider as StateProvider } from "react-redux";
import { store } from "../store";

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <StateProvider store={store}>
        <BrowserRouter>
          <AppRoute />
        </BrowserRouter>
      </StateProvider>
    </ChakraProvider>
  );
};
