import React from "react";
import AddLocationComponent from "../components/add-location.component";

interface AddLocationsPageProps {}

export const AddLocationsPage: React.FC<AddLocationsPageProps> = (
  props: AddLocationsPageProps
) => {
  return (
    <>
      <AddLocationComponent />
    </>
  );
};

export default AddLocationsPage;
