import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button
} from "@chakra-ui/react";

interface ConfirmAlertComponentProps {
  operationType?: string;
  cancelRef: any;
  isOpen: any;
  onOpen: any;
  onClose: any;
  handleApprove: any;
  status: any;
}

export const ConfirmAlertComponent: React.FC<ConfirmAlertComponentProps> = (
  props: ConfirmAlertComponentProps
) => {
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={props.cancelRef}
      onClose={props.onClose}
      isOpen={props.isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>Confirmation</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          {`Are you sure you want to ${
            props.operationType ? props.operationType : "proceed"
          } this Operation ?`}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button onClick={props.onClose}>No</Button>
          <Button
            ml={3}
            colorScheme="green"
            loadingText="Proceeding"
            isLoading={props.status.isLoading ? true : false}
            onClick={props.handleApprove}
          >
            {`Yes, ${props.operationType ? props.operationType : "Proceed"}`}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
