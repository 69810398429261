import { PDFDownloadLink } from "@react-pdf/renderer";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useGetDemandPrintDataQuery } from "../../../api";
import { GetDemandPrintDataResponse } from "../../../api/type";
import { InventoryDemandPrintPage } from "./inventory-demand-print.page";

interface InventoryDemandPrintComponentProps {
  id: number;
}

export const InventoryDemandPrintComponent: React.FC<
  InventoryDemandPrintComponentProps
> = (props: InventoryDemandPrintComponentProps) => {
  const { data } = useGetDemandPrintDataQuery({
    data: { report: { demandStockOperationId: Number(props.id) } }
  });

  const [printData, setPrintData] = useState<GetDemandPrintDataResponse>();
  useEffect(() => {
    setPrintData(data);
  }, [data]);

  const demandHistory = `Demand-History-${
    printData?.originLocation.name
  }-${dayjs().format("DD-MM-YYYY")}.pdf`;

  return (
    <>
      {printData && (
        <PDFDownloadLink
          document={
            <InventoryDemandPrintPage
              printData={printData}
              originLocationName={printData.originLocation.name}
            />
          }
          fileName={demandHistory}
        >
          {({ blob, url, loading, error }) =>
            loading && data ? "Loading document..." : "Print Report"
          }
        </PDFDownloadLink>
      )}
    </>
  );
};
