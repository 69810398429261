import React, { useEffect, useState } from "react";
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import { EditTrackingNumber } from "../types/stock-operation.type";
import "react-datepicker/dist/react-datepicker.css";
import { useUpdateProductTrackingNumberMutation } from "../../../api";


interface TrackingDataEditModalComponetProps {
    cancelRef: any;
    isOpen: any;
    onOpen: any;
    onClose: any;
    product: EditTrackingNumber
}

export const TrackingDataEditModalComponet: React.FC<TrackingDataEditModalComponetProps> = (props: TrackingDataEditModalComponetProps) => {
    const toast = useToast();

    const [newTrackignId, setNewTrackingId] = useState<string>();
    const [updateTrackingNumber, updateTrackingNumberResult] = useUpdateProductTrackingNumberMutation()



    const formSubmit = () => {

        if (newTrackignId && newTrackignId.length > 0) {
            updateTrackingNumber({
                data: {
                    productId: props.product.productId,
                    oldTrackingNumber: props.product.trackingNumber,
                    newTrackingNumber: newTrackignId,
                    trackingType: props.product.trackingType
                }
            })
        }

    }

    useEffect(() => {
        if (updateTrackingNumberResult.isSuccess) {
            toast({
                title: "Success",
                description: `tracking Number ${props.product.trackingNumber} ==> ${newTrackignId}`,
                status: "success",
                position: "top",
                duration: 2000,
                isClosable: true
            });
            props.onClose();
        }
    }, [updateTrackingNumberResult])
    return (<Stack>
        <Modal
            motionPreset="slideInBottom"
            onClose={props.onClose}
            isOpen={props.isOpen}
            size="6xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Update Product ExpiryDate and Manufacture Date</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>Product name</FormLabel>
                        <Input value={props.product.productName}
                            color="blue.500"
                            variant="unstyled"
                            colorScheme="blue"
                            isDisabled
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Old Tracking Number</FormLabel>
                        <Input placeholder='Old Tracking Number' value={props.product.trackingNumber} isDisabled
                            color="red.500"
                            variant="unstyled"
                            colorScheme="blue" />
                    </FormControl>
                    <FormControl>
                        <FormLabel>New Tracking Number</FormLabel>
                        <Input placeholder='Give New Tracking Number' onChange={(e) => setNewTrackingId(e.target.value)} />
                    </FormControl>


                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={() => formSubmit()}>
                        Save
                    </Button>
                    <Button onClick={props.onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Stack>);
}