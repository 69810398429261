import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { api } from "../api";
import { invalidateAuth } from "../api/invalidate-auth.middleware";
import { rtkqLogger } from "../api/logger.middleware";
import { rtkqToast } from "../api/toast.middleware";
import customReportSlice from "../modules/report/state/custom-report.slice";
import reportSlice from "../modules/report/state/report.slice";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    customReport: customReportSlice,
    report: reportSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware,
      rtkqToast,
      rtkqLogger,
      invalidateAuth
    )
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
