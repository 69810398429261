import { Box, Button, Select, Stack, Text } from "@chakra-ui/react";
import { Table } from "@tanstack/react-table";
import React from "react";
import { displayAllTablePage } from "../../../config";
import { displayPageNumberToStirng } from "../../../utils/tablePageNumberShow.util";

interface PaginationBottomComponentProps {
  table: Table<unknown>;
  pageIndex: number;
  pageSize: number;
  count: number | undefined;
}

export const PaginationBottomComponent: React.FC<
  PaginationBottomComponentProps
> = ({ table, pageIndex, pageSize, count }: PaginationBottomComponentProps) => {
  const displayPageNumber: string = displayPageNumberToStirng(
    pageIndex,
    pageSize,
    count,
    table.getState().pagination.pageIndex,
    table.getPageCount()
  );
  return (
    <div>
      <Stack>
        <Stack
          direction={{ base: "column", lg: "row" }}
          justify="space-between"
          alignItems="center"
          mt="1"
        >
          <Box width={{ base: "100%", lg: "60%" }}>
            <Text fontSize="lg" fontWeight="bold">
              {displayPageNumber}
            </Text>{" "}
          </Box>

          <Stack
            direction="row"
            width={{ base: "100%", lg: "40%" }}
            justifyContent="space-between"
          >
            <Stack direction="row" width={{ lg: "80%" }}>
              <Button
                colorScheme="blue"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {"<<"}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {"<"}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {">"}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {">>"}
              </Button>
            </Stack>

            <Box width={{ base: "100%", md: "50%", lg: "100%" }}>
              <Select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {displayAllTablePage.map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Select>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};
