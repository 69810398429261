// Fonts

import "@fontsource/poppins";
import "@fontsource/poppins";

const fonts = {
  heading: "Poppins",
  body: "Poppins"
};

export default fonts;
