import { Button, useDisclosure } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { DailyProductReportResponse } from "../../../../api/type";
import { PdfPrinterModal } from "../../../core/components/pdf-printer-modal.component";
import { CmchDailyReportPrintComponent } from "./cmch-daily-report-print.component";

interface CmchDailyProductReportPdfComponentProps {
  printData: DailyProductReportResponse;
}

export const CmchDailyProductReportPdfComponent: React.FC<
  CmchDailyProductReportPdfComponentProps
> = (props: CmchDailyProductReportPdfComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = (
    <CmchDailyReportPrintComponent printData={props.printData} />
  );

  const dailyReport = `Daily-Report-${
    props.printData.data.location.name
  }-${dayjs(props.printData.data.startDate).format("DD-MM-YY")}.pdf`;
  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={dailyReport}
      />
    </>
  );
};
