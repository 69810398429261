import { FaClipboardList, FaServer, FaUserFriends } from "react-icons/fa";
import { IDashboard } from "../route.type";
import DashboardPage from "../../modules/dashboard/pages/dashboard.page";
import { TransferPage } from "../../modules/stock-operation/pages/transfer.page";
import { DemandInventoryPage } from "../../modules/stock-operation/pages/demand-inventory.page";
import { PendingOperationPage } from "../../modules/stock-operation/pages/pending-operation.page";
import { LogoComponent } from "../../branding/logo.component";
import { UserProfilePage } from "../../modules/user/pages/user-profile.page";
import OperationDetailsPage from "../../modules/report/pages/all-operation-details.page";
import MonthlyProductReportPage from "../../modules/report/pages/monthly-product-report.page";
import DailyProductReportPage from "../../modules/report/pages/daily-product-report.page";
import ApprovalOperationDetailsPage from "../../modules/stock-operation/pages/approval-operation-details.page";
import { EditTransferPage } from "../../modules/stock-operation/pages/edit-transfer.page";
import LocationOperationsPage from "../../modules/report/pages/location-operations.page";
import LocationInventoryPage from "../../modules/report/pages/location-inventory.page";
import InventoryByDatePage from "../../modules/report/pages/inventory-by-date.page";
import { VendorSupplyPage } from "../../modules/stock-operation/pages/vendor-supply.page";
import { ConsumptionPage } from "../../modules/stock-operation/pages/consumption.page";
import { AdjustmentReportPage } from "../../modules/report/pages/adjustment-report.page";
import { DemanFulfillPage } from "../../modules/stock-operation/pages/deman-fulfill.page";
import { SingleProductMovementByLocationPage } from "../../modules/report/pages/single-product-movement-by-location.page";
import { ChangePasswordPage } from "../../modules/auth/pages/change-password.page";
import { SingleProductMovementByDatePage } from "../../modules/report/pages/single-product-movement-by-date.page";
import { AllNotificationComponent } from "../../modules/notification/component/all-notification.component";
import { LowStockPage } from "../../modules/dashboard/pages/low-stock.page";
import { ToBeExpiredPage } from "../../modules/dashboard/pages/to-be-expired.page";

export const substoreManagerDashboard: IDashboard = {
  dashboardName: "SUBSTOREMANAGER",
  logo: <LogoComponent />,
  pages: [
    {
      name: "Items To Be expired",
      link: "/substore-manager/to-be-expired",
      content: <ToBeExpiredPage />
    },
    {
      name: "Low Stock Products",
      link: "/substore-manager/low-stock",
      content: <LowStockPage />
    },
    {
      name: "All Notification",
      link: "/substore-manager/notification-all",
      content: <AllNotificationComponent />
    },
    {
      name: "Dashboard",
      link: "/substore-manager/dashboard",
      content: <DashboardPage />
    },
    {
      name: "Transfer Operation",
      link: "/substore-manager/stock-operation/transfer/create",
      content: <TransferPage />
    },
    {
      name: "Demand Inventory",
      link: "/substore-manager/stock-operation/demand/create",
      content: <DemandInventoryPage />
    },
    {
      name: "Pending Operation",
      link: "/substore-manager/stock-operation/pending-operation",
      content: <PendingOperationPage />
    },
    {
      name: "Demand Fulfill",
      link: "/substore-manager/demand-fulfil/:id",
      content: <DemanFulfillPage />
    },
    {
      name: "Approval Operation Details",
      link: "/substore-manager/approval/operation-details",
      content: <ApprovalOperationDetailsPage />
    },
    {
      name: "Edit Transfer Operation",
      link: "/substore-manager/stock-operation/transfer/edit/:operationId",
      content: <EditTransferPage />
    },
    {
      name: "Consumption",
      link: "/substore-manager/stock-operation/consumption/create",
      content: <ConsumptionPage />
    },
    // Reports
    {
      name: "Location Operations",
      link: "/substore-manager/report/location-operations",
      content: <LocationOperationsPage />
    },
    {
      name: "Location Inventory",
      link: "/substore-manager/report/location-inventory",
      content: <LocationInventoryPage />
    },
    {
      name: "Daily Product Report",
      link: "/substore-manager/report/daily-product-report",
      content: <DailyProductReportPage />
    },
    {
      name: "Monthly Product Report",
      link: "/substore-manager/report/monthly-product-report",
      content: <MonthlyProductReportPage />
    },
    {
      name: "Operation Details",
      link: "/substore-manager/report/operation-details/:id",
      content: <OperationDetailsPage />
    },
    {
      name: "Inventory By Date",
      link: "/substore-manager/report/previous-inventory",
      content: <InventoryByDatePage />
    },
    // My Profile
    {
      name: "Profile",
      link: "/substore-manager/profile",
      content: <UserProfilePage />
    },
    {
      name: "Reset Password",
      link: "/substore-manager/change-password",
      content: <ChangePasswordPage />
    },
    {
      name: "Adjustment Report",
      link: "/substore-manager/report/adjustment-report",
      content: <AdjustmentReportPage />
    },
    {
      name: "Single Product Movement (By Location)",
      link: "/substore-manager/report/single-product-movement",
      content: <SingleProductMovementByLocationPage />
    },
    {
      name: "Single Product Movement (By Date)",
      link: "/substore-manager/report/date-wise-product-movement",
      content: <SingleProductMovementByDatePage />
    }
  ],

  sidebar: [
    {
      name: "Dashboard",
      link: "/substore-manager/dashboard",
      content: <DashboardPage />
    },

    {
      header: "Stock Operation",
      icon: FaServer,
      pages: [
        {
          name: "Transfer Operation",
          link: "/substore-manager/stock-operation/transfer/create",
          content: <TransferPage />
        },
        {
          name: "Demand Inventory",
          link: "/substore-manager/stock-operation/demand/create",
          content: <DemandInventoryPage />
        },
        {
          name: "Consumption",
          link: "/substore-manager/stock-operation/consumption/create",
          content: <ConsumptionPage />
        },
        {
          name: "Pending Operation",
          link: "/substore-manager/stock-operation/pending-operation",
          content: <PendingOperationPage />
        }
      ]
    },
    {
      header: "Report",
      icon: FaClipboardList,
      pages: [
        {
          name: "Location Operations",
          link: "/substore-manager/report/location-operations",
          content: <LocationOperationsPage />
        },
        {
          name: "Location Inventory",
          link: "/substore-manager/report/location-inventory",
          content: <LocationInventoryPage />
        },
        {
          name: "Inventory By Date",
          link: "/substore-manager/report/previous-inventory",
          content: <InventoryByDatePage />
        },
        {
          name: "Daily Product Report",
          link: "/substore-manager/report/daily-product-report",
          content: <DailyProductReportPage />
        },
        {
          name: "Monthly Product Report",
          link: "/substore-manager/report/monthly-product-report",
          content: <MonthlyProductReportPage />
        },
        {
          name: "Adjustment Report",
          link: "/substore-manager/report/adjustment-report",
          content: <AdjustmentReportPage />
        },
        {
          name: "Single Product Movement (By Location)",
          link: "/substore-manager/report/single-product-movement",
          content: <SingleProductMovementByLocationPage />
        },
        {
          name: "Single Product Movement (By Date)",
          link: "/substore-manager/report/date-wise-product-movement",
          content: <SingleProductMovementByDatePage />
        }
      ]
    },
    {
      header: "User",
      icon: FaUserFriends,
      pages: [
        {
          name: "Profile",
          link: "/substore-manager/profile",
          content: <UserProfilePage />
        }
      ]
    }
  ]
};
