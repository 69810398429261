import React from "react";
import { AllProductsComponent } from "../components/all-products.component";

interface AllProductsPageProps {}

export const AllProductsPage: React.FC<AllProductsPageProps> = (
  props: AllProductsPageProps
) => {
  return (
    <>
      <AllProductsComponent />
    </>
  );
};
