import { useEffect, useRef, useState } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ButtonGroup,
  useToast,
  useDisclosure,
  Textarea,
  Box,
  Text,
  Stat,
  StatLabel,
  StatNumber
} from "@chakra-ui/react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Select, GroupBase } from "chakra-react-select";
import { ConfirmAlertComponent } from "./../confirm-alert.component";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  ENTER_KEY,
  fieldNameType,
  OriginLocationGroup,
  ProductGroup,
  DistributionStockOperationItems,
  itemGroup
} from "../../types/stock-operation.type";
import {
  RemoveSelectedProductFromAllProducts,
  userCurrentRole
} from "../../../../utils/common-functions";
import {
  useGetAssignUserLocationsQuery,
  useGetFilterProductQuery,
  useDistributionMutation,
  useLazyGetPrescriptionQuery
} from "../../../../api";
import { ProductTrackingTypeOptions } from "../../../../api/type";
import { OperationService } from "../../../../service/operation/operation.service";
import { PageCardComponent } from "../../../core/components/page-card.component";
import { env } from "../../../../config/index";
import { AlreadyDistributedProductTable } from "../already-distributed-product-table";
import { AddItemComponent } from "./../add-item.component";

type DistributionFormValues = {
  originLocation: OriginLocationGroup;
  stockOperationItems: DistributionStockOperationItems[];
  notes: string;
};

interface DistributionComponentProps {}

export const CmchDistributionComponent: React.FC<DistributionComponentProps> = (
  props: DistributionComponentProps
) => {
  const toast = useToast();
  const navigate = useNavigate();
  const role = userCurrentRole();
  let id = localStorage.getItem("location");
  const distributionConfirmAlert = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const getLocationQuery = useGetAssignUserLocationsQuery({});
  const getProductQuery = useGetFilterProductQuery({ id: Number(id) });

  const [distributionOperation, distributionOperationResult] =
    useDistributionMutation();

  const [getPrescription, getPrescriptionResult] =
    useLazyGetPrescriptionQuery();

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);
  const [originLocation, setOriginLocation] = useState<OriginLocationGroup>();
  const [productOptions, setProductOptions] = useState<ProductGroup[]>([]);
  const [barcodeInputValue, setBarcodeInputValue] = useState<string>("");

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(id))
      );
    }
  }, [id, originLocationOptions]);

  useEffect(() => {
    if (getProductQuery.data?.data.products) {
      const productWithValues = getProductQuery.data?.data.products.map(
        (product) => {
          return {
            label: product.name,
            value: product.id,
            unit: product.unit,
            type: product.type,
            quantity: product.quantity,
            genericName: product.genericName
          };
        }
      );
      setProductOptions(productWithValues);
    }
  }, [getProductQuery.data]);

  const {
    register,
    handleSubmit,
    control,
    setFocus,
    setValue,
    formState: { errors }
  } = useForm<DistributionFormValues>({
    defaultValues: {
      stockOperationItems: [{}]
    }
  });

  const { fields, append, update, remove } = useFieldArray<
    DistributionFormValues,
    "stockOperationItems"
  >({
    control,
    name: "stockOperationItems"
  });

  const watch = useWatch({
    control,
    name: "stockOperationItems"
  });

  const distributionHandleSubmit = handleSubmit((data) => {
    if (data.stockOperationItems.length > 0) {
      const allStockOperationItems = data.stockOperationItems.map(
        (singleItem) => {
          return {
            productId: singleItem.product.value,
            quantity: OperationService.getQuantity(
              singleItem.product.type as string,
              singleItem.quantity
            ),
            trackingNumber: singleItem.trackingNumber
          };
        }
      );

      if (getPrescriptionResult.isSuccess && getPrescriptionResult.data) {
        const distributedTo = {
          prescription: {
            id: getPrescriptionResult.data.prescription.id,
            type: getPrescriptionResult.data.prescription.type
          },
          patient: {
            id: getPrescriptionResult.data.patient.id,
            name: getPrescriptionResult.data.patient.name,
            contactNumber: getPrescriptionResult.data.patient.contactNumber
          }
        };
        distributionOperation({
          data: {
            stockOperation: {
              locationId: Number(id),
              stockOperationItems: allStockOperationItems,
              notes: data.notes,
              distributedTo: distributedTo
            }
          }
        });
      } else {
        distributionOperation({
          data: {
            stockOperation: {
              locationId: Number(id),
              stockOperationItems: allStockOperationItems,
              notes: data.notes
            }
          }
        });
      }
    }
    // reset();
  });

  useEffect(() => {
    if (distributionOperationResult.isSuccess) {
      toast({
        title: "Success",
        description: "Distribution operation successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      navigate(`/${role}/report/location-operations`);
    }
  }, [distributionOperationResult, toast, navigate, role]);

  const handleKeyDown = (event: any, index: number, fildName: string) => {
    if (event.keyCode !== ENTER_KEY) return;
    const lastIndex = fields.length - 1;
    const currentIndex = index;
    const nextIndex = index + 1;

    let currentProductType = watch[index].product.type;

    if (
      currentIndex === lastIndex &&
      currentProductType === ProductTrackingTypeOptions.SERIALIZED &&
      fildName === "trackingNumber"
    ) {
      append({});
      setTimeout(() => {
        focusElement(nextIndex, "product");
      }, 100);
    } else if (currentIndex === lastIndex && fildName === "quantity") {
      append({});
      setTimeout(() => {
        focusElement(nextIndex, "product");
      }, 100);
    } else if (
      currentProductType === ProductTrackingTypeOptions.NONE &&
      fildName === "quantity"
    ) {
      focusElement(nextIndex);
    } else if (
      currentProductType === ProductTrackingTypeOptions.SERIALIZED &&
      fildName === "trackingNumber"
    ) {
      focusElement(nextIndex);
    } else if (
      currentProductType === ProductTrackingTypeOptions.BATCH &&
      fildName === "trackingNumber"
    ) {
      focusElement(currentIndex, "quantity");
    } else if (
      currentProductType === ProductTrackingTypeOptions.BATCH &&
      fildName === "quantity"
    ) {
      focusElement(nextIndex);
    }
  };

  function focusElement(index: number, fieldName?: fieldNameType) {
    if (fieldName) {
      setFocus(`stockOperationItems.${index}.${fieldName}`);
      return;
    }

    let selectedProduct = watch[index].product;
    if (!selectedProduct) {
      setFocus(`stockOperationItems.${index}.product`);
      return;
    }
    let productType = selectedProduct.type;
    if (productType !== ProductTrackingTypeOptions.NONE) {
      setFocus(`stockOperationItems.${index}.trackingNumber`);
      return;
    }
    setFocus(`stockOperationItems.${index}.quantity`);
  }

  const handleProductSelect = (OnChange: any, value: any, index: number) => {
    const currentIndexId = index;
    OnChange(value);
    setTimeout(() => {
      if (value.type) {
        value.type === ProductTrackingTypeOptions.NONE
          ? focusElement(currentIndexId, "quantity")
          : focusElement(currentIndexId, "trackingNumber");
      }
    }, 10);
  };
  const getQuantity = (productType: any) => {
    if (productType === ProductTrackingTypeOptions.SERIALIZED) {
      return 1;
    }
    return "";
  };
  const handleAddItem = () => {
    const currentindex = fields.length - 1;
    const nextIndex = currentindex + 1;
    append({});
    setTimeout(() => {
      focusElement(nextIndex, "product");
    }, 10);
  };

  const barcodeAutoFocus = () => {
    // setFocus("SearchbyScanning")
  };

  const onChangeBarcode = (event: any) => {
    setBarcodeInputValue(event.target.value);
  };
  const onKeyPressBarcode = (event: any) => {
    if (event.keyCode === 13) {
      setBarcodeInputValue(event.target.value);
      getPrescription({ query: { prescriptionId: event.target.value } });
      setBarcodeInputValue("");
    }
  };

  useEffect(() => {
    if (
      getPrescriptionResult.isSuccess &&
      getProductQuery.data?.data.products
    ) {
      let allSelectedItems: DistributionStockOperationItems[] = [];
      getPrescriptionResult.data.medicineGenerics?.map(
        (singleGenericMedicine) => {
          const targetProduct = getProductQuery.data?.data.products.find(
            (pro) => pro.genericName === singleGenericMedicine.name
          );

          if (targetProduct) {
            let newSelectedItem = {
              product: {
                label: targetProduct.name,
                value: targetProduct.id,
                unit: targetProduct.unit,
                type: targetProduct.type,
                quantity: targetProduct.quantity,
                genericName: targetProduct.genericName
              },
              quantity: singleGenericMedicine.quantity
            };
            allSelectedItems.push(newSelectedItem);
          }
        }
      );
      if (allSelectedItems.length > 0) {
        setValue("stockOperationItems", allSelectedItems);
      } else {
        setValue("stockOperationItems", []);
        append({});
      }
    } else if (getPrescriptionResult.isError) {
      toast({
        title: "Failed to find prescription",
        description: "Please provide valid prescription ID",
        status: "error",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
  }, [getPrescriptionResult]);

  const locationFieldWidth = env.prescriptionModule ? "80%" : "100%";

  const addSingleItems = (selectedItem: itemGroup, quantity: number) => {
    const lastIndex = fields.length - 1;
    const trackingNumber = selectedItem?.trackingData
      ? selectedItem?.trackingData
      : undefined;
    const newProduct = {
      product: {
        label: selectedItem?.label,
        value: selectedItem?.productId,
        quantity: selectedItem.available,
        unit: selectedItem?.unit,
        type: selectedItem?.type
      },
      quantity: quantity,
      trackingNumber: trackingNumber
    };
    if (fields[lastIndex].product) {
      append(newProduct);
    } else {
      update(lastIndex, newProduct);
    }
  };

  return (
    <Stack>
      <form onSubmit={distributionHandleSubmit}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          width="100%"
          bgColor={"white"}
          borderRadius="md"
          p={4}
          mb={4}
        >
          <Box
            w={{
              base: locationFieldWidth,
              md: locationFieldWidth,
              xl: locationFieldWidth
            }}
          >
            <FormControl mb="3">
              <FormLabel>Origin Location</FormLabel>
              <Input
                _disabled={{ color: "blackAlpha.700", cursor: "not-allowed" }}
                defaultValue={originLocation ? originLocation.label : ""}
                isDisabled={true}
              />
            </FormControl>
          </Box>
          {env.prescriptionModule && (
            <Box w={{ base: "20%", md: "20%", xl: "20%" }}>
              <FormControl mb="3">
                <FormLabel>Prescription ID</FormLabel>
                <Input
                  autoFocus={true}
                  placeholder="Start Scanning"
                  value={barcodeInputValue}
                  onChange={onChangeBarcode}
                  id="prescriptionID"
                  className="searchInput"
                  onKeyDown={onKeyPressBarcode}
                  onBlur={barcodeAutoFocus}
                />
              </FormControl>
            </Box>
          )}
        </Stack>
        {getPrescriptionResult.data && env.prescriptionModule && (
          <Stack direction={{ base: "column", xl: "row" }} bgColor={"white"}>
            <Box width="40%" fontSize={8} display="flex">
              <PageCardComponent>
                <Stat>
                  <StatLabel>patient name</StatLabel>
                  <StatNumber fontSize="sm" fontWeight={600}>
                    {getPrescriptionResult.data?.patient.name}
                  </StatNumber>
                  <StatLabel>contactNumber</StatLabel>
                  <StatNumber fontSize="sm" fontWeight={600}>
                    {getPrescriptionResult.data?.patient.contactNumber}
                  </StatNumber>
                </Stat>
              </PageCardComponent>
              <PageCardComponent>
                <Stat>
                  <StatLabel>Prescription ID</StatLabel>
                  <StatNumber fontSize="sm" fontWeight={600}>
                    {getPrescriptionResult.data?.prescription.id}
                  </StatNumber>
                  <StatLabel>Type</StatLabel>
                  <StatNumber fontSize="sm" fontWeight={600}>
                    {getPrescriptionResult.data?.prescription.type}
                  </StatNumber>
                </Stat>
              </PageCardComponent>
            </Box>
            {getPrescriptionResult.data?.distributedProducts && (
              <Box width="60%">
                <AlreadyDistributedProductTable
                  distributedProducts={
                    getPrescriptionResult.data?.distributedProducts
                  }
                  key="table"
                />
              </Box>
            )}
          </Stack>
        )}

        {/* <Stack spacing={4}>
          <Button
            my="2"
            width="100%"
            colorScheme="blue"
            variant="outline"
            leftIcon={<FaPlus />}
            onClick={() => handleAddItem()}
          >
            Add
          </Button>
        </Stack> */}
        {originLocation && (
          <Stack spacing={4} key="231433124">
            <PageCardComponent>
              <AddItemComponent
                locationId={Number(originLocation?.value)}
                addSingleItems={addSingleItems}
              />
            </PageCardComponent>
          </Stack>
        )}

        {fields
          .slice()
          .reverse()
          .map((field, index) => {
            const currentIndex = fields.length - index - 1;
            return (
              <Stack
                direction={{ base: "column", xl: "row" }}
                width="100%"
                bgColor={index > 0 ? "#F7FAFC" : "white"}
                borderRadius="md"
                spacing={4}
                px="2"
                py="1"
                key={field.id}
                mt="2"
              >
                <Box w={{ base: "100%", md: "100%", xl: "2%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        ID
                      </FormLabel>
                    )}

                    {fields.length - index}
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "35%" }}>
                  <Controller
                    control={control}
                    name={`stockOperationItems.${currentIndex}.product`}
                    rules={{ required: "Select product" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { error }
                    }) => (
                      <FormControl
                        isInvalid={!!error}
                        id={`stockOperationItems.${currentIndex}.product`}
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        {index === 0 && (
                          <FormLabel
                            fontSize={{
                              base: "10",
                              md: "10",
                              lg: "10",
                              xl: "10",
                              "2xl": "12"
                            }}
                          >
                            Select Product
                          </FormLabel>
                        )}
                        <Select<ProductGroup, true, GroupBase<ProductGroup>>
                          name={name}
                          ref={ref}
                          onChange={(value) => {
                            handleProductSelect(onChange, value, currentIndex);
                          }}
                          onBlur={onBlur}
                          value={value}
                          options={RemoveSelectedProductFromAllProducts(
                            productOptions,
                            watch
                          )}
                          placeholder="Select product"
                          closeMenuOnSelect={true}
                          size="md"
                        />
                        <FormErrorMessage>
                          {error && error.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Unit
                      </FormLabel>
                    )}
                    <Input
                      _disabled={{
                        color: "blackAlpha.900",
                        cursor: "not-allowed"
                      }}
                      defaultValue={watch[currentIndex]?.product?.unit}
                      disabled={true}
                      placeholder="unit"
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                    />
                  </FormControl>
                </Box>

                <Box w={{ base: "100%", md: "100%", xl: "8%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Available
                      </FormLabel>
                    )}
                    <Input
                      _disabled={{
                        color: "blackAlpha.900",
                        cursor: "not-allowed"
                      }}
                      defaultValue={watch[currentIndex]?.product?.quantity}
                      disabled={true}
                      placeholder="Available"
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                    />
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "12%" }}>
                  <FormControl
                    isDisabled={
                      watch[currentIndex]?.product?.type !==
                        ProductTrackingTypeOptions.SERIALIZED &&
                      watch[currentIndex]?.product?.type !==
                        ProductTrackingTypeOptions.BATCH
                    }
                    isInvalid={
                      errors?.stockOperationItems?.[currentIndex]
                        ?.trackingNumber
                        ? true
                        : false
                    }
                  >
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Tracking Number
                      </FormLabel>
                    )}
                    <Input
                      {...register(
                        `stockOperationItems.${Number(
                          currentIndex
                        )}.trackingNumber` as const,
                        {
                          validate: {
                            required: (value) => {
                              if (
                                watch[currentIndex]?.product?.type ===
                                  ProductTrackingTypeOptions.NONE ||
                                value !== ""
                              )
                                return true;
                              return "Give Tracking data";
                            }
                          }
                        }
                      )}
                      key={`tracking${currentIndex}`}
                      type={"string"}
                      placeholder="Tracking Number"
                      onKeyDown={(e) =>
                        handleKeyDown(e, currentIndex, "trackingNumber")
                      }
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                    />
                    <FormErrorMessage>
                      {
                        errors?.stockOperationItems?.[currentIndex]
                          ?.trackingNumber?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "12%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Estimated Balance
                      </FormLabel>
                    )}
                    <Input
                      _disabled={{
                        color: "blackAlpha.900",
                        cursor: "not-allowed"
                      }}
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                      value={
                        watch[currentIndex]?.product &&
                        watch[currentIndex]?.product.type &&
                        watch[currentIndex]?.product.type ===
                          ProductTrackingTypeOptions.SERIALIZED
                          ? Number(watch[currentIndex]?.product?.quantity) - 1
                          : Number(watch[currentIndex]?.product?.quantity) -
                            Number(watch[currentIndex]?.quantity)
                      }
                      disabled={true}
                      placeholder="Available"
                    />
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "13%" }}>
                  <FormControl
                    mb="3"
                    isInvalid={
                      errors?.stockOperationItems?.[currentIndex]?.quantity
                        ? true
                        : false
                    }
                  >
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Quantity
                      </FormLabel>
                    )}
                    <Input
                      {...register(
                        `stockOperationItems.${currentIndex}.quantity` as const,
                        {
                          validate: {
                            required: (value) => {
                              if (
                                watch[currentIndex]?.product?.type ===
                                  ProductTrackingTypeOptions.SERIALIZED ||
                                value > 0
                              )
                                return true;
                              return "Give Amount";
                            }
                          }
                        }
                      )}
                      onKeyDown={(e) =>
                        handleKeyDown(e, currentIndex, "quantity")
                      }
                      key={`quantity${currentIndex}`}
                      disabled={
                        watch[currentIndex]?.product?.type !==
                          ProductTrackingTypeOptions.NONE &&
                        watch[currentIndex]?.product?.type !==
                          ProductTrackingTypeOptions.BATCH
                      }
                      defaultValue={getQuantity(
                        watch[currentIndex]?.product?.type
                      )}
                      placeholder="Enter product quantity"
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                      type="number"
                    />
                    <FormErrorMessage>
                      {
                        errors?.stockOperationItems?.[currentIndex]?.quantity
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "8%" }}>
                  <FormControl mb="3">
                    <ButtonGroup>
                      <Button
                        mt={index === 0 ? 8 : 1}
                        colorScheme="red"
                        variant="outline"
                        leftIcon={<FaTimes />}
                        onClick={() => remove(currentIndex)}
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                        size="sm"
                      >
                        Remove
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </Box>
              </Stack>
            );
          })}
        <Stack spacing={4}>
          <Textarea
            placeholder="Enter notes for this Operation"
            {...register("notes")}
          />

          <Button
            width="100%"
            colorScheme="blue"
            onClick={distributionConfirmAlert.onOpen}
          >
            Submit
          </Button>
          <ConfirmAlertComponent
            cancelRef={cancelRef}
            onClose={distributionConfirmAlert.onClose}
            isOpen={distributionConfirmAlert.isOpen}
            onOpen={distributionConfirmAlert.onOpen}
            handleApprove={distributionHandleSubmit}
            status={distributionOperationResult}
          />
        </Stack>
      </form>
    </Stack>
  );
};
