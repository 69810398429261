import React from "react";
import { Flex, Stack } from "@chakra-ui/react";
import { SelectLocationComponent } from "../components/select-location.component";

interface Props {}

const SelectLocationPage: React.FC<Props> = (props: Props) => {
  return (
    <Stack minH="100vh" direction={{ base: "column", md: "row" }}>
      <Flex flex={1} p={8} align="center" justify="center">
        <SelectLocationComponent />
      </Flex>
    </Stack>
  );
};

export default SelectLocationPage;
