import React from "react";
import { GetProductAllLocationInventoryResponse } from "../../../api/type";
import { SingleInventoryPrintComponent } from "./single-inventory-print.component";
import { Button, useDisclosure } from "@chakra-ui/react";
import { PdfPrinterModal } from "../../core/components/pdf-printer-modal.component";

interface SingleProductInventoryPrintComponentProps {
  productInventory: GetProductAllLocationInventoryResponse;
}

export const SingleProductInventoryPrintComponent: React.FC<
  SingleProductInventoryPrintComponentProps
> = (props: SingleProductInventoryPrintComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = (
    <SingleInventoryPrintComponent productInventory={props.productInventory} />
  );

  const singleProductInventoryReport = `Product-Inventory-${props.productInventory.productName}.pdf`;

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={singleProductInventoryReport}
      />
    </>
  );
};
