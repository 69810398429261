import React from "react";
import { FaSearch } from "react-icons/fa";

import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

interface Props {}
// TODO: Implement sitewise search

export const PathSearchComponent: React.FC<Props> = (props: Props) => {
  return (
    <InputGroup w="96" display={{ base: "none", lg: "flex" }}>
      <InputLeftElement color="gray.500" children={<FaSearch />} />
      <Input placeholder="Search..." />
    </InputGroup>
  );
};
