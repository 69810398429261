import React from "react";
import { AssignUserToLocationComponent } from "../components/assign-user-to-location.component";

interface AssignUserToLocationPageProps {}

export const AssignUserToLocationPage: React.FC<
  AssignUserToLocationPageProps
> = (props: AssignUserToLocationPageProps) => {
  return (
    <>
      <AssignUserToLocationComponent />
    </>
  );
};
