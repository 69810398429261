export const displayPageNumberToStirng = (
  pageIndex: number,
  pageSize: number,
  count: number | undefined,
  tableState: number,
  tableCount: number
) => {
  const firstData = pageIndex * pageSize + 1;
  const currentData = Math.min(
    (pageIndex + 1) * pageSize + 1,
    count ? count : 0
  );
  const lastData = count ? count : 0;
  const currentPageNumber = tableState + 1;
  const lastPageNumber = tableCount;

  const displayPage: string = `
  Showing ${firstData} -
  ${currentData} of 
  ${lastData} (Page
  ${currentPageNumber} of
  ${lastPageNumber})`;
  return displayPage;
};
