import {
  CustomReportGroup,
  SearchByGroup,
  LocationGroup,
  ProductGroup
} from "../types/custom-report.type";
export const customReportOptions: CustomReportGroup[] = [
  {
    label: "Inventory",
    value: "INVENTORY"
  },
  {
    label: "Stock Operation",
    value: "STOCK_OPERATION"
  }
];

export const searchByGroupOptions: SearchByGroup[] = [
  {
    label: "Product",
    value: "PRODUCT"
  },
  {
    label: "Location",
    value: "LOCATION"
  },
  {
    label: "Origin Location",
    value: "LOCATION_ORIGIN"
  },
  {
    label: "Destination Location",
    value: "LOCATION_DESTINATION"
  },
  {
    label: "User",
    value: "USER"
  },
  {
    label: "Stock Operation Type",
    value: "STOCK_OPERATION_TYPE"
  }
];

export const locationOptions: LocationGroup[] = [
  {
    label: "Medicine",
    value: "MEDICINE"
  },
  {
    label: "ICT",
    value: "ICT"
  }
];

export const productOptions: ProductGroup[] = [
  {
    label: "Product 1",
    value: "P1"
  },
  {
    label: "Product 2",
    value: "P2"
  }
];

export const inventoryFilterOptions = [
  {
    label: "All",
    value: "ALL"
  },
  {
    label: "Medicine",
    value: "MEDICINE"
  },
  {
    label: "ICT",
    value: "ICT"
  },
  {
    label: "Chemical",
    value: "CHEMICAL"
  },
  {
    label: "General",
    value: "GENERAL"
  },
  {
    label: "Linen",
    value: "LINEN"
  },
  {
    label: "Instrument",
    value: "INSTRUMENT"
  },
  {
    label: "Outdoor Dispensary",
    value: "OUTDOOR_DISPENSARY"
  }
];
