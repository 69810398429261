import React, { useRef } from "react";
import { useEffect, useState } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ButtonGroup,
  useToast,
  Textarea,
  useDisclosure
} from "@chakra-ui/react";
import { Select, GroupBase } from "chakra-react-select";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  DestinationLocationGroup,
  ProductGroup,
  StockOperationItems
} from "../types/stock-operation.type";
import { FaPlus, FaTimes } from "react-icons/fa";
import { PageCardComponent } from "../../core/components/page-card.component";
// import { env } from "../../../config/index";
import { env } from "../../../config/index";

import {
  useGetLocationQuery,
  useGetProductQuery,
  useLazyGetProductQuantityQuery,
  useSupplyOperationWithApprovalMutation
} from "../../../api";
import {
  chakraStyles,
  RemoveSelectedProductFromAllProducts,
  userCurrentRole
} from "../../../utils/common-functions";
import { useNavigate } from "react-router-dom";
import { ConfirmAlertComponent } from "./confirm-alert.component";
import { ProductTrackingTypeOptions } from "../../../api/type";
import { Box } from "@chakra-ui/layout";
import { OperationService } from "../../../service/operation/operation.service";
import dayjs from "dayjs";
type VendorSupplyFormValues = {
  destinationLocation: DestinationLocationGroup;
  stockOperationItems: StockOperationItems[];
  notes: string;
};

interface VendorSupplyComponentProps {}

export const VendorSupplyComponent: React.FC<VendorSupplyComponentProps> = (
  props: VendorSupplyComponentProps
) => {
  const toast = useToast();
  const navigate = useNavigate();
  const role = userCurrentRole();
  const vendorSupplyConfirmAlert = useDisclosure();
  const cancelRef = useRef();
  const getLocationQuery = useGetLocationQuery({});
  let locationId = localStorage.getItem("location");
  let initialState = {
    label: "",
    value: Number(locationId)
  };

  const [destinationLocationOptions, setDestinationLocationOptions] = useState<
    DestinationLocationGroup[]
  >([]);
  const [destinationLocation, setDestinationLocation] =
    useState<DestinationLocationGroup>(initialState);

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setDestinationLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);
  useEffect(() => {
    if (destinationLocationOptions.length) {
      setDestinationLocation(
        destinationLocationOptions.find((d) => d.value === Number(locationId))!
      );
    }
  }, [locationId, destinationLocationOptions]);

  const [productOptions, setProductOptions] = useState<ProductGroup[]>([]);

  const getProductQuery = useGetProductQuery({});

  useEffect(() => {
    if (getProductQuery.data?.data.products) {
      const productWithValues = getProductQuery.data?.data.products.map(
        (product) => {
          return {
            label: product.name,
            value: product.id,
            type: product.trackingType,
            unit: product.unit
          };
        }
      );
      setProductOptions(productWithValues);
    }
  }, [getProductQuery.data]);

  const [createSupply, resultSupply] = useSupplyOperationWithApprovalMutation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<VendorSupplyFormValues>({
    defaultValues: {
      stockOperationItems: [{}]
    }
  });

  const { fields, append, remove } = useFieldArray<
    VendorSupplyFormValues,
    "stockOperationItems"
  >({
    control,
    name: "stockOperationItems"
  });

  const watch = useWatch({
    control,
    name: "stockOperationItems"
  });

  const supplyHandleSubmit = handleSubmit((data) => {
    const allStockOperationItems = data.stockOperationItems.map(
      (singleItem) => {
        return {
          productId: singleItem.product.value,
          quantity: OperationService.getQuantity(
            singleItem.product.type as string,
            singleItem.quantity
          ),
          trackingNumber: singleItem.trackingNumber
        };
      }
    );
    createSupply({
      data: {
        stockOperation: {
          locationId: destinationLocation.value,
          stockOperationItems: allStockOperationItems,
          notes: data.notes
        }
      }
    });
  });

  if (resultSupply.isSuccess) {
    navigate(`/${role}/stock-operation/pending-operation`);
  }

  useEffect(() => {
    if (resultSupply.isSuccess) {
      toast({
        title: "Success",
        description: "Vendor supply successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
  }, [resultSupply, toast]);

  const handleSelectLocationChange = (location: any) => {
    setDestinationLocation(location);
  };

  const [getProductQuantity, getProductQuantityResult] =
    useLazyGetProductQuantityQuery();
  const [availableQuantity, setAvailableQuantity] = useState<
    StockOperationItems[]
  >([]);
  useEffect(() => {
    if (Object.keys(watch[0]).length !== 0) {
      watch.map((d) => {
        if (d?.product?.value) {
          getProductQuantity({
            data: {
              product: {
                productId: d?.product?.value,
                locationId: Number(destinationLocation.value),
                productType: d?.product.type
                  ? d?.product.type
                  : ProductTrackingTypeOptions.NONE
              }
            }
          });
        }
      });
    }
  }, [watch, fields]);

  useEffect(() => {
    if (getProductQuantityResult && Object.keys(watch[0]).length !== 0) {
      watch.map((d, index) => {
        if (
          d?.product?.value ===
          getProductQuantityResult?.data?.data?.products?.productId
        ) {
          watch[index].product["available"] =
            getProductQuantityResult.data?.data?.products?.quantity;
        }
      });
    }
  }, [getProductQuantityResult, watch]);

  useEffect(() => {
    if (
      Object.keys(watch[watch.length - 1]).length !== 0 &&
      watch[watch.length - 1].product?.available
    ) {
      setAvailableQuantity(watch);
    }
  }, [watch, availableQuantity, getProductQuantityResult]);

  return (
    <div>
      <Stack>
        <form onSubmit={supplyHandleSubmit}>
          <Stack
            direction={{ base: "column", xl: "row" }}
            justify="space-between"
          >
            <PageCardComponent>
              <Controller
                control={control}
                name="destinationLocation"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error }
                }) => (
                  <FormControl
                    mb="3"
                    isInvalid={!!error}
                    id="destinationLocation"
                  >
                    <FormLabel>Location</FormLabel>
                    <Select
                      chakraStyles={chakraStyles}
                      name={name}
                      ref={ref}
                      onChange={(location) =>
                        handleSelectLocationChange(location)
                      }
                      onBlur={onBlur}
                      value={destinationLocation}
                      isDisabled={role !== "admin" ? true : false}
                      options={destinationLocationOptions}
                      placeholder="Select destination"
                      closeMenuOnSelect={true}
                    />
                    <FormErrorMessage>
                      {errors.destinationLocation?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </PageCardComponent>
          </Stack>
          {fields.map((field, index) => {
            return (
              <Stack
                direction={{ base: "column", xl: "row" }}
                width="100%"
                bgColor={"white"}
                borderRadius="md"
                spacing={4}
                px="4"
                py="2"
                key={field.id}
                mt="2"
              >
                <Box w={{ base: "100%", md: "100%", xl: "45%" }}>
                  <Controller
                    control={control}
                    name={`stockOperationItems.${index}.product`}
                    rules={{ required: "Select product" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { error }
                    }) => (
                      <FormControl
                        isInvalid={!!error}
                        id={`stockOperationItems.${index}.product`}
                      >
                        {index === 0 && (
                          <FormLabel
                            fontSize={{
                              base: "10",
                              md: "10",
                              lg: "10",
                              xl: "10",
                              "2xl": "12"
                            }}
                          >
                            Select Product
                          </FormLabel>
                        )}
                        <Select<ProductGroup, true, GroupBase<ProductGroup>>
                          name={name}
                          ref={ref}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          options={RemoveSelectedProductFromAllProducts(
                            productOptions,
                            watch
                          )}
                          placeholder="Select product"
                          closeMenuOnSelect={true}
                          size="md"
                        />
                        <FormErrorMessage>
                          {error && error.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Available
                      </FormLabel>
                    )}
                    <Input
                      _disabled={{
                        color: "blackAlpha.900",
                        cursor: "not-allowed"
                      }}
                      defaultValue={
                        watch[index]?.product?.value ===
                        availableQuantity[index]?.product?.value
                          ? availableQuantity[index]?.product?.available
                          : ""
                      }
                      disabled={true}
                      placeholder="Available"
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                    />
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Unit
                      </FormLabel>
                    )}
                    <Input
                      _disabled={{
                        color: "blackAlpha.700",
                        cursor: "not-allowed"
                      }}
                      defaultValue={watch[index]?.product?.unit}
                      disabled={true}
                      placeholder="Product unit"
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                    />
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "14%" }}>
                  <FormControl
                    isDisabled={
                      watch[index]?.product?.type ===
                      ProductTrackingTypeOptions.NONE
                        ? true
                        : false
                    }
                    isInvalid={
                      errors?.stockOperationItems?.[index]?.trackingNumber
                        ? true
                        : false
                    }
                  >
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "8",
                          md: "8",
                          lg: "8",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Tracking Id
                      </FormLabel>
                    )}
                    <Input
                      {...register(
                        `stockOperationItems.${index}.trackingNumber` as const,
                        {
                          validate: {
                            required: (value) => {
                              if (
                                watch[index]?.product?.type ===
                                  ProductTrackingTypeOptions.NONE ||
                                value !== ""
                              )
                                return true;
                              return "Give Tracking data";
                            }
                          }
                        }
                      )}
                      key={`tracking${index}`}
                      type={"string"}
                      placeholder="Tracking Number"
                      fontSize={{
                        base: "12",
                        md: "12",
                        lg: "12",
                        xl: "12",
                        "2xl": "14"
                      }}
                    />
                    <FormErrorMessage>
                      {
                        errors?.stockOperationItems?.[index]?.trackingNumber
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box w={{ base: "100%", md: "100%", xl: "14%" }}>
                  <FormControl
                    mb="3"
                    isInvalid={
                      errors?.stockOperationItems?.[index]?.quantity
                        ? true
                        : false
                    }
                  >
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Quantity
                      </FormLabel>
                    )}
                    <Input
                      {...register(
                        `stockOperationItems.${index}.quantity` as const,
                        {
                          validate: {
                            required: (value) => {
                              if (
                                watch[index]?.product?.type ===
                                  ProductTrackingTypeOptions.SERIALIZED ||
                                value > 0
                              )
                                return true;
                              return "Give Amount";
                            }
                          }
                        }
                      )}
                      key={`quantity${index}`}
                      disabled={
                        watch[index]?.product?.type === "SERIALIZED"
                          ? true
                          : false
                      }
                      defaultValue={
                        watch[index]?.product?.type === "SERIALIZED" ? 1 : ""
                      }
                      type={"number"}
                      placeholder="Enter product quantity"
                      fontSize={{
                        base: "12",
                        md: "12",
                        lg: "12",
                        xl: "12",
                        "2xl": "14"
                      }}
                    />
                    <FormErrorMessage>
                      {errors?.stockOperationItems?.[index]?.quantity?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "2%" }}>
                  <ButtonGroup>
                    <Button
                      mt={index === 0 ? 8 : 0}
                      colorScheme="red"
                      variant="outline"
                      leftIcon={<FaTimes />}
                      onClick={() => remove(index)}
                      isDisabled={index > 0 ? false : true}
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                      size="sm"
                    ></Button>
                  </ButtonGroup>
                </Box>
              </Stack>
            );
          })}
          <Stack spacing={4}>
            <Button
              disabled={Object.keys(watch[0]).length !== 0 ? false : true}
              my="2"
              width="100%"
              colorScheme="blue"
              variant="outline"
              leftIcon={<FaPlus />}
              onClick={() => append({})}
            >
              Add
            </Button>
            <Textarea
              placeholder="Enter notes for this Operation"
              {...register("notes")}
            />
            <Button
              width="100%"
              colorScheme="blue"
              onClick={vendorSupplyConfirmAlert.onOpen}
            >
              Supply
            </Button>
            <ConfirmAlertComponent
              cancelRef={cancelRef}
              onClose={vendorSupplyConfirmAlert.onClose}
              isOpen={vendorSupplyConfirmAlert.isOpen}
              onOpen={vendorSupplyConfirmAlert.onOpen}
              handleApprove={supplyHandleSubmit}
              status={resultSupply}
            />
          </Stack>
        </form>
      </Stack>
    </div>
  );
};
