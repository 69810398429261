import React from "react";
import {
  StyleSheet,
  Document,
  Font,
  Page,
  Text,
  View,
  Image
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import { env } from "../../../config";
import { LocationInventoryFilterDataType } from "../../report/types/custom-report.type";
import { InventoyFilterGroup } from "../../report/types/stock-operation.type";
import { userCurrentRole } from "../../../utils/common-functions";

interface InventoryPrintComponentProps {
  printData: LocationInventoryFilterDataType;
  filterBy: InventoyFilterGroup;
  searchKeyword: string;
  shouldHideZeroProducts: boolean;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  page: {
    padding: "0.75in",
    fontFamily: "Noto Serif Bengali",
    position: "relative"
  },
  logo: {
    width: "70px",
    objectFit: "contain",
    alignSelf: "center"
  },
  organizationName: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center"
  },
  header: {
    width: "100%",
    marginTop: 3,
    marginBottom: 5
  },
  headerContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    fontSize: 11
  },
  headerContentDetails: {
    width: "50%",
    border: "1px solid #dfdfdf",
    paddingHorizontal: 5,
    paddingVertical: 1
  },
  backgroundImage: {
    position: "absolute",
    top: "45%",
    width: "200px",
    opacity: 0.05,
    objectFit: "contain",
    alignSelf: "center"
  },
  table: {
    width: "100%",
    marginTop: 5,
    borderLeft: "1px solid #dfdfdf",
    borderRight: "1px solid #dfdfdf"
  },
  tableHeader: {
    backgroundColor: "#EBECF0",
    fontWeight: "semibold"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 11,
    textAlign: "center",
    borderBottom: "1px solid #dfdfdf"
  },
  serial: {
    width: "10%",
    borderRight: "1px solid #dfdfdf"
  },
  productName: {
    width: "60%",
    borderRight: "1px solid #dfdfdf",
    textAlign: "left",
    paddingHorizontal: 10
  },
  quantity: {
    width: "15%",
    textAlign: "left",
    paddingHorizontal: 10,
    borderRight: "1px solid #dfdfdf"
  },
  unit: {
    width: "15%"
  },
  expandTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    fontSize: 8,
    backgroundColor: "#EBECF0",
    borderBottom: "1px solid #dfdfdf"
  },
  no: {
    width: "10%",
    textAlign: "center",
    borderRight: "1px solid #dfdfdf"
  },
  trackingId: {
    width: "20%",
    textAlign: "left",
    paddingHorizontal: 10,
    borderRight: "1px solid #dfdfdf"
  },
  manufactureDate: {
    width: "20%",
    paddingHorizontal: 5,
    borderRight: "1px solid #dfdfdf"
  },
  expiryDate: {
    width: "20%",
    paddingHorizontal: 5,
    borderRight: "1px solid #dfdfdf"
  },
  singleQuantity: {
    width: "30%",
    paddingHorizontal: 10
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    fontSize: 6,
    color: "#666666",
    bottom: "0.50in",
    left: "0.75in",
    right: "0.75in",
    borderTop: "1px solid #000"
  },
  signature: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 11,
    fontWeight: "semibold",
    marginTop: 50
  }
});

export const InventoryPrintComponent: React.FC<InventoryPrintComponentProps> = (
  props: InventoryPrintComponentProps
) => {
  return (
    <Document>
      {props.printData && (
        <Page size="A4" style={styles.page}>
          <Image style={styles.backgroundImage} src={env.reportLogo} fixed />
          <View>
            <Image style={styles.logo} src={env.organizationLogo} />
            <Text style={styles.organizationName}>
              {env.organizationFullName}
            </Text>
            <View style={styles.header}>
              <View style={styles.headerContent}>
                <Text style={styles.headerContentDetails}>
                  Report Type : Location Inventory Report
                </Text>
                <Text style={styles.headerContentDetails}>
                  Location Name : {props.printData.location.name}
                </Text>
              </View>
              <View style={styles.headerContent}>
                <Text style={styles.headerContentDetails}>
                  Search By : {props.searchKeyword}
                </Text>
                <Text style={styles.headerContentDetails}>
                  Filter By : {props.filterBy.label} Department
                </Text>
              </View>
              <View style={styles.headerContent}>
                <Text style={styles.headerContentDetails}>
                  Report Date :{"  "}
                  {dayjs().format("DD MMMM, YYYY")}
                </Text>
                <Text style={styles.headerContentDetails}>
                  Printout Date :{"  "}
                  {dayjs().format("DD MMMM YYYY, hh:mm A")}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.table}>
            <View style={[styles.tableHeader, styles.row]}>
              <Text style={styles.serial}>Serial</Text>
              <Text style={styles.productName}>Product Name</Text>
              <Text style={styles.quantity}>Quantity</Text>
              <Text style={styles.unit}>Unit</Text>
            </View>
            {props.printData.inventory?.map((inventoryDetails, i) => (
              <View key={i}>
                <View style={styles.row} key={i} wrap={false}>
                  <Text style={styles.serial}>{i + 1} </Text>
                  <Text style={styles.productName}>
                    {inventoryDetails.productName + " "}
                  </Text>
                  <Text style={styles.quantity}>
                    {inventoryDetails.quantity}
                  </Text>
                  <Text style={styles.unit}>{inventoryDetails.unit}</Text>
                </View>
                <View>
                  {inventoryDetails.trackingData.length > 0 && (
                    <View>
                      <View style={styles.expandTable}>
                        <Text style={styles.no}>No.</Text>
                        <Text style={styles.trackingId}>Tracking ID</Text>
                        <Text style={styles.manufactureDate}>
                          Manufacture Date
                        </Text>
                        <Text style={styles.expiryDate}>Expiry Date</Text>
                        <Text style={styles.singleQuantity}>Quantity</Text>
                      </View>
                    </View>
                  )}

                  {props.shouldHideZeroProducts
                    ? inventoryDetails.trackingData
                        .filter(
                          (singleTrackingData) => singleTrackingData.amount > 0
                        )
                        .map((inventory, id) => (
                          <View key={id}>
                            <View
                              style={styles.expandTable}
                              key={id}
                              wrap={false}
                            >
                              <Text style={styles.no}>{id + 1}</Text>
                              <Text style={styles.trackingId}>
                                {inventory.trackingId}
                              </Text>
                              <Text style={styles.manufactureDate}>
                                {inventory.manufactureDate
                                  ? dayjs(inventory.manufactureDate).format(
                                      "MMM, YYYY"
                                    )
                                  : "N/A"}
                              </Text>
                              <Text style={styles.expiryDate}>
                                {inventory.expiryDate
                                  ? dayjs(inventory.expiryDate).format(
                                      "MMM, YYYY"
                                    )
                                  : "N/A"}
                              </Text>
                              <Text style={styles.singleQuantity}>
                                {inventory.amount}
                              </Text>
                            </View>
                          </View>
                        ))
                    : inventoryDetails.trackingData.map((inventory, id) => (
                        <View key={id}>
                          <View
                            style={styles.expandTable}
                            key={id}
                            wrap={false}
                          >
                            <Text style={styles.no}>{id + 1}</Text>
                            <Text style={styles.trackingId}>
                              {inventory.trackingId}
                            </Text>
                            <Text style={styles.manufactureDate}>
                              {inventory.manufactureDate
                                ? dayjs(inventory.manufactureDate).format(
                                    "MMM, YYYY"
                                  )
                                : "N/A"}
                            </Text>
                            <Text style={styles.expiryDate}>
                              {inventory.expiryDate
                                ? dayjs(inventory.expiryDate).format(
                                    "MMM, YYYY"
                                  )
                                : "N/A"}
                            </Text>
                            <Text style={styles.singleQuantity}>
                              {inventory.amount}
                            </Text>
                          </View>
                        </View>
                      ))}
                </View>
              </View>
            ))}
          </View>
          <View style={styles.signature}>
            <View>
              <Text>Verify By,</Text>
            </View>
            <View>
              <Text>Authorized By,</Text>
            </View>
          </View>
          <View style={styles.footer} fixed>
            <View>
              <Text>Powered By Bindulogic Limited</Text>
            </View>
            <View>
              <Text
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} of ${totalPages}`
                }
              />
            </View>
            <View>
              <Text>
                Printed By: {userCurrentRole() || "Unknown"},{" "}
                {dayjs().format("DD MMM YYYY, hh:mm A")}
              </Text>
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
};
