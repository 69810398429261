import React from "react";
import { MonthlyAdjustmentRespone } from "../../../api/type";
import dayjs from "dayjs";
import { AdjustmentReportPrintComponent } from "./adjustment-report-print.component";
import { Button, useDisclosure } from "@chakra-ui/react";
import { PdfPrinterModal } from "../../core/components/pdf-printer-modal.component";

interface AdjustmentProductReportPrintComponentProps {
  printData: MonthlyAdjustmentRespone;
}

export const AdjustmentProductReportPrintComponent: React.FC<
  AdjustmentProductReportPrintComponentProps
> = (props: AdjustmentProductReportPrintComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = <AdjustmentReportPrintComponent data={props.printData} />;

  const adjustmentReport = `Adjustment-${
    props.printData.data.location.name
  }-${dayjs(props.printData.data.month).format("MMM-YYYY")}.pdf`;

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={adjustmentReport}
      />
    </>
  );
};
