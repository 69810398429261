import {
  DepartmentGroup,
  UnitGroup,
  TrackingTypeGroup,
  ProductStatus
} from "./../type/product.type";

export const departmentOptions: DepartmentGroup[] = [
  {
    label: "Medicine",
    value: "MEDICINE"
  },
  {
    label: "Chemical",
    value: "CHEMICAL"
  },
  {
    label: "General",
    value: "GENERAL"
  },
  {
    label: "ICT",
    value: "ICT"
  },
  {
    label: "Linen",
    value: "LINEN"
  },
  {
    label: "Instrument",
    value: "INSTRUMENT"
  },
  {
    label: "Outdoor Dispensary",
    value: "OUTDOOR_DISPENSARY"
  }
];

export const productStatusOptions: ProductStatus[] = [
  {
    label: "Active",
    value: null
  },
  {
    label: "Inactive",
    value: true
  }
];

export const unitOptions: UnitGroup[] = [
  {
    label: "Piece",
    value: "PIECE"
  },
  {
    label: "Liter",
    value: "LITER"
  },
  {
    label: "Kg",
    value: "KG"
  },
  {
    label: "Pair",
    value: "PAIR"
  },
  {
    label: "Tube",
    value: "TUBE"
  },
  {
    label: "Pack",
    value: "PACK"
  },
  {
    label: "Set",
    value: "SET"
  },
  {
    label: "Dozen",
    value: "DOZEN"
  },
  {
    label: "Packet",
    value: "PACKET"
  },
  {
    label: "Jar",
    value: "JAR"
  },
  {
    label: "Bottle",
    value: "BOTTLE"
  },
  {
    label: "Roll",
    value: "ROLL"
  },
  {
    label: "Box",
    value: "BOX"
  },
  {
    label: "Cartidge",
    value: "CARTIGE"
  },
  {
    label: "Vial",
    value: "VIAL"
  },
  {
    label: "Carton",
    value: "CARTON"
  },

  {
    label: "Device",
    value: "DEVICE"
  },
  {
    label: "Pot",
    value: "POT"
  },
  {
    label: "Kit",
    value: "KIT"
  },
  {
    label: "Bag",
    value: "BAG"
  },
  {
    label: "Ream",
    value: "REAM"
  },
  {
    label: "Feet",
    value: "FEET"
  },
  {
    label: "Than",
    value: "THAN"
  },
  {
    label: "Bundle",
    value: "BUNDLE"
  },
  {
    label: "Test",
    value: "TEST"
  },
  {
    label: "Meter",
    value: "METER"
  },
  {
    label: "Mililiter",
    value: "MILLILITER"
  },
  {
    label: "Gram",
    value: "GRAM"
  },
  {
    label: "Gross",
    value: "GROSS"
  },
  {
    label: "Container",
    value: "CONTAINER"
  },
  {
    label: "Sheet",
    value: "SHEET"
  },
  {
    label: "Cartridge",
    value: "CARTRIDGE"
  },
  {
    label: "amp",
    value: "AMP"
  },
  {
    label: "Book",
    value: "BOOK"
  },
  {
    label: "Coil",
    value: "COIL"
  },
  {
    label: "Gallon",
    value: "GALLON"
  },
  {
    label: "Case",
    value: "CASE"
  },
  {
    label: "Can",
    value: "CAN"
  },
  {
    label: "Hundred",
    value: "HUNDRED"
  },
  {
    label: "Per Number",
    value: "PER_NUMBER"
  },
  {
    label: "Square Feet",
    value: "SFT"
  }
];

export const trackingTypeOptions: TrackingTypeGroup[] = [
  {
    label: "None",
    value: "NONE"
  },
  {
    label: "Serialise",
    value: "SERIALIZED"
  },
  {
    label: "Batch",
    value: "BATCH"
  }
];
