import React from "react";
import { Stack } from "@chakra-ui/react";
import TrashComponent from "../components/trash.component";
import CmchTrashComponent from "../components/cmch/cmch-trash.component";
import { env } from '../../../config/index';
import { allUIType } from "../../../api/type";

interface TrashPageProps { }

export const TrashPage: React.FC<TrashPageProps> = (props: TrashPageProps) => {
  return (
    <>
      {
        env.uiType === allUIType.CMCH ? <CmchTrashComponent /> : <TrashComponent />
      }

    </>
  );
};
