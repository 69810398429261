import React from "react";
import { DateWiseProductMovementResponse } from "../../../api/type";
import { Button, useDisclosure } from "@chakra-ui/react";
import { PdfPrinterModal } from "../../core/components/pdf-printer-modal.component";
import { SingleMovementByDatePrintComponent } from "./single-movement-by-date-print.component";

interface SingleProductMovementByDatePrintComponentProps {
  dateWiseProductMovement: DateWiseProductMovementResponse;
}

export const SingleProductMovementByDatePrintComponent: React.FC<
  SingleProductMovementByDatePrintComponentProps
> = (props: SingleProductMovementByDatePrintComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = (
    <SingleMovementByDatePrintComponent
      dateWiseProductMovement={props.dateWiseProductMovement}
    />
  );
  const dateWiseProductMovementReport = `Single Product Movement(By Date)-${props.dateWiseProductMovement.product.name}.pdf`;
  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={dateWiseProductMovementReport}
      />
    </>
  );
};
