import React, { useEffect, useState } from "react";
import { Heading, Tag, Tooltip } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useGetUserProfileQuery } from "../../../api";

interface UserLocationComponentProps {}

export const UserLocationComponent: React.FC<UserLocationComponentProps> = (
  props: UserLocationComponentProps
) => {
  const { data } = useGetUserProfileQuery({});
  let id = localStorage.getItem("location");
  const [location, setLocation] = useState({ id: 0, name: "undefined" });

  useEffect(() => {
    let currentLocation = data?.data.user.locations.find(
      (d) => d.id === Number(id)
    );
    if (currentLocation !== undefined) {
      setLocation(currentLocation);
    }
  }, [data, id]);

  const navigate = useNavigate();

  return (
    <Tooltip hasArrow label="Click to change location" bg="gray.700">
      <Tag
        mx="3"
        p="2"
        size="md"
        colorScheme="green"
        cursor="pointer"
        onClick={() => {
          navigate("/select-location");
        }}
      >
        <Heading fontSize="sm">{location.name}</Heading>
      </Tag>
    </Tooltip>
  );
};
