import { isFulfilled, isRejected, isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { createStandaloneToast } from "@chakra-ui/toast";

const { toast } = createStandaloneToast();

export const rtkqToast: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      toast({
        title: "Error",
        description: action.payload.data
          ? action.payload.data.message
          : "Server is not responsive",
        status: "error",
        position: "top",
        duration: 1000,
        isClosable: true
      });
    }

    return next(action);
  };
