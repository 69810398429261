import React from "react";
import PendingOperationComponent from "../components/pending-operation.component";

interface PendingOperationPageProps {}

export const PendingOperationPage: React.FC<PendingOperationPageProps> = (
  props: PendingOperationPageProps
) => {
  return (
    <div>
      <PendingOperationComponent />
    </div>
  );
};
