import React from "react";
import { EditLocationComponent } from "../components/edit-location.component";

interface EditLocationPageProps {}

export const EditLocationPage: React.FC<EditLocationPageProps> = (
  props: EditLocationPageProps
) => {
  return (
    <>
      <EditLocationComponent />
    </>
  );
};
