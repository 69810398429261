import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../config";
import * as ApiTypes from "./type";

const baseQuery = fetchBaseQuery({
  baseUrl: env.apiEndpoint,
  credentials: "include",
  prepareHeaders: (headers, { getState }: any) => {
    const accessToken = window.localStorage.getItem("jwt");
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  }
});

export const api = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: [
    "Product",
    "Location",
    "Operation",
    "User",
    "Count",
    "Report",
    "locationInventory",
    "updateTrackingNumber",
    "addItem"
  ],
  endpoints: (builder) => ({
    register: builder.mutation<
      ApiTypes.RegisterResponse,
      ApiTypes.RegisterRequest
    >({
      query: ({
        data: {
          user: { name, email, contact, password }
        }
      }) => ({
        url: `/register`,
        method: `POST`,
        body: { data: { user: { name, email, contact, password } } }
      }),
      invalidatesTags: ["User"]
    }),

    login: builder.mutation<ApiTypes.LoginResponse, ApiTypes.LoginRequest>({
      query: ({
        data: {
          user: { email, password }
        }
      }) => ({
        url: `/login`,
        method: `POST`,
        body: { data: { user: { email, password } } }
      })
    }),

    forgetPassword: builder.mutation<
      ApiTypes.ForgetPasswordRequest,
      ApiTypes.ForgetPasswordRequest
    >({
      query: ({ data }) => ({
        url: `/forgot-password`,
        method: `POST`,
        body: { data: data }
      })
    }),

    addProduct: builder.mutation<
      ApiTypes.AddProductResponse,
      ApiTypes.AddProductRequest
    >({
      query: ({ data }) => ({
        url: `/product/add`,
        method: `POST`,
        body: {
          data
        }
      }),
      invalidatesTags: ["Product", "Count"]
    }),

    getProduct: builder.query<
      ApiTypes.GetProductResponse,
      ApiTypes.GetProductRequest
    >({
      query: () => ({
        url: "/product/summary",
        method: "GET"
      }),
      providesTags: ["Product"]
    }),

    getFilterProduct: builder.query<
      ApiTypes.GetFilterProductResponse,
      ApiTypes.GetFilterProductRequest
    >({
      query: ({ id }) => ({
        url: `/product/filter-product/${id}`,
        method: "GET"
      }),
      providesTags: ["Product"]
    }),
    getFilterItemsWithTrackingData: builder.query<
      ApiTypes.GetFilterItemResponse,
      ApiTypes.GetFilterProductRequest
    >({
      query: ({ id }) => ({
        url: `/product/filter-items/${id}`,
        method: "GET"
      }),
      providesTags: ["addItem"]
    }),
    getProductLocationInventory: builder.query<
      ApiTypes.GetProductLocationInventoryResponse,
      ApiTypes.GetProductLocationInventoryRequest
    >({
      query: ({
        data: {
          product: { id }
        }
      }) => ({
        url: `/product/get-inventory`,
        method: `POST`,
        body: {
          data: { product: { id } }
        }
      })
    }),

    // All Products Table
    getAllProducts: builder.query<
      ApiTypes.GetAllProductsResponse,
      ApiTypes.GetAllProductsRequest
    >({
      query: ({ query: { search, pageIndex, pageSize } }) => ({
        url: `/product/summary?search=${search}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: "GET"
      }),
      providesTags: ["Product"]
    }),

    getSearchedProducts: builder.query<
      ApiTypes.GetSearchedProductsResponse,
      ApiTypes.GetSearchedProductsRequest
    >({
      query: ({ query }) => ({
        url: `/product/search?text=${query.text}&pageIndex=${query.pageIndex}&pageSize=${query.pageSize}`,
        method: "GET"
      }),
      providesTags: ["Product"]
    }),

    addLocation: builder.mutation<
      ApiTypes.AddLocationResponse,
      ApiTypes.AddLocationRequest
    >({
      query: ({
        data: {
          location: { name, type, department, parentID }
        }
      }) => ({
        url: `/location/create`,
        method: `POST`,
        body: {
          data: { location: { name, type, department, parentID } }
        }
      }),
      invalidatesTags: ["Location", "Count"]
    }),

    selectLocation: builder.mutation<
      ApiTypes.SelectLocationResponse,
      ApiTypes.SelectLocationRequest
    >({
      query: ({
        data: {
          location: { parentLocation }
        }
      }) => ({
        url: `/location/select`,
        method: `POST`,
        body: {
          data: { location: { parentLocation } }
        }
      })
    }),

    // get all location without pagination
    getLocation: builder.query<
      ApiTypes.GetLocationResponse,
      ApiTypes.GetLocationRequest
    >({
      query: () => ({
        url: "/location/summary",
        method: "GET"
      }),
      providesTags: ["Location"]
    }),

    // Get sub location
    getSubLocation: builder.query<
      ApiTypes.GetSubLocationResponse,
      ApiTypes.GetSubLocationRequest
    >({
      query: ({ data }) => ({
        url: "/location/sub-location",
        method: "POST",
        body: { data }
      }),
      providesTags: ["Location"]
    }),

    // All Locations Table
    getAllLocations: builder.query<
      ApiTypes.GetAllLocationsResponse,
      ApiTypes.GetAllLocationsRequest
    >({
      query: ({ query: { search, pageIndex, pageSize } }) => ({
        url: `/location/summary?search=${search}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: "GET"
      }),
      providesTags: ["Location"]
    }),

    getSearchedLocations: builder.query<
      ApiTypes.GetSearchedLocationsResponse,
      ApiTypes.GetSearchedLocationsRequest
    >({
      query: ({ query }) => ({
        url: `/location/search?text=${query.text}&pageIndex=${query.pageIndex}&pageSize=${query.pageSize}`,
        method: "GET"
      }),
      providesTags: ["Location"]
    }),

    // transfer operation
    transferOperation: builder.mutation<
      ApiTypes.TransferResponse,
      ApiTypes.TransferRequest
    >({
      query: ({ data }) => ({
        url: `stock-operation/transfer/create`,
        method: `POST`,
        body: {
          data
        }
      }),
      invalidatesTags: ["Operation", "Count", "Product"]
    }),
    // transfer operation edit
    transferEdit: builder.mutation<
      ApiTypes.TransferResponse,
      ApiTypes.EditTransferRequest
    >({
      query: ({ data }) => ({
        url: `stock-operation/transfer/edit`,
        method: `POST`,
        body: {
          data
        }
      }),
      invalidatesTags: ["Operation"]
    }),

    // initial inventory
    initialInventory: builder.mutation<
      ApiTypes.InitialInventoryResponse,
      ApiTypes.InitialInventoryRequest
    >({
      query: ({ data }) => ({
        url: `stock-operation/initial-inventory/create`,
        method: `POST`,
        body: {
          data
        }
      }),
      invalidatesTags: ["Operation", "Count", "addItem"]
    }),

    // trash
    trash: builder.mutation<
      ApiTypes.InitialInventoryResponse,
      ApiTypes.InitialInventoryRequest
    >({
      query: ({ data }) => ({
        url: `/stock-operation/trash/create`,
        method: `POST`,
        body: {
          data
        }
      }),
      invalidatesTags: ["Operation", "Product", "Report", "addItem"]
    }),

    // Consumtion
    consumption: builder.mutation<
      ApiTypes.InitialInventoryResponse,
      ApiTypes.InitialInventoryRequest
    >({
      query: ({ data }) => ({
        url: `/stock-operation/consumption/create`,
        method: `POST`,
        body: {
          data
        }
      }),
      invalidatesTags: ["Operation", "Product", "Report", "addItem"]
    }),

    demandInventory: builder.mutation<
      ApiTypes.DemandOperationResponse,
      ApiTypes.DemandInventoryRequest
    >({
      query: ({ data }) => ({
        url: `stock-operation/demand/create`,
        method: `POST`,
        body: {
          data
        }
      }),
      invalidatesTags: ["Operation", "Count"]
    }),

    getPendingOperation: builder.query<
      ApiTypes.GetPendingOperationResponse,
      ApiTypes.GetPendingOperationRequest
    >({
      query: () => ({
        url: "/stock-operation/pending-approvals",
        method: `GET`
      }),
      providesTags: ["Operation"]
    }),

    // Approve Transfer Operation
    approveTransferPendingOperation: builder.mutation<
      ApiTypes.ApprovePendingOperationResponse,
      ApiTypes.ApprovePendingOperationRequest
    >({
      query: ({
        data: {
          stockOperation: { id }
        }
      }) => ({
        url: "/stock-operation/transfer/finalize",
        method: "Post",
        body: {
          data: {
            stockOperation: {
              id
            }
          }
        }
      }),
      invalidatesTags: ["Operation", "Product", "Report", "addItem"]
    }),

    // Cancel Tranfer Operation

    cancelTransferPendingOperation: builder.mutation<
      ApiTypes.CancelPendingOperationResponse,
      ApiTypes.CancelPendingOperationRequest
    >({
      query: ({
        data: {
          stockOperation: { id }
        }
      }) => ({
        url: "/stock-operation/transfer/cancel",
        method: "Post",
        body: {
          data: {
            stockOperation: {
              id
            }
          }
        }
      }),
      invalidatesTags: ["Operation"]
    }),

    // Approve Vendor Operation
    approveVendorPendingOperation: builder.mutation<
      ApiTypes.ApprovePendingOperationResponse,
      ApiTypes.ApprovePendingOperationRequest
    >({
      query: ({
        data: {
          stockOperation: { id }
        }
      }) => ({
        url: "/stock-operation/vendor-supply/finalize",
        method: "Post",
        body: {
          data: {
            stockOperation: {
              id
            }
          }
        }
      }),
      invalidatesTags: ["Operation", "Product", "Report", "addItem"]
    }),

    // Cancel Vendor Operation

    cancelVendorPendingOperation: builder.mutation<
      ApiTypes.CancelPendingOperationResponse,
      ApiTypes.CancelPendingOperationRequest
    >({
      query: ({
        data: {
          stockOperation: { id }
        }
      }) => ({
        url: "/stock-operation/vendor-supply/cancel",
        method: "Post",
        body: {
          data: {
            stockOperation: {
              id
            }
          }
        }
      }),
      invalidatesTags: ["Operation"]
    }),

    // Cancel Demand Operation

    cancelDemandPendingOperation: builder.mutation<
      ApiTypes.CancelPendingOperationResponse,
      ApiTypes.CancelPendingOperationRequest
    >({
      query: ({
        data: {
          stockOperation: { id }
        }
      }) => ({
        url: "/stock-operation/demand/cancel",
        method: "Post",
        body: {
          data: {
            stockOperation: {
              id
            }
          }
        }
      }),
      invalidatesTags: ["Operation"]
    }),

    lastTransferAvailable: builder.query<
      ApiTypes.LastAvailableTransferResponse,
      ApiTypes.LastAvailableTransferRequest
    >({
      query: ({
        data: {
          product: { productId, originLocationId, destinationLocationId }
        }
      }) => ({
        url: "/product/last-transfer-available",
        method: "Post",
        body: {
          data: {
            product: {
              productId,
              originLocationId,
              destinationLocationId
            }
          }
        }
      })
    }),

    /**
     * REPORTS API
     */

    //Get Location Inventory
    getLocationInventory: builder.query<
      ApiTypes.GetLocationInventoryResponse,
      ApiTypes.GetLocationInventoryRequest
    >({
      query: ({ data }) => ({
        url: "/report/location-inventory",
        method: "POST",
        body: {
          data
        }
      }),
      providesTags: ["Operation", "locationInventory"]
    }),
    //Get Location InventoryGetDemandPrintData
    getPreviousInventory: builder.query<
      ApiTypes.GetPreviousInventoryResponse,
      ApiTypes.GetPreviousInventoryRequest
    >({
      query: ({
        data: {
          report: { locationId, date }
        }
      }) => ({
        url: "/report/previous-inventory",
        method: "POST",
        body: {
          data: { report: { locationId, date } }
        }
      }),
      providesTags: ["Operation"]
    }),

    // all users table
    getAllUsers: builder.query<
      ApiTypes.GetAllUsersResponse,
      ApiTypes.GetAllUsersRequest
    >({
      query: ({ query: { search, pageIndex, pageSize } }) => ({
        url: `/user/get-all?search=${search}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: "GET"
      }),
      providesTags: ["User"]
    }),

    getSearchedUsers: builder.query<
      ApiTypes.GetSearchedUsersResponse,
      ApiTypes.GetSearchedUsersRequest
    >({
      query: ({ query }) => ({
        url: `/user/search?text=${query.text}&pageIndex=${query.pageIndex}&pageSize=${query.pageSize}`,
        method: "GET"
      }),
      providesTags: ["User"]
    }),

    // Get All Operations
    getAllOperation: builder.query<
      ApiTypes.GetAllOperationResponse,
      ApiTypes.GetAllOperationRequest
    >({
      query: ({ query: { pageIndex, pageSize, startTime, endTime } }) => ({
        url: `/report/operations?pageIndex=${pageIndex}&pageSize=${pageSize}&startTime=${startTime}&endTime=${endTime}`,
        method: "GET"
      })
    }),

    // Get All Operation Details
    getAllOperationDetails: builder.query<
      ApiTypes.GetAllOperationDetailsResponse,
      ApiTypes.GetAllOperationDetailsRequest
    >({
      query: ({
        data: {
          report: { operationId }
        }
      }) => ({
        url: `/report/operation-details`,
        method: "POST",
        body: {
          data: { report: { operationId } }
        }
      }),
      providesTags: ["Operation"]
    }),

    updateNote: builder.mutation<
      ApiTypes.UpdateNoteResponse,
      ApiTypes.UpdateNoteRequest
    >({
      query: ({ data }) => ({
        url: `/stock-operation/note/edit`,
        method: `POST`,
        body: {
          data
        }
      }),
      invalidatesTags: ["Operation"]
    }),

    // Get All Operation Details
    getDemandFulfillOperationDetails: builder.query<
      ApiTypes.GetDemandFulFillDetailsResponse,
      ApiTypes.GetAllOperationDetailsRequest
    >({
      query: ({
        data: {
          report: { operationId }
        }
      }) => ({
        url: `/report/demand-details`,
        method: "POST",
        body: {
          data: { report: { operationId } }
        }
      }),
      providesTags: ["Operation"]
    }),
    // Get All Operation Details
    getDemandPrintData: builder.query<
      ApiTypes.GetDemandPrintDataResponse,
      ApiTypes.GetDemandPrintDataRequest
    >({
      query: ({
        data: {
          report: { demandStockOperationId }
        }
      }) => ({
        url: `/report/print/demand`,
        method: "POST",
        body: {
          data: { report: { demandStockOperationId } }
        }
      }),
      providesTags: ["Operation"]
    }),

    getVendoerDemandDetails: builder.query<
      ApiTypes.GetVendorDemandDataResponse,
      ApiTypes.GetVendorDemandDataRequest
    >({
      query: ({ data }) => ({
        url: `/report/vendor-demand/${data.report.demandId}`,
        method: "GET"
      })
    }),

    // Location Operations
    getLocationOperations: builder.query<
      ApiTypes.LocationOperationsResponse,
      ApiTypes.LocationOperationsRequest
    >({
      query: ({ data }) => ({
        url: `/report/location-operations`,
        method: "POST",
        body: {
          data
        }
      }),
      providesTags: ["Operation"]
    }),

    // Get Daily Report
    getDailyProductReport: builder.query<
      ApiTypes.DailyProductReportResponse,
      ApiTypes.DailyProductReportRequest
    >({
      query: ({
        data: {
          report: { locationId, startDate, endDate }
        }
      }) => ({
        url: `/report/product-operation-summary`,
        method: "POST",
        body: {
          data: { report: { locationId, startDate, endDate } }
        }
      }),
      providesTags: ["Report"]
    }),

    // Authorized Location
    getAuthorizedLocation: builder.query<
      ApiTypes.GetAuthorizedLocationsResponse,
      ApiTypes.GetAuthorizedLocationsRequest
    >({
      query: () => ({
        url: `/user/get-authorized-locations`,
        method: `GET`
      })
    }),

    // Assign user locations
    getAssignUserLocations: builder.query<
      ApiTypes.GetAssignUserLocationResponse,
      ApiTypes.GetAssignUserLocationRequest
    >({
      query: () => ({
        url: `/location/summary`,
        method: "GET"
      }),
      providesTags: ["Location"]
    }),

    // Assign user list
    getAssignUser: builder.query<
      ApiTypes.GetAssignUserResponse,
      ApiTypes.GetAssignUserRequest
    >({
      query: () => ({
        url: `/user/get-all`,
        method: "GET"
      })
    }),

    // Assign user to location permission
    getAssignUserLocationPermission: builder.mutation<
      ApiTypes.AssignUserPermissionResponse,
      ApiTypes.AssignUserPermissionRequest
    >({
      query: ({ data: { locationId, userId } }) => ({
        url: `/permission/add-location-to-user`,
        method: "POST",
        body: {
          data: { locationId: locationId, userId: userId }
        }
      })
    }),

    // Remove user location permission
    removeUserLocationPermission: builder.mutation<
      ApiTypes.RemoveUserLocationPermissionResponse,
      ApiTypes.RemoveUserLocationPermissionRequest
    >({
      query: ({ data: { locationId, userId } }) => ({
        url: `/permission/remove-location-to-user`,
        method: "POST",
        body: {
          data: { locationId: locationId, userId: userId }
        }
      })
    }),

    // Assign user role permission
    getAssignUserRolePermission: builder.mutation<
      ApiTypes.AssignedUserRolePermissionResponse,
      ApiTypes.AssignedUserRolePermissionRequest
    >({
      query: ({ data: { assignTo, roleName } }) => ({
        url: `/role/assign/user`,
        method: "POST",
        body: {
          data: { assignTo: assignTo, roleName: roleName }
        }
      })
    }),

    // Get product details
    getProductDetails: builder.query<
      ApiTypes.GetProductDetailsResponse,
      ApiTypes.GetProductDetailsRequest
    >({
      query: ({
        data: {
          product: { id }
        }
      }) => ({
        url: `/product/details`,
        method: "POST",
        body: {
          data: { product: { id } }
        }
      }),
      providesTags: ["Product"]
    }),

    // Get product quantity
    getProductQuantity: builder.query<
      ApiTypes.GetProductQuantityResponse,
      ApiTypes.GetProductQuantityRequest
    >({
      query: ({
        data: {
          product: { productId, locationId, productType }
        }
      }) => ({
        url: `/product/quantity`,
        method: "POST",
        body: {
          data: { product: { productId, locationId, productType } }
        }
      })
    }),

    // Edit products
    editProduct: builder.mutation<
      ApiTypes.EditProductResponse,
      ApiTypes.EditProductRequest
    >({
      query: ({ data }) => ({
        url: `/product/update`,
        method: "POST",
        body: {
          data
        }
      }),
      invalidatesTags: ["Product"]
    }),

    // Get location details
    getLocationDetails: builder.query<
      ApiTypes.GetLocationDetailsResponse,
      ApiTypes.GetLocationDetailsRequest
    >({
      query: ({
        data: {
          location: { locationId }
        }
      }) => ({
        url: `/location/details/${locationId}`,
        method: "GET"
      }),
      providesTags: ["Location"]
    }),

    // Edit location
    editLocation: builder.mutation<
      ApiTypes.EditLocationResponse,
      ApiTypes.EditLocationRequest
    >({
      query: ({ data }) => ({
        url: `/location/update`,
        method: "POST",
        body: {
          data
        }
      }),
      invalidatesTags: ["Location"]
    }),

    // get user profile
    getUserProfile: builder.query<
      ApiTypes.UserProfileResponse,
      ApiTypes.UserProfileRequest
    >({
      query: () => ({
        url: `/user/me`,
        method: "GET"
      })
    }),

    // dashboard count
    dashboardCount: builder.query<
      ApiTypes.DashboardCountResponse,
      ApiTypes.DashboardCountRequest
    >({
      query: ({ query }) => ({
        url: `/report/dashboard-count`,
        method: "GET",
        params: query
      }),
      providesTags: ["Count"]
    }),

    // vendor supply operation
    supplyOperation: builder.mutation<
      ApiTypes.VendorSupplyResponse,
      ApiTypes.VendorSupplyRequest
    >({
      query: ({ data }) => ({
        url: `stock-operation/vendor-supply/create`,
        method: `POST`,
        body: {
          data
        }
      }),
      invalidatesTags: ["Operation", "Count", "Product"]
    }),

    supplyOperationWithApproval: builder.mutation<
      ApiTypes.VendorSupplyResponse,
      ApiTypes.VendorSupplyRequest
    >({
      query: ({ data }) => ({
        url: `stock-operation/vendor-supply/create-with-approval`,
        method: `POST`,
        body: {
          data
        }
      }),
      invalidatesTags: ["Operation", "Count", "Product"]
    }),

    // change password
    changePassword: builder.mutation<
      ApiTypes.ChangePasswordResponse,
      ApiTypes.ChangePasswordRequest
    >({
      query: ({
        data: {
          user: { oldPassword, newPassword }
        }
      }) => ({
        url: `/user/change-password`,
        method: `POST`,
        body: { data: { user: { oldPassword, newPassword } } }
      })
    }),

    // reset password
    resetPassword: builder.mutation<
      ApiTypes.ResetPasswordResponse,
      ApiTypes.ResetPasswordRequest
    >({
      query: (data) => ({
        url: `/reset-password`,
        method: `POST`,
        body: data
      })
    }),

    getMe: builder.query<ApiTypes.GetMeResponse, ApiTypes.GetMeRequest>({
      query: () => ({
        url: `/user/me`,
        method: `GET`
      })
    }),

    // custom report
    customReport: builder.mutation<
      ApiTypes.CustomReportResponse,
      ApiTypes.CustomReportRequest
    >({
      query: ({ data }) => ({
        url: `/report/custom`,
        method: `POST`,
        body: {
          data
        }
      })
    }),

    // Adjustment
    adjustment: builder.mutation<
      ApiTypes.AdjustmentResponse,
      ApiTypes.AdjustmentRequest
    >({
      query: ({ data }) => ({
        url: `/stock-operation/adjustment/create`,
        method: `POST`,
        body: {
          data
        }
      }),
      invalidatesTags: ["addItem"]
    }),

    // distribution
    distribution: builder.mutation<
      ApiTypes.DistributionResponse,
      ApiTypes.DistributionRequest
    >({
      query: ({ data }) => ({
        url: `/stock-operation/distribution/create`,
        method: `POST`,
        body: {
          data
        }
      }),
      invalidatesTags: ["Operation", "Product", "Report", "addItem"]
    }),
    //Vendor demand
    vendorDemand: builder.mutation<
      ApiTypes.VendorDemandResponse,
      ApiTypes.VendorDemandRequest
    >({
      query: ({ data }) => ({
        url: `/stock-operation/vendor-demand/create`,
        method: `POST`,
        body: {
          data
        }
      }),
      invalidatesTags: ["Operation", "Product", "Report"]
    }),
    getAllVendorDemand: builder.query<
      ApiTypes.AllVendorDemandResponse,
      ApiTypes.AllVendorDemandRequest
    >({
      query: ({ data }) => ({
        url: `/report/all-vendor-demand`,
        method: `POST`,
        body: {
          data
        }
      }),
      providesTags: ["Operation"]
    }),

    // monthly adjustment report
    monthyAdjustmentReport: builder.query<
      ApiTypes.MonthlyAdjustmentRespone,
      ApiTypes.MonthlyAdjustmentRequest
    >({
      query: ({ data }) => ({
        url: `/report/adjustment-monthly`,
        method: "POST",
        body: {
          data
        }
      })
    }),

    // single operation print
    getOperationPrintData: builder.query<
      ApiTypes.GetOperationPrintDataResponse,
      ApiTypes.GetOperationPrintDataRequest
    >({
      query: ({
        data: {
          report: { operationId }
        }
      }) => ({
        url: `/report/operation-details`,
        method: "POST",
        body: {
          data: { report: { operationId } }
        }
      }),
      providesTags: ["Operation"]
    }),

    // location inventory print
    getLocationInventoryPrint: builder.query<
      ApiTypes.GetLocationInventoryPrintResponse,
      ApiTypes.GetLocationInventoryPrintRequest
    >({
      query: ({
        data: {
          report: { locationId }
        }
      }) => ({
        url: "/report/location-inventory",
        method: "POST",
        body: {
          data: { report: { locationId } }
        }
      }),
      providesTags: ["Operation"]
    }),
    // Assign user locations
    getProductAllLocaitonInventory: builder.query<
      ApiTypes.GetProductAllLocationInventoryResponse,
      ApiTypes.GetProductAllLocationInventoryRequest
    >({
      query: ({ id }) => ({
        url: `/report/product-all-inventory/${id}`,
        method: "GET"
      }),
      providesTags: ["updateTrackingNumber"]
    }),

    updateExpiryDateAndManufactureDate: builder.mutation<
      ApiTypes.UpdateExpiryDateAndManufactureDateResponse,
      ApiTypes.UpdateExpiryDateAndManufactureDateRequest
    >({
      query: ({ data }) => ({
        url: `/stock-operation/expiry-manufacture/update`,
        method: "POST",
        body: {
          data: data
        }
      }),
      invalidatesTags: ["locationInventory"]
    }),
    updateProductTrackingNumber: builder.mutation<
      ApiTypes.UpdateProductTrackingNumberResponse,
      ApiTypes.UpdateProductTrackingNumberRequest
    >({
      query: ({ data }) => ({
        url: `/product/tracking-number/update`,
        method: "POST",
        body: {
          data: data
        }
      }),
      invalidatesTags: ["updateTrackingNumber"]
    }),

    // single product  Operations movemnt
    getSingleProductMovement: builder.mutation<
      ApiTypes.SingleProductMovementResponse,
      ApiTypes.SingleProductMovementRequest
    >({
      query: ({ data }) => ({
        url: `/report/single-product-movement`,
        method: "POST",
        body: {
          data
        }
      })
    }),
    getDateWiseProductMovement: builder.mutation<
      ApiTypes.DateWiseProductMovementResponse,
      ApiTypes.DateWiseProductMovementRequest
    >({
      query: ({ data }) => ({
        url: `/report/date-wise/product-movement`,
        method: "POST",
        body: {
          data
        }
      })
    }),

    // Get Annual Report
    getAnnualProductReport: builder.query<
      ApiTypes.GetAnnualProductReportResponse,
      ApiTypes.GetAnnualProductReportRequest
    >({
      query: ({ data: { locationId, startDate, endDate } }) => ({
        url: `/report/product-prediction`,
        method: "POST",
        body: {
          data: { locationId, startDate, endDate }
        }
      }),
      providesTags: ["Report"]
    }),
    searchGenerics: builder.query<
      ApiTypes.SearchGenericsResponse,
      ApiTypes.SearchGenericsRequest
    >({
      query: ({ query: { keyword } }) => ({
        url: `/product/generic/search?keyword=${keyword}`,
        method: "GET"
      })
    }),

    // update password
    updatePassword: builder.mutation<
      ApiTypes.UpdatePasswordResponse,
      ApiTypes.UpdatePasswordRequest
    >({
      query: (data) => ({
        url: `/update-password`,
        method: `POST`,
        body: data
      })
    }),
    getPrescription: builder.query<
      ApiTypes.GetPrescriptionResponse,
      ApiTypes.GetPrescriptionRequest
    >({
      query: ({ query }) => ({
        url: `/prescription/medicine-generic/get`,
        method: "GET",
        params: query
      })
    }),
    getNotifications: builder.query<
      ApiTypes.GetNotificationsResponse,
      ApiTypes.GetNotificationsRequest
    >({
      query: ({ query }) => ({
        url: `/notification/get`,
        method: "GET",
        params: query
      })
    }),
    // update password
    readNotification: builder.mutation<
      ApiTypes.ReadNotificationResponse,
      ApiTypes.ReadNotificationRequest
    >({
      query: (data) => ({
        url: `/notification/read`,
        method: `POST`,
        body: data
      })
    }),
    getDistributedForPrescription: builder.mutation<
      ApiTypes.GetDistributionForPrescriptionResponse,
      ApiTypes.GetDistributionForPrescriptionRequest
    >({
      query: (data) => ({
        url: `/report/distribution/to-prescription`,
        method: `POST`,
        body: data
      })
    }),
    // update operation Item
    operationItemEdit: builder.mutation<
      ApiTypes.OperationItemEditResponse,
      ApiTypes.OperationItemEditRequest
    >({
      query: (data) => ({
        url: `/operation/item-edit`,
        method: `POST`,
        body: data
      }),
      invalidatesTags: ["Operation"]
    }),
    getLowStock: builder.query<
      ApiTypes.GetLowStockResponse,
      ApiTypes.GetLowStockRequest
    >({
      query: ({ query }) => ({
        url: `/report/low-stock`,
        method: "GET",
        params: query
      })
    }),
    getToBeExpiredItems: builder.query<
      ApiTypes.GetToBeExpiredItemsResponse,
      ApiTypes.GetToBeExpiredItemsRequest
    >({
      query: ({ query }) => ({
        url: `/report/to-be-expired-items`,
        method: "GET",
        params: query
      })
    })
  })
});

export const {
  useRegisterMutation,
  useLoginMutation,
  useAddProductMutation,
  useGetProductQuery,
  useGetFilterProductQuery,
  useLazyGetFilterProductQuery,
  useAddLocationMutation,
  useGetLocationQuery,
  useTransferOperationMutation,
  useGetLocationInventoryQuery,
  useLazyGetLocationInventoryQuery,
  useInitialInventoryMutation,
  useDemandInventoryMutation,
  useGetAllProductsQuery,
  useLazyGetSearchedProductsQuery,
  useSelectLocationMutation,
  useGetAllUsersQuery,
  useLazyGetSearchedUsersQuery,
  useGetAllOperationQuery,
  useGetAllOperationDetailsQuery,
  useLazyGetAllOperationDetailsQuery,
  useGetDailyProductReportQuery,
  useGetLocationOperationsQuery,
  useGetAllLocationsQuery,
  useLazyGetSearchedLocationsQuery,
  useGetAuthorizedLocationQuery,
  useGetPendingOperationQuery,
  useApproveTransferPendingOperationMutation,
  useCancelTransferPendingOperationMutation,
  useCancelDemandPendingOperationMutation,
  useGetAssignUserLocationsQuery,
  useGetAssignUserQuery,
  useGetAssignUserLocationPermissionMutation,
  useGetProductDetailsQuery,
  useEditProductMutation,
  useGetLocationDetailsQuery,
  useEditLocationMutation,
  useGetUserProfileQuery,
  useDashboardCountQuery,
  useSupplyOperationMutation,
  useChangePasswordMutation,
  useGetProductLocationInventoryQuery,
  useGetProductQuantityQuery,
  useTransferEditMutation,
  useGetMeQuery,
  useGetAssignUserRolePermissionMutation,
  useLastTransferAvailableQuery,
  useLazyLastTransferAvailableQuery,
  useGetPreviousInventoryQuery,
  useLazyGetPreviousInventoryQuery,
  useCustomReportMutation,
  useAdjustmentMutation,
  useApproveVendorPendingOperationMutation,
  useCancelVendorPendingOperationMutation,
  useRemoveUserLocationPermissionMutation,
  useTrashMutation,
  useConsumptionMutation,
  useGetSubLocationQuery,
  useLazyGetProductQuantityQuery,
  useDistributionMutation,
  useGetDemandPrintDataQuery,
  useVendorDemandMutation,
  useGetVendoerDemandDetailsQuery,
  useGetAllVendorDemandQuery,
  useMonthyAdjustmentReportQuery,
  useGetOperationPrintDataQuery,
  useGetLocationInventoryPrintQuery,
  useGetDemandFulfillOperationDetailsQuery,
  useGetProductAllLocaitonInventoryQuery,
  useUpdateExpiryDateAndManufactureDateMutation,
  useGetSingleProductMovementMutation,
  useGetFilterItemsWithTrackingDataQuery,
  useUpdateProductTrackingNumberMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useGetAnnualProductReportQuery,
  useLazySearchGenericsQuery,
  useUpdatePasswordMutation,
  useLazyGetPrescriptionQuery,
  useGetDateWiseProductMovementMutation,
  useGetDistributedForPrescriptionMutation,
  useOperationItemEditMutation,
  useUpdateNoteMutation,
  useGetNotificationsQuery,
  useLazyGetNotificationsQuery,
  useReadNotificationMutation,
  useGetLowStockQuery,
  useGetToBeExpiredItemsQuery,
  useSupplyOperationWithApprovalMutation
} = api;
