import React, { Fragment, useMemo, useState } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  useReactTable,
  FilterFn,
  getExpandedRowModel,
  getPaginationRowModel
} from "@tanstack/react-table";
import {
  Box,
  Button,
  Select as SelectPageSize,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  IconButton,
  Tooltip
} from "@chakra-ui/react";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import { customPagination, displayAllTablePage } from "../../../config";
import {
  DateWiseMovementSummary,
  LocationMovementHistory
} from "../../../api/type";
import {
  FaMinusCircle,
  FaPlusCircle,
  FaChevronDown,
  FaChevronUp,
  FaInfoCircle
} from "react-icons/fa";
import dayjs from "dayjs";
declare module "@tanstack/table-core" {
  interface FilterFns {
    tableFilter: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const tableFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank
  });
  return itemRank.passed;
};

interface DateWiseProductMovementTableComponentProps {
  dateWiseProductMovement: DateWiseMovementSummary[];
}

export const DateWiseProductMovementTableComponent: React.FC<
  DateWiseProductMovementTableComponentProps
> = (props: DateWiseProductMovementTableComponentProps) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: customPagination.pageIndex,
    pageSize: customPagination.pageSize
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );

  const [globalFilter, setGlobalFilter] = useState("");

  const columns = useMemo<ColumnDef<DateWiseMovementSummary, any>[]>(
    () => [
      {
        header: "SL",
        cell: (row) => {
          return Number(row.row.id) + 1;
        }
      },
      {
        header: "Date",
        accessorFn: (row) => dayjs(row.date).format("MMM D, YYYY"),
        id: "date",
        cell: (row) => row.getValue()
      },
      {
        header: "Incoming",
        accessorFn: (row) => row.inComing,
        id: "inComming",
        cell: (row) => row.getValue()
      },
      {
        header: "Outgoing",
        accessorFn: (row) => row.outGoing,
        id: "outGoing",
        cell: (row) => row.getValue()
      },
      {
        header: "Count",
        accessorFn: (row) => row.count,
        id: "count",
        cell: (row) => row.getValue()
      },
      {
        id: "expander",
        header: "Expand",
        cell: ({ row }) => {
          return {
            ...(row.getIsExpanded() ? (
              <IconButton
                onClick={row.getToggleExpandedHandler()}
                isRound={true}
                variant="solid"
                aria-label="Done"
                fontSize="15px"
                icon={<FaMinusCircle />}
              />
            ) : (
              <IconButton
                onClick={row.getToggleExpandedHandler()}
                isRound={true}
                variant="solid"
                aria-label="Done"
                fontSize="15px"
                icon={<FaPlusCircle />}
              />
            ))
          };
        }
      }
    ],
    []
  );

  const table = useReactTable({
    data: props.dateWiseProductMovement ? props.dateWiseProductMovement : [],
    columns: columns,
    filterFns: {
      tableFilter
    },
    state: {
      pagination,
      globalFilter
    },
    getRowCanExpand(row) {
      return true;
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    globalFilterFn: tableFilter,
    debugTable: true
  });

  const renderSubComponent = ({ row }: any) => {
    return (
      <Table variant="striped" size="sm">
        <Thead>
          <Tr bg="gray.500">
            <Th color="white">No</Th>
            <Th color="white">OP No</Th>
            <Th color="white">Operation Type</Th>
            <Th color="white">From</Th>
            <Th color="white">To</Th>
            <Th color="white">Invoice No</Th>
            <Th color="white">Tracking Id</Th>
            <Th color="white">Quantity</Th>
            <Th color="white">By </Th>
            <Th color="white">At</Th>
            <Th color="white">Mark</Th>
            <Th color="white">Notes</Th>
          </Tr>
        </Thead>
        <Tbody>
          {row.original.history.map(
            (singleStockOperation: LocationMovementHistory, index: number) => (
              <Tr key={index} color="black">
                <Td>{index + 1}</Td>
                <Td>{singleStockOperation.stockOperationId}</Td>
                <Td>{singleStockOperation.operationType}</Td>
                <Td>{singleStockOperation.originLocation.name}</Td>
                <Td>{singleStockOperation.destinationLocation?.name}</Td>
                <Td>{singleStockOperation.invoiceNo}</Td>
                <Td>{singleStockOperation.trackingNumber}</Td>
                <Td>{singleStockOperation.quantity}</Td>
                <Td>{singleStockOperation.createdBy.name}</Td>
                <Td>
                  {dayjs(singleStockOperation.createdAt).format(
                    "MMM D, YYYY h:mm A"
                  )}
                </Td>
                <Td>
                  {singleStockOperation.isIncrement ? (
                    <FaChevronUp color="green" />
                  ) : (
                    <FaChevronDown color="red" />
                  )}
                </Td>
                <Td>
                  {singleStockOperation.notes &&
                    singleStockOperation.notes !== "" &&
                    singleStockOperation.notes !== null && (
                      <Tooltip hasArrow label={singleStockOperation.notes}>
                        <IconButton
                          isRound={true}
                          variant="solid"
                          aria-label="Done"
                          fontSize="15px"
                          icon={<FaInfoCircle />}
                        />
                      </Tooltip>
                    )}
                </Td>
              </Tr>
            )
          )}
        </Tbody>
      </Table>
    );
  };

  return (
    <Stack>
      <Table variant="striped">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    bg="gray.700"
                    color="white"
                  >
                    {header.isPlaceholder ? null : (
                      <Box>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Box>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Fragment key={row.id}>
                <Tr>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
                {row.getIsExpanded() && (
                  <Tr>
                    <Td colSpan={row.getVisibleCells().length}>
                      {renderSubComponent({ row })}
                    </Td>
                  </Tr>
                )}
              </Fragment>
            );
          })}
        </Tbody>
      </Table>

      <Stack>
        <Stack
          direction={{ base: "column", lg: "row" }}
          justify="space-between"
          alignItems="center"
          mt="2"
        >
          <Box width={{ base: "100%", lg: "60%" }}>
            <Text fontSize="lg" fontWeight="bold">
              Showing (Page {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()})
            </Text>{" "}
          </Box>
          <Stack
            direction="row"
            width={{ base: "100%", lg: "40%" }}
            justifyContent="space-between"
          >
            <Stack direction="row" width={{ lg: "80%" }}>
              <Button
                colorScheme="blue"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {"<<"}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {"<"}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {">"}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {">>"}
              </Button>{" "}
            </Stack>

            <Box width={{ base: "100%", md: "50%", lg: "100%" }}>
              <SelectPageSize
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {displayAllTablePage.map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </SelectPageSize>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
