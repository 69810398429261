import { useEffect, useRef, useState } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ButtonGroup,
  useToast,
  Textarea,
  useDisclosure,
  Select as ChakraSelect,
  Box
} from "@chakra-ui/react";
import {
  useGetAssignUserLocationsQuery,
  useGetProductQuery,
  useVendorDemandMutation
} from "../../../api";
import { Select, GroupBase } from "chakra-react-select";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  OriginLocationGroup,
  ProductGroup
} from "../types/stock-operation.type";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { PageCardComponent } from "../../core/components/page-card.component";
import {
  RemoveSelectedProductFromAllProducts,
  userCurrentRole
} from "../../../utils/common-functions";
import { ConfirmAlertComponent } from "./confirm-alert.component";

interface VendorDemandOperationItems {
  product: ProductGroup;
  appTotalAmount: number;
  appPurchasedAmount: number;
  tenderSerialNumber: number;
  monthlyRequirment: number;
  currentRequirment: number;
}

type VendorDemandFormValues = {
  originLocation: OriginLocationGroup;
  monthDuration: string;
  stockOperationItems: VendorDemandOperationItems[];
  notes: string;
};

const VendorDemandComponent = () => {
  let id = localStorage.getItem("location");
  const toast = useToast();
  const navigate = useNavigate();
  const role = userCurrentRole();
  const demandConfirmAlert = useDisclosure();
  const cancelRef = useRef();
  const getLocationQuery = useGetAssignUserLocationsQuery({});
  const [productOptions, setProductOptions] = useState<ProductGroup[]>([]);
  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);
  const [originLocation, setOriginLocation] = useState<OriginLocationGroup>();
  const [vendorDemand, vendorDemandResult] = useVendorDemandMutation();
  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(id))
      );
    }
  }, [id, originLocationOptions]);

  const getProductQuery = useGetProductQuery({});
  useEffect(() => {
    if (getProductQuery.data?.data.products) {
      const productWithValues = getProductQuery.data?.data.products.map(
        (product) => {
          return {
            label: product.name,
            value: product.id,
            unit: product.unit
          };
        }
      );
      setProductOptions(productWithValues);
    }
  }, [getProductQuery.data]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<VendorDemandFormValues>({
    defaultValues: {
      stockOperationItems: [{}]
    }
  });

  const { fields, append, remove } = useFieldArray<
    VendorDemandFormValues,
    "stockOperationItems"
  >({
    control,
    name: "stockOperationItems"
  });

  const watch = useWatch({
    control,
    name: "stockOperationItems"
  });

  const vendorDemandHandleSubmit = handleSubmit((data) => {
    vendorDemand({
      data: {
        stockOperation: {
          originLocationId: Number(id),
          monthDuration: data.monthDuration,
          stockOperationItems: data.stockOperationItems.map((d) => ({
            productId: d.product.value,
            tenderSerialNumber: Number(d.tenderSerialNumber),
            monthlyRequirment: Number(d.monthlyRequirment),
            currentRequirment: Number(d.currentRequirment),
            appPurchasedAmount: Number(d.appPurchasedAmount),
            appTotalAmount: Number(d.appTotalAmount)
          })),
          notes: data.notes
        }
      }
    });
  });
  useEffect(() => {
    if (vendorDemandResult.isSuccess) {
      toast({
        title: "Success",
        description: "Vendor Demand successfull",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      navigate(`/${role}/report/all-vendor-demand`);
    }
  }, [vendorDemandResult, toast, navigate, role]);

  return (
    <Stack>
      <form onSubmit={vendorDemandHandleSubmit}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
        >
          <PageCardComponent>
            <FormControl mb="3">
              <FormLabel>Origin Location</FormLabel>
              <Input
                _disabled={{ color: "blackAlpha.900", cursor: "not-allowed" }}
                defaultValue={originLocation ? originLocation.label : ""}
                isDisabled={true}
              />
            </FormControl>
          </PageCardComponent>
          <PageCardComponent>
            <FormControl mb="3">
              <FormLabel>Month Duration</FormLabel>
              <Input
                placeholder="Month duration"
                {...register("monthDuration")}
              />
            </FormControl>
          </PageCardComponent>
        </Stack>
        {fields.map((field, index) => {
          return (
            <Stack
              direction={{ base: "column", xl: "row" }}
              width="100%"
              bgColor={"white"}
              borderRadius="md"
              spacing={4}
              px="4"
              py="2"
              key={field.id}
              mt="2"
            >
              <Box w={{ base: "100%", md: "100%", xl: "30%" }}>
                <Controller
                  control={control}
                  name={`stockOperationItems.${index}.product`}
                  rules={{ required: "Select product" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error }
                  }) => (
                    <FormControl
                      isInvalid={!!error}
                      id={`stockOperationItems.${index}.product`}
                    >
                      {index === 0 && (
                        <FormLabel
                          fontSize={{
                            base: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                            "2xl": "12"
                          }}
                        >
                          Select Product
                        </FormLabel>
                      )}
                      <Select<ProductGroup, true, GroupBase<ProductGroup>>
                        name={name}
                        ref={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        options={RemoveSelectedProductFromAllProducts(
                          productOptions,
                          watch
                        )}
                        placeholder="product"
                        closeMenuOnSelect={true}
                        size="sm"
                      />
                      <FormErrorMessage>
                        {error && error.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                <FormControl>
                  {index === 0 && (
                    <FormLabel
                      fontSize={{
                        base: "8",
                        md: "8",
                        lg: "8",
                        xl: "8",
                        "2xl": "12"
                      }}
                    >
                      Unit
                    </FormLabel>
                  )}
                  <Input
                    size={"sm"}
                    _disabled={{
                      color: "blackAlpha.900",
                      cursor: "not-allowed"
                    }}
                    defaultValue={watch[index]?.product?.unit}
                    disabled={true}
                    placeholder="unit"
                  />
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                <FormControl
                  mb="3"
                  isInvalid={
                    errors?.stockOperationItems?.[index]?.tenderSerialNumber
                      ? true
                      : false
                  }
                >
                  {index === 0 && (
                    <FormLabel
                      fontSize={{
                        base: "8",
                        md: "8",
                        lg: "8",
                        xl: "8",
                        "2xl": "12"
                      }}
                    >
                      Tender S/N
                    </FormLabel>
                  )}
                  <Input
                    size={"sm"}
                    {...register(
                      `stockOperationItems.${index}.tenderSerialNumber` as const,
                      { required: "Enter tender S/N" }
                    )}
                    type={"number"}
                    placeholder="tender S/N"
                  />
                  <FormErrorMessage>
                    {
                      errors?.stockOperationItems?.[index]?.tenderSerialNumber
                        ?.message
                    }
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "15%" }}>
                <FormControl
                  mb="3"
                  isInvalid={
                    errors?.stockOperationItems?.[index]?.appTotalAmount
                      ? true
                      : false
                  }
                >
                  {index === 0 && (
                    <FormLabel
                      fontSize={{
                        base: "8",
                        md: "8",
                        lg: "8",
                        xl: "8",
                        "2xl": "12"
                      }}
                    >
                      APP. Total Amount
                    </FormLabel>
                  )}
                  <Input
                    size={"sm"}
                    {...register(
                      `stockOperationItems.${index}.appTotalAmount` as const,
                      { required: "Enter APP. TTL amount" }
                    )}
                    type={"number"}
                    placeholder="APP. TTL amount"
                  />
                  <FormErrorMessage>
                    {
                      errors?.stockOperationItems?.[index]?.appTotalAmount
                        ?.message
                    }
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "15%" }}>
                <FormControl
                  mb="3"
                  isInvalid={
                    errors?.stockOperationItems?.[index]?.appPurchasedAmount
                      ? true
                      : false
                  }
                >
                  {index === 0 && (
                    <FormLabel
                      fontSize={{
                        base: "8",
                        md: "8",
                        lg: "8",
                        xl: "8",
                        "2xl": "12"
                      }}
                    >
                      APP. Purchased Amount
                    </FormLabel>
                  )}
                  <Input
                    size={"sm"}
                    {...register(
                      `stockOperationItems.${index}.appPurchasedAmount` as const,
                      { required: "Enter APP. Pur. amount" }
                    )}
                    type={"number"}
                    placeholder="APP. Purchased amount"
                  />
                  <FormErrorMessage>
                    {
                      errors?.stockOperationItems?.[index]?.appPurchasedAmount
                        ?.message
                    }
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                <FormControl
                  mb="3"
                  isInvalid={
                    errors?.stockOperationItems?.[index]?.monthlyRequirment
                      ? true
                      : false
                  }
                >
                  {index === 0 && (
                    <FormLabel
                      fontSize={{
                        base: "8",
                        md: "8",
                        lg: "8",
                        xl: "8",
                        "2xl": "12"
                      }}
                    >
                      Monthly REQs
                    </FormLabel>
                  )}
                  <Input
                    size={"sm"}
                    {...register(
                      `stockOperationItems.${index}.monthlyRequirment` as const,
                      { required: "Enter M. REQs" }
                    )}
                    type={"number"}
                    placeholder="Monthly REQs"
                  />
                  <FormErrorMessage>
                    {
                      errors?.stockOperationItems?.[index]?.monthlyRequirment
                        ?.message
                    }
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                <FormControl
                  mb="3"
                  isInvalid={
                    errors?.stockOperationItems?.[index]?.currentRequirment
                      ? true
                      : false
                  }
                >
                  {index === 0 && (
                    <FormLabel
                      fontSize={{
                        base: "8",
                        md: "8",
                        lg: "8",
                        xl: "8",
                        "2xl": "12"
                      }}
                    >
                      Curr. REQs
                    </FormLabel>
                  )}
                  <Input
                    size={"sm"}
                    {...register(
                      `stockOperationItems.${index}.currentRequirment` as const,
                      { required: "Enter Curr. REQs" }
                    )}
                    type={"number"}
                    placeholder="Current REQs"
                  />
                  <FormErrorMessage>
                    {
                      errors?.stockOperationItems?.[index]?.currentRequirment
                        ?.message
                    }
                  </FormErrorMessage>
                </FormControl>
              </Box>

              <ButtonGroup>
                <Button
                  size={"xs"}
                  mt={index === 0 ? 7 : 0}
                  colorScheme="red"
                  variant="outline"
                  leftIcon={<FaTimes />}
                  onClick={() => remove(index)}
                  isDisabled={index > 0 ? false : true}
                >
                  Remove
                </Button>
              </ButtonGroup>
            </Stack>
          );
        })}
        <Stack spacing={4}>
          <Button
            my="2"
            width="100%"
            colorScheme="blue"
            variant="outline"
            leftIcon={<FaPlus />}
            onClick={() => append({})}
          >
            Add
          </Button>
          <Textarea
            placeholder="Enter notes for this Operation"
            {...register("notes")}
          />
          <Button
            width="100%"
            colorScheme="blue"
            onClick={demandConfirmAlert.onOpen}
          >
            Submit
          </Button>
          <ConfirmAlertComponent
            cancelRef={cancelRef}
            onClose={demandConfirmAlert.onClose}
            isOpen={demandConfirmAlert.isOpen}
            onOpen={demandConfirmAlert.onOpen}
            handleApprove={vendorDemandHandleSubmit}
            status={vendorDemandResult}
          />
        </Stack>
      </form>
    </Stack>
  );
};
export default VendorDemandComponent;
