import React from "react";
import dayjs from "dayjs";
import { Button, useDisclosure } from "@chakra-ui/react";
import { DailyReportPrintComponent } from "./daily-report-print.component";
import { PdfPrinterModal } from "../../core/components/pdf-printer-modal.component";
import { DailyReportFilterDataType } from "../../report/types/custom-report.type";

interface DailyProductReportPrintComponentProps {
  printData: DailyReportFilterDataType;
  edclProducts: boolean;
}

export const DailyProductReportPrintComponent: React.FC<
  DailyProductReportPrintComponentProps
> = (props: DailyProductReportPrintComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = (
    <DailyReportPrintComponent
      printData={props.printData}
      edclProducts={props.edclProducts}
    />
  );

  const dailyReport = `Daily-Report-${props.printData.location.name}-${dayjs(
    props.printData.startDate
  ).format("DD-MM-YY")}.pdf`;

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={dailyReport}
      />
    </>
  );
};
