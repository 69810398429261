import React, { forwardRef, useEffect, useState } from "react";
import { OriginLocationGroup } from "../../types/stock-operation.type";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton
} from "@chakra-ui/react";
import _ from "lodash";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { Select } from "chakra-react-select";
import { CalendarIcon } from "@chakra-ui/icons";
import { Controller, useForm } from "react-hook-form";
import makePDF from "../../../../service/printPdf/makePDF";
import { LoaderComponent } from "../../../core/components/loader.component";
import { PageCardComponent } from "../../../core/components/page-card.component";
import { StockOperationType } from "../../../stock-operation/types/stock-operation.type";
import {
  canSelectLocation,
  chakraStyles,
  userCurrentRole
} from "../../../../utils/common-functions";
import {
  useGetAssignUserLocationsQuery,
  useGetDailyProductReportQuery
} from "../../../../api";
import { CmchReportHistoryModalComponent } from "./cmch-report-history-modal.component";
import { ProductOperation } from "../../../../api/type";

type MonthlyReportFormValues = {
  originLocation: OriginLocationGroup;
};

interface CmchMonthlyProductReportComponentProps {}

export const CmchMonthlyProductReportComponent: React.FC<
  CmchMonthlyProductReportComponentProps
> = (props: CmchMonthlyProductReportComponentProps) => {
  let locationId = localStorage.getItem("location");
  const role = userCurrentRole();

  let initialState = {
    label: "",
    value: Number(locationId)
  };

  const [originLocation, setOriginLocation] =
    useState<OriginLocationGroup>(initialState);

  const [modalData, setModalData] = useState<ProductOperation>();

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);

  const getLocationQuery = useGetAssignUserLocationsQuery({});

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(locationId))!
      );
    }
  }, [locationId, originLocationOptions]);

  const handleGetLocation = (data: any) => {
    setOriginLocation(data);
  };

  const { control } = useForm<MonthlyReportFormValues>({});

  const [dateStart, setDateStart] = useState(new Date());

  const { data, isLoading } = useGetDailyProductReportQuery({
    data: {
      report: {
        locationId: Number(originLocation?.value),
        startDate: dayjs(dateStart.toISOString())
          .startOf("month")
          .toDate()
          .toISOString(),
        endDate: dayjs(dateStart.toISOString())
          .endOf("month")
          .toDate()
          .toISOString()
      }
    }
  });

  const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
    <Input
      autoComplete="off"
      background="white"
      value={value}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
    />
  );
  customDateInput.displayName = "DateInput";

  const CustomInput = forwardRef(customDateInput);
  function onChangeHandler(value: any) {
    setDateStart(value);
  }
  const icon = <CalendarIcon fontSize="sm" />;

  let allLocation = data?.data.productOperationSummary
    .map((op) => op.operations.map((o) => o.destinationLocation))
    .flat();

  let allUniqueLocation = _.uniqBy(allLocation, "id");
  let allUniqueLocationWithoutNull: { id: number; name: string }[] = [];
  allUniqueLocation.forEach((element) => {
    if (element.id) {
      allUniqueLocationWithoutNull.push(element);
    }
  });

  let headerLocation = [
    {
      name: "P_Id",
      id: Number.MIN_SAFE_INTEGER
    },
    {
      name: "Product Name",
      id: 0
    },
    ...allUniqueLocationWithoutNull,
    {
      name: "Total In",
      id: 99998
    },
    {
      name: "Total Out",
      id: 99999
    }
  ];

  let reportData: any[] = [];

  const findeIndexExist = (id: number) => {
    let indexId = headerLocation.findIndex((TH) => TH.id === id);
    return indexId;
  };

  data?.data.productOperationSummary.forEach((product) => {
    let temp: Array<string | number> = [product.productId];
    temp.push(product.name);
    allUniqueLocationWithoutNull.map((d) => {
      temp.push(0);
    });
    temp.push(0);
    temp.push(0);
    product.operations.map((op) => {
      if (
        op.destinationLocation.id &&
        op.operationType === StockOperationType.TRANSFER &&
        op.destinationLocation.id !== originLocation.value
      ) {
        const indexId = findeIndexExist(op.destinationLocation.id);
        if (indexId !== -1) {
          temp[indexId] = +temp[indexId] + op.quantity;
          temp[temp.length - 1] = +temp[temp.length - 1] + op.quantity;
        }
      } else if (op.operationType === StockOperationType.ADJUSTMENT) {
        if (op.quantity < 0) {
          temp[temp.length - 1] = +temp[temp.length - 1] + op.quantity;
        }
      }
    });
    temp[temp.length - 2] = product.inwardCount;
    reportData.push(temp);
  });

  const csvData = [headerLocation.map((d) => d.name), ...reportData];

  const monthlyProductReport = `Monthly-Product-Report-${dateStart}.csv`;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOperationDetails = (id: number) => {
    let operationSummary = data?.data.productOperationSummary.find(
      (p) => p.productId === id
    );

    if (operationSummary) {
      setModalData(operationSummary);
    }
  };

  return (
    <Stack>
      <Stack direction={{ base: "column", xl: "row" }} justify="space-between">
        <PageCardComponent>
          <Controller
            control={control}
            name="originLocation"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { error }
            }) => (
              <FormControl mb="3" isInvalid={!!error} id="originLocation">
                <FormLabel>Select Location</FormLabel>
                <Select
                  chakraStyles={chakraStyles}
                  name={name}
                  ref={ref}
                  onChange={(location) => handleGetLocation(location)}
                  onBlur={onBlur}
                  value={originLocation}
                  isDisabled={!canSelectLocation(role)}
                  options={originLocationOptions}
                  placeholder="Select location"
                  closeMenuOnSelect={true}
                />
                <FormErrorMessage>{error && error.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </PageCardComponent>
      </Stack>
      <Stack>
        {modalData && (
          <CmchReportHistoryModalComponent
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            operationSummary={modalData}
          />
        )}
        <Stack direction={{ base: "column", xl: "row" }} width="100%" my="2">
          <Box></Box>
          <Spacer />
          <Box>
            <InputGroup className="dark-theme">
              <DatePicker
                id="dateStartEnd"
                selected={dateStart}
                onChange={onChangeHandler}
                dateFormat="MMM yyyy"
                className="react-datapicker__input-text"
                placeholderText="Select Month"
                customInput={<CustomInput />}
                showDisabledMonthNavigation
                showMonthYearPicker
              />
              <InputRightElement color="gray.500" children={icon} />
            </InputGroup>
          </Box>
          <ButtonGroup gap="2">
            {data ? (
              <CSVLink data={csvData} filename={monthlyProductReport}>
                <Button colorScheme="blue">Export CSV</Button>
              </CSVLink>
            ) : undefined}
          </ButtonGroup>
          <ButtonGroup gap="2">
            {data ? (
              <Button
                colorScheme="blue"
                onClick={() => makePDF(data, "monthlyReport")}
              >
                Print Report
              </Button>
            ) : undefined}
          </ButtonGroup>
        </Stack>
      </Stack>

      {isLoading ? (
        <LoaderComponent />
      ) : (
        <TableContainer>
          {headerLocation.length && reportData.length > 0 ? (
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  {headerLocation.map((value, key) => {
                    return <Th key={key}>{value.name}</Th>;
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {reportData.map((row, key) => {
                  return (
                    <>
                      <Tr
                        key={key}
                        onClick={() => handleOperationDetails(row[0])}
                      >
                        {row.map((value: any) => {
                          return <Td onClick={onOpen}>{value}</Td>;
                        })}
                      </Tr>
                    </>
                  );
                })}
              </Tbody>
            </Table>
          ) : (
            <Center width={"70vw"} height={"30vh"}>
              <Flex>No Data Found !!</Flex>
            </Center>
          )}
        </TableContainer>
      )}
    </Stack>
  );
};
