import { Center, Stack } from "@chakra-ui/react";
import { PageCardComponent } from "../../core/components/page-card.component";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

interface DashboardCountChartComponentProps {
  data:
    | {
        locationCount: number;
        operationCount: number;
        productCount: number;
      }
    | undefined;
}

const DashboardCountChartComponent = (
  props: DashboardCountChartComponentProps
) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const data = {
    labels: ["Products", "Operations", "Locations"],
    datasets: [
      {
        label: "Total",
        data: [
          props.data?.productCount,
          props.data?.operationCount,
          props.data?.locationCount
        ],
        backgroundColor: ["#FF4069", "#059BFF", "#FFC234"]
      }
    ]
  };
  return (
    <Stack boxShadow="sm">
      <PageCardComponent>
        <Center>
          <Stack width={"50%"}>
            <Pie data={data} />
          </Stack>
        </Center>
      </PageCardComponent>
    </Stack>
  );
};

export default DashboardCountChartComponent;
