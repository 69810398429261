import React, { FC, useEffect, useState } from "react";
import {
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Text,
  Box,
  useToast
} from "@chakra-ui/react";
import { PageCardComponent } from "../../core/components/page-card.component";
import { useGetFilterItemsWithTrackingDataQuery } from "../../../api";
import { ENTER_KEY, itemGroup } from "../types/stock-operation.type";
import {
  Select,
  chakraComponents,
  OptionBase,
  GroupBase,
  SelectComponentsConfig
} from "chakra-react-select";

import dayjs from "dayjs";
import { productExpColorLevel } from "../../../utils/exp-color";
import { FaPlus } from "react-icons/fa";
import { ProductTrackingTypeOptions } from "../../../api/type";

const CustomSelect: SelectComponentsConfig<
  itemGroup,
  true,
  GroupBase<itemGroup>
> = {
  Option: ({ children, ...props }) => {
    return (
      <>
        <chakraComponents.Option {...props}>
          <Text
            color={
              props.data.expirydate
                ? productExpColorLevel(props.data.expirydate)
                : "black"
            }
          >
            {" "}
            {children?.toLocaleString().toUpperCase()}
          </Text>
        </chakraComponents.Option>
        <chakraComponents.Option {...props}>
          <Text
            fontSize={{
              base: "8",
              md: "8",
              lg: "8",
              xl: "8",
              "2xl": "10"
            }}
            color={
              props.data.expirydate
                ? productExpColorLevel(props.data.expirydate)
                : "black"
            }
          >
            available-{" "}
            <Text
              as="span"
              fontSize={{
                base: "12"
              }}
            >
              {props.data.available}
              {"   "}{" "}
            </Text>
            {"   "} {props.data.unit.toLowerCase()}{" "}
            {props.data.expirydate ? (
              <Text as="span">
                exp -{dayjs(props.data.expirydate).format("MMM-YY")}{" "}
              </Text>
            ) : null}
          </Text>
        </chakraComponents.Option>
      </>
    );
  },
  MultiValueContainer: ({ children, ...props }) => (
    <>
      <chakraComponents.MultiValueContainer {...props}>
        {children}
      </chakraComponents.MultiValueContainer>
    </>
  )
};
interface AddItemComponentProps {
  locationId: number;
  addSingleItems(selectedItem: itemGroup, quantity: number): void;
}

export const AddItemComponent: React.FC<AddItemComponentProps> = (
  props: AddItemComponentProps
) => {
  const toast = useToast();
  const getProductQuery = useGetFilterItemsWithTrackingDataQuery({
    id: Number(props.locationId)
  });

  const [productOptions, setProductOptions] = useState<itemGroup[]>([]);
  const [selectedItem, setSelectedItem] = useState<itemGroup | null>(null);
  const [addQuantity, setAddedQuantity] = useState<number>();
  const [key, setKey] = useState<number>(1);
  const handleItemSelect = (data: any) => {
    setSelectedItem(data);
  };

  useEffect(() => {
    if (getProductQuery.data?.data.items) {
      const productWithValues = getProductQuery.data?.data.items
        .filter((singleItem) => singleItem.quantity > 0)
        .map((product) => {
          return {
            label: product.name,
            value: product.id,
            productId: product.productId,
            unit: product.unit,
            available: product.quantity,
            type: product.type,
            trackingData: product.trackingData,
            expirydate: product.expirydate,
            manuFacturngDate: product.manuFacturngDate
          };
        });
      setProductOptions(productWithValues);
    }
  }, [getProductQuery.data]);

  const submitItem = () => {
    if (selectedItem && addQuantity) {
      props.addSingleItems(selectedItem, addQuantity);
      setSelectedItem(null);
      setAddedQuantity(undefined);
      setKey((pre) => pre++);
      return;
    }
    toast({
      title: "Error",
      description: "Select product and quantity",
      status: "error",
      position: "top",
      duration: 2000,
      isClosable: true
    });
  };

  const pressEnter = (event: any) => {
    if (event.keyCode !== ENTER_KEY) return;
    if (selectedItem && addQuantity) {
      if (selectedItem.type === ProductTrackingTypeOptions.SERIALIZED) {
        props.addSingleItems(selectedItem, 1);
      } else {
        props.addSingleItems(selectedItem, addQuantity);
      }

      setSelectedItem(null);
      setAddedQuantity(undefined);
      setKey((pre) => pre++);
      return;
    }
    toast({
      title: "Error",
      description: "Select product and quantity",
      status: "error",
      position: "top",
      duration: 2000,
      isClosable: true
    });
  };

  return (
    <Stack>
      <Stack width="100%" bgColor="white" borderRadius="md" p={2} spacing={4}>
        <Stack
          direction="column"
          width="100%"
          border="2px dashed lightgrey"
          borderRadius="md"
          p={2}
        >
          <Stack
            direction={{ base: "column", xl: "row" }}
            width="100%"
            borderRadius="md"
            alignItems={"flex-end"}
          >
            <Stack width="100%">
              <FormControl>
                <FormLabel display="flex">Add Item</FormLabel>
                <Select
                  name="addItem"
                  options={productOptions}
                  placeholder="Add Item..."
                  components={CustomSelect}
                  value={selectedItem}
                  onChange={(data) => handleItemSelect(data)}
                  size="sm"
                />
              </FormControl>
            </Stack>

            {selectedItem && (
              <Stack width="100%" direction="row" alignItems={"flex-end"}>
                <FormControl>
                  <FormLabel display="flex">Quantity</FormLabel>
                  <Input
                    _disabled={{
                      color: "blackAlpha.900",
                      cursor: "not-allowed"
                    }}
                    placeholder="Quantity"
                    value={addQuantity || ""}
                    onChange={(e) => {
                      setAddedQuantity(Number(e.target.value));
                    }}
                    onKeyDown={(e) => pressEnter(e)}
                    size="sm"
                  />
                </FormControl>
                <Button
                  leftIcon={<FaPlus />}
                  width="20%"
                  colorScheme="green"
                  fontSize={{
                    base: "8",
                    md: "8",
                    lg: "8",
                    xl: "10",
                    "2xl": "12"
                  }}
                  onClick={submitItem}
                  size="sm"
                >
                  Add Item
                </Button>
              </Stack>
            )}
          </Stack>

          {selectedItem && (
            <Stack
              direction={{ base: "column", xl: "row" }}
              width="100%"
              borderRadius="md"
            >
              <Box w={{ base: "100%", md: "100%", xl: "25%" }}>
                <FormControl>
                  <FormLabel
                    fontSize={{
                      base: "8",
                      md: "8",
                      lg: "8",
                      xl: "10",
                      "2xl": "12"
                    }}
                  >
                    Unit
                  </FormLabel>
                  <Input
                    _disabled={{
                      color: "blackAlpha.900",
                      cursor: "not-allowed"
                    }}
                    disabled={true}
                    placeholder="unit"
                    value={selectedItem.unit}
                    size="sm"
                    fontSize={{
                      base: "8",
                      md: "8",
                      lg: "8",
                      xl: "10",
                      "2xl": "12"
                    }}
                  />
                </FormControl>
              </Box>
              {selectedItem.trackingData && (
                <Box w={{ base: "100%", md: "100%", xl: "25%" }}>
                  <FormLabel
                    fontSize={{
                      base: "8",
                      md: "8",
                      lg: "8",
                      xl: "10",
                      "2xl": "12"
                    }}
                  >
                    Tracking ID
                  </FormLabel>
                  <Input
                    _disabled={{
                      color: "blackAlpha.900",
                      cursor: "not-allowed"
                    }}
                    disabled={true}
                    placeholder="Serial / Batch Number"
                    value={selectedItem.trackingData}
                    size="sm"
                    fontSize={{
                      base: "8",
                      md: "8",
                      lg: "8",
                      xl: "10",
                      "2xl": "12"
                    }}
                  />
                </Box>
              )}
              <Box w={{ base: "100%", md: "100%", xl: "25%" }}>
                <FormControl mb="3" alignItems="center">
                  <FormLabel
                    fontSize={{
                      base: "8",
                      md: "8",
                      lg: "8",
                      xl: "10",
                      "2xl": "12"
                    }}
                  >
                    Expiry Date
                  </FormLabel>
                  <Input
                    _disabled={{
                      color: "blackAlpha.900",
                      cursor: "not-allowed"
                    }}
                    disabled={true}
                    placeholder="Expiry Date"
                    value={
                      selectedItem.expirydate
                        ? dayjs(selectedItem.expirydate).format("MMM-YY")
                        : "Not Found"
                    }
                    size="sm"
                  />
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "25%" }}>
                <FormControl mb="3" alignItems="center">
                  <FormLabel
                    fontSize={{
                      base: "8",
                      md: "8",
                      lg: "8",
                      xl: "10",
                      "2xl": "12"
                    }}
                  >
                    Available
                  </FormLabel>
                  <Input
                    _disabled={{
                      color: "blackAlpha.900",
                      cursor: "not-allowed"
                    }}
                    value={selectedItem.available}
                    disabled={true}
                    placeholder="available"
                    size="sm"
                    fontSize={{
                      base: "8",
                      md: "8",
                      lg: "8",
                      xl: "10",
                      "2xl": "12"
                    }}
                  />
                </FormControl>
              </Box>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
