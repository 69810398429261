import React, { useState } from "react";
import { useEffect } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast
} from "@chakra-ui/react";
import { Select, GroupBase } from "chakra-react-select";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PageCardComponent } from "../../core/components/page-card.component";
import { UserListGroup, UserRoleGroup } from "../types/user.type";
import { roleOptions } from "../data/user-role.data";

import {
  useGetAssignUserQuery,
  useGetAssignUserRolePermissionMutation
} from "../../../api";
import { userCurrentRole } from "../../../utils/common-functions";

type AssignUserRoleFormValues = {
  userList: UserListGroup;
  role: UserRoleGroup;
};

interface AssignUserRoleComponentProps {}

export const AssignUserRoleComponent: React.FC<AssignUserRoleComponentProps> = (
  props: AssignUserRoleComponentProps
) => {
  const navigate = useNavigate();
  const toast = useToast();
  const role = userCurrentRole();

  const getUserQuery = useGetAssignUserQuery({});

  const [userListOptions, setUserListOptions] = useState<UserListGroup[]>([]);

  useEffect(() => {
    if (getUserQuery.data?.data.user) {
      const userWithValues = getUserQuery.data?.data.user.map((user) => {
        return {
          label: user.name,
          value: user.id
        };
      });
      setUserListOptions(userWithValues);
    }
  }, [getUserQuery.data]);

  const { handleSubmit, control } = useForm<AssignUserRoleFormValues>({});

  const [assignedUserRoleCreate, assignedUserRoleResult] =
    useGetAssignUserRolePermissionMutation();

  const handleAssignedUserRole = handleSubmit((data) => {
    assignedUserRoleCreate({
      data: {
        assignTo: data.userList.value,
        roleName: data.role.value
      }
    });
  });

  useEffect(() => {
    if (assignedUserRoleResult.isSuccess) {
      toast({
        title: "Success",
        description: "Assigned user role successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      navigate(`/${role}/dashboard`);
    }
  }, [assignedUserRoleResult, toast, navigate, role]);

  return (
    <Stack>
      <form onSubmit={handleAssignedUserRole}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
        >
          <PageCardComponent>
            <Controller
              control={control}
              name="userList"
              rules={{ required: "Please select at least one user." }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => (
                <FormControl mb="3" isInvalid={!!error} id="userList">
                  <FormLabel>User List</FormLabel>
                  <Select<UserListGroup, true, GroupBase<UserListGroup>>
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    options={userListOptions}
                    placeholder="Select user"
                    closeMenuOnSelect={true}
                  />
                  <FormErrorMessage>{error && error.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </PageCardComponent>

          <PageCardComponent>
            <Controller
              control={control}
              name="role"
              rules={{ required: "Select role" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => (
                <FormControl isInvalid={!!error} id="role">
                  <FormLabel>User Role</FormLabel>
                  <Select<UserRoleGroup, true, GroupBase<UserRoleGroup>>
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    options={roleOptions}
                    placeholder="Select role"
                    closeMenuOnSelect={true}
                  />
                  <FormErrorMessage>{error && error.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </PageCardComponent>
        </Stack>
        <Button mt="2" width="100%" type="submit" colorScheme="blue">
          Submit
        </Button>
      </form>
    </Stack>
  );
};
