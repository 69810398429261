import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useToast
} from "@chakra-ui/react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { PageCardComponent } from "../../core/components/page-card.component";
import { useUpdatePasswordMutation } from "../../../api";

interface UpdatePasswordComponentProps {}

type UpdatePasswordFormValues = {
  userEmail: string;
  userPassword: string;
  userConfirmPassword: string;
  adminPassword: string;
};

const loginFormSchema = z
  .object({
    adminPassword: z
      .string()
      .min(8, { message: "Password must be 8 or more characters long" }),
    userEmail: z.string().email(),
    userPassword: z
      .string()
      .min(8, { message: "Password must be 8 or more characters long" }),
    userConfirmPassword: z.string()
  })
  .refine((data) => data.userPassword === data.userConfirmPassword, {
    message: "Passwords do not match.",
    path: ["userConfirmPassword"]
  });
export const UpdatePasswordComponent: React.FC<UpdatePasswordComponentProps> = (
  props: UpdatePasswordComponentProps
) => {
  const toast = useToast();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<UpdatePasswordFormValues>({
    resolver: zodResolver(loginFormSchema)
  });

  const [updatePassword, updatePasswordResult] = useUpdatePasswordMutation();

  const handleUpdatePassword = handleSubmit((formData) => {
    updatePassword({
      data: {
        userEmail: formData.userEmail,
        userPassword: formData.userPassword,
        userConfirmPassword: formData.userConfirmPassword,
        adminPassword: formData.adminPassword
      }
    });
  });

  useEffect(() => {
    if (updatePasswordResult.isSuccess) {
      toast({
        title: "Success",
        description: "Updated password successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      reset();
    }
  }, [updatePasswordResult, toast]);

  const [tooglePassword, setTooglePassword] = useState(false);

  const ViewPassword = (props: {
    tooglePassword: boolean;
    setTooglePassword: typeof setTooglePassword;
  }) => {
    return (
      <InputRightElement
        cursor="pointer"
        onClick={() => setTooglePassword(!tooglePassword)}
        children={
          tooglePassword ? (
            <FaRegEye color="gray.300" />
          ) : (
            <FaRegEyeSlash color="gray.300" />
          )
        }
      />
    );
  };

  return (
    <Stack>
      <form onSubmit={handleUpdatePassword}>
        <Stack my="2">
          <PageCardComponent>
            <FormControl
              id="adminPassword"
              isInvalid={errors.adminPassword ? true : false}
            >
              <FormLabel>Admin Password</FormLabel>
              <InputGroup>
                <Input
                  {...register("adminPassword")}
                  placeholder="********"
                  type={tooglePassword ? "text" : "password"}
                />
                <ViewPassword
                  tooglePassword={tooglePassword}
                  setTooglePassword={setTooglePassword}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.adminPassword?.message}
              </FormErrorMessage>
            </FormControl>
          </PageCardComponent>
        </Stack>

        <Stack my="2">
          <PageCardComponent>
            <FormControl
              id="userEmail"
              isInvalid={errors.userEmail ? true : false}
            >
              <FormLabel>User Email</FormLabel>
              <Input
                {...register("userEmail")}
                type="email"
                placeholder="johndoe@gmail.com"
              />
              <FormErrorMessage>{errors.userEmail?.message}</FormErrorMessage>
            </FormControl>
          </PageCardComponent>
        </Stack>

        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
          my="2"
        >
          <PageCardComponent>
            <FormControl
              id="userPassword"
              isInvalid={errors.userPassword ? true : false}
            >
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  {...register("userPassword")}
                  placeholder="********"
                  type={tooglePassword ? "text" : "password"}
                />
                <ViewPassword
                  tooglePassword={tooglePassword}
                  setTooglePassword={setTooglePassword}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.userPassword?.message}
              </FormErrorMessage>
            </FormControl>
          </PageCardComponent>

          <PageCardComponent>
            <FormControl
              id="password-confirmation"
              isInvalid={errors.userConfirmPassword ? true : false}
            >
              <FormLabel>Confirm Password</FormLabel>
              <InputGroup>
                <Input
                  {...register("userConfirmPassword")}
                  placeholder="********"
                  type={tooglePassword ? "text" : "password"}
                />
                <ViewPassword
                  tooglePassword={tooglePassword}
                  setTooglePassword={setTooglePassword}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.userConfirmPassword?.message}
              </FormErrorMessage>
            </FormControl>
          </PageCardComponent>
        </Stack>

        <Button
          width="100%"
          colorScheme={"blue"}
          variant={"solid"}
          type="submit"
        >
          Update Password
        </Button>
      </form>
    </Stack>
  );
};
