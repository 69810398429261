import React from "react";
import { allUIType } from "../../../api/type";
import { env } from "../../../config/index";
import { OperationDetailsPrintPreview } from "./operation-details-print-preview";
import { OperationDetailsPrintDownload } from "./operation-details-print-download";
import { Button } from "@chakra-ui/react";

interface OperationDetailsPrintComponentProps {
  id: number;
}

export const OperationDetailsPrintComponent: React.FC<
  OperationDetailsPrintComponentProps
> = (props: OperationDetailsPrintComponentProps) => {
  const operationId = props.id;

  return (
    <>
      {env.uiType === allUIType.CMCH ? (
        <Button colorScheme="blue">
          <OperationDetailsPrintPreview id={operationId} />
        </Button>
      ) : (
        <OperationDetailsPrintDownload id={operationId} />
      )}
    </>
  );
};
