import React, { Fragment, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Spacer,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import { PageCardComponent } from "../../core/components/page-card.component";
import {
  useCancelDemandPendingOperationMutation,
  useGetAllOperationDetailsQuery,
  useTransferOperationMutation
} from "../../../api";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { userCurrentRole } from "../../../utils/common-functions";
import { CancelAlertPopupComponent } from "../../core/components/cancel-alert-popup.component";
import { IoMdArrowRoundBack } from "react-icons/io";
import { LocationDemandPrintComponent } from "../../print/components/location-demand-print.component";
import { env } from "../../../config/index";
import { InventoryDemandPrintComponent } from "../../print/components/inventory-demand-print.component";

interface DemandOperationDetailsProps {
  id: string | null;
  type: string | null;
}

const DemandOperationDetailsComponent: React.FC<DemandOperationDetailsProps> = (
  props: DemandOperationDetailsProps
) => {
  const navigate = useNavigate();
  const role = userCurrentRole();
  const toast = useToast();
  const locationId = localStorage.getItem("location");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const { data } = useGetAllOperationDetailsQuery({
    data: { report: { operationId: Number(props.id) } }
  });

  const [cancelPendingOperation, cancelPendingOperationResult] =
    useCancelDemandPendingOperationMutation();

  const handleCancel = () => {
    if (data?.data.operationDetails.operationType === "DEMAND") {
      cancelPendingOperation({
        data: {
          stockOperation: {
            id: Number(data?.data.operationDetails.id)
          }
        }
      });
    }
  };

  const [createTransfer, resultTransfer] = useTransferOperationMutation();

  const handleFulfilDemand = (id: number) => {
    navigate(`/${role}/demand-fulfil/${id}`);
  };

  useEffect(() => {
    if (cancelPendingOperationResult.isSuccess) {
      toast({
        title: "Success",
        description: "Cancelled Demand operation successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      navigate(`/${role}/report/location-operations`);
    }

    if (resultTransfer.isSuccess) {
      toast({
        title: "Success",
        description: "Approved Demand operation successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      navigate(`/${role}/report/location-operations`);
    }
  }, [cancelPendingOperationResult, toast, navigate, resultTransfer, role]);
  return (
    <Stack>
      <Flex justifyContent="end">
        <Button
          colorScheme="blue"
          leftIcon={<IoMdArrowRoundBack />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Flex>

      <Stack spacing={8} direction={{ base: "column", lg: "row" }}>
        <PageCardComponent>
          <Stack>
            <Table size={"sm"} variant={"unstyled"}>
              <Tbody>
                <Tr>
                  <Th>Origin Location</Th>
                  <Td>:</Td>
                  <Td>{data?.data.operationDetails.originLocation?.name}</Td>
                </Tr>
                <Tr>
                  <Th>Operation Type</Th>
                  <Td>:</Td>
                  <Td>{data?.data.operationDetails.operationType}</Td>
                </Tr>
                <Tr>
                  <Th>Operation Status</Th>
                  <Td>:</Td>
                  <Td>{data?.data.operationDetails.operationStatus}</Td>
                </Tr>
                <Tr>
                  <Th>Operation By</Th>
                  <Td>:</Td>
                  <Td>{data?.data.operationDetails.createdBy?.name}</Td>
                </Tr>
                <Tr>
                  <Th>Phone</Th>
                  <Td>:</Td>
                  <Td>{data?.data.operationDetails.createdBy?.contact}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Stack>
        </PageCardComponent>
        <PageCardComponent>
          <Stack>
            <Table size={"sm"} variant={"unstyled"}>
              <Tbody>
                <Tr>
                  <Th>Destination Location</Th>
                  <Td>:</Td>
                  <Td>
                    {data?.data.operationDetails?.destinationLocation?.name}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Date</Th>
                  <Td>:</Td>
                  <Td>
                    {" "}
                    {dayjs(data?.data.operationDetails.createdAt).format(
                      "ddd, MMM D, YYYY h:mm A"
                    )}
                  </Td>
                </Tr>

                <Tr>
                  <Th>Notes</Th>
                  <Td>:</Td>
                  <Td>{data?.data.operationDetails.notes}</Td>
                </Tr>
                <Tr>
                  <Th>Operation ID</Th>
                  <Td>:</Td>
                  <Td fontWeight={700}>{data?.data.operationDetails.id}</Td>
                </Tr>
                <Tr>
                  <Th> Invoice Number</Th>
                  <Td>:</Td>
                  <Td fontWeight={700}>
                    {data?.data.operationDetails.invoiceNo}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Stack>
        </PageCardComponent>
      </Stack>
      <PageCardComponent>
        <Flex alignItems="center">
          <Box>
            <Heading as="h1" size="sm">
              Operation Summary
            </Heading>
          </Box>
          <Spacer />
          <Box>
            {data?.data.operationDetails.operationStatus === "INITIALIZED" &&
              data?.data.operationDetails.operationType === "DEMAND" && (
                <Fragment>
                  {env.uiType === "DMCH" && (
                    <Button colorScheme="blue">
                      <LocationDemandPrintComponent
                        id={data.data.operationDetails.id}
                      />
                    </Button>
                  )}
                  {env.uiType === "CMCH" && (
                    <Button colorScheme="blue">
                      <InventoryDemandPrintComponent
                        id={data.data.operationDetails.id}
                      />
                    </Button>
                  )}
                  <Button colorScheme="red" ml={2} onClick={onOpen}>
                    Cancel
                  </Button>
                  {data?.data.operationDetails.originLocation.id !==
                    Number(locationId) && (
                    <Button
                      colorScheme="green"
                      ml="2"
                      onClick={() =>
                        handleFulfilDemand(data.data.operationDetails.id)
                      }
                    >
                      Fulfill Demand
                    </Button>
                  )}
                  <CancelAlertPopupComponent
                    cancelRef={cancelRef}
                    onClose={onClose}
                    isOpen={isOpen}
                    onOpen={onOpen}
                    handleCancel={handleCancel}
                  />
                </Fragment>
              )}
          </Box>
        </Flex>
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr bg="gray.700">
                <Th color="white">ID</Th>
                <Th color="white">Product Name</Th>
                <Th color="white">Total Amount</Th>
                <Th color="white">Unit</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.data?.operationDetails?.stockOperationItems?.map(
                (data, key) => (
                  <Tr key={key}>
                    <Td>{key + 1}</Td>
                    <Td>{data.product.name}</Td>
                    <Td>{data.quantity}</Td>
                    <Td>{data.product.unit}</Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </PageCardComponent>
    </Stack>
  );
};

export default DemandOperationDetailsComponent;
