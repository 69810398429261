import React, { useRef } from "react";
import { useEffect, useState } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ButtonGroup,
  useToast,
  Textarea,
  useDisclosure
} from "@chakra-ui/react";
import { Select, GroupBase } from "chakra-react-select";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  DestinationLocationGroup,
  ENTER_KEY,
  fieldNameType,
  ProductGroup,
  StockOperationItems
} from "../../types/stock-operation.type";
import { FaPlus, FaTimes } from "react-icons/fa";
import { PageCardComponent } from "../../../core/components/page-card.component";
// import { env } from "../../../config/index";
import { env } from "../../../../config/index";

import {
  useGetLocationQuery,
  useGetProductQuery,
  useLazyGetProductQuantityQuery,
  useSupplyOperationMutation
} from "../../../../api";
import {
  chakraStyles,
  RemoveSelectedProductFromAllProducts,
  userCurrentRole
} from "../../../../utils/common-functions";
import { useNavigate } from "react-router-dom";
import { ConfirmAlertComponent } from "../confirm-alert.component";
import { ProductTrackingTypeOptions } from "../../../../api/type";
import { Box } from "@chakra-ui/layout";
import { OperationService } from "../../../../service/operation/operation.service";
import dayjs from "dayjs";
type VendorSupplyFormValues = {
  destinationLocation: DestinationLocationGroup;
  stockOperationItems: StockOperationItems[];
  notes: string;
};

interface CmchVendorSupplyComponentProps {}

export const CmchVendorSupplyComponent: React.FC<
  CmchVendorSupplyComponentProps
> = (props: CmchVendorSupplyComponentProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const role = userCurrentRole();
  const vendorSupplyConfirmAlert = useDisclosure();
  const cancelRef = useRef();
  const getLocationQuery = useGetLocationQuery({});
  let locationId = localStorage.getItem("location");
  let initialState = {
    label: "",
    value: Number(locationId)
  };

  const [destinationLocationOptions, setDestinationLocationOptions] = useState<
    DestinationLocationGroup[]
  >([]);
  const [destinationLocation, setDestinationLocation] =
    useState<DestinationLocationGroup>(initialState);

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setDestinationLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);
  useEffect(() => {
    if (destinationLocationOptions.length) {
      setDestinationLocation(
        destinationLocationOptions.find((d) => d.value === Number(locationId))!
      );
    }
  }, [locationId, destinationLocationOptions]);

  const [productOptions, setProductOptions] = useState<ProductGroup[]>([]);

  const getProductQuery = useGetProductQuery({});

  useEffect(() => {
    if (getProductQuery.data?.data.products) {
      const productWithValues = getProductQuery.data?.data.products.map(
        (product) => {
          return {
            label: product.name,
            value: product.id,
            type: product.trackingType,
            unit: product.unit
          };
        }
      );
      setProductOptions(productWithValues);
    }
  }, [getProductQuery.data]);

  const [createSupply, resultSupply] = useSupplyOperationMutation();

  const {
    register,
    handleSubmit,
    control,
    setFocus,
    formState: { errors }
  } = useForm<VendorSupplyFormValues>({
    defaultValues: {
      stockOperationItems: [{}]
    }
  });

  const { fields, append, remove } = useFieldArray<
    VendorSupplyFormValues,
    "stockOperationItems"
  >({
    control,
    name: "stockOperationItems"
  });

  const watch = useWatch({
    control,
    name: "stockOperationItems"
  });

  const supplyHandleSubmit = handleSubmit((data) => {
    const allStockOperationItems = data.stockOperationItems.map(
      (singleItem) => {
        let tempObj: any = {};
        if (singleItem.expiryDate) {
          if (dayjs(singleItem.expiryDate).isValid()) {
            tempObj["expiryDate"] = dayjs(singleItem.expiryDate);
          }
        }
        if (singleItem.manufactureDate) {
          if (dayjs(singleItem.manufactureDate).isValid()) {
            tempObj["manufactureDate"] = dayjs(singleItem.manufactureDate);
          }
        }

        return {
          productId: singleItem.product.value,
          quantity: OperationService.getQuantity(
            singleItem.product.type as string,
            singleItem.quantity
          ),
          trackingNumber: singleItem.trackingNumber,
          ...tempObj
        };
      }
    );
    createSupply({
      data: {
        stockOperation: {
          locationId: destinationLocation.value,
          stockOperationItems: allStockOperationItems,
          notes: data.notes
        }
      }
    });
  });

  useEffect(() => {
    if (resultSupply.isSuccess) {
      toast({
        title: "Success",
        description: "Vendor supply successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });

      navigate(
        `/${role}/report/operation-details/${resultSupply.data.data.id}?locationId=${destinationLocation.value}&selectedSegmentaion=ALL`
      );
    }
  }, [resultSupply, toast, navigate, role]);

  const handleSelectLocationChange = (location: any) => {
    setDestinationLocation(location);
  };

  const [getProductQuantity, getProductQuantityResult] =
    useLazyGetProductQuantityQuery();
  const [availableQuantity, setAvailableQuantity] = useState<
    StockOperationItems[]
  >([]);
  useEffect(() => {
    if (Object.keys(watch[0]).length !== 0) {
      watch.map((d) => {
        if (d?.product?.value) {
          getProductQuantity({
            data: {
              product: {
                productId: d?.product?.value,
                locationId: Number(destinationLocation.value),
                productType: d?.product.type
                  ? d?.product.type
                  : ProductTrackingTypeOptions.NONE
              }
            }
          });
        }
      });
    }
  }, [watch, fields]);

  useEffect(() => {
    if (getProductQuantityResult && Object.keys(watch[0]).length !== 0) {
      watch.map((d, index) => {
        if (
          d?.product?.value ===
          getProductQuantityResult?.data?.data?.products?.productId
        ) {
          watch[index].product["available"] =
            getProductQuantityResult.data?.data?.products?.quantity;
        }
      });
    }
  }, [getProductQuantityResult, watch]);

  useEffect(() => {
    if (
      Object.keys(watch[watch.length - 1]).length !== 0 &&
      watch[watch.length - 1].product?.available
    ) {
      setAvailableQuantity(watch);
    }
  }, [watch, availableQuantity, getProductQuantityResult]);
  const handleAddItem = () => {
    const currentindex = fields.length - 1;
    if (watch[currentindex]?.product?.value) {
      const nextIndex = currentindex + 1;
      append({});

      setTimeout(() => {
        focusElement(nextIndex, "product");
      }, 10);
    }
  };

  const handleKeyDown = (event: any, index: number, fildName: string) => {
    if (event.keyCode !== ENTER_KEY) return;
    const lastIndex = fields.length - 1;
    const currentIndex = index;
    const nextIndex = index + 1;

    let currentProductType = watch[index].product.type;

    if (
      currentIndex === lastIndex &&
      currentProductType === ProductTrackingTypeOptions.SERIALIZED &&
      fildName === "trackingNumber"
    ) {
      append({});
      setTimeout(() => {
        focusElement(nextIndex, "product");
      }, 100);
    } else if (currentIndex === lastIndex && fildName === "quantity") {
      append({});
      setTimeout(() => {
        focusElement(nextIndex, "product");
      }, 100);
    } else if (
      currentProductType === ProductTrackingTypeOptions.NONE &&
      fildName === "quantity"
    ) {
      focusElement(nextIndex);
    } else if (
      currentProductType === ProductTrackingTypeOptions.SERIALIZED &&
      fildName === "trackingNumber"
    ) {
      focusElement(nextIndex);
    } else if (
      currentProductType === ProductTrackingTypeOptions.BATCH &&
      fildName === "trackingNumber"
    ) {
      focusElement(currentIndex, "quantity");
    } else if (
      currentProductType === ProductTrackingTypeOptions.BATCH &&
      fildName === "quantity"
    ) {
      focusElement(nextIndex);
    }
  };

  function focusElement(index: number, fieldName?: fieldNameType) {
    if (fieldName) {
      setFocus(`stockOperationItems.${index}.${fieldName}`);
      return;
    }

    let selectedProduct = watch[index].product;
    if (!selectedProduct) {
      setFocus(`stockOperationItems.${index}.product`);
      return;
    }
    let productType = selectedProduct.type;
    if (productType !== ProductTrackingTypeOptions.NONE) {
      setFocus(`stockOperationItems.${index}.trackingNumber`);
      return;
    }
    setFocus(`stockOperationItems.${index}.quantity`);
  }

  const handleProductSelect = (OnChange: any, value: any, index: number) => {
    const currentIndexId = index;
    OnChange(value);
    setTimeout(() => {
      if (value.type) {
        value.type === ProductTrackingTypeOptions.NONE
          ? focusElement(currentIndexId, "quantity")
          : focusElement(currentIndexId, "trackingNumber");
      }
    }, 10);
  };
  const getQuantity = (productType: any) => {
    if (productType === ProductTrackingTypeOptions.SERIALIZED) {
      return 1;
    }
    return "";
  };

  return (
    <div>
      <Stack>
        <form onSubmit={supplyHandleSubmit}>
          <Stack
            direction={{ base: "column", xl: "row" }}
            justify="space-between"
          >
            <PageCardComponent>
              <Controller
                control={control}
                name="destinationLocation"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error }
                }) => (
                  <FormControl
                    mb="3"
                    isInvalid={!!error}
                    id="destinationLocation"
                  >
                    <FormLabel>Location</FormLabel>
                    <Select
                      chakraStyles={chakraStyles}
                      name={name}
                      ref={ref}
                      onChange={(location) =>
                        handleSelectLocationChange(location)
                      }
                      onBlur={onBlur}
                      value={destinationLocation}
                      isDisabled={role !== "admin" ? true : false}
                      options={destinationLocationOptions}
                      placeholder="Select destination"
                      closeMenuOnSelect={true}
                    />
                    <FormErrorMessage>
                      {errors.destinationLocation?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </PageCardComponent>
          </Stack>
          <Stack spacing={4}>
            <Button
              my="2"
              width="100%"
              colorScheme="blue"
              variant="outline"
              leftIcon={<FaPlus />}
              onClick={() => handleAddItem()}
            >
              Add
            </Button>
          </Stack>
          {fields
            .slice()
            .reverse()
            .map((field, index) => {
              const currentIndex = fields.length - index - 1;
              return (
                <Stack
                  direction={{ base: "column", xl: "row" }}
                  width="100%"
                  bgColor={index > 0 ? "#F7FAFC" : "white"}
                  borderRadius="md"
                  spacing={2}
                  px="4"
                  py="2"
                  key={field.id}
                  mt="2"
                >
                  <Box w={{ base: "100%", md: "100%", xl: "2%" }}>
                    <FormControl>
                      {index === 0 && (
                        <FormLabel
                          fontSize={{
                            base: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                            "2xl": "8"
                          }}
                        >
                          ID
                        </FormLabel>
                      )}

                      {fields.length - index}
                    </FormControl>
                  </Box>
                  <Box w={{ base: "100%", md: "100%", xl: "35%" }}>
                    <Controller
                      control={control}
                      name={`stockOperationItems.${currentIndex}.product`}
                      rules={{ required: "Select product" }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { error }
                      }) => (
                        <FormControl
                          isInvalid={!!error}
                          id={`stockOperationItems.${currentIndex}.product`}
                          fontSize={{
                            base: "10",
                            md: "10",
                            lg: "10",
                            xl: "10",
                            "2xl": "12"
                          }}
                        >
                          {index === 0 && (
                            <FormLabel
                              fontSize={{
                                base: "10",
                                md: "10",
                                lg: "10",
                                xl: "10",
                                "2xl": "12"
                              }}
                            >
                              Select Product
                            </FormLabel>
                          )}
                          <Select<ProductGroup, true, GroupBase<ProductGroup>>
                            name={name}
                            ref={ref}
                            onChange={(value) => {
                              handleProductSelect(
                                onChange,
                                value,
                                currentIndex
                              );
                            }}
                            onBlur={onBlur}
                            value={value}
                            options={RemoveSelectedProductFromAllProducts(
                              productOptions,
                              watch
                            )}
                            placeholder="Select product"
                            closeMenuOnSelect={true}
                            size="md"
                            styles={{}}
                          />
                          <FormErrorMessage>
                            {error && error.message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  </Box>
                  <Box w={{ base: "100%", md: "100%", xl: "6%" }}>
                    <FormControl>
                      {index === 0 && (
                        <FormLabel
                          fontSize={{
                            base: "10",
                            md: "10",
                            lg: "10",
                            xl: "10",
                            "2xl": "12"
                          }}
                        >
                          Available
                        </FormLabel>
                      )}
                      <Input
                        _disabled={{
                          color: "blackAlpha.900",
                          cursor: "not-allowed"
                        }}
                        defaultValue={
                          watch[currentIndex]?.product?.value ===
                          availableQuantity[currentIndex]?.product?.value
                            ? availableQuantity[currentIndex]?.product
                                ?.available
                            : ""
                        }
                        disabled={true}
                        placeholder="Available"
                        fontSize={{
                          base: "8",
                          md: "8",
                          lg: "8",
                          xl: "8",
                          "2xl": "12"
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box w={{ base: "100%", md: "100%", xl: "8%" }}>
                    <FormControl>
                      {index === 0 && (
                        <FormLabel
                          fontSize={{
                            base: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                            "2xl": "12"
                          }}
                        >
                          Unit
                        </FormLabel>
                      )}
                      <Input
                        _disabled={{
                          color: "blackAlpha.700",
                          cursor: "not-allowed"
                        }}
                        defaultValue={watch[currentIndex]?.product?.unit}
                        disabled={true}
                        placeholder="Product unit"
                        fontSize={{
                          base: "8",
                          md: "8",
                          lg: "8",
                          xl: "8",
                          "2xl": "12"
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                    <FormControl
                      isDisabled={
                        watch[currentIndex]?.product?.type !==
                          ProductTrackingTypeOptions.SERIALIZED &&
                        watch[currentIndex]?.product?.type !==
                          ProductTrackingTypeOptions.BATCH
                      }
                      isInvalid={
                        errors?.stockOperationItems?.[currentIndex]
                          ?.trackingNumber
                          ? true
                          : false
                      }
                    >
                      {index === 0 && (
                        <FormLabel
                          fontSize={{
                            base: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                            "2xl": "12"
                          }}
                        >
                          Tracking Number
                        </FormLabel>
                      )}
                      <Input
                        {...register(
                          `stockOperationItems.${currentIndex}.trackingNumber` as const,
                          {
                            validate: {
                              required: (value) => {
                                if (
                                  watch[currentIndex]?.product?.type ===
                                    ProductTrackingTypeOptions.NONE ||
                                  value !== ""
                                )
                                  return true;
                                return "Give Tracking data";
                              }
                            }
                          }
                        )}
                        onKeyDown={(e) =>
                          handleKeyDown(e, currentIndex, "trackingNumber")
                        }
                        key={`tracking${currentIndex}`}
                        type={"string"}
                        placeholder="Tracking Number"
                        fontSize={{
                          base: "8",
                          md: "8",
                          lg: "8",
                          xl: "8",
                          "2xl": "12"
                        }}
                      />
                      <FormErrorMessage>
                        {
                          errors?.stockOperationItems?.[currentIndex]
                            ?.trackingNumber?.message
                        }
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box w={{ base: "100%", md: "100%", xl: "8%" }}>
                    <FormControl>
                      {index === 0 && (
                        <FormLabel
                          fontSize={{
                            base: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                            "2xl": "12"
                          }}
                        >
                          After Supply
                        </FormLabel>
                      )}
                      <Input
                        _disabled={{
                          color: "blackAlpha.900",
                          cursor: "not-allowed"
                        }}
                        fontSize={{
                          base: "8",
                          md: "8",
                          lg: "8",
                          xl: "8",
                          "2xl": "12"
                        }}
                        value={
                          watch[currentIndex]?.product &&
                          watch[currentIndex]?.product.type &&
                          watch[currentIndex]?.product.type ===
                            ProductTrackingTypeOptions.SERIALIZED
                            ? Number(watch[currentIndex]?.product?.available) +
                              1
                            : Number(watch[currentIndex]?.product?.available) +
                              Number(watch[currentIndex]?.quantity)
                        }
                        disabled={true}
                        placeholder="Available"
                      />
                    </FormControl>
                  </Box>
                  <Box w={{ base: "100%", md: "100%", xl: "9%" }}>
                    <FormControl
                      isDisabled={
                        watch[currentIndex]?.product?.type !==
                          ProductTrackingTypeOptions.SERIALIZED &&
                        watch[currentIndex]?.product?.type !==
                          ProductTrackingTypeOptions.BATCH
                      }
                      isInvalid={
                        errors?.stockOperationItems?.[currentIndex]
                          ?.manufactureDate
                          ? true
                          : false
                      }
                    >
                      {index === 0 && (
                        <FormLabel
                          fontSize={{
                            base: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                            "2xl": "12"
                          }}
                        >
                          M. Date
                        </FormLabel>
                      )}
                      <Input
                        fontSize={{
                          base: "8",
                          md: "8",
                          lg: "8",
                          xl: "8",
                          "2xl": "12"
                        }}
                        type="date"
                        {...register(
                          `stockOperationItems.${currentIndex}.manufactureDate` as const,
                          { valueAsDate: true }
                        )}
                        defaultValue={undefined}
                        // onKeyDown={(e) => handleKeyDown(e, currentIndex, "trackingNumber")}

                        key={`tracking${currentIndex}`}
                        placeholder="Tracking Number"
                      />
                      <FormErrorMessage>
                        {
                          errors?.stockOperationItems?.[currentIndex]
                            ?.manufactureDate?.message
                        }
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box w={{ base: "100%", md: "100%", xl: "9%" }}>
                    <FormControl
                      isDisabled={
                        watch[currentIndex]?.product?.type !==
                          ProductTrackingTypeOptions.SERIALIZED &&
                        watch[currentIndex]?.product?.type !==
                          ProductTrackingTypeOptions.BATCH
                      }
                      isInvalid={
                        errors?.stockOperationItems?.[currentIndex]?.expiryDate
                          ? true
                          : false
                      }
                    >
                      {index === 0 && (
                        <FormLabel
                          fontSize={{
                            base: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                            "2xl": "12"
                          }}
                        >
                          Expiry Date
                        </FormLabel>
                      )}
                      <Input
                        fontSize={{
                          base: "8",
                          md: "8",
                          lg: "8",
                          xl: "8",
                          "2xl": "12"
                        }}
                        type="date"
                        {...register(
                          `stockOperationItems.${currentIndex}.expiryDate` as const,
                          { valueAsDate: true }
                        )}
                        defaultValue={undefined}
                        // onKeyDown={(e) => handleKeyDown(e, currentIndex, "trackingNumber")}

                        key={`tracking${currentIndex}`}
                        placeholder="Expiry Data"
                      />
                      <FormErrorMessage>
                        {
                          errors?.stockOperationItems?.[currentIndex]
                            ?.expiryDate?.message
                        }
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                    <FormControl
                      mb="3"
                      isInvalid={
                        errors?.stockOperationItems?.[currentIndex]?.quantity
                          ? true
                          : false
                      }
                    >
                      {index === 0 && (
                        <FormLabel
                          fontSize={{
                            base: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                            "2xl": "12"
                          }}
                        >
                          Quantity
                        </FormLabel>
                      )}
                      <Input
                        {...register(
                          `stockOperationItems.${currentIndex}.quantity` as const,
                          {
                            validate: {
                              required: (value) => {
                                if (
                                  watch[currentIndex]?.product?.type ===
                                    ProductTrackingTypeOptions.SERIALIZED ||
                                  value > 0
                                )
                                  return true;
                                return "Give Amount";
                              }
                            }
                          }
                        )}
                        key={`quantity${currentIndex}`}
                        onKeyDown={(e) =>
                          handleKeyDown(e, currentIndex, "quantity")
                        }
                        type={"number"}
                        placeholder="Enter product quantity"
                        fontSize={{
                          base: "8",
                          md: "8",
                          lg: "8",
                          xl: "8",
                          "2xl": "12"
                        }}
                        disabled={
                          watch[currentIndex]?.product?.type !==
                            ProductTrackingTypeOptions.NONE &&
                          watch[currentIndex]?.product?.type !==
                            ProductTrackingTypeOptions.BATCH
                        }
                        defaultValue={getQuantity(
                          watch[currentIndex]?.product?.type
                        )}
                      />
                      <FormErrorMessage>
                        {
                          errors?.stockOperationItems?.[currentIndex]?.quantity
                            ?.message
                        }
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box w={{ base: "100%", md: "100%", xl: "3%" }}>
                    <FormControl mb="3">
                      <ButtonGroup>
                        <Button
                          mt={index === 0 ? 8 : 1}
                          colorScheme="red"
                          variant="outline"
                          leftIcon={<FaTimes />}
                          onClick={() => remove(currentIndex)}
                          isDisabled={currentIndex > 0 ? false : true}
                          fontSize={{
                            base: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                            "2xl": "12"
                          }}
                          size="sm"
                        ></Button>
                      </ButtonGroup>
                    </FormControl>
                  </Box>
                </Stack>
              );
            })}
          <Stack spacing={4}>
            <Textarea
              placeholder="Enter notes for this Operation"
              {...register("notes")}
            />
            <Button
              width="100%"
              colorScheme="blue"
              onClick={vendorSupplyConfirmAlert.onOpen}
            >
              Supply
            </Button>
            <ConfirmAlertComponent
              cancelRef={cancelRef}
              onClose={vendorSupplyConfirmAlert.onClose}
              isOpen={vendorSupplyConfirmAlert.isOpen}
              onOpen={vendorSupplyConfirmAlert.onOpen}
              handleApprove={supplyHandleSubmit}
              status={resultSupply}
            />
          </Stack>
        </form>
      </Stack>
    </div>
  );
};
