import { CalendarIcon } from "@chakra-ui/icons";
import {
  AbsoluteCenter,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr
} from "@chakra-ui/react";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  PaginationState,
  useReactTable
} from "@tanstack/react-table";
import { Select } from "chakra-react-select";
import dayjs from "dayjs";
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useMemo,
  useState
} from "react";
import { CSVLink } from "react-csv";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { FaInfoCircle, FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { OperationHistory, ProductOperation } from "../../../api/type";
import { customPagination } from "../../../config";
import {
  canSelectLocation,
  chakraStyles,
  userCurrentRole
} from "../../../utils/common-functions";
import { PageCardComponent } from "../../core/components/page-card.component";
import { TableSearchInput } from "../../core/components/table-search-component";
import { OriginLocationGroup } from "../types/stock-operation.type";
import {
  useGetAssignUserLocationsQuery,
  useMonthyAdjustmentReportQuery
} from "../../../api";
import { LoaderComponent } from "../../core/components/loader.component";
import { AdjustmentProductReportPrintComponent } from "../../print/components/adjustment-product-report-print.component";

type monthlyAdjustmentReportFormValues = {
  originLocation: OriginLocationGroup;
};

declare module "@tanstack/table-core" {
  interface FilterFns {
    tableFilter: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}
const tableFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank
  });
  return itemRank.passed;
};

const headers = [
  { label: "Product Id", key: "productId" },
  { label: "Product Name", key: "name" },
  { label: "Increment", key: "inwardCount" },
  { label: "Decrement", key: "outboundCount" },
  { label: "Unit", key: "unit" }
];

interface AdjustmentReportComponentProps {}

export const AdjustmentReportComponent: React.FC<
  AdjustmentReportComponentProps
> = (props: AdjustmentReportComponentProps) => {
  let locationId = localStorage.getItem("location");
  const role = userCurrentRole();
  let initialState = {
    label: "",
    value: Number(locationId)
  };

  const [originLocation, setOriginLocation] =
    useState<OriginLocationGroup>(initialState);

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);

  const getLocationQuery = useGetAssignUserLocationsQuery({});

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(locationId))!
      );
    }
  }, [locationId, originLocationOptions]);

  const handleGetLocation = (data: any) => {
    setOriginLocation(data);
  };

  const { control } = useForm<monthlyAdjustmentReportFormValues>({});

  const [dateStart, setDateStart] = useState(new Date());

  const { data, isLoading, isSuccess } = useMonthyAdjustmentReportQuery({
    data: {
      report: {
        locationId: Number(originLocation?.value),
        month: dateStart.toISOString()
      }
    }
  });

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: customPagination.pageIndex,
    pageSize: customPagination.pageSize
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const [globalFilter, setGlobalFilter] = useState("");

  const columns = useMemo<ColumnDef<ProductOperation, any>[]>(
    () => [
      {
        header: "SL",
        cell: (row) => {
          return Number(row.row.id) + 1;
        }
      },
      {
        header: "Product Name",
        accessorFn: (row) => row.name,
        id: "name",
        cell: (row) => row.getValue()
      },
      {
        header: "Unit",
        accessorFn: (row) => row.unit,
        id: "unit",
        cell: (row) => row.getValue()
      },
      {
        header: "Increment",
        accessorFn: (row) => row.inwardCount,
        id: "inwardCount",
        cell: (row) => row.getValue()
      },
      {
        header: "Decrement",
        accessorFn: (row) => row.outboundCount,
        id: "outboundCount",
        cell: (row) => row.getValue()
      },
      {
        id: "expander",
        header: "Expand",
        cell: ({ row }) => {
          return {
            ...(row.getIsExpanded() ? (
              <IconButton
                onClick={row.getToggleExpandedHandler()}
                isRound={true}
                variant="solid"
                aria-label="Done"
                fontSize="15px"
                icon={<FaMinusCircle />}
              />
            ) : (
              <IconButton
                onClick={row.getToggleExpandedHandler()}
                isRound={true}
                variant="solid"
                aria-label="Done"
                fontSize="15px"
                icon={<FaPlusCircle />}
              />
            ))
          };
        }
      }
    ],
    []
  );

  const table = useReactTable({
    data: data ? data.data.productOperationSummary : [],
    columns: columns,
    filterFns: {
      tableFilter
    },
    state: {
      pagination,
      globalFilter
    },
    getRowCanExpand(row) {
      return true;
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    globalFilterFn: tableFilter,
    debugTable: true
  });

  const renderSubComponent = ({ row }: any) => {
    return (
      <Table variant="striped" size="sm">
        <Thead>
          <Tr bg="gray.500">
            <Th color="white">Origin Location</Th>
            <Th color="white">Destination Location</Th>
            <Th color="white">Created By</Th>
            <Th color="white">quantity</Th>
            <Th color="white">Operation Type</Th>
            <Th color="white">Operation Time</Th>
            <Th color="white">Notes</Th>
          </Tr>
        </Thead>
        <Tbody>
          {row.original.operations.map(
            (singleStockOperation: OperationHistory, index: number) => {
              return (
                <Tr key={index}>
                  <Td>{singleStockOperation.originLocation.name}</Td>
                  <Td>{singleStockOperation.destinationLocation?.name}</Td>
                  <Td>{singleStockOperation.createdBy?.name}</Td>
                  <Td>{singleStockOperation.quantity}</Td>
                  <Td>{singleStockOperation.operationType}</Td>
                  <Td>
                    {dayjs(singleStockOperation.createdAt).format(
                      "ddd, MMM D, YYYY h:mm A"
                    )}
                  </Td>
                  <Td>
                    {singleStockOperation.notes &&
                      singleStockOperation.notes !== "" &&
                      singleStockOperation.notes !== null && (
                        <Tooltip hasArrow label={singleStockOperation.notes}>
                          <IconButton
                            isRound={true}
                            variant="solid"
                            aria-label="Done"
                            fontSize="15px"
                            icon={<FaInfoCircle />}
                          />
                        </Tooltip>
                      )}
                  </Td>
                </Tr>
              );
            }
          )}
        </Tbody>
      </Table>
    );
  };

  const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
    <Input
      autoComplete="off"
      background="white"
      value={value}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
    />
  );
  customDateInput.displayName = "DateInput";

  const CustomInput = forwardRef(customDateInput);
  function onChangeHandler(value: any) {
    setDateStart(value);
  }
  const icon = <CalendarIcon fontSize="sm" />;

  return (
    <Stack>
      <Stack direction={{ base: "column", xl: "row" }} justify="space-between">
        <PageCardComponent>
          <Controller
            control={control}
            name="originLocation"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { error }
            }) => (
              <FormControl mb="3" isInvalid={!!error} id="originLocation">
                <FormLabel>Select Location</FormLabel>
                <Select
                  chakraStyles={chakraStyles}
                  name={name}
                  ref={ref}
                  onChange={(location) => handleGetLocation(location)}
                  onBlur={onBlur}
                  value={originLocation}
                  isDisabled={!canSelectLocation(role)}
                  options={originLocationOptions}
                  placeholder="Select location"
                  closeMenuOnSelect={true}
                />
                <FormErrorMessage>{error && error.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </PageCardComponent>
      </Stack>

      <Stack>
        <Stack direction={{ base: "column", xl: "row" }} width="100%" my="2">
          <Box>
            <TableSearchInput
              value={globalFilter ?? ""}
              onChange={(value) => setGlobalFilter(String(value))}
            />
          </Box>
          <Spacer />
          <Box>
            <InputGroup className="dark-theme">
              <ReactDatePicker
                id="dateStartEnd"
                selected={dateStart}
                onChange={onChangeHandler}
                dateFormat="MMM yyyy"
                className="react-datapicker__input-text"
                placeholderText="Select Month"
                customInput={<CustomInput />}
                showDisabledMonthNavigation
                showMonthYearPicker
              />
              <InputRightElement color="gray.200" children={icon} />
            </InputGroup>
          </Box>
          {data && data?.data.productOperationSummary.length > 0 && (
            <>
              <ButtonGroup gap="2">
                <CSVLink
                  data={data.data.productOperationSummary}
                  headers={headers}
                >
                  <Button colorScheme="blue">Export CSV</Button>
                </CSVLink>
              </ButtonGroup>
              <ButtonGroup gap="2">
                <AdjustmentProductReportPrintComponent printData={data} />
              </ButtonGroup>
            </>
          )}
        </Stack>
      </Stack>

      {isLoading ? (
        <LoaderComponent />
      ) : isSuccess && data.data.productOperationSummary.length <= 0 ? (
        <AbsoluteCenter>
          <Heading as="h5" size="sm">
            No adjustment history found in{" "}
            {dayjs(dateStart).format("MMMM, YYYY")}
          </Heading>
        </AbsoluteCenter>
      ) : (
        <Table variant="striped">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      bg="gray.700"
                      color="white"
                    >
                      {header.isPlaceholder ? null : (
                        <Box>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Box>
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Fragment key={row.id}>
                  <Tr>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                  {row.getIsExpanded() && (
                    <Tr>
                      <Td colSpan={row.getVisibleCells().length}>
                        {renderSubComponent({ row })}
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              );
            })}
          </Tbody>
        </Table>
      )}
    </Stack>
  );
};
