import { useParams } from "react-router-dom";

import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  useReactTable,
  FilterFn,
  getExpandedRowModel,
  getPaginationRowModel
} from "@tanstack/react-table";
import {
  Box,
  Button,
  Flex,
  Select as SelectPageSize,
  Spacer,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure
} from "@chakra-ui/react";

import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";

import {
  EditTrackingNumber,
  OriginLocationGroup
} from "../types/stock-operation.type";
import { customPagination, displayAllTablePage } from "../../../config";
import { SingleProductAllLocationInventory } from "../../../api/type";
import {
  useGetAssignUserLocationsQuery,
  useGetLocationInventoryQuery,
  useGetProductAllLocaitonInventoryQuery
} from "../../../api";
import { LoaderComponent } from "../../core/components/loader.component";

import { TableSearchInput } from "../../core/components/table-search-component";
import { FaEdit, FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { PageCardComponent } from "../../core/components/page-card.component";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { SingleProductInventoryPrintComponent } from "../../print/components/single-product-inventory-print.component";
import { TrackingDataEditModalComponet } from "./tracking-data-edit.modal.componet";

declare module "@tanstack/table-core" {
  interface FilterFns {
    tableFilter: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const tableFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank
  });
  return itemRank.passed;
};

interface SingleProductInventoryReportComponentProps {
  productId: number;
}

export const SingleProductInventoryReportComponent: React.FC<
  SingleProductInventoryReportComponentProps
> = (props: SingleProductInventoryReportComponentProps) => {
  const editTrackingNumberModal = useDisclosure();
  const productId = props.productId.toString();

  const { data, isLoading } = useGetProductAllLocaitonInventoryQuery({
    id: productId
  });

  let locationId = localStorage.getItem("location");

  let initialState = {
    label: "",
    value: Number(locationId)
  };

  const [originLocation, setOriginLocation] =
    useState<OriginLocationGroup>(initialState);

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);

  const getLocationQuery = useGetAssignUserLocationsQuery({});

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(locationId))!
      );
    }
  }, [locationId, originLocationOptions]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: customPagination.pageIndex,
    pageSize: customPagination.pageSize
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const [globalFilter, setGlobalFilter] = useState("");

  const columns = useMemo<ColumnDef<SingleProductAllLocationInventory, any>[]>(
    () => [
      {
        header: "SL",
        cell: (row) => {
          return Number(row.row.id) + 1;
        }
      },
      {
        header: "Location Name",
        accessorFn: (row) => row.locationName,
        id: "LocationName",
        cell: (row) => row.getValue()
      },
      {
        header: "Quantity",
        accessorFn: (row) => row.quantity,
        id: "quantity",
        cell: (row) => row.getValue()
      },

      {
        id: "expander",
        header: "Expand",
        cell: ({ row }) => {
          return (
            row &&
            row.getCanExpand() && (
              <button
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: "pointer" }
                }}
              >
                {row.getIsExpanded() ? <FaMinusCircle /> : <FaPlusCircle />}
              </button>
            )
          );
        }
      }
    ],
    []
  );

  const table = useReactTable({
    data: data ? data.locationInventory : [],
    columns: columns,
    filterFns: {
      tableFilter
    },
    state: {
      pagination,
      globalFilter
    },
    getRowCanExpand(row) {
      return true;
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    globalFilterFn: tableFilter,
    debugTable: true
  });

  const renderSubComponent = ({ row }: any) => {
    return (
      <Table variant="striped" size="sm">
        <Thead>
          <Tr bg="gray.500">
            <Th color="white">Tracking Id</Th>
            <Th color="white">Manufacture Date</Th>
            <Th color="white">Expiry Date</Th>
            <Th color="white">Amount</Th>
            <Th color="white">Edit Tracking Number</Th>
          </Tr>
        </Thead>
        <Tbody>
          {row.original.trackingData.map((d: any, index: any) => (
            <Tr key={index}>
              <Td>{d.trackingId}</Td>
              <Td>
                {d.manufactureDate
                  ? dayjs(d.manufactureDate).format("ddd, MMM DD, YYYY")
                  : null}
              </Td>
              <Td>
                {d.expiryDate
                  ? dayjs(d.expiryDate).format("ddd, MMM DD, YYYY")
                  : null}
              </Td>
              <Td>{d.amount}</Td>
              {data && (
                <Td
                  onClick={() =>
                    editTrackingNumberModalOpen(
                      data.productId,
                      data?.productName,
                      d.trackingId,
                      data.trackingType
                    )
                  }
                >
                  <FaEdit />
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  };

  const editTrackingNumberModalOpen = (
    productId: number,
    productName: string,
    tranckingId: string,
    trackingType: string
  ) => {
    editTrackingNumberModal.onOpen();
    //expiryAddModalOpen(row.orginal.productId, row.productName, d.trackingId, d.expiryDate, d.manufactureDate)
    setCurrentSelectedProduct({
      productId: productId,
      productName: productName,
      trackingNumber: tranckingId,
      trackingType: trackingType
    });
  };

  const cancelRef = React.useRef();
  const [currentSelectedProduct, setCurrentSelectedProduct] =
    useState<EditTrackingNumber>();
  return (
    <Stack>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          <Stack
            direction={{ base: "column", xl: "row" }}
            justify="space-between"
            my="2"
          >
            {currentSelectedProduct && (
              <TrackingDataEditModalComponet
                cancelRef={cancelRef}
                onClose={editTrackingNumberModal.onClose}
                isOpen={editTrackingNumberModal.isOpen}
                onOpen={editTrackingNumberModal.onOpen}
                product={currentSelectedProduct}
                key={currentSelectedProduct.trackingNumber}
              />
            )}
            <PageCardComponent>
              <Stat>
                <StatLabel>Product Name</StatLabel>
                <StatNumber>{data?.productName}</StatNumber>
                <StatHelpText>{data?.notes}</StatHelpText>
              </Stat>
            </PageCardComponent>
            <PageCardComponent>
              <Stat>
                <StatLabel>Total in all Location</StatLabel>
                <StatNumber>{data?.overAllTotal}</StatNumber>
              </Stat>
            </PageCardComponent>
            <PageCardComponent>
              <Stat>
                <StatLabel>Product Unit</StatLabel>
                <StatNumber> {data?.unit}</StatNumber>
                <StatHelpText>{data?.trackingType}</StatHelpText>
              </Stat>
            </PageCardComponent>
          </Stack>
          <Stack
            direction={{ base: "column", xl: "row" }}
            minWidth="max-content"
            alignItems="center"
          >
            <Box>
              <TableSearchInput
                value={globalFilter ?? ""}
                onChange={(value) => setGlobalFilter(String(value))}
              />
            </Box>
            <Spacer />
            {data ? (
              <SingleProductInventoryPrintComponent productInventory={data} />
            ) : undefined}
          </Stack>
          <Table variant="striped">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        bg="gray.700"
                        color="white"
                      >
                        {header.isPlaceholder ? null : (
                          <Box>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Box>
                        )}
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Fragment key={row.id}>
                    <Tr>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                    {row.getIsExpanded() && (
                      <Tr>
                        <Td colSpan={row.getVisibleCells().length}>
                          {renderSubComponent({ row })}
                        </Td>
                      </Tr>
                    )}
                  </Fragment>
                );
              })}
            </Tbody>
          </Table>

          <Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              justify="space-between"
              alignItems="center"
              mt="2"
            >
              <Box width={{ base: "100%", lg: "60%" }}>
                <Text fontSize="lg" fontWeight="bold">
                  Showing (Page {table.getState().pagination.pageIndex + 1} of{" "}
                  {table.getPageCount()})
                </Text>{" "}
              </Box>
              <Stack
                direction="row"
                width={{ base: "100%", lg: "40%" }}
                justifyContent="space-between"
              >
                <Stack direction="row" width={{ lg: "80%" }}>
                  <Button
                    colorScheme="blue"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {"<<"}
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {"<"}
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    {">"}
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                  >
                    {">>"}
                  </Button>{" "}
                </Stack>

                <Box width={{ base: "100%", md: "50%", lg: "100%" }}>
                  <SelectPageSize
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      table.setPageSize(Number(e.target.value));
                    }}
                  >
                    {displayAllTablePage.map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </SelectPageSize>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};
