import React from "react";
import { Button, useDisclosure } from "@chakra-ui/react";
import { GetLowStockResponse } from "../../../api/type";
import { PdfPrinterModal } from "../../core/components/pdf-printer-modal.component";
import { LowStockAmountPrintComponent } from "./low-stock-amount-print.component";
import dayjs from "dayjs";

interface LowStockAmountPrintPageProps {
  lowstockitem: GetLowStockResponse;
}

export const LowStockAmountPrintPage: React.FC<LowStockAmountPrintPageProps> = (
  props: LowStockAmountPrintPageProps
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = (
    <LowStockAmountPrintComponent lowstockitem={props.lowstockitem} />
  );

  const lowStockReport = `Low Stock-${
    props.lowstockitem.location.name
  }-${dayjs().format("DD MMM YYYY")}.pdf`;

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={lowStockReport}
      />
    </>
  );
};
