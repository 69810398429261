import { OptionBase } from "chakra-react-select";

export interface OriginLocationGroup extends OptionBase {
  label: string;
  value: number;
}
export interface DestinationLocationGroup extends OptionBase {
  label: string;
  value: number;
}
export interface ProductGroup extends OptionBase {
  label: string;
  value: number;
  unit: string;
  type?: string;
  available?: number;
  quantity?: number;
  itemId?: number;
  genericName?: string;
}
export interface StockOperationItems {
  product: ProductGroup;
  quantity: number;
  trackingNumber?: string;
  manufactureDate?: Date;
  expiryDate?: Date;
  itemNote?: string;
}

export interface DistributionStockOperationItems {
  product: ProductGroup;
  quantity: number;
  trackingNumber?: string;
  manufactureDate?: Date;
  expiryDate?: Date;
  itemNote?: string;
}
export interface DemandStockOperationItems {
  product: ProductGroup;
  quantity: number;
  demandAmount: number;
  trackingNumber?: string;
}
export const ENTER_KEY = 13;
export type fieldNameType = "product" | "trackingNumber" | "quantity";

export interface DemandFulFillStockOperationItems {
  product: ProductGroup;
  quantity?: number;
  demandAmount: number;
  trackingNumber?: string;
}

export enum StockOperationType {
  INITIAL_INVENTORY = "INITIAL_INVENTORY",
  DISTRIBUTION = "DISTRIBUTION",
  TRANSFER = "TRANSFER",
  DEMAND = "DEMAND",
  CONSUMPTION = "CONSUMPTION",
  VENDOR_SUPPLY = "VENDOR_SUPPLY",
  TRASH = "TRASH",
  ADJUSTMENT = "ADJUSTMENT",
  VENDOR_DEMAND = "VENDOR_DEMAND"
}

export interface itemGroup extends OptionBase {
  label: string;
  value: number;
  productId: number;
  unit: string;
  available: number;
  type: string;
  trackingData: string | null;
  expirydate: string | null;
  manuFacturngDate: string | null;
}
