import React, {
  forwardRef,
  Fragment,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  useReactTable,
  FilterFn,
  getPaginationRowModel,
  getExpandedRowModel
} from "@tanstack/react-table";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select as SelectPageSize,
  Spacer,
  Stack,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr
} from "@chakra-ui/react";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./chakra-react-datepicker.css";
import { CalendarIcon } from "@chakra-ui/icons";
import { CSVLink } from "react-csv";
import { Controller, useForm } from "react-hook-form";
import { PageCardComponent } from "../../core/components/page-card.component";
import { Select } from "chakra-react-select";
import { OriginLocationGroup } from "../types/stock-operation.type";
import { customPagination, displayAllTablePage } from "../../../config";
import { OperationHistory, ProductOperation } from "../../../api/type";
import {
  useGetAssignUserLocationsQuery,
  useGetDailyProductReportQuery
} from "../../../api";
import {
  canSelectLocation,
  chakraStyles,
  userCurrentRole
} from "../../../utils/common-functions";
import dayjs from "dayjs";
import { TableSearchInput } from "../../core/components/table-search-component";
import { LoaderComponent } from "../../core/components/loader.component";
import { FaInfoCircle, FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { DailyProductReportPrintComponent } from "../../print/components/daily-product-report-print.component";
import { DailyReportFilterDataType } from "../types/custom-report.type";

type DailyReportFormValues = {
  originLocation: OriginLocationGroup;
};
declare module "@tanstack/table-core" {
  interface FilterFns {
    tableFilter: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const tableFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank
  });
  return itemRank.passed;
};

const headers = [
  { label: "Product Id", key: "productId" },
  { label: "Product Name", key: "name" },
  { label: "Total In", key: "inwardCount" },
  { label: "Total Out", key: "outboundCount" },
  { label: "Unit", key: "unit" }
];

interface DailyProductReportProps {}
const DailyProductReportComponent: React.FC<DailyProductReportProps> = (
  props: DailyProductReportProps
) => {
  let locationId = localStorage.getItem("location");
  const role = userCurrentRole();

  let initialState = {
    label: "",
    value: Number(locationId)
  };

  const [originLocation, setOriginLocation] =
    useState<OriginLocationGroup>(initialState);
  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [edclProducts, setEdclProducts] = useState(false);
  const [dateStart, setDateStart] = useState(new Date());
  const [filterData, setFilterData] = useState<DailyReportFilterDataType>();

  const getLocationQuery = useGetAssignUserLocationsQuery({});

  const getDailyProductReportResult = useGetDailyProductReportQuery({
    data: {
      report: {
        locationId: Number(originLocation?.value),
        startDate: dayjs(dateStart).startOf("day").toISOString(),
        endDate: dayjs(dateStart).endOf("day").toISOString()
      }
    }
  });

  const handleGetLocation = (data: any) => {
    setOriginLocation(data);
  };

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === initialState.value)!
      );
    }
  }, [initialState.value, originLocationOptions]);

  useEffect(() => {
    if (getDailyProductReportResult.data) {
      let newData: DailyReportFilterDataType = {
        productOperationSummary: edclProducts
          ? getDailyProductReportResult.data?.data.productOperationSummary.filter(
              (daily) => daily.isEdcl === true
            )
          : getDailyProductReportResult.data?.data.productOperationSummary,
        location: getDailyProductReportResult.data?.data.location,
        startDate: getDailyProductReportResult.data.data.startDate,
        endDate: getDailyProductReportResult.data.data.endDate,
        reportType: getDailyProductReportResult.data.data.reportType
      };
      setFilterData(newData);
    }
  }, [getDailyProductReportResult.data, edclProducts]);

  const { control } = useForm<DailyReportFormValues>({});

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: customPagination.pageIndex,
    pageSize: customPagination.pageSize
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );

  const columns = useMemo<ColumnDef<ProductOperation, any>[]>(
    () => [
      {
        header: "SL",
        cell: (row) => {
          return Number(row.row.index) + 1;
        }
      },
      {
        header: "Product Name",
        accessorFn: (row) => row.name,
        id: "name",
        cell: (row) => row.getValue()
      },
      {
        header: "Unit",
        accessorFn: (row) => row.unit,
        id: "unit",
        cell: (row) => row.getValue()
      },
      {
        header: "Total In",
        accessorFn: (row) => row.inwardCount,
        id: "inwardCount",
        cell: (row) => row.getValue()
      },
      {
        header: "Total Out",
        accessorFn: (row) => row.outboundCount,
        id: "outboundCount",
        cell: (row) => row.getValue()
      },
      {
        id: "expander",
        header: "Expand",
        cell: ({ row }) => {
          return {
            ...(row.getIsExpanded() ? (
              <IconButton
                onClick={row.getToggleExpandedHandler()}
                isRound={true}
                variant="solid"
                aria-label="Done"
                fontSize="15px"
                icon={<FaMinusCircle />}
              />
            ) : (
              <IconButton
                onClick={row.getToggleExpandedHandler()}
                isRound={true}
                variant="solid"
                aria-label="Done"
                fontSize="15px"
                icon={<FaPlusCircle />}
              />
            ))
          };
        }
      }
    ],
    []
  );

  const table = useReactTable({
    data: filterData?.productOperationSummary
      ? filterData.productOperationSummary
      : [],
    columns: columns,
    filterFns: {
      tableFilter
    },
    state: {
      pagination,
      globalFilter
    },
    getRowCanExpand(row) {
      return true;
    },
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    globalFilterFn: tableFilter,
    debugTable: true
  });

  const renderSubComponent = ({ row }: any) => {
    return (
      <Table variant="simple" size="sm">
        <Thead>
          <Tr bg="gray.500">
            <Th color="white">Origin Location </Th>
            <Th color="white">Destination Location</Th>
            <Th color="white">Created By</Th>
            <Th color="white">Quantity</Th>
            <Th color="white">Operation Type</Th>
            <Th color="white">Operation time</Th>
            <Th color="white">Notes</Th>
          </Tr>
        </Thead>
        <Tbody>
          {row.original.operations.map(
            (singleStockOperation: OperationHistory, index: any) => (
              <Tr key={index}>
                <Td>{singleStockOperation.originLocation.name}</Td>
                <Td>{singleStockOperation.destinationLocation?.name}</Td>
                <Td>{singleStockOperation.createdBy?.name}</Td>
                <Td>{singleStockOperation.quantity}</Td>
                <Td>{singleStockOperation.operationType}</Td>
                <Td>
                  {dayjs(singleStockOperation.createdAt).format(
                    "ddd, MMM D, YYYY h:mm A"
                  )}
                </Td>
                <Td>
                  {singleStockOperation.notes &&
                    singleStockOperation.notes !== "" &&
                    singleStockOperation.notes !== null && (
                      <Tooltip hasArrow label={singleStockOperation.notes}>
                        <IconButton
                          isRound={true}
                          variant="solid"
                          aria-label="Done"
                          fontSize="15px"
                          icon={<FaInfoCircle />}
                        />
                      </Tooltip>
                    )}
                </Td>
              </Tr>
            )
          )}
        </Tbody>
      </Table>
    );
  };

  const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
    <Input
      autoComplete="off"
      background="white"
      value={value}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
    />
  );
  customDateInput.displayName = "DateInput";

  const CustomInput = forwardRef(customDateInput);
  function onChangeHandler(value: any) {
    setDateStart(value);
  }
  const icon = <CalendarIcon fontSize="sm" />;

  return (
    <Stack>
      <Stack direction={{ base: "column", xl: "row" }} justify="space-between">
        <PageCardComponent>
          <Controller
            control={control}
            name="originLocation"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { error }
            }) => (
              <FormControl mb="3" isInvalid={!!error} id="originLocation">
                <FormLabel>Select Location</FormLabel>
                <Select
                  chakraStyles={chakraStyles}
                  name={name}
                  ref={ref}
                  onChange={(location) => handleGetLocation(location)}
                  onBlur={onBlur}
                  isDisabled={!canSelectLocation(role)}
                  value={originLocation}
                  options={originLocationOptions}
                  placeholder="Select location"
                  closeMenuOnSelect={true}
                />
                <FormErrorMessage>{error && error.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </PageCardComponent>
      </Stack>

      <Stack>
        <Stack direction={{ base: "column", xl: "row" }} alignItems="center">
          <Box>
            <TableSearchInput
              value={globalFilter ?? ""}
              onChange={(value) => setGlobalFilter(String(value))}
            />
          </Box>
          <Box>
            <FormControl display="flex" alignItems="end">
              <FormLabel htmlFor="edcl-products" mb="0">
                Only EDCL Products
              </FormLabel>
              <Switch onChange={(e) => setEdclProducts((value) => !value)} />
            </FormControl>
          </Box>
          <Spacer />

          <Box>
            <InputGroup className="dark-theme">
              <DatePicker
                id="dateStartEnd"
                selected={dateStart}
                onChange={(date) => onChangeHandler(date)}
                minDate={new Date("12-20-2022")}
                maxDate={new Date("12-12-2099")}
                dateFormat="dd MMM yyyy"
                className="react-datapicker__input-text"
                placeholderText="Select Date"
                customInput={<CustomInput />}
                showDisabledMonthNavigation
              />
              <InputRightElement color="gray.500" children={icon} />
            </InputGroup>
          </Box>

          <>
            {filterData && filterData.productOperationSummary.length > 0 && (
              <>
                <ButtonGroup gap="2">
                  <CSVLink
                    data={filterData?.productOperationSummary}
                    headers={headers}
                  >
                    <Button colorScheme="blue">Export CSV</Button>
                  </CSVLink>
                </ButtonGroup>
                <ButtonGroup gap="2">
                  <DailyProductReportPrintComponent
                    printData={filterData}
                    edclProducts={edclProducts}
                  />
                </ButtonGroup>
              </>
            )}
          </>
        </Stack>
      </Stack>

      {getDailyProductReportResult.isLoading ? (
        <LoaderComponent />
      ) : getDailyProductReportResult.isSuccess &&
        getDailyProductReportResult.data.data.productOperationSummary.length <=
          0 ? (
        <Center height="200px">
          <Text>
            No product history found in{" "}
            {dayjs(dateStart).format("dddd, MMMM DD, YYYY")}
          </Text>
        </Center>
      ) : (
        <Table variant="striped">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      bg="gray.700"
                      color="white"
                    >
                      {header.isPlaceholder ? null : (
                        <Box>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Box>
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Fragment key={row.id}>
                  <Tr>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                  {row.getIsExpanded() && (
                    <Tr>
                      <Td colSpan={row.getVisibleCells().length}>
                        {renderSubComponent({ row })}
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              );
            })}
          </Tbody>
        </Table>
      )}

      {getDailyProductReportResult.data?.data &&
        getDailyProductReportResult.data.data.productOperationSummary.length >
          0 && (
          <Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              justify="space-between"
              alignItems="center"
              mt="2"
            >
              <Box width={{ base: "100%", lg: "60%" }}>
                <Text fontSize="lg" fontWeight="bold">
                  Showing (Page {table.getState().pagination.pageIndex + 1} of{" "}
                  {table.getPageCount()})
                </Text>{" "}
              </Box>
              <Stack
                direction="row"
                width={{ base: "100%", lg: "40%" }}
                justifyContent="space-between"
              >
                <Stack direction="row" width={{ lg: "80%" }}>
                  <Button
                    colorScheme="blue"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {"<<"}
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {"<"}
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    {">"}
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                  >
                    {">>"}
                  </Button>{" "}
                </Stack>
                <Box width={{ base: "100%", md: "50%", lg: "100%" }}>
                  <SelectPageSize
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      table.setPageSize(Number(e.target.value));
                    }}
                  >
                    {displayAllTablePage.map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </SelectPageSize>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        )}
    </Stack>
  );
};

export default DailyProductReportComponent;
