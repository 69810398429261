import { useEffect, useRef, useState } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ButtonGroup,
  useToast,
  useDisclosure,
  Textarea,
  Text,
  Box
} from "@chakra-ui/react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { Select, GroupBase } from "chakra-react-select";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  OriginLocationGroup,
  ProductGroup
} from "../types/stock-operation.type";
import {
  useGetAssignUserLocationsQuery,
  useGetProductQuery,
  useInitialInventoryMutation
} from "../../../api";
import { useNavigate } from "react-router-dom";
import {
  RemoveSelectedProductFromAllProducts,
  userCurrentRole
} from "../../../utils/common-functions";
import { ConfirmAlertComponent } from "./confirm-alert.component";
import { StockOperationItems } from "../types/stock-operation.type";
import { ProductTrackingTypeOptions } from "../../../api/type";
import { OperationService } from "../../../service/operation/operation.service";

type InitialInventoryFormValues = {
  originLocation: OriginLocationGroup;
  stockOperationItems: StockOperationItems[];
  notes: string;
};

const InitialInventoryComponent = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const role = userCurrentRole();
  let id = localStorage.getItem("location");
  const initialInventoryConfirmAlert = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const getLocationQuery = useGetAssignUserLocationsQuery({});
  const getProductQuery = useGetProductQuery({ id: Number(id) });

  const [initialOperationCreate, initialOperationResult] =
    useInitialInventoryMutation();

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);
  const [originLocation, setOriginLocation] = useState<OriginLocationGroup>();
  const [productOptions, setProductOptions] = useState<ProductGroup[]>([]);

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(id))
      );
    }
  }, [id, originLocationOptions]);

  // TODO: Track this
  useEffect(() => {
    if (getProductQuery.data) {
      const productWithValues = getProductQuery.data.data.products.map(
        (product) => {
          return {
            label: product.name,
            value: product.id,
            type: product.trackingType,
            unit: product.unit
          };
        }
      );
      setProductOptions(productWithValues);
    }
  }, [getProductQuery.data]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors }
  } = useForm<InitialInventoryFormValues>({
    defaultValues: {
      stockOperationItems: [{}]
    },
    shouldUnregister: false
  });

  const { fields, append, remove } = useFieldArray<
    InitialInventoryFormValues,
    "stockOperationItems"
  >({
    control,
    name: "stockOperationItems"
  });

  const watch = useWatch({
    control,
    name: "stockOperationItems"
  });

  const initialInventoryHandleSubmit = handleSubmit((data) => {
    const allStockOperationItems = data.stockOperationItems.map(
      (singleItem) => {
        return {
          productId: singleItem.product.value,
          quantity: OperationService.getQuantity(
            singleItem.product.type as string,
            singleItem.quantity
          ),
          trackingNumber: singleItem.trackingNumber
        };
      }
    );
    initialOperationCreate({
      data: {
        stockOperation: {
          locationId: Number(id),
          stockOperationItems: allStockOperationItems,
          notes: data.notes
        }
      }
    });
  });

  useEffect(() => {
    if (initialOperationResult.isSuccess) {
      toast({
        title: "Success",
        description: "Initial inventory operation successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      navigate(`/${role}/report/location-operations`);
    }
  }, [initialOperationResult, toast, navigate, role]);

  return (
    <Stack>
      <form onSubmit={initialInventoryHandleSubmit}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          width="100%"
          bgColor={"white"}
          borderRadius="md"
          p={4}
          mb={4}
        >
          <FormControl mb="3">
            <FormLabel>Origin Location</FormLabel>
            <Input
              _disabled={{ color: "blackAlpha.900", cursor: "not-allowed" }}
              defaultValue={originLocation ? originLocation.label : ""}
              isDisabled={true}
            />
          </FormControl>
        </Stack>

        {fields.map((field, index) => {
          return (
            <Stack
              direction={{ base: "column", xl: "row" }}
              width="100%"
              bgColor={"white"}
              borderRadius="md"
              spacing={4}
              px={4}
              py={2}
              key={field.id}
            >
              <Box w={{ base: "100%", md: "100%", xl: "50%" }}>
                <Controller
                  control={control}
                  name={`stockOperationItems.${index}.product`}
                  rules={{ required: "Select product" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error }
                  }) => (
                    <FormControl
                      isInvalid={!!error}
                      id={`stockOperationItems.${index}.product`}
                    >
                      {index === 0 && <FormLabel>Select Product</FormLabel>}
                      <Select<ProductGroup, true, GroupBase<ProductGroup>>
                        key={`product${index}`}
                        name={name}
                        ref={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        options={RemoveSelectedProductFromAllProducts(
                          productOptions,
                          watch
                        )}
                        placeholder="Select Product"
                        closeMenuOnSelect={true}
                      />
                      <FormErrorMessage>
                        {error && error.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                <FormControl>
                  {index === 0 && <FormLabel>Unit</FormLabel>}
                  <Input
                    _disabled={{
                      color: "blackAlpha.700",
                      cursor: "not-allowed"
                    }}
                    defaultValue={watch[index]?.product?.unit}
                    disabled={true}
                    placeholder="Product unit"
                  />
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "15%" }}>
                <FormControl
                  isDisabled={
                    watch[index]?.product?.type ===
                    ProductTrackingTypeOptions.NONE
                      ? true
                      : false
                  }
                  isInvalid={
                    errors?.stockOperationItems?.[index]?.trackingNumber
                      ? true
                      : false
                  }
                >
                  {index === 0 && <FormLabel>Tracking Number</FormLabel>}
                  <Input
                    {...register(
                      `stockOperationItems.${index}.trackingNumber` as const,
                      {
                        validate: {
                          required: (value) => {
                            if (
                              watch[index]?.product?.type ===
                                ProductTrackingTypeOptions.NONE ||
                              value !== ""
                            )
                              return true;
                            return "Give Tracking data";
                          }
                        }
                      }
                    )}
                    key={`tracking${index}`}
                    type={"string"}
                    placeholder="Tracking Number"
                  />
                  <FormErrorMessage>
                    {
                      errors?.stockOperationItems?.[index]?.trackingNumber
                        ?.message
                    }
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "15%" }}>
                <FormControl
                  isInvalid={
                    errors?.stockOperationItems?.[index]?.quantity
                      ? true
                      : false
                  }
                >
                  {index === 0 && <FormLabel>Quantity</FormLabel>}
                  <Input
                    {...register(
                      `stockOperationItems.${index}.quantity` as const,
                      {
                        validate: {
                          required: (value) => {
                            if (
                              watch[index]?.product?.type ===
                                ProductTrackingTypeOptions.SERIALIZED ||
                              value > 0
                            )
                              return true;
                            return "Give Amount";
                          }
                        }
                      }
                    )}
                    key={`quantity${index}`}
                    type={"number"}
                    placeholder="Enter product quantity"
                    disabled={
                      watch[index]?.product?.type === "SERIALIZED"
                        ? true
                        : false
                    }
                    defaultValue={
                      watch[index]?.product?.type === "SERIALIZED" ? 1 : ""
                    }
                  />
                  <FormErrorMessage>
                    {errors?.stockOperationItems?.[index]?.quantity?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>

              <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                <ButtonGroup>
                  <Button
                    mt={index === 0 ? 8 : 0}
                    colorScheme="red"
                    variant="outline"
                    leftIcon={<FaTimes />}
                    onClick={() => remove(index)}
                    isDisabled={index > 0 ? false : true}
                  >
                    Remove
                  </Button>
                </ButtonGroup>
              </Box>
            </Stack>
          );
        })}
        <Stack spacing={4}>
          <Button
            my="2"
            width="100%"
            colorScheme="blue"
            variant="outline"
            leftIcon={<FaPlus />}
            onClick={() => append({})}
          >
            Add
          </Button>

          <Textarea
            placeholder="Enter notes for this Operation"
            {...register("notes")}
          />

          <Button
            width="100%"
            colorScheme="blue"
            onClick={initialInventoryConfirmAlert.onOpen}
          >
            Submit
          </Button>
          <ConfirmAlertComponent
            cancelRef={cancelRef}
            onClose={initialInventoryConfirmAlert.onClose}
            isOpen={initialInventoryConfirmAlert.isOpen}
            onOpen={initialInventoryConfirmAlert.onOpen}
            handleApprove={initialInventoryHandleSubmit}
            status={initialOperationResult}
          />
        </Stack>
      </form>
    </Stack>
  );
};
export default InitialInventoryComponent;
